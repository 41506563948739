// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  className: string,
};

function MonitorModel(props: Props): Node {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 550 440"
      enableBackground="new 0 0 550 440"
      xmlSpace="preserve"
      className={props.className}
    >
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="799.5225"
          y1="-507.9004"
          x2="973.4827"
          y2="-507.9004"
          gradientTransform="matrix(1 0 0 -1 -607.9805 -70.8799)"
        >
          <stop offset="0.0061" stopColor="#58595B" />
          <stop offset="0.2803" stopColor="#757679" />
          <stop offset="0.5091" stopColor="#88898D" />
          <stop offset="1" stopColor="#58595B" />
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M192.598,434.041v1.071c0,0.056,0,0.106,0,0.162v0.061c0,3.185,4.455,4.665,17.977,4.665
        c26.166,0,54.507,0,64.879,0c2.625,0,4.097,0,4.097,0c10.373,0,38.715,0,64.881,0c13.521,0,18.246-1.986,18.291-4.966h-0.358
        v-0.993H192.598z"
        />

        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="884.5723"
          y1="-442.8438"
          x2="884.5723"
          y2="-518.8727"
          gradientTransform="matrix(1 0 0 -1 -607.9805 -70.8799)"
        >
          <stop offset="0.0848" stopColor="#6D6F73" />
          <stop offset="0.127" stopColor="#97999C" />
          <stop offset="0.1841" stopColor="#CCCDCE" />
          <stop offset="0.2121" stopColor="#E0E1E2" />
          <stop offset="0.3152" stopColor="#A7A9AC" />
          <stop offset="0.3893" stopColor="#868789" />
          <stop offset="0.4606" stopColor="#636363" />
          <stop offset="0.5264" stopColor="#6E6F6F" />
          <stop offset="0.6452" stopColor="#8B8D8F" />
          <stop offset="0.7394" stopColor="#A6AAAD" />
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M345.344,415.691c-12.063-12.301-12.521-39.26-12.521-39.26h-54.181h-4.097h-54.182
        c0,0-0.455,26.959-12.521,39.26c-12.065,12.299-30.96,23.313,1.821,23.313c26.166,0,54.507,0,64.881,0c2.623,0,4.098,0,4.098,0
        c10.373,0,38.715,0,64.881,0C376.303,439.007,357.408,427.99,345.344,415.691z"
        />

        <radialGradient
          id="SVGID_3_"
          cx="967.1172"
          cy="-543.043"
          r="64.1901"
          gradientTransform="matrix(0.9928 0 0 -0.9932 -600.4538 -71.2543)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#FFFFFF" />
          <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
        </radialGradient>
        <path
          fill="url(#SVGID_3_)"
          d="M287.371,439.007c6.693-14.896,28.074-26.847,54.674-29.448c1.219,2.068,2.609,3.944,4.211,5.577
        c11.854,12.081,30.295,22.878-0.146,23.871H287.371z"
        />

        <radialGradient
          id="SVGID_4_"
          cx="1718.3662"
          cy="-543.043"
          r="64.1903"
          gradientTransform="matrix(-0.9928 0 0 -0.9932 1901.2957 -71.2543)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#FFFFFF" />
          <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
        </radialGradient>
        <path
          fill="url(#SVGID_4_)"
          d="M267.639,439.007c-6.694-14.896-28.075-26.847-54.677-29.448c-1.218,2.068-2.609,3.944-4.209,5.577
        c-11.854,12.081-30.297,22.878,0.146,23.871H267.639z"
        />

        <linearGradient
          id="SVGID_5_"
          gradientUnits="userSpaceOnUse"
          x1="816.8789"
          y1="-510.0112"
          x2="952.4111"
          y2="-510.0112"
          gradientTransform="matrix(1 0 0 -1 -607.9805 -70.8799)"
        >
          <stop offset="0.0061" stopColor="#FFFFFF" stopOpacity="0" />
          <stop offset="0.5091" stopColor="#FFFFFF" />
          <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
        </linearGradient>
        <path
          fill="url(#SVGID_5_)"
          d="M208.898,439.131c11.293-0.188,22.589-0.274,33.884-0.35l33.884-0.105l33.881,0.104l16.943,0.128
        l16.94,0.224l-16.94,0.226l-16.943,0.126l-33.881,0.104l-33.884-0.104C231.487,439.405,220.191,439.318,208.898,439.131z"
        />

        <linearGradient
          id="SVGID_6_"
          gradientUnits="userSpaceOnUse"
          x1="598.375"
          y1="-442.605"
          x2="1196.2743"
          y2="-403.4135"
          gradientTransform="matrix(1 0 0 -1 -607.9805 -70.8799)"
        >
          <stop offset="0" stopColor="#78797D" />
          <stop offset="0.3173" stopColor="#737478" />
          <stop offset="0.4909" stopColor="#6D6F73" />
          <stop offset="1" stopColor="#6D6F73" />
        </linearGradient>
        <path
          fill="url(#SVGID_6_)"
          d="M0,360.784c0,9.057,7.793,16.643,16.846,16.643h254.056h8.651H533.61
        c9.053,0,16.391-7.586,16.391-16.643v-31.032H0V360.784z"
        />
        <path
          d="M533.609,0H279.553h-8.651H16.846C7.792,0,0,6.456,0,15.513v313.246h550V15.513C550,6.456,542.66,0,533.609,0z
         M527.166,305.914H22.833V21.851h504.333V305.914z"
        />

        <linearGradient
          id="SVGID_7_"
          gradientUnits="userSpaceOnUse"
          x1="598.4277"
          y1="-441.6074"
          x2="1196.2677"
          y2="-402.4199"
          gradientTransform="matrix(1 0 0 -1 -607.9805 -70.8799)"
        >
          <stop offset="0" stopColor="#898A8F" />
          <stop offset="0.0499" stopColor="#909196" />
          <stop offset="0.3137" stopColor="#B1B2B6" />
          <stop offset="0.5663" stopColor="#C8CACD" />
          <stop offset="0.801" stopColor="#D6D8DB" />
          <stop offset="1" stopColor="#DBDDE0" />
        </linearGradient>
        <path
          fill="url(#SVGID_7_)"
          d="M0,358.962c0,9.058,7.793,17.472,16.846,17.472h254.056h8.651H533.61
        c9.053,0,16.391-8.414,16.391-17.472v-30.203H0V358.962z"
        />
      </g>
    </svg>
  );
}

export default MonitorModel;
