// @flow
import React from 'react';
import type { Node } from 'react';

function DesignLogo(): Node {
  return (
    <svg
      className="logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 581.24 240"
    >
      <path d="M179.37,190.34v-2.29h3.16q5.33,0,7.56-3.41c.9-1.36,1.36-4.42,1.36-9.17V121.23c0-5.24-.58-8.53-1.73-9.85q-2.41-2.72-7.19-2.72h-3.16v-2.29h34.19q18.83,0,28.64,4.27A34.16,34.16,0,0,1,258,124.88a44,44,0,0,1,6,23q0,17.52-10.65,29.29-12,13.13-36.41,13.13Zm24-6.07A63.59,63.59,0,0,0,216.53,186q14.37,0,23.84-10.09t9.47-27.37q0-17.4-9.47-27.44t-24.34-10a52.49,52.49,0,0,0-12.69,1.8Z" />
      <path d="M277.87,136.11a49.27,49.27,0,0,0,3.28-5.28,23.24,23.24,0,0,0,2.17-5.66,15.15,15.15,0,0,0,.21-5.85,13.86,13.86,0,0,0-2.45-5.81c-.2.29-.4.61-.61,1a4.74,4.74,0,0,1-.75,1,3.85,3.85,0,0,1-2.89,1.24,4.1,4.1,0,0,1-2.88-1.31,5.4,5.4,0,0,1-1.71-3.5,4.57,4.57,0,0,1,1.57-3.63,5.55,5.55,0,0,1,4.56-1.85,7.09,7.09,0,0,1,4.49,2.2,11,11,0,0,1,3.24,6.6,21,21,0,0,1-.53,7.73,34.22,34.22,0,0,1-3,7.59,43.37,43.37,0,0,1-4,6.31Z" />
      <path d="M299.11,190.34v-2.29h3.16q5.33,0,7.56-3.41c.9-1.36,1.36-4.42,1.36-9.17V121.23c0-5.24-.58-8.53-1.73-9.85q-2.41-2.72-7.19-2.72h-3.16v-2.29H333.3q18.82,0,28.64,4.27a34.16,34.16,0,0,1,15.79,14.24,44,44,0,0,1,6,23q0,17.52-10.65,29.29-11.94,13.13-36.41,13.13Zm24-6.07A63.59,63.59,0,0,0,336.27,186q14.37,0,23.84-10.09t9.47-27.37q0-17.4-9.47-27.44t-24.34-10a52.49,52.49,0,0,0-12.69,1.8Z" />
      <path d="M424.64,182.16q-8.75,6.75-11,7.8a16.67,16.67,0,0,1-7.13,1.55,12.77,12.77,0,0,1-9.69-4,14.79,14.79,0,0,1-3.81-10.59,13.44,13.44,0,0,1,1.86-7.18q2.54-4.22,8.83-7.93t20.9-9v-2.23q0-8.49-2.7-11.64t-7.83-3.16a8.79,8.79,0,0,0-6.19,2.1,6.36,6.36,0,0,0-2.36,4.83l.13,3.6a6.17,6.17,0,0,1-1.46,4.39,5,5,0,0,1-3.81,1.55,4.83,4.83,0,0,1-3.74-1.61,6.32,6.32,0,0,1-1.46-4.4q0-5.33,5.45-9.78t15.3-4.46q7.54,0,12.38,2.54a11.66,11.66,0,0,1,5.39,6q1.11,2.65,1.11,10.9v19.25a89.9,89.9,0,0,0,.31,9.94,4.16,4.16,0,0,0,1,2.45,2.43,2.43,0,0,0,1.64.62,3.37,3.37,0,0,0,1.73-.44,37.83,37.83,0,0,0,5-4.52v3.47q-6.94,9.28-13.26,9.29a6,6,0,0,1-4.83-2.11Q424.71,187.24,424.64,182.16Zm0-4V156.52a123,123,0,0,0-12.08,5.27,20.89,20.89,0,0,0-7,5.7,11,11,0,0,0-2.1,6.5,10.63,10.63,0,0,0,2.66,7.4,8.06,8.06,0,0,0,6.13,2.94Q417,184.33,424.64,178.14Z" />
      <path d="M465.38,131.94V144.7q7.13-12.76,14.62-12.76a7.94,7.94,0,0,1,5.63,2.07,6.39,6.39,0,0,1,2.23,4.8,5.68,5.68,0,0,1-1.61,4.09,5.12,5.12,0,0,1-3.84,1.67,8.21,8.21,0,0,1-4.86-2.13c-1.79-1.43-3.13-2.14-4-2.14a3.67,3.67,0,0,0-2.42,1.24,29.69,29.69,0,0,0-5.76,8.36v27.18a16.69,16.69,0,0,0,1.18,7.12,6.41,6.41,0,0,0,2.85,2.79,12.52,12.52,0,0,0,5.88,1.12v2.23h-29v-2.23c2.89,0,5-.46,6.44-1.37a5.32,5.32,0,0,0,2.17-3.15,32.58,32.58,0,0,0,.31-6v-22q0-9.91-.41-11.8a4.65,4.65,0,0,0-1.48-2.76,4.24,4.24,0,0,0-2.7-.86,12.4,12.4,0,0,0-4.33.92l-.62-2.22,17.15-6.94Z" />
      <path d="M510.59,131.94v45.58q0,5.32.77,7.09a5.49,5.49,0,0,0,2.29,2.63q1.51.87,5.55.87v2.23H491.64v-2.23q4.14,0,5.57-.81a5.44,5.44,0,0,0,2.26-2.66q.84-1.86.84-7.12V155.66q0-9.22-.56-11.95a4.89,4.89,0,0,0-1.36-2.76,3.83,3.83,0,0,0-2.54-.77,12.55,12.55,0,0,0-4.21.92l-.87-2.22,17.09-6.94Zm-5.14-29.66a6.23,6.23,0,0,1,6.25,6.25,6.15,6.15,0,0,1-1.82,4.46,6,6,0,0,1-4.43,1.86,6.35,6.35,0,0,1-6.32-6.32,6,6,0,0,1,1.83-4.42A6.09,6.09,0,0,1,505.45,102.28Z" />
      <path d="M554,131.94q12.87,0,20.68,9.79a30,30,0,0,1,6.63,19.19,36.09,36.09,0,0,1-3.66,15.42,25.93,25.93,0,0,1-24.33,15.73,24.05,24.05,0,0,1-20.38-10.22,31.68,31.68,0,0,1-6.38-19.32A34.17,34.17,0,0,1,530.44,147a26.46,26.46,0,0,1,10.18-11.4A26.19,26.19,0,0,1,554,131.94Zm-1.92,4a12.93,12.93,0,0,0-6.6,1.95q-3.32,2-5.35,6.84a32.76,32.76,0,0,0-2,12.57q0,12.39,4.93,21.36t13,9a12.1,12.1,0,0,0,9.91-4.95q3.9-5,3.9-17,0-15.1-6.5-23.78Q558.88,136,552.08,136Z" />
      <path d="M101.76,50.74a66.86,66.86,0,0,0-31.44-9.39H31.43a4.11,4.11,0,1,0,0,8.21H70.32a59.45,59.45,0,0,1,27.22,8.21c18.14,10.89,27.33,30.43,27.33,58.08s-9.12,47-27.12,57.94a59.67,59.67,0,0,1-27.43,8.34H35.53V160.79H65.77c.38,0,38.13-.52,38.13-44.94S66.15,70.9,65.77,70.9H31.43A4.11,4.11,0,0,0,27.32,75v29.76H4.16a4.11,4.11,0,0,0,0,8.21H31.43a4.11,4.11,0,0,0,4.1-4.1V79.11H65.77c1.22,0,29.93.42,29.93,36.74C95.7,152,67,152.57,65.77,152.58H31.43a4.11,4.11,0,0,0-4.11,4.1v29.55a4.12,4.12,0,0,0,4.11,4.11H70.32A66.86,66.86,0,0,0,101.76,181c25.89-15.53,31.32-44,31.32-65.1S127.65,66.27,101.76,50.74Z" />
      <path d="M67.45,129.75a4.11,4.11,0,0,0-4.1-4.1H4.16a4.11,4.11,0,0,0,0,8.21H63.35A4.11,4.11,0,0,0,67.45,129.75Z" />
    </svg>
  );
}

export default DesignLogo;
