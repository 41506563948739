// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  className?: string,
};

function Vinyl(props: Props): Node {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 365 355"
      className={props.className}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6,177.4l0.1-4.5l0.1-4.5l0.3-4.5l0.4-4.4l0.5-4.5l0.6-4.4l0.8-4.3l0.9-4.4l0.9-4.3l1-4.1l1.1-4.3
l1.3-4.1l1.4-4.1l1.4-4l1.5-4l1.6-4l1.8-3.9l1.8-3.9l1.9-3.8l2-3.8l2.1-3.8l2.1-3.6l2.3-3.6l2.3-3.5l2.5-3.4l2.5-3.5l2.5-3.3
l2.6-3.4l2.8-3.1l2.8-3.1l2.9-3.1l3-3l3-3l3-2.8l3.1-2.9l3.3-2.8l3.3-2.6l3.4-2.5l3.4-2.5l3.5-2.4l3.5-2.4l3.6-2.3l3.6-2.1l3.6-2
l3.8-2l3.9-1.9l3.9-1.9l3.9-1.6l4-1.6l4-1.5l4-1.5l4.1-1.4l4.1-1.1l4.1-1.1l4.3-1.1l4.3-0.9l4.3-0.9l4.4-0.6l4.4-0.6l4.4-0.5
l4.5-0.4l4.4-0.4l4.5-0.1l4.5-0.1l4.7,0.1l4.5,0.1l4.4,0.4l4.5,0.4l4.4,0.5l4.4,0.6l4.4,0.6l4.3,0.9l4.3,0.9l4.3,1.1l4.1,1.1
l4.1,1.1l4.1,1.4l4,1.5l4,1.5l4,1.6l3.9,1.6l3.9,1.9l3.8,1.9l3.8,2l3.8,2l3.6,2.1l3.6,2.3l3.5,2.4l3.5,2.4l3.4,2.5l3.4,2.5
l3.3,2.6l3.3,2.8l3.1,2.9l3,2.8l3,3l3,3l2.9,3.1l2.8,3.1l2.8,3.1l2.6,3.4l2.5,3.3l2.5,3.5l2.4,3.4l2.4,3.5l2.3,3.6l2.1,3.6
l2.1,3.8l1.9,3.8l2,3.8l1.8,3.9l1.8,3.9l1.6,4l1.5,4l1.4,4l1.4,4.1l1.3,4.1l1.1,4.3l1,4.1l0.9,4.3l0.9,4.4l0.8,4.3l0.6,4.4
l0.5,4.5l0.4,4.4l0.3,4.5l0.1,4.5l0.1,4.5l-0.1,4.5l-0.1,4.5l-0.3,4.5l-0.4,4.4l-0.5,4.4l-0.6,4.4l-0.8,4.4l-0.9,4.3l-0.9,4.3
l-1,4.3l-1.1,4.1l-1.3,4.3l-1.4,4l-1.4,4.1l-1.5,4l-1.6,3.9l-1.8,4l-1.8,3.8l-2,3.9l-1.9,3.8l-2.1,3.6l-2.1,3.6l-2.3,3.6
l-2.4,3.5l-2.4,3.5l-2.5,3.4l-2.5,3.4l-2.6,3.3l-2.8,3.3l-2.8,3.1l-2.9,3.1l-3,3l-3,2.9l-3,2.9l-3.1,2.8l-3.3,2.8l-3.3,2.6
l-3.4,2.6l-3.4,2.5l-3.5,2.4l-3.5,2.3L278,328l-3.6,2.1l-3.8,2.1l-3.8,2l-3.8,1.9l-3.9,1.8l-3.9,1.8l-4,1.6l-4,1.5l-4,1.4
l-4.1,1.4l-4.1,1.3l-4.1,1.1l-4.3,1l-4.3,1l-4.3,0.8l-4.4,0.8l-4.4,0.6l-4.4,0.5l-4.5,0.4l-4.4,0.3l-4.5,0.3h-4.7h-4.5l-4.5-0.3
l-4.4-0.3l-4.5-0.4l-4.4-0.5l-4.4-0.6l-4.4-0.8l-4.3-0.8l-4.3-1l-4.3-1l-4.1-1.1l-4.1-1.3l-4.1-1.4l-4-1.4l-4-1.5l-4-1.6
l-3.9-1.8l-3.9-1.8l-3.9-1.9l-3.8-2l-3.6-2.1l-3.6-2.1l-3.6-2.3l-3.5-2.3l-3.5-2.4l-3.4-2.5l-3.4-2.6l-3.3-2.6l-3.3-2.8l-3.1-2.8
l-3-2.9l-3-2.9l-3-3l-2.9-3.1l-2.8-3.1l-2.8-3.3l-2.6-3.3l-2.5-3.4l-2.5-3.4l-2.5-3.5l-2.3-3.5l-2.3-3.6l-2.1-3.6l-2.1-3.6
l-2-3.8l-1.9-3.9l-1.8-3.8l-1.8-4l-1.6-3.9l-1.5-4l-1.4-4.1l-1.4-4l-1.3-4.3l-1.1-4.1l-1-4.3l-0.9-4.3l-0.9-4.3l-0.8-4.4
l-0.6-4.4l-0.5-4.4l-0.4-4.4l-0.3-4.5l-0.1-4.5L10.6,177.4z M0.5,177.4l0.1-4.5l0.1-4.5l0.3-4.5l0.4-4.4l0.5-4.5l0.6-4.4l0.8-4.3
l0.9-4.4l0.9-4.3l1-4.1l1.1-4.3l1.3-4.1l1.4-4.1l1.4-4l1.5-4l1.6-4l1.8-3.9l1.8-3.9l1.9-3.8l2-3.8l2.1-3.8L26,86l2.3-3.6l2.3-3.5
l2.5-3.4l2.5-3.5l2.5-3.3l2.6-3.4l2.8-3.1l2.8-3.1l2.9-3.1l3-3l3-3l3-2.8l3.1-2.9l3.3-2.8l3.3-2.6l3.4-2.5l3.4-2.5l3.5-2.4
l3.5-2.4l3.6-2.3l3.6-2.1l3.6-2l3.8-2l3.9-1.9l3.9-1.9l3.9-1.6l4-1.6l4-1.5l4-1.5l4.1-1.4l4.1-1.1l4.1-1.1l4.3-1.1l4.3-0.9
l4.3-0.9l4.4-0.6l4.4-0.6l4.4-0.5l4.5-0.4l4.4-0.4l4.5-0.1l4.5-0.1l4.7,0.1l4.5,0.1l4.4,0.4l4.5,0.4l4.4,0.5l4.4,0.6l4.4,0.6
l4.3,0.9l4.3,0.9l4.3,1.1l4.1,1.1l4.1,1.1l4.1,1.4l4,1.5l4,1.5l4,1.6l3.9,1.6l3.9,1.9l3.8,1.9l3.8,2l3.8,2l3.6,2.1l3.6,2.3
l3.5,2.4l3.5,2.4l3.4,2.5l3.4,2.5l3.3,2.6l3.3,2.8l3.1,2.9l3,2.8l3,3l3,3l2.9,3.1l2.8,3.1l2.8,3.1l2.6,3.4l2.5,3.3l2.5,3.5
l2.4,3.4l2.4,3.5l2.3,3.6l2.1,3.6l2.1,3.8l1.9,3.8l2,3.8l1.8,3.9l1.8,3.9l1.6,4l1.5,4l1.4,4l1.4,4.1l1.3,4.1l1.1,4.3l1,4.1
l0.9,4.3l0.9,4.4l0.8,4.3l0.6,4.4l0.5,4.5l0.4,4.4l0.3,4.5l0.1,4.5l0.1,4.5l-0.1,4.5l-0.1,4.5l-0.3,4.5l-0.4,4.4l-0.5,4.4
l-0.6,4.4l-0.8,4.4l-0.9,4.3l-0.9,4.3l-1,4.3l-1.1,4.1l-1.3,4.3l-1.4,4l-1.4,4.1l-1.5,4l-1.6,3.9l-1.8,4l-1.8,3.8l-2,3.9
l-1.9,3.8l-2.1,3.6l-2.1,3.6l-2.3,3.6l-2.4,3.5l-2.4,3.5l-2.5,3.4l-2.5,3.4l-2.6,3.3l-2.8,3.3l-2.8,3.1l-2.9,3.1l-3,3l-3,2.9
l-3,2.9l-3.1,2.8l-3.3,2.8l-3.3,2.6l-3.4,2.6l-3.4,2.5l-3.5,2.4l-3.5,2.3L268,328l-3.6,2.1l-3.8,2.1l-3.8,2l-3.8,1.9l-3.9,1.8
l-3.9,1.8l-4,1.6l-4,1.5l-4,1.4l-4.1,1.4l-4.1,1.3l-4.1,1.1l-4.3,1l-4.3,1l-4.3,0.8l-4.4,0.8l-4.4,0.6l-4.4,0.5l-4.5,0.4
l-4.4,0.3l-4.5,0.3h-4.7h-4.5l-4.5-0.3l-4.4-0.3l-4.5-0.4l-4.4-0.5l-4.4-0.6l-4.4-0.8l-4.3-0.8l-4.3-1l-4.3-1l-4.1-1.1l-4.1-1.3
l-4.1-1.4l-4-1.4l-4-1.5l-4-1.6l-3.9-1.8l-3.9-1.8l-3.9-1.9l-3.8-2l-3.6-2.1l-3.6-2.1l-3.6-2.3l-3.5-2.3l-3.5-2.4l-3.4-2.5
l-3.4-2.6l-3.3-2.6l-3.3-2.8l-3.1-2.8l-3-2.9l-3-2.9l-3-3l-2.9-3.1l-2.8-3.1l-2.8-3.3l-2.6-3.3l-2.5-3.4l-2.5-3.4l-2.5-3.5
l-2.3-3.5l-2.3-3.6l-2.1-3.6l-2.1-3.6l-2-3.8l-1.9-3.9l-1.8-3.8l-1.8-4l-1.6-3.9l-1.5-4l-1.4-4.1l-1.4-4l-1.3-4.3L6,221.4l-1-4.3
l-0.9-4.3l-0.9-4.3l-0.8-4.4l-0.6-4.4l-0.5-4.4L1,191l-0.3-4.5l-0.1-4.5L0.5,177.4z"
        />
        <path
          d="M10.1,177.4l0.3-9.1l0.6-9.2l0.5-4.4l0.6-4.4l1.5-8.7l2-8.5l2.4-8.3l2.8-8.3l3.1-8l3.5-7.8l3.9-7.5l4.4-7.4l4.5-7.2
l4.9-6.9l2.6-3.3l2.6-3.4l5.5-6.5l5.8-6l6.2-5.9l6.4-5.5l6.7-5.2l7-4.9l7.2-4.7l7.3-4.3l7.7-3.8l7.8-3.6l8-3.3l8.3-2.8l8.4-2.4
l8.5-1.9l8.7-1.6l8.8-1l8.9-0.8l9-0.3v1l-9,0.3l-8.9,0.8l-8.7,1l-8.7,1.6l-8.5,1.9l-8.3,2.4l-8.3,2.8l-7.8,3.1l-7.8,3.6l-7.7,3.8
l-7.3,4.3l-7.2,4.7l-6.9,4.8l-6.7,5.2l-6.4,5.5l-6.2,5.9l-5.8,6l-5.4,6.4L48.5,69l-2.6,3.3L41,79.2l-4.5,7.2l-4.3,7.3l-3.9,7.5
l-3.5,7.8l-3.1,8l-2.8,8.2l-2.4,8.3l-2,8.5l-1.5,8.5l-0.6,4.4l-0.5,4.4l-0.6,8.9l-0.3,9.1H10.1z"
        />
        <path
          d="M186.7,0.6l9.1,0.3l9.1,0.8l4.4,0.5l4.4,0.5l8.8,1.6l8.5,1.9l8.3,2.4l8.2,2.8l8.2,3.3l7.8,3.6l7.5,3.8l7.4,4.3l7.2,4.7
l6.9,4.9l3.3,2.5L299,41l6.4,5.5l6.2,6l5.9,6L323,65l5.2,6.7l4.9,6.9l4.7,7.2l4.3,7.4l3.8,7.5l3.6,7.8l3.3,8.2l2.8,8.2l2.4,8.3
l1.9,8.5l1.6,8.8l1,8.8l0.8,9.1l0.3,9.1h-1l-0.3-9.1l-0.8-8.9l-1-8.8l-1.6-8.7l-1.9-8.5l-2.4-8.3l-2.8-8.2l-3.1-7.9l-3.6-7.8
l-3.8-7.5l-4.3-7.4l-4.7-7.2l-4.8-6.8l-5.2-6.7l-5.5-6.4l-5.9-6l-6-5.9l-6.4-5.5l-3.4-2.6l-3.3-2.5l-6.8-4.8l-7.2-4.7l-7.4-4.3
l-7.5-3.8l-7.8-3.6l-7.9-3.1l-8.2-2.8l-8.3-2.4l-8.5-1.9l-8.7-1.6L209,3.1l-4.4-0.5l-8.9-0.8l-9.1-0.3V0.6z"
        />
        <path
          d="M363.4,177.4l-0.3,9.1l-0.8,8.9l-0.5,4.4l-0.5,4.4l-1.6,8.7l-1.9,8.5l-2.4,8.4l-2.8,8.3l-3.3,8l-3.6,7.8l-3.8,7.7
l-4.3,7.3l-4.7,7.2l-4.9,7l-2.5,3.4l-2.6,3.3l-5.5,6.4l-5.9,6.2l-6.2,5.8l-6.4,5.5l-6.7,5.3l-6.9,4.9l-7.2,4.5l-7.4,4.4l-7.5,3.9
l-7.8,3.5l-8,3.1l-8.3,2.8l-8.3,2.4l-8.5,2l-8.7,1.5l-8.8,1.1l-9.2,0.6l-9.1,0.3v-0.9l9.1-0.3l8.9-0.6l8.8-1.1l8.5-1.5l8.5-2
l8.3-2.4l8.2-2.8l8-3.1l7.8-3.5l7.5-3.9l7.3-4.3l7.2-4.5l6.9-4.9l6.7-5.3l6.4-5.5l6-5.7l5.9-6.2l5.5-6.4l2.6-3.3l2.5-3.4l4.8-6.9
l4.7-7.2l4.3-7.3l3.8-7.7l3.6-7.8l3.1-7.8l2.8-8.3l2.4-8.3l1.9-8.5l1.6-8.7l0.5-4.4l0.5-4.3l0.8-8.9l0.3-9.1H363.4z"
        />
        <path
          d="M186.7,354l-9-0.3l-8.9-0.6l-4.5-0.5l-4.4-0.6l-8.7-1.5l-8.5-2l-8.4-2.4l-8.3-2.8l-7.9-3.1l-7.8-3.5l-7.7-3.9l-7.4-4.4
l-7.2-4.5l-6.9-4.9l-3.4-2.6l-3.3-2.6l-6.4-5.5l-6.2-5.8l-5.8-6.2l-5.5-6.4l-5.3-6.7l-4.9-6.9l-4.5-7.2l-4.4-7.4l-3.9-7.7
l-3.5-7.8l-3.1-7.9l-2.8-8.3l-2.4-8.4l-2-8.5l-1.5-8.7l-1.1-8.9l-0.6-8.9l-0.3-9.1h0.9l0.3,9.1l0.6,8.9l1.1,8.7l1.5,8.7l2,8.5
l2.4,8.3l2.8,8.3l3.1,7.8l3.5,7.8l3.9,7.7l4.3,7.3l4.5,7.2l4.9,6.9l5.3,6.7l5.4,6.3l5.8,6.2l6.2,5.8l6.4,5.5l3.3,2.6l3.4,2.6
l6.9,4.9l7.2,4.5l7.3,4.3l7.7,3.9l7.8,3.5l7.8,3.1l8.3,2.8l8.3,2.4l8.5,2l8.7,1.5l4.4,0.6l4.3,0.5l8.9,0.6l9,0.3V354z"
        />
        <path
          d="M0,177.4l0.3-9.1l0.6-9.2l0.5-4.4l0.6-4.4l1.5-8.7l2-8.5l2.4-8.3l2.8-8.3l3.1-8l3.5-7.8l3.9-7.5l4.4-7.4l4.5-7.2l4.9-6.9
l2.6-3.3l2.6-3.4l5.5-6.5l5.8-6l6.2-5.9l6.4-5.5l6.7-5.2l7-4.9l7.2-4.7l7.3-4.3l7.7-3.8l7.8-3.6l8-3.3l8.3-2.8l8.4-2.4l8.5-1.9
l8.7-1.6l8.8-1l8.9-0.8l9.1-0.3v1l-9.1,0.3l-8.9,0.8l-8.7,1l-8.7,1.6l-8.5,1.9l-8.3,2.4l-8.3,2.8l-7.8,3.1l-7.8,3.6l-7.7,3.8
l-7.3,4.3l-7.2,4.7l-6.9,4.8l-6.7,5.2l-6.4,5.5l-6.2,5.9l-5.8,6l-5.4,6.4L38.5,69l-2.6,3.3l-4.9,6.9l-4.5,7.2l-4.3,7.3l-3.9,7.5
l-3.5,7.8l-3.1,8l-2.8,8.2l-2.4,8.3l-2,8.5l-1.5,8.5L2.3,155l-0.5,4.4l-0.6,8.9l-0.3,9.1H0z"
        />
        <path
          d="M176.6,0.6l9,0.3l9.1,0.8l4.4,0.5l4.4,0.5l8.8,1.6l8.5,1.9l8.3,2.4l8.2,2.8l8.2,3.3l7.8,3.6l7.5,3.8l7.4,4.3l7.2,4.7
l6.9,4.9l3.3,2.5L289,41l6.4,5.5l6.2,6l5.9,6L313,65l5.2,6.7l4.9,6.9l4.6,7.2l4.3,7.4l3.8,7.5l3.6,7.8l3.3,8.2l2.8,8.2l2.4,8.3
l1.9,8.5l1.6,8.8l1,8.8l0.8,9.1l0.3,9.1h-1l-0.3-9.1l-0.8-8.9l-1-8.8l-1.6-8.7l-1.9-8.5l-2.4-8.3l-2.8-8.2l-3.1-7.9l-3.6-7.8
l-3.8-7.5l-4.3-7.4l-4.7-7.2l-4.8-6.8l-5.2-6.7l-5.5-6.4l-5.9-6l-6-5.9l-6.4-5.5l-3.4-2.6l-3.3-2.5l-6.8-4.8l-7.2-4.7l-7.4-4.3
l-7.5-3.8l-7.8-3.6l-7.9-3.1l-8.2-2.8l-8.3-2.4L212,5.3l-8.7-1.6L199,3.1l-4.4-0.5l-8.9-0.8l-9-0.3V0.6z"
        />
        <path
          d="M353.3,177.4l-0.3,9.1l-0.8,8.9l-0.5,4.4l-0.5,4.4l-1.6,8.7l-1.9,8.5l-2.4,8.4l-2.8,8.3l-3.3,8l-3.6,7.8l-3.8,7.7
l-4.3,7.3l-4.6,7.2l-4.9,7l-2.5,3.4l-2.6,3.3l-5.5,6.4l-5.9,6.2l-6.2,5.8l-6.4,5.5l-6.7,5.3l-6.9,4.9l-7.2,4.5l-7.4,4.4l-7.5,3.9
l-7.8,3.5l-8,3.1l-8.3,2.8l-8.3,2.4l-8.5,2l-8.7,1.5l-8.8,1.1l-9.2,0.6l-9,0.3v-0.9l9-0.3l8.9-0.6l8.8-1.1l8.5-1.5l8.5-2l8.3-2.4
l8.2-2.8l8-3.1l7.8-3.5l7.5-3.9l7.3-4.3l7.2-4.5l6.9-4.9l6.7-5.3l6.4-5.5l6-5.7l5.9-6.2l5.5-6.4l2.6-3.3l2.5-3.4l4.8-6.9l4.7-7.2
l4.3-7.3l3.8-7.7l3.6-7.8l3.1-7.8l2.8-8.3l2.4-8.3l1.9-8.5l1.6-8.7l0.5-4.4l0.5-4.3l0.8-8.9l0.3-9.1H353.3z"
        />
        <path
          d="M176.6,354l-9.1-0.3l-8.9-0.6l-4.5-0.5l-4.4-0.6l-8.7-1.5l-8.5-2l-8.4-2.4l-8.3-2.8l-7.9-3.1l-7.8-3.5l-7.7-3.9l-7.4-4.4
l-7.2-4.5l-6.9-4.9l-3.4-2.6l-3.3-2.6l-6.4-5.5l-6.2-5.8l-5.8-6.2l-5.5-6.4l-5.3-6.7l-4.9-6.9l-4.5-7.2l-4.4-7.4l-3.9-7.7
l-3.5-7.8l-3.1-7.9l-2.8-8.3l-2.4-8.4l-2-8.5L2,204.3l-1.1-8.9l-0.6-8.9L0,177.4h0.9l0.3,9.1l0.6,8.9l1.1,8.7l1.5,8.7l2,8.5
l2.4,8.3l2.8,8.3l3.1,7.8l3.5,7.8l3.9,7.7l4.3,7.3l4.5,7.2l4.9,6.9l5.3,6.7l5.4,6.3l5.8,6.2l6.2,5.8l6.4,5.5l3.3,2.6l3.4,2.6
l6.9,4.9l7.2,4.5l7.3,4.3l7.7,3.9l7.8,3.5l7.8,3.1l8.3,2.8l8.3,2.4l8.5,2l8.7,1.5l4.4,0.6l4.3,0.5l8.9,0.6l9.1,0.3V354z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1,177.4l0.1-4.4l0.1-4.3l0.3-4.3l0.4-4.1l0.5-4.3l0.6-4.1l0.6-4.1l0.8-4l0.9-4.1l1-4l1.1-4l1.1-3.9
l1.3-3.9l1.4-3.9l1.5-3.8l1.5-3.8l1.6-3.8l1.6-3.6l1.9-3.6l1.9-3.6l2-3.5l2-3.5l2.1-3.4l2.3-3.4l2.3-3.3l2.4-3.3l2.4-3.1l2.5-3.1
l2.6-3l2.6-3l2.8-3l2.8-2.9l2.9-2.8l2.9-2.8l3-2.6l3.1-2.5l3.1-2.6l3.1-2.4l3.3-2.4l3.3-2.3l3.4-2.3l3.4-2.1l3.5-2l3.5-2l3.5-1.9
l3.6-1.8l3.8-1.8l3.6-1.6l3.8-1.5l3.9-1.5l3.8-1.4l4-1.3l3.9-1.1l4-1.1l4-0.9l4-0.9l4.1-0.9l4.1-0.6l4.1-0.6l4.1-0.5l4.3-0.4
l4.3-0.3l4.3-0.1h4.3h4.4l4.3,0.1l4.3,0.3l4.3,0.4l4.1,0.5l4.1,0.6l4.1,0.6l4.1,0.9l4,0.9l4,0.9l4,1.1l3.9,1.1l3.9,1.3l3.9,1.4
l3.9,1.5l3.8,1.5l3.6,1.6l3.8,1.8l3.6,1.8l3.5,1.9l3.5,2l3.5,2l3.4,2.1l3.4,2.3l3.3,2.3l3.3,2.4l3.1,2.4l3.1,2.6l3.1,2.5l3,2.6
l2.9,2.8l2.9,2.8l2.8,2.9l2.8,3l2.6,3l2.6,3l2.5,3.1l2.4,3.1l2.4,3.3l2.3,3.3l2.3,3.4l2.1,3.4l2,3.5l2,3.5l1.9,3.6l1.8,3.6
l1.8,3.6l1.6,3.8l1.5,3.8l1.5,3.8l1.4,3.9l1.3,3.9l1.1,3.9l1.1,4l1,4l0.9,4.1l0.8,4l0.6,4.1l0.6,4.1l0.5,4.3l0.4,4.1l0.3,4.3
l0.1,4.3l0.1,4.4l-0.1,4.3l-0.1,4.3l-0.3,4.3l-0.4,4.3l-0.5,4.1l-0.6,4.3l-0.6,4l-0.8,4.1l-0.9,4l-1,4.1l-1.1,3.9l-1.1,4
l-1.3,3.9l-1.4,3.9l-1.5,3.8l-1.5,3.8l-1.6,3.8l-1.8,3.6l-1.8,3.6l-1.9,3.5l-2,3.5l-2,3.5l-2.1,3.4l-2.3,3.4l-2.3,3.3l-2.4,3.3
l-2.4,3.3l-2.5,3.1l-2.6,3l-2.6,3l-2.8,2.9l-2.8,2.9l-2.9,2.8l-2.9,2.8l-3,2.6l-3.1,2.6l-3.1,2.5l-3.1,2.5l-3.3,2.3l-3.3,2.4
l-3.4,2.1l-3.4,2.1l-3.5,2.1l-3.5,1.9l-3.5,1.9l-3.6,1.9l-3.8,1.6l-3.6,1.6l-3.8,1.6l-3.9,1.4l-3.9,1.4l-3.9,1.3l-3.9,1.3l-4,1
l-4,1l-4,0.9l-4.1,0.8l-4.1,0.8l-4.1,0.5l-4.1,0.5l-4.3,0.4l-4.3,0.3l-4.3,0.3h-4.4h-4.3l-4.3-0.3l-4.3-0.3l-4.3-0.4l-4.1-0.5
l-4.1-0.5l-4.1-0.8l-4.1-0.8l-4-0.9l-4-1l-4-1l-3.9-1.3l-4-1.3l-3.8-1.4l-3.9-1.4l-3.8-1.6l-3.6-1.6l-3.8-1.6l-3.6-1.9l-3.5-1.9
l-3.5-1.9l-3.5-2.1l-3.4-2.1l-3.4-2.1l-3.3-2.4l-3.3-2.3l-3.1-2.5l-3.1-2.5l-3.1-2.6l-3-2.6l-2.9-2.8l-2.9-2.8l-2.8-2.9l-2.8-2.9
l-2.6-3l-2.6-3l-2.5-3.1l-2.4-3.3l-2.4-3.3l-2.3-3.3l-2.3-3.4l-2.1-3.4l-2-3.5l-2-3.5l-1.9-3.5l-1.9-3.6l-1.6-3.6l-1.6-3.8
l-1.5-3.8l-1.5-3.8l-1.4-3.9l-1.3-3.9l-1.1-4l-1.1-3.9l-1-4.1l-0.9-4l-0.8-4.1l-0.6-4l-0.6-4.3l-0.5-4.1l-0.4-4.3l-0.3-4.3
l-0.1-4.3L19.1,177.4z M9.6,177.4l0.1-4.4l0.1-4.3l0.3-4.3l0.4-4.1l0.5-4.3l0.6-4.1l0.6-4.1l0.8-4l0.9-4.1l1-4l1.1-4l1.1-3.9
l1.3-3.9l1.4-3.9l1.5-3.8l1.5-3.8l1.6-3.8l1.6-3.6l1.9-3.6l1.9-3.6l2-3.5l2-3.5l2.1-3.4l2.3-3.4l2.3-3.3l2.4-3.3l2.4-3.1l2.5-3.1
l2.6-3l2.6-3l2.8-3l2.8-2.9l2.9-2.8l2.9-2.8l3-2.6l3.1-2.5l3.1-2.6l3.1-2.4l3.3-2.4l3.3-2.3l3.4-2.3l3.4-2.1l3.5-2l3.5-2l3.5-1.9
l3.6-1.8l3.8-1.8l3.6-1.6l3.8-1.5l3.9-1.5l3.8-1.4l4-1.3l3.9-1.1l4-1.1l4-0.9l4-0.9l4.1-0.9l4.1-0.6l4.1-0.6l4.1-0.5l4.3-0.4
l4.3-0.3l4.3-0.1h4.3h4.4l4.3,0.1l4.3,0.3l4.3,0.4l4.1,0.5l4.1,0.6l4.1,0.6l4.1,0.9l4,0.9l4,0.9l4,1.1l3.9,1.1l3.9,1.3l3.9,1.4
l3.9,1.5l3.8,1.5l3.6,1.6l3.8,1.8l3.6,1.8l3.5,1.9l3.5,2l3.5,2l3.4,2.1l3.4,2.3l3.3,2.3l3.3,2.4l3.1,2.4l3.1,2.6l3.1,2.5l3,2.6
l2.9,2.8l2.9,2.8l2.8,2.9l2.8,3l2.6,3l2.6,3l2.5,3.1l2.4,3.1l2.4,3.3l2.3,3.3l2.3,3.4l2.1,3.4l2,3.5l2,3.5l1.9,3.6l1.8,3.6
l1.8,3.6l1.6,3.8l1.5,3.8l1.5,3.8l1.4,3.9l1.3,3.9l1.1,3.9l1.1,4l1,4l0.9,4.1l0.8,4l0.6,4.1l0.6,4.1l0.5,4.3l0.4,4.1l0.3,4.3
l0.1,4.3l0.1,4.4l-0.1,4.3l-0.1,4.3l-0.3,4.3l-0.4,4.3l-0.5,4.1l-0.6,4.3l-0.6,4l-0.8,4.1l-0.9,4l-1,4.1l-1.1,3.9l-1.1,4
l-1.3,3.9l-1.4,3.9l-1.5,3.8l-1.5,3.8l-1.6,3.8l-1.8,3.6l-1.8,3.6l-1.9,3.5l-2,3.5l-2,3.5l-2.1,3.4l-2.3,3.4l-2.3,3.3l-2.4,3.3
l-2.4,3.3l-2.5,3.1l-2.6,3l-2.6,3l-2.8,2.9l-2.8,2.9l-2.9,2.8l-2.9,2.8l-3,2.6l-3.1,2.6l-3.1,2.5l-3.1,2.5l-3.3,2.3l-3.3,2.4
l-3.4,2.1l-3.4,2.1l-3.5,2.1l-3.5,1.9l-3.5,1.9l-3.6,1.9l-3.8,1.6l-3.6,1.6l-3.8,1.6l-3.9,1.4l-3.9,1.4l-3.9,1.3l-3.9,1.3l-4,1
l-4,1l-4,0.9l-4.1,0.8l-4.1,0.8l-4.1,0.5l-4.1,0.5l-4.3,0.4l-4.3,0.3l-4.3,0.3h-4.4h-4.3l-4.3-0.3l-4.3-0.3l-4.3-0.4l-4.1-0.5
l-4.1-0.5l-4.1-0.8l-4.1-0.8l-4-0.9l-4-1l-4-1l-3.9-1.3l-4-1.3l-3.8-1.4l-3.9-1.4l-3.8-1.6l-3.6-1.6l-3.8-1.6l-3.6-1.9l-3.5-1.9
l-3.5-1.9l-3.5-2.1l-3.4-2.1l-3.4-2.1l-3.3-2.4l-3.3-2.3l-3.1-2.5l-3.1-2.5l-3.1-2.6l-3-2.6l-2.9-2.8l-2.9-2.8l-2.8-2.9l-2.8-2.9
l-2.6-3l-2.6-3l-2.5-3.1l-2.4-3.3l-2.4-3.3l-2.3-3.3l-2.3-3.4l-2.1-3.4l-2-3.5l-2-3.5l-1.9-3.5l-1.9-3.6l-1.6-3.6l-1.6-3.8
l-1.5-3.8l-1.5-3.8l-1.4-3.9l-1.3-3.9l-1.1-4l-1.1-3.9l-1-4.1l-0.9-4l-0.8-4.1l-0.6-4l-0.6-4.3l-0.5-4.1l-0.4-4.3l-0.3-4.3
l-0.1-4.3L9.6,177.4z"
        />
        <path
          d="M18.6,177.4l0.3-8.7l0.6-8.5l1-8.4l1.5-8.3l0.9-4l1-4l2.3-7.9l2.6-7.9l1.4-3.8l1.6-3.9l1.6-3.6l1.6-3.6l3.8-7.3l4.1-7
l4.3-6.8l4.8-6.5l4.9-6.3l5.3-6.2l5.5-5.9l5.8-5.5l6.2-5.3l6.3-4.9l6.7-4.7l6.8-4.4l7-4l7.2-3.6l7.4-3.4l7.8-3.1l7.7-2.6l8.2-2.3
l8-1.9l8.3-1.4l8.3-1.1l8.5-0.6l8.5-0.3v1l-8.5,0.3l-8.5,0.6l-8.2,1.1l-8.3,1.4l-8,1.9l-8,2.3l-7.7,2.6l-7.5,3l-7.4,3.4l-7.2,3.6
l-7,4l-6.8,4.4l-6.5,4.5l-6.3,4.9l-6.2,5.3l-5.8,5.5l-5.5,5.9l-5.2,6l-4.9,6.3L48,84.1l-4.3,6.8l-4,6.9l-3.8,7.3l-1.6,3.6
l-1.6,3.6l-1.6,3.9l-1.4,3.8l-2.6,7.8l-2.3,7.9l-1,4l-0.9,4l-1.5,8.2l-1,8.4l-0.6,8.3l-0.3,8.7H18.6z"
        />
        <path
          d="M186.4,9.4l8.7,0.3l8.4,0.6l8.4,1.1l8.4,1.4l4,1l4,0.9l7.9,2.3l7.8,2.6l3.9,1.6l3.9,1.5l3.6,1.6l3.6,1.8l7.3,3.6l7,4
l6.8,4.4l6.5,4.7l6.3,4.9l6,5.3l6,5.7l5.5,5.9l5.3,6l4.9,6.3l4.7,6.5l4.4,6.8l4,7l3.6,7.3l3.4,7.3l3.1,7.8l2.6,7.8l2.3,7.9l1.9,8
l1.4,8.4l1.1,8.4l0.6,8.4l0.3,8.7h-1l-0.3-8.7l-0.6-8.3l-1.1-8.4l-1.4-8.3l-1.9-8l-2.3-7.9l-2.6-7.8l-3-7.5l-3.4-7.3l-3.6-7.3
l-4-7l-4.4-6.8l-4.5-6.4l-4.9-6.3l-5.3-6l-5.5-5.9l-5.9-5.5l-6-5.3l-6.3-4.9l-6.4-4.5l-6.8-4.4l-7-4l-7.3-3.6l-3.6-1.8l-3.6-1.6
l-3.9-1.5l-3.6-1.5l-7.8-2.6l-7.9-2.3l-4-0.9l-4-1l-8.3-1.4l-8.4-1.1l-8.3-0.6l-8.7-0.3V9.4z"
        />
        <path
          d="M354.3,177.4l-0.3,8.5l-0.6,8.5l-1.1,8.3l-1.4,8.3l-1,4l-0.9,4.1l-2.3,8l-2.6,7.7l-1.6,4l-1.5,3.8l-1.6,3.8l-1.8,3.6
l-3.6,7.2l-4,7l-4.4,6.8l-4.7,6.7l-4.9,6.3l-5.3,6.2l-5.5,5.8l-6,5.5l-6,5.3l-6.3,4.9l-6.5,4.8l-6.8,4.3l-7,4.1l-7.3,3.8
l-7.3,3.3l-7.7,3l-7.9,2.6l-7.9,2.3l-8,1.9l-8.3,1.5l-8.4,1l-8.5,0.6l-8.7,0.3v-0.9l8.7-0.3l8.3-0.6l8.4-1l8.2-1.5l8-1.9l7.9-2.3
l7.8-2.6l7.7-3l7.3-3.3l7.3-3.8l6.9-4l6.8-4.3l6.5-4.8l6.3-4.9l6-5.3l5.9-5.4l5.5-5.8l5.3-6.2l4.9-6.3l4.5-6.5l4.4-6.8l4-7
l3.6-7.2l1.8-3.6l1.6-3.8l1.5-3.6l1.5-3.9l2.6-7.7l2.3-8l0.9-4l1-4l1.4-8.3l1.1-8.2l0.6-8.5l0.3-8.5H354.3z"
        />
        <path
          d="M186.4,345.2l-8.5-0.3l-8.5-0.6l-8.4-1l-8.3-1.5l-4-0.9l-4.1-1l-8-2.3l-7.7-2.6l-3.9-1.4L121,332l-3.8-1.6l-3.6-1.6
l-7.2-3.8l-7.2-4.1l-6.8-4.3l-6.5-4.8l-6.3-4.9l-6.2-5.3l-5.8-5.5l-5.5-5.8l-5.3-6.2l-4.9-6.3l-4.8-6.5l-4.3-6.8l-4.1-7.2
l-3.8-7.2l-3.3-7.4l-3-7.7l-2.6-7.7l-2.3-8.2l-1.9-8l-1.5-8.3l-1-8.4l-0.6-8.5l-0.3-8.5h0.9l0.3,8.5l0.6,8.5l1,8.2l1.5,8.3l1.9,8
l2.3,8l2.6,7.7l3,7.5l3.3,7.4l3.8,7.2l4,7l4.3,6.8l4.8,6.5l4.9,6.3l5.2,6l5.5,5.8l5.8,5.5l6.2,5.3l6.3,4.9l6.5,4.8l6.8,4.3l7,4
l7.2,3.8l3.6,1.6l3.8,1.6l3.6,1.6l3.9,1.4l7.7,2.6l8,2.3l4,1l4,0.9l8.3,1.5l8.2,1l8.5,0.6l8.5,0.3V345.2z"
        />
        <path
          d="M9.1,177.4l0.3-8.7l0.6-8.5l1-8.4l1.5-8.3l0.9-4l1-4l2.3-7.9l2.6-7.9l1.4-3.8l1.6-3.9l1.6-3.6l1.6-3.6l3.8-7.3l4.1-7
l4.3-6.8l4.8-6.5l4.9-6.3l5.3-6.2l5.5-5.9L64,53l6.2-5.3l6.3-4.9l6.7-4.7l6.8-4.4l7-4l7.2-3.6l7.4-3.4l7.8-3.1l7.7-2.6l8.2-2.3
l8-1.9l8.3-1.4l8.3-1.1l8.5-0.6l8.5-0.3v1l-8.5,0.3l-8.5,0.6l-8.2,1.1l-8.3,1.4l-8,1.9l-8,2.3l-7.7,2.6l-7.5,3l-7.4,3.4l-7.2,3.6
l-7,4l-6.8,4.4L77,43.6l-6.3,4.9l-6.2,5.3l-5.8,5.5l-5.5,5.9l-5.2,6l-4.9,6.3l-4.8,6.5l-4.3,6.8l-4,6.9l-3.8,7.3l-1.6,3.6
l-1.6,3.6l-1.6,3.9l-1.4,3.8l-2.6,7.8l-2.3,7.9l-1,4l-0.9,4l-1.5,8.2l-1,8.4l-0.6,8.3l-0.3,8.7H9.1z"
        />
        <path
          d="M176.8,9.4l8.7,0.3l8.4,0.6l8.4,1.1l8.4,1.4l4,1l4,0.9l7.9,2.3l7.8,2.6l3.9,1.6l3.9,1.5l3.6,1.6l3.6,1.8l7.3,3.6l7,4
l6.8,4.4l6.5,4.7l6.3,4.9l6,5.3l6,5.7l5.5,5.9l5.3,6l4.9,6.3l4.7,6.5l4.4,6.8l4,7l3.6,7.3l3.4,7.3l3.1,7.8l2.6,7.8l2.3,7.9l1.9,8
l1.4,8.4l1.1,8.4l0.6,8.4l0.3,8.7h-1l-0.3-8.7l-0.6-8.3l-1.1-8.4l-1.4-8.3l-1.9-8l-2.3-7.9l-2.6-7.8l-3-7.5l-3.4-7.3l-3.6-7.3
l-4-7l-4.4-6.8l-4.5-6.4l-4.9-6.3l-5.3-6l-5.5-5.9l-5.9-5.5l-6-5.3l-6.3-4.9l-6.4-4.5l-6.8-4.4l-7-4l-7.3-3.6l-3.6-1.8l-3.6-1.6
l-3.9-1.5l-3.6-1.5l-7.8-2.6l-7.9-2.3l-4-0.9l-4-1l-8.3-1.4l-8.4-1.1l-8.3-0.6l-8.7-0.3V9.4z"
        />
        <path
          d="M344.8,177.4l-0.3,8.5l-0.6,8.5l-1.1,8.3l-1.4,8.3l-1,4l-0.9,4.1l-2.3,8l-2.6,7.7l-1.6,4l-1.5,3.8l-1.6,3.8l-1.8,3.6
l-3.6,7.2l-4,7l-4.4,6.8l-4.7,6.7l-4.9,6.3l-5.3,6.2l-5.5,5.8l-6,5.5l-6,5.3l-6.3,4.9l-6.5,4.8l-6.8,4.3l-7,4.1l-7.3,3.8
l-7.3,3.3l-7.7,3l-7.9,2.6l-7.9,2.3l-8,1.9l-8.3,1.5l-8.4,1l-8.5,0.6l-8.7,0.3v-0.9l8.7-0.3l8.3-0.6l8.4-1l8.2-1.5l8-1.9l7.9-2.3
l7.8-2.6l7.7-3l7.3-3.3l7.3-3.8l6.9-4l6.8-4.3l6.5-4.8l6.3-4.9l6-5.3l5.9-5.4l5.5-5.8l5.3-6.2l4.9-6.3l4.5-6.5l4.4-6.8l4-7
l3.6-7.2l1.8-3.6l1.6-3.8l1.5-3.6l1.5-3.9l2.6-7.7l2.3-8l0.9-4l1-4l1.4-8.3l1.1-8.2l0.6-8.5l0.3-8.5H344.8z"
        />
        <path
          d="M176.8,345.2l-8.5-0.3l-8.5-0.6l-8.4-1l-8.3-1.5l-4-0.9l-4.1-1l-8-2.3l-7.7-2.6l-3.9-1.4l-3.8-1.6l-3.8-1.6l-3.6-1.6
l-7.2-3.8l-7.2-4.1l-6.8-4.3l-6.5-4.8l-6.3-4.9l-6.2-5.3l-5.8-5.5l-5.5-5.8l-5.3-6.2l-4.9-6.3l-4.8-6.5l-4.3-6.8l-4.1-7.2
l-3.8-7.2l-3.3-7.4l-3-7.7l-2.6-7.7l-2.3-8.2l-1.9-8l-1.5-8.3l-1-8.4l-0.6-8.5l-0.3-8.5h0.9l0.3,8.5l0.6,8.5l1,8.2l1.5,8.3l1.9,8
l2.3,8l2.6,7.7l3,7.5l3.3,7.4l3.8,7.2l4,7l4.3,6.8l4.8,6.5l4.9,6.3l5.2,6l5.5,5.8l5.8,5.5l6.2,5.3L77,311l6.5,4.8l6.8,4.3l7,4
l7.2,3.8l3.6,1.6l3.8,1.6l3.6,1.6l3.9,1.4l7.7,2.6l8,2.3l4,1l4,0.9l8.3,1.5l8.2,1l8.5,0.6l8.5,0.3V345.2z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3,177.4v-4.1l0.3-4l0.3-4.1l0.3-4l0.5-3.9l0.5-4l0.8-3.9l0.8-3.9l0.8-3.9l1-3.8l1-3.8l1.1-3.8l1.1-3.8
l1.4-3.6l1.4-3.6l1.4-3.5l1.6-3.6l1.6-3.4l1.6-3.5l1.9-3.4l1.8-3.4l2-3.3l2-3.3l2.1-3.1l2.1-3.1l2.3-3.1l2.4-3l2.4-3l2.4-2.9
l2.5-2.9l2.6-2.8l2.6-2.6l2.8-2.8l2.8-2.5l2.9-2.5l2.9-2.5l3-2.4l3-2.4l3-2.3l3.1-2.1l3.3-2.1l3.3-2l3.3-1.9l3.4-1.9l3.4-1.9
l3.4-1.6l3.5-1.6l3.5-1.6l3.6-1.4l3.6-1.4l3.6-1.3l3.6-1.3l3.8-1.1l3.8-1l3.9-1l3.9-0.8l3.9-0.8l3.9-0.6l3.9-0.6l4-0.4l4-0.4
l4-0.3l4.1-0.1l4.1-0.1l4,0.1l4.1,0.1l4,0.3l4,0.4l4,0.4l3.9,0.6l3.9,0.6l3.9,0.8l3.9,0.8l3.9,1l3.8,1l3.8,1.1l3.6,1.3l3.6,1.3
l3.6,1.4l3.6,1.4l3.5,1.6l3.5,1.6l3.4,1.6l3.4,1.9l3.4,1.9l3.3,1.9l3.3,2l3.3,2.1l3.1,2.1l3,2.3l3,2.4l3,2.4l2.9,2.5l2.9,2.5
l2.8,2.5l2.8,2.8l2.6,2.6l2.6,2.8l2.5,2.9l2.4,2.9l2.5,3l2.3,3l2.3,3.1l2.1,3.1l2.1,3.1l2,3.3l2,3.3l1.9,3.4l1.8,3.4l1.8,3.5
l1.6,3.4l1.5,3.6l1.5,3.5l1.3,3.6l1.4,3.6l1.1,3.8l1.1,3.8l1,3.8l1,3.8l0.9,3.9l0.6,3.9l0.8,3.9l0.5,4l0.5,3.9l0.4,4l0.3,4.1
l0.1,4v4.1v4l-0.1,4.1l-0.3,4l-0.4,4l-0.5,4l-0.5,3.9l-0.8,4l-0.6,3.9l-0.9,3.8l-1,3.9l-1,3.8l-1.1,3.8l-1.1,3.6l-1.4,3.8
l-1.3,3.6l-1.5,3.5l-1.5,3.5l-1.6,3.5l-1.8,3.5l-1.8,3.4l-1.9,3.3l-2,3.4l-2,3.1l-2.1,3.3l-2.1,3.1l-2.3,3l-2.3,3.1l-2.5,2.9
l-2.4,2.9l-2.5,2.9l-2.6,2.8l-2.6,2.8l-2.8,2.6l-2.8,2.6l-2.9,2.5l-2.9,2.5l-3,2.4l-3,2.3l-3,2.3l-3.1,2.3l-3.3,2l-3.3,2l-3.3,2
l-3.4,1.9l-3.4,1.8l-3.4,1.8l-3.5,1.6l-3.5,1.5l-3.6,1.5l-3.6,1.4l-3.6,1.3l-3.6,1.3l-3.8,1.1l-3.8,1l-3.9,0.9l-3.9,0.9l-3.9,0.8
l-3.9,0.6l-3.9,0.5l-4,0.5l-4,0.4l-4,0.3l-4.1,0.1h-4h-4.1l-4.1-0.1l-4-0.3l-4-0.4l-4-0.5l-3.9-0.5l-3.9-0.6l-3.9-0.8l-3.9-0.9
l-3.9-0.9l-3.8-1l-3.8-1.1l-3.6-1.3l-3.6-1.3l-3.6-1.4l-3.6-1.5l-3.5-1.5l-3.5-1.6l-3.4-1.8l-3.4-1.8l-3.4-1.9l-3.3-2l-3.3-2
l-3.3-2l-3.1-2.3l-3-2.3l-3-2.3l-3-2.4l-2.9-2.5l-2.9-2.5l-2.8-2.6l-2.8-2.6l-2.6-2.8l-2.6-2.8l-2.5-2.9l-2.4-2.9l-2.4-2.9
l-2.4-3.1l-2.3-3l-2.1-3.1l-2.1-3.3l-2-3.1l-2-3.4l-1.8-3.3l-1.9-3.4l-1.6-3.5l-1.6-3.5l-1.6-3.5l-1.4-3.5l-1.4-3.6l-1.4-3.8
l-1.1-3.6l-1.1-3.8l-1-3.8l-1-3.9l-0.8-3.8l-0.8-3.9l-0.8-4l-0.5-3.9l-0.5-4l-0.3-4l-0.3-4l-0.3-4.1V177.4z M18.2,177.4v-4.1
l0.1-4l0.3-4.1l0.4-4l0.5-3.9l0.5-4l0.6-3.9l0.8-3.9l0.9-3.9l0.9-3.8l1.1-3.8l1.1-3.8l1.1-3.8l1.3-3.6l1.4-3.6l1.5-3.5l1.5-3.6
l1.6-3.4l1.8-3.5l1.8-3.4l1.9-3.4l2-3.3l2-3.3l2.1-3.1l2.1-3.1l2.3-3.1l2.3-3l2.4-3l2.5-2.9l2.5-2.9l2.6-2.8l2.6-2.6l2.8-2.8
l2.8-2.5l2.9-2.5l2.9-2.5l3-2.4l3-2.4l3-2.3l3.1-2.1l3.3-2.1l3.3-2l3.3-1.9l3.4-1.9l3.4-1.9l3.4-1.6l3.5-1.6l3.5-1.6l3.6-1.4
l3.6-1.4l3.6-1.3l3.6-1.3l3.8-1.1l3.8-1l3.9-1l3.8-0.8l3.9-0.8l4-0.6l3.9-0.6l4-0.4l4-0.4l4-0.3l4.1-0.1l4-0.1l4.1,0.1l4,0.1
l4.1,0.3l4,0.4l3.9,0.4l4,0.6l3.9,0.6l3.9,0.8l3.9,0.8l3.8,1l3.9,1l3.6,1.1l3.8,1.3l3.6,1.3l3.6,1.4l3.6,1.4l3.5,1.6l3.5,1.6
l3.4,1.6l3.4,1.9l3.4,1.9l3.3,1.9l3.3,2l3.1,2.1l3.1,2.1l3.1,2.3l3,2.4l3,2.4l2.9,2.5l2.9,2.5l2.8,2.5l2.8,2.8l2.6,2.6l2.5,2.8
l2.6,2.9l2.4,2.9l2.4,3l2.4,3l2.3,3.1l2.1,3.1l2.1,3.1l2,3.3l1.9,3.3l1.9,3.4l1.9,3.4l1.6,3.5l1.6,3.4l1.6,3.6l1.4,3.5l1.4,3.6
l1.4,3.6l1.1,3.8l1.1,3.8l1,3.8l1,3.8l0.8,3.9l0.8,3.9l0.6,3.9l0.6,4l0.5,3.9l0.3,4l0.3,4.1l0.3,4v4.1v4l-0.3,4.1l-0.3,4l-0.3,4
l-0.5,4l-0.6,3.9l-0.6,4l-0.8,3.9l-0.8,3.8l-1,3.9l-1,3.8l-1.1,3.8l-1.1,3.6l-1.4,3.8l-1.4,3.6l-1.4,3.5l-1.6,3.5l-1.6,3.5
l-1.6,3.5l-1.9,3.4l-1.9,3.3l-1.9,3.4l-2,3.1l-2.1,3.3l-2.1,3.1l-2.3,3l-2.4,3.1l-2.4,2.9l-2.4,2.9l-2.6,2.9l-2.5,2.8l-2.6,2.8
l-2.8,2.6l-2.8,2.6l-2.9,2.5l-2.9,2.5l-3,2.4l-3,2.3l-3.1,2.3l-3.1,2.3l-3.1,2l-3.3,2l-3.3,2l-3.4,1.9l-3.4,1.8l-3.4,1.8
l-3.5,1.6l-3.5,1.5l-3.6,1.5l-3.6,1.4l-3.6,1.3l-3.8,1.3l-3.6,1.1l-3.9,1l-3.8,0.9l-3.9,0.9l-3.9,0.8l-3.9,0.6l-4,0.5l-3.9,0.5
l-4,0.4l-4.1,0.3l-4,0.1h-4.1h-4l-4.1-0.1l-4-0.3l-4-0.4l-4-0.5l-3.9-0.5l-4-0.6l-3.9-0.8l-3.8-0.9l-3.9-0.9l-3.8-1l-3.8-1.1
l-3.6-1.3l-3.6-1.3l-3.6-1.4l-3.6-1.5l-3.5-1.5l-3.5-1.6l-3.4-1.8l-3.4-1.8l-3.4-1.9l-3.3-2l-3.3-2l-3.3-2l-3.1-2.3l-3-2.3
l-3-2.3l-3-2.4l-2.9-2.5l-2.9-2.5l-2.8-2.6l-2.8-2.6l-2.6-2.8l-2.6-2.8l-2.5-2.9l-2.5-2.9l-2.4-2.9l-2.3-3.1l-2.3-3l-2.1-3.1
l-2.1-3.3l-2-3.1l-2-3.4l-1.9-3.3l-1.8-3.4l-1.8-3.5l-1.6-3.5l-1.5-3.5l-1.5-3.5l-1.4-3.6l-1.3-3.8l-1.1-3.6l-1.1-3.8l-1.1-3.8
l-0.9-3.9l-0.9-3.8l-0.8-3.9l-0.6-4l-0.5-3.9l-0.5-4l-0.4-4l-0.3-4l-0.1-4.1V177.4z"
        />
        <path
          d="M26.9,177.4l0.1-8.2l0.6-8.3l1-7.9l1.4-7.8l1.9-7.7l2.1-7.5l2.4-7.4l2.9-7.4l3.1-7l3.5-6.8l3.9-6.7l4.1-6.4l4.4-6.3
l4.8-6l4.9-5.8l5.3-5.5l5.5-5.2l5.8-5l6.2-4.9l6.2-4.4l6.5-4.1l3.3-1.9l3.4-1.9l6.8-3.5l7-3.3l7.5-2.8l7.3-2.5l7.5-2.1l7.8-1.8
l7.8-1.4l7.9-1l8-0.6l8.2-0.3v0.9l-8.2,0.3l-8,0.6l-7.8,1l-7.8,1.4l-7.8,1.8L139,26l-7.3,2.5l-7.3,2.6l-6.9,3.3l-6.8,3.5
l-3.4,1.9l-3.3,1.9l-6.5,4.1l-6.2,4.4l-6,4.8l-5.8,5L74,65.2l-5.3,5.5l-4.9,5.8l-4.8,6l-4.4,6.3l-4.1,6.4l-3.8,6.5l-3.5,6.8
l-3.1,7l-2.9,7.3l-2.4,7.4l-2.1,7.5l-1.9,7.5l-1.4,7.8l-1,7.9l-0.6,8l-0.1,8.2H26.9z"
        />
        <path
          d="M186.2,18l8.3,0.3l8,0.6l7.9,1l7.8,1.4l7.7,1.8l7.7,2.1l7.3,2.5l7.5,2.8l7,3.3l6.8,3.5l6.7,3.8l6.5,4.1l6.2,4.4l6,4.9
l5.8,5l5.5,5.2l5.3,5.5l4.9,5.8l4.9,6l4.4,6.3l4.1,6.4l2,3.3l1.8,3.4l3.5,6.8l3.3,7l2.8,7.5l2.5,7.4l2.1,7.5l1.8,7.7l1.5,7.8
l1,7.9l0.5,8.2l0.3,8.2h-0.9l-0.3-8.2l-0.5-8l-1-7.9l-1.5-7.8l-1.8-7.7l-2.1-7.5l-2.5-7.4l-2.6-7.2l-3.3-7l-3.5-6.8l-1.8-3.4
l-2-3.3l-4.1-6.4l-4.4-6.3l-4.8-5.9l-4.9-5.8l-5.3-5.5l-5.5-5.2l-5.8-5l-5.9-4.8l-6.2-4.4l-6.5-4.1l-6.7-3.8l-6.8-3.5l-7-3.3
l-7.2-2.6l-7.3-2.5l-7.7-2.1l-7.7-1.8l-7.8-1.4l-7.9-1l-7.9-0.6l-8.3-0.3V18z"
        />
        <path
          d="M345.5,177.4l-0.3,8.2l-0.5,8l-1,7.9l-1.5,7.9l-1.8,7.7l-2.1,7.5l-2.5,7.4l-2.8,7.4l-3.3,7l-3.5,6.9l-3.8,6.5l-4.1,6.5
l-4.4,6.2l-4.9,6.2l-4.9,5.8l-5.3,5.5l-5.5,5.3l-5.8,5l-6,4.7l-6.2,4.4l-6.5,4.1l-3.3,2l-3.4,1.9l-6.8,3.5l-7,3.1l-7.4,2.9
l-7.3,2.5l-7.7,2.1l-7.7,1.8l-7.8,1.4l-7.9,1l-8.2,0.6l-8.3,0.1v-0.8l8.3-0.1l7.9-0.6l7.9-1l7.8-1.4l7.5-1.8l7.7-2.1l7.3-2.5
l7.3-2.9l7-3.1l6.8-3.5l3.4-1.9l3.1-1.9l6.5-4.1l6.2-4.4l6-4.7l5.8-5l5.5-5.3l5.3-5.5l4.9-5.8l4.8-6l4.4-6.2l4.1-6.5l3.8-6.5
l3.5-6.9l3.3-6.9l2.6-7.2l2.5-7.4l2.1-7.5l1.8-7.7l1.5-7.9l1-7.8l0.5-8l0.3-8.2H345.5z"
        />
        <path
          d="M186.2,336.7l-8.2-0.1l-8-0.6l-8-1l-7.8-1.4l-7.8-1.8l-7.7-2.1l-7.3-2.5l-7.3-2.9l-7-3.1l-6.8-3.5l-6.7-3.9l-6.5-4.1
l-6.3-4.4l-6-4.7l-5.8-5l-5.4-5.3l-5.4-5.5l-4.9-5.8l-4.8-6l-4.4-6.3l-4.1-6.5l-2-3.3l-1.9-3.3l-3.5-6.9l-3.1-7l-2.9-7.2
l-2.4-7.4l-2.1-7.7l-1.9-7.7l-1.4-7.9l-1-8l-0.6-8l-0.1-8.2h0.8l0.1,8.2l0.6,8l1,7.8l1.4,7.9l1.9,7.7l2.1,7.5l2.4,7.4l2.9,7.2
l3.1,6.9l3.5,6.9l1.9,3.3l1.9,3.3l4.1,6.5l4.4,6.2l4.8,6l4.9,5.8l5.3,5.5l5.5,5.3l5.8,5l6,4.7l6.2,4.4l6.5,4.1l6.7,3.8l6.8,3.5
l6.9,3.1l7.3,2.9l7.3,2.5l7.5,2.1l7.8,1.8l7.8,1.4l7.8,1l8,0.6l8.2,0.1V336.7z"
        />
        <path
          d="M17.8,177.4l0.1-8.2l0.6-8.3l1-7.9l1.4-7.8l1.8-7.7l2.1-7.5l2.5-7.4l2.9-7.4l3.1-7l3.5-6.8l3.9-6.7l4.1-6.4l4.4-6.3
l4.7-6l5-5.8l5.3-5.5l5.5-5.2l5.8-5l6.2-4.9l6.2-4.4l6.4-4.1l3.4-1.9l3.3-1.9l6.9-3.5l7-3.3l7.4-2.8l7.4-2.5l7.5-2.1l7.7-1.8
l7.9-1.4l7.9-1l8-0.6l8.2-0.3v0.9l-8.2,0.3l-8,0.6l-7.8,1l-7.9,1.4l-7.7,1.8L130,26l-7.4,2.5l-7.2,2.6l-6.9,3.3l-6.9,3.5
l-3.3,1.9l-3.4,1.9l-6.4,4.1l-6.2,4.4l-6,4.8l-5.8,5L65,65.2l-5.3,5.5l-5,5.8l-4.6,6l-4.4,6.3l-4.1,6.4l-3.8,6.5l-3.5,6.8l-3.1,7
l-2.9,7.3l-2.5,7.4l-2.1,7.5l-1.8,7.5l-1.4,7.8l-1,7.9l-0.6,8l-0.1,8.2H17.8z"
        />
        <path
          d="M177.1,18l8.2,0.3l8.2,0.6l7.9,1l7.8,1.4l7.7,1.8l7.5,2.1l7.4,2.5l7.5,2.8l7,3.3l6.8,3.5l6.7,3.8l6.4,4.1l6.3,4.4l6,4.9
l5.8,5l5.4,5.2l5.3,5.5l5,5.8l4.9,6l4.4,6.3l4.1,6.4l1.9,3.3l1.9,3.4l3.5,6.8l3.3,7l2.8,7.5l2.5,7.4l2.1,7.5l1.8,7.7l1.4,7.8
l1,7.9l0.6,8.2l0.3,8.2h-0.9l-0.3-8.2l-0.6-8l-1-7.9l-1.4-7.8l-1.8-7.7l-2.1-7.5l-2.5-7.4l-2.6-7.2l-3.3-7l-3.5-6.8l-1.9-3.4
l-1.9-3.3l-4.1-6.4l-4.4-6.3l-4.8-5.9l-5-5.8l-5.3-5.5l-5.4-5.2l-5.8-5l-5.9-4.8l-6.3-4.4l-6.4-4.1l-6.7-3.8l-6.8-3.5l-7-3.3
l-7.2-2.6l-7.4-2.5l-7.5-2.1l-7.7-1.8l-7.8-1.4l-7.9-1l-8-0.6l-8.2-0.3V18z"
        />
        <path
          d="M336.5,177.4l-0.3,8.2l-0.6,8l-1,7.9l-1.4,7.9l-1.8,7.7l-2.1,7.5l-2.5,7.4l-2.8,7.4l-3.3,7l-3.5,6.9l-3.8,6.5l-4.1,6.5
l-4.4,6.2l-4.9,6.2l-5,5.8l-5.3,5.5l-5.4,5.3l-5.8,5l-6,4.7l-6.3,4.4l-6.4,4.1l-3.3,2l-3.4,1.9l-6.8,3.5l-7,3.1l-7.4,2.9
l-7.4,2.5l-7.5,2.1l-7.7,1.8l-7.8,1.4l-7.9,1l-8.3,0.6l-8.2,0.1v-0.8l8.2-0.1l8-0.6l7.9-1l7.8-1.4l7.5-1.8l7.5-2.1l7.4-2.5
l7.3-2.9l7-3.1l6.8-3.5l3.4-1.9l3.1-1.9l6.4-4.1l6.3-4.4l6-4.7l5.8-5l5.4-5.3l5.3-5.5l5-5.8l4.8-6l4.4-6.2l4.1-6.5l3.8-6.5
l3.5-6.9l3.3-6.9l2.6-7.2l2.5-7.4l2.1-7.5l1.8-7.7l1.4-7.9l1-7.8l0.6-8l0.3-8.2H336.5z"
        />
        <path
          d="M177.1,336.7l-8.2-0.1l-8-0.6l-8-1l-7.9-1.4l-7.7-1.8l-7.7-2.1l-7.4-2.5l-7.2-2.9l-7-3.1l-6.9-3.5l-6.7-3.9l-6.4-4.1
l-6.3-4.4l-6-4.7l-5.8-5l-5.4-5.3l-5.4-5.5l-5-5.8l-4.7-6l-4.4-6.3l-4.1-6.5l-2-3.3l-1.9-3.3l-3.5-6.9l-3.1-7l-2.9-7.2l-2.5-7.4
l-2.1-7.7l-1.8-7.7l-1.4-7.9l-1-8l-0.6-8l-0.1-8.2h0.8l0.1,8.2l0.6,8l1,7.8l1.4,7.9l1.8,7.7l2.1,7.5l2.5,7.4l2.9,7.2l3.1,6.9
l3.5,6.9l1.9,3.3l1.9,3.3l4.1,6.5l4.4,6.2l4.6,6l5,5.8l5.3,5.5l5.5,5.3l5.8,5l6,4.7l6.2,4.4l6.4,4.1l6.7,3.8l6.9,3.5l6.9,3.1
l7.2,2.9l7.4,2.5l7.5,2.1l7.7,1.8l7.9,1.4l7.8,1l8,0.6l8.2,0.1V336.7z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.9,177.4l0.1-3.9l0.1-3.9l0.3-3.9l0.3-3.8l0.5-3.8l0.5-3.8l0.6-3.8l0.8-3.6l0.8-3.8l0.9-3.5l1-3.6l1-3.5
l1.1-3.6l1.3-3.4l1.4-3.5l1.4-3.4l1.4-3.4l1.6-3.3l1.6-3.3l1.6-3.3l1.8-3.1l1.9-3.1l2-3.1l1.9-3l2.1-3L65,87l2.3-2.9l2.3-2.8
l2.3-2.8l2.4-2.8l2.5-2.6l2.5-2.6l2.6-2.5l2.6-2.5l2.6-2.4l2.9-2.3l2.8-2.3l2.9-2.3l2.9-2.1l3-2l3-2l3.1-2l3.1-1.8l3.1-1.9
l3.3-1.6l3.3-1.6l3.3-1.6l3.4-1.4l3.4-1.4l3.5-1.4l3.4-1.3l3.5-1.1l3.6-1l3.5-1l3.6-0.9l3.8-0.8l3.6-0.8L163,28l3.8-0.5l3.8-0.4
l3.8-0.4l3.9-0.3l3.9-0.1l3.9-0.1l3.9,0.1l3.9,0.1l3.8,0.3l3.9,0.4l3.8,0.4L209,28l3.6,0.6l3.8,0.8l3.6,0.8l3.6,0.9l3.6,1l3.5,1
l3.5,1.1l3.5,1.3l3.4,1.4l3.4,1.4l3.4,1.4l3.4,1.6l3.3,1.6l3.1,1.6l3.3,1.9l3.1,1.8l3,2l3,2l3,2l3,2.1l2.9,2.3l2.8,2.3l2.8,2.3
l2.8,2.4l2.6,2.5l2.5,2.5l2.5,2.6l2.5,2.6l2.4,2.8l2.4,2.8l2.3,2.8l2.3,2.9l2.1,2.9l2,3l2,3l1.9,3.1l1.9,3.1l1.8,3.1l1.8,3.3
l1.6,3.3l1.5,3.3l1.5,3.4l1.4,3.4l1.3,3.5l1.3,3.4l1.1,3.6l1.1,3.5l0.9,3.6l0.9,3.5l0.9,3.8l0.6,3.6l0.6,3.8l0.6,3.8l0.4,3.8
l0.4,3.8l0.3,3.9l0.1,3.9v3.9v3.9l-0.1,3.9l-0.3,3.8l-0.4,3.9l-0.4,3.8l-0.6,3.8l-0.6,3.6l-0.6,3.8l-0.9,3.6l-0.9,3.6l-0.9,3.6
l-1.1,3.5l-1.1,3.5l-1.3,3.5l-1.3,3.4l-1.4,3.4l-1.5,3.4l-1.5,3.4l-1.6,3.3l-1.8,3.1l-1.8,3.3l-1.9,3.1l-1.9,3l-2,3.1l-2,2.9
l-2.1,3l-2.3,2.9l-2.3,2.8l-2.4,2.8l-2.4,2.8l-2.5,2.6l-2.5,2.5l-2.5,2.5l-2.6,2.5l-2.8,2.4l-2.8,2.4l-2.8,2.3l-2.9,2.3l-3,2.1
l-3,2l-3,2l-3,1.9l-3.1,1.9l-3.3,1.8l-3.1,1.8l-3.3,1.6l-3.4,1.5l-3.4,1.5l-3.4,1.4l-3.4,1.3l-3.5,1.3l-3.5,1.1l-3.5,1.1
l-3.6,0.9l-3.6,0.9l-3.6,0.9l-3.8,0.6l-3.6,0.6l-3.8,0.6l-3.8,0.4l-3.9,0.4l-3.8,0.3l-3.9,0.1H186h-3.9l-3.9-0.1l-3.9-0.3
l-3.8-0.4l-3.8-0.4l-3.8-0.6l-3.8-0.6l-3.6-0.6l-3.8-0.9l-3.6-0.9l-3.5-0.9l-3.6-1.1l-3.5-1.1l-3.4-1.3l-3.5-1.3l-3.4-1.4
l-3.4-1.5l-3.3-1.5l-3.3-1.6l-3.3-1.8l-3.1-1.8l-3.1-1.9l-3.1-1.9l-3-2l-3-2l-2.9-2.1l-2.9-2.3l-2.8-2.3l-2.9-2.4l-2.6-2.4
l-2.6-2.5l-2.6-2.5l-2.5-2.5l-2.5-2.6l-2.4-2.8l-2.3-2.8l-2.3-2.8l-2.3-2.9l-2.1-3l-2.1-2.9l-1.9-3.1l-2-3l-1.9-3.1l-1.8-3.3
l-1.6-3.1l-1.6-3.3l-1.6-3.4l-1.4-3.4l-1.4-3.4l-1.4-3.4l-1.3-3.5l-1.1-3.5l-1-3.5l-1-3.6l-0.9-3.6l-0.8-3.6l-0.8-3.8l-0.6-3.6
l-0.5-3.8l-0.5-3.8l-0.3-3.9l-0.3-3.8l-0.1-3.9L34.9,177.4z M26.4,177.4v-3.9l0.1-3.9l0.3-3.9l0.4-3.8l0.4-3.8l0.6-3.8l0.5-3.8
l0.8-3.6l0.8-3.8l1-3.5l0.9-3.6l1.1-3.5l1.1-3.6l1.3-3.4l1.3-3.5l1.4-3.4l1.5-3.4l1.5-3.3l1.6-3.3l1.8-3.3l1.8-3.1l1.9-3.1
l1.9-3.1l2-3l2-3l2.1-2.9l2.3-2.9l2.3-2.8l2.4-2.8l2.4-2.8l2.4-2.6l2.6-2.6l2.5-2.5l2.6-2.5l2.8-2.4l2.8-2.3l2.8-2.3l2.9-2.3
l3-2.1l2.9-2l3.1-2l3-2l3.1-1.8l3.1-1.9l3.3-1.6l3.3-1.6l3.4-1.6l3.3-1.4l3.5-1.4l3.4-1.4l3.5-1.3l3.5-1.1l3.5-1l3.6-1l3.6-0.9
l3.6-0.8l3.6-0.8l3.8-0.6l3.8-0.5l3.8-0.4l3.9-0.4l3.8-0.3l3.9-0.1l3.9-0.1l3.9,0.1l3.9,0.1l3.9,0.3l3.8,0.4l3.8,0.4l3.8,0.5
l3.8,0.6l3.6,0.8l3.6,0.8l3.6,0.9l3.6,1l3.5,1l3.5,1.1l3.5,1.3l3.5,1.4l3.4,1.4l3.4,1.4l3.3,1.6l3.3,1.6l3.3,1.6l3.1,1.9l3.1,1.8
l3.1,2l3,2l3,2l2.9,2.1l2.9,2.3l2.8,2.3l2.8,2.3l2.8,2.4l2.6,2.5l2.6,2.5l2.5,2.6l2.4,2.6l2.5,2.8l2.3,2.8l2.3,2.8l2.3,2.9
l2.1,2.9l2,3l2,3l2,3.1l1.8,3.1l1.9,3.1l1.6,3.3l1.6,3.3l1.5,3.3l1.5,3.4l1.4,3.4l1.4,3.5l1.1,3.4l1.3,3.6l1,3.5l1,3.6l0.9,3.5
l0.8,3.8l0.8,3.6l0.6,3.8l0.5,3.8l0.4,3.8l0.4,3.8l0.3,3.9l0.1,3.9l0.1,3.9l-0.1,3.9l-0.1,3.9l-0.3,3.8l-0.4,3.9l-0.4,3.8
l-0.5,3.8L326,204l-0.8,3.8l-0.8,3.6l-0.9,3.6l-1,3.6l-1,3.5l-1.3,3.5l-1.1,3.5l-1.4,3.4l-1.4,3.4l-1.5,3.4l-1.5,3.4l-1.6,3.3
l-1.6,3.1l-1.9,3.3l-1.8,3.1l-2,3l-2,3.1l-2,2.9l-2.1,3l-2.3,2.9l-2.3,2.8l-2.3,2.8l-2.5,2.8l-2.4,2.6l-2.5,2.5l-2.6,2.5
l-2.6,2.5l-2.8,2.4l-2.8,2.4l-2.8,2.3l-2.9,2.3l-2.9,2.1l-3,2l-3,2l-3.1,1.9l-3.1,1.9l-3.1,1.8l-3.3,1.8l-3.3,1.6l-3.3,1.5
l-3.4,1.5l-3.4,1.4l-3.5,1.3l-3.5,1.3l-3.5,1.1l-3.5,1.1l-3.6,0.9l-3.6,0.9l-3.6,0.9l-3.6,0.6l-3.8,0.6l-3.8,0.6l-3.8,0.4
L189,328l-3.9,0.3l-3.9,0.1h-3.9h-3.9l-3.9-0.1l-3.8-0.3l-3.9-0.4l-3.8-0.4l-3.8-0.6l-3.8-0.6l-3.6-0.6l-3.6-0.9l-3.6-0.9
l-3.6-0.9l-3.5-1.1l-3.5-1.1l-3.5-1.3l-3.4-1.3l-3.5-1.4l-3.3-1.5l-3.4-1.5l-3.3-1.6l-3.3-1.8l-3.1-1.8l-3.1-1.9l-3-1.9l-3.1-2
l-2.9-2l-3-2.1l-2.9-2.3l-2.8-2.3l-2.8-2.4l-2.8-2.4l-2.6-2.5l-2.5-2.5l-2.6-2.5l-2.4-2.6l-2.4-2.8l-2.4-2.8l-2.3-2.8l-2.3-2.9
l-2.1-3l-2-2.9l-2-3.1l-1.9-3l-1.9-3.1l-1.8-3.3l-1.8-3.1l-1.6-3.3l-1.5-3.4l-1.5-3.4l-1.4-3.4l-1.3-3.4l-1.3-3.5l-1.1-3.5
l-1.1-3.5l-0.9-3.6l-1-3.6l-0.8-3.6l-0.8-3.8l-0.5-3.6l-0.6-3.8l-0.4-3.8l-0.4-3.9l-0.3-3.8l-0.1-3.9V177.4z"
        />
        <path
          d="M34.6,177.4l0.3-7.8l0.5-7.8l1-7.5l1.4-7.4l1.6-7.4l2-7.2l2.4-6.9l2.8-7l3-6.7l1.6-3.3l1.6-3.3l3.6-6.3l3.9-6.2l4.3-5.9
l2.1-2.9l2.4-2.9l4.7-5.4l5-5.3l5.3-5l5.4-4.7l5.9-4.7l2.9-2.1l3-2.1l6.2-3.9l6.3-3.6l6.5-3.3l6.7-3l7.2-2.8l6.9-2.4l7.2-2
l7.3-1.6l7.5-1.4l7.5-1l7.7-0.5l7.8-0.3v0.9l-7.8,0.3l-7.7,0.5l-7.4,1l-7.5,1.4l-7.3,1.6l-7.2,2l-6.9,2.4l-6.9,2.6l-6.5,3
l-6.5,3.3l-6.3,3.6l-6.2,3.9l-3,2.1l-2.9,2.1l-5.8,4.5l-5.4,4.7l-5.3,5l-5,5.3l-4.7,5.4l-2.4,2.9l-2.1,2.9L61,93.3l-3.9,6.2
l-3.5,6.2l-1.6,3.3l-1.6,3.3l-3,6.7l-2.8,6.9l-2.4,6.9l-2,7.2l-1.6,7.3l-1.4,7.4l-1,7.5l-0.5,7.5l-0.3,7.8H34.6z"
        />
        <path
          d="M186,25.9l7.7,0.3l7.7,0.5l7.7,1l7.4,1.4l7.3,1.6l7.2,2l7,2.4l7,2.8l6.8,3l3.3,1.6l3.1,1.6l6.4,3.6l6,3.9l5.9,4.3
l2.9,2.3l2.9,2.4l5.4,4.7l5.3,5l5,5.3l4.8,5.4l4.5,5.8l2.1,2.9l2.1,3l3.9,6.2l3.6,6.3l3.4,6.5l3,6.7l2.6,7.2l2.4,6.9l2,7.2
l1.8,7.4l1.3,7.4l1,7.5l0.6,7.7l0.1,7.8h-0.9l-0.1-7.8l-0.6-7.5l-1-7.5l-1.3-7.4l-1.8-7.4l-2-7.2l-2.4-6.9l-2.5-6.8l-3-6.7
l-3.4-6.5l-3.6-6.3l-3.9-6.2l-2.1-3l-2.1-2.9l-4.4-5.7l-4.8-5.4l-5-5.3l-5.3-5l-5.4-4.7l-2.9-2.4l-2.8-2.1l-5.9-4.3l-6-3.9
l-6.4-3.6l-3.1-1.6l-3.3-1.6l-6.8-3l-6.7-2.6l-7-2.4l-7.2-2l-7.3-1.6l-7.4-1.4l-7.7-1l-7.5-0.5l-7.7-0.3V25.9z"
        />
        <path
          d="M337.4,177.4l-0.1,7.8l-0.6,7.7l-1,7.5l-1.3,7.4l-1.8,7.3l-2,7.2l-2.4,7l-2.6,7l-3,6.8l-1.6,3.3l-1.8,3.1l-3.6,6.4
l-3.9,6l-4.3,6l-2.3,2.9l-2.3,2.9l-4.8,5.5l-5,5.2l-5.3,5l-5.4,4.8l-5.8,4.4l-2.9,2.1l-3,2.1l-6,3.9l-6.4,3.6l-6.4,3.4l-6.8,3
l-6.9,2.6l-7,2.4l-7.2,2l-7.3,1.8l-7.4,1.3l-7.7,1l-7.8,0.6l-7.7,0.1V328l7.7-0.1l7.5-0.6l7.7-1l7.4-1.3l7.2-1.8l7.2-2l7-2.4
l6.8-2.6l6.8-3l6.4-3.4l6.3-3.5l6-3.9l3-2.1l2.9-2.1l5.8-4.4l5.4-4.8l5.3-5l5-5.2l4.8-5.5l2.3-2.9l2.1-2.8l4.3-6l3.9-6l3.6-6.4
l1.8-3.1l1.6-3.1l3-6.8l2.5-6.8l2.4-7l2-7.2l1.8-7.3l1.3-7.4l1-7.4l0.6-7.7l0.1-7.8H337.4z"
        />
        <path
          d="M186,328.7l-7.8-0.1l-7.7-0.6l-7.7-1l-7.5-1.3l-7.3-1.8l-7.3-2l-6.9-2.4l-6.9-2.6l-6.7-3l-3.3-1.6l-3.3-1.8l-6.3-3.6
l-6.2-3.9l-6-4.3l-2.9-2.1l-2.9-2.3l-5.4-4.8l-5.2-5l-5.2-5.2l-4.7-5.5l-4.5-5.7l-2.1-3l-2.1-3.1l-3.9-6l-3.6-6.4l-3.3-6.4
l-3-6.8l-2.8-6.8l-2.4-7l-2-7.3l-1.6-7.3l-1.4-7.4l-1-7.7l-0.5-7.7l-0.3-7.8h0.8l0.3,7.8l0.5,7.7l1,7.4l1.4,7.4l1.6,7.3l2,7.2
l2.4,7l2.8,6.8l3,6.7l3.3,6.4l3.5,6.4l3.9,6l2.1,3l2.1,3l4.5,5.7l4.7,5.5l5,5.2l5.3,5l5.4,4.8l2.9,2.3l2.9,2.1l5.9,4.3l6.2,3.9
l6.3,3.5l3.3,1.8l3.1,1.6l6.7,3l6.9,2.6l6.9,2.4l7.2,2l7.3,1.8l7.5,1.3l7.4,1l7.7,0.6l7.8,0.1V328.7z"
        />
        <path
          d="M26,177.4l0.1-7.8l0.6-7.8l0.9-7.5l1.4-7.4l1.6-7.4l2.1-7.2l2.3-6.9l2.8-7l3-6.7l1.6-3.3l1.8-3.3l3.6-6.3l3.9-6.2
l4.1-5.9l2.3-2.9l2.3-2.9l4.8-5.4l4.9-5.3l5.3-5l5.5-4.7l5.8-4.7l3-2.1l2.9-2.1l6.2-3.9l6.3-3.6l6.5-3.3l6.7-3l7.2-2.8l7-2.4
l7.2-2l7.3-1.6l7.4-1.4l7.5-1l7.7-0.5l7.8-0.3v0.9l-7.8,0.3l-7.7,0.5l-7.4,1l-7.4,1.4l-7.3,1.6l-7.2,2l-7,2.4l-6.9,2.6l-6.5,3
l-6.5,3.3l-6.3,3.6l-6.2,3.9l-2.9,2.1l-3,2.1l-5.7,4.5L76,65.9l-5.3,5l-4.9,5.3l-4.8,5.4l-2.3,2.9l-2.3,2.9l-4.1,5.9l-3.9,6.2
l-3.5,6.2l-1.8,3.3l-1.6,3.3l-3,6.7l-2.8,6.9l-2.3,6.9l-2.1,7.2l-1.6,7.3l-1.4,7.4l-0.9,7.5l-0.6,7.5l-0.1,7.8H26z"
        />
        <path
          d="M177.4,25.9l7.8,0.3l7.7,0.5l7.5,1l7.4,1.4l7.3,1.6l7.2,2l7,2.4l7.2,2.8l6.7,3l3.3,1.6l3.3,1.6l6.3,3.6l6.2,3.9l5.9,4.3
l2.9,2.3l2.8,2.4l5.5,4.7l5.3,5l4.9,5.3l4.8,5.4l4.6,5.8l2.1,2.9l2,3l4,6.2l3.5,6.3l3.4,6.5l3,6.7l2.8,7.2l2.3,6.9l2.1,7.2
l1.6,7.4l1.4,7.4l0.9,7.5l0.6,7.7l0.1,7.8h-0.9l-0.1-7.8l-0.6-7.5l-0.9-7.5l-1.4-7.4l-1.6-7.4l-2.1-7.2l-2.3-6.9l-2.6-6.8l-3-6.7
l-3.4-6.5l-3.5-6.3l-4-6.2l-2-3l-2.1-2.9l-4.5-5.7l-4.8-5.4l-4.9-5.3l-5.3-5l-5.5-4.7l-2.8-2.4l-2.8-2.1l-5.9-4.3l-6.2-3.9
l-6.3-3.6l-3.3-1.6l-3.3-1.6l-6.7-3l-6.8-2.6l-7-2.4l-7.2-2l-7.3-1.6l-7.4-1.4l-7.5-1l-7.5-0.5l-7.8-0.3V25.9z"
        />
        <path
          d="M328.7,177.4l-0.1,7.8l-0.6,7.7l-0.9,7.5l-1.4,7.4l-1.6,7.3l-2.1,7.2l-2.3,7l-2.8,7l-3,6.8l-1.6,3.3l-1.8,3.1l-3.5,6.4
l-4,6l-4.1,6l-2.4,2.9l-2.3,2.9l-4.8,5.5l-4.9,5.2l-5.3,5l-5.5,4.8l-5.7,4.4l-2.9,2.1l-3,2.1l-6.2,3.9l-6.3,3.6l-6.5,3.4l-6.7,3
l-7,2.6l-7,2.4l-7.2,2l-7.3,1.8l-7.4,1.3l-7.5,1l-7.8,0.6l-7.8,0.1V328l7.8-0.1l7.5-0.6l7.5-1l7.4-1.3l7.2-1.8l7.2-2l7-2.4
l6.9-2.6l6.7-3l6.5-3.4l6.2-3.5l6.2-3.9l3-2.1l2.9-2.1l5.7-4.4l5.5-4.8l5.3-5l4.9-5.2l4.8-5.5l2.3-2.9l2.3-2.8l4.1-6l4-6l3.5-6.4
l1.8-3.1l1.6-3.1l3-6.8l2.6-6.8l2.3-7l2.1-7.2l1.6-7.3l1.4-7.4l0.9-7.4l0.6-7.7l0.1-7.8H328.7z"
        />
        <line fill="none" x1="328.7" y1="177.4" x2="327.8" y2="177.4" />
        <path
          d="M177.4,328.7l-7.8-0.1l-7.7-0.6l-7.7-1l-7.4-1.3l-7.3-1.8l-7.3-2l-7-2.4l-6.9-2.6l-6.7-3l-3.3-1.6l-3.3-1.8l-6.3-3.6
l-6.2-3.9l-6-4.3l-2.9-2.1l-2.8-2.3l-5.5-4.8l-5.2-5l-5-5.2l-4.8-5.5l-4.5-5.7l-2.1-3l-2-3.1l-3.9-6l-3.6-6.4l-3.4-6.4l-3-6.8
l-2.8-6.8l-2.3-7l-2.1-7.3l-1.6-7.3l-1.4-7.4l-0.9-7.7l-0.6-7.7l-0.1-7.8h0.8l0.1,7.8l0.6,7.7l0.9,7.4l1.4,7.4l1.6,7.3l2.1,7.2
l2.3,7l2.8,6.8l3,6.7L45,249l3.5,6.4l3.9,6l2,3l2.1,3l4.5,5.7l4.8,5.5l4.9,5.2l5.3,5l5.5,4.8l2.8,2.3l2.9,2.1l5.9,4.3l6.2,3.9
l6.3,3.5l3.3,1.8l3.1,1.6l6.7,3l6.9,2.6l7,2.4l7.2,2l7.3,1.8l7.4,1.3l7.4,1l7.7,0.6l7.8,0.1V328.7z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.4,177.4v-3.8l0.1-3.6l0.3-3.6l0.4-3.6l0.4-3.6l0.5-3.5l0.5-3.5l0.8-3.5l0.8-3.5l0.9-3.4l0.9-3.5l1-3.4
l1.1-3.3L51,128l1.3-3.3l1.3-3.1l1.4-3.3l1.5-3.1l1.6-3.1l1.5-3l1.8-3l1.8-3l1.8-2.9l2-2.9l1.9-2.9l2-2.8l2.1-2.8l2.1-2.6
l2.3-2.6l2.3-2.6l2.4-2.5l2.4-2.4l2.5-2.4l2.5-2.4l2.5-2.3l2.6-2.3l2.6-2.1l2.8-2.1l2.8-2l2.9-2l2.9-1.9l2.9-1.8l3-1.8l3-1.8
l3-1.6l3.1-1.5l3.1-1.5l3.3-1.4l3.3-1.3l3.3-1.3l3.3-1.3l3.4-1l3.4-1l3.4-1l3.4-0.8l3.5-0.8l3.5-0.8l3.5-0.5l3.6-0.5l3.5-0.5
l3.6-0.3l3.6-0.3l3.8-0.1l3.6-0.1l3.6,0.1l3.8,0.1l3.6,0.3l3.6,0.3l3.5,0.5l3.6,0.5l3.5,0.5l3.5,0.8l3.5,0.8l3.4,0.8l3.4,1l3.4,1
l3.4,1l3.3,1.3l3.4,1.3l3.1,1.3l3.3,1.4L248,48l3.1,1.5l3,1.6l3,1.8l3,1.8l2.9,1.8l2.9,1.9l2.9,2l2.8,2l2.8,2.1l2.6,2.1l2.6,2.3
l2.5,2.3l2.6,2.4l2.4,2.4l2.4,2.4l2.4,2.5l2.3,2.6l2.3,2.6l2.1,2.6l2.1,2.8l2,2.8l1.9,2.9l2,2.9l1.8,2.9l1.8,3l1.8,3l1.6,3
l1.5,3.1l1.5,3.1l1.4,3.3l1.3,3.1l1.3,3.3l1.1,3.4l1.1,3.3l1,3.4l0.9,3.5l0.9,3.4l0.8,3.5l0.8,3.5l0.5,3.5l0.5,3.5l0.5,3.6
l0.3,3.6l0.3,3.6l0.1,3.6v3.8v3.6l-0.1,3.8l-0.3,3.6l-0.3,3.5l-0.5,3.6l-0.5,3.6l-0.5,3.5l-0.8,3.5l-0.8,3.5l-0.9,3.4l-0.9,3.4
l-1,3.4l-1.1,3.4l-1.1,3.3l-1.3,3.3l-1.3,3.3l-1.4,3.3l-1.5,3.1l-1.5,3l-1.6,3.1l-1.8,3l-1.8,3l-1.8,2.9l-2,2.9l-1.9,2.9l-2,2.8
l-2.1,2.6l-2.1,2.8l-2.3,2.6l-2.3,2.5l-2.4,2.5l-2.4,2.5l-2.4,2.4l-2.6,2.4l-2.5,2.3l-2.6,2.3l-2.6,2.1l-2.8,2l-2.8,2.1l-2.9,1.9
l-2.9,1.9l-2.9,1.9l-3,1.8l-3,1.6l-3,1.6l-3.1,1.6l-3.1,1.4l-3.3,1.4l-3.1,1.4l-3.4,1.3l-3.3,1.1l-3.4,1.1l-3.4,1l-3.4,0.9
l-3.4,0.9l-3.5,0.8l-3.5,0.6l-3.5,0.6l-3.6,0.5l-3.5,0.4l-3.6,0.4l-3.6,0.3l-3.8,0.1h-3.6h-3.6l-3.8-0.1l-3.6-0.3l-3.6-0.4
l-3.5-0.4l-3.6-0.5l-3.5-0.6l-3.5-0.6l-3.5-0.8l-3.4-0.9l-3.4-0.9l-3.4-1l-3.4-1.1l-3.3-1.1l-3.3-1.3l-3.3-1.4l-3.3-1.4l-3.1-1.4
l-3.1-1.6l-3-1.6l-3-1.6l-3-1.8l-2.9-1.9l-2.9-1.9l-2.9-1.9l-2.8-2.1l-2.8-2l-2.6-2.1l-2.6-2.3l-2.5-2.3l-2.5-2.4l-2.5-2.4
l-2.4-2.5l-2.4-2.5l-2.3-2.5l-2.3-2.6l-2.1-2.8l-2.1-2.6l-2-2.8l-1.9-2.9l-2-2.9l-1.8-2.9l-1.8-3l-1.8-3l-1.5-3.1l-1.6-3
l-1.5-3.1l-1.4-3.3l-1.3-3.3l-1.3-3.3l-1.1-3.3l-1.1-3.4l-1-3.4l-0.9-3.4l-0.9-3.4l-0.8-3.5l-0.8-3.5l-0.5-3.5l-0.5-3.6l-0.4-3.6
l-0.4-3.5l-0.3-3.6l-0.1-3.8V177.4z M34.1,177.4l0.1-3.8l0.1-3.6l0.3-3.6l0.3-3.6l0.5-3.6l0.5-3.5l0.5-3.5l0.8-3.5l0.8-3.5
l0.8-3.4l1-3.5l1-3.4l1-3.3l1.3-3.4l1.3-3.3l1.3-3.1l1.4-3.3l1.5-3.1l1.5-3.1l1.6-3l1.8-3l1.8-3l1.8-2.9l1.9-2.9l2-2.9l2-2.8
l2.1-2.8l2.1-2.6l2.3-2.6l2.3-2.6l2.4-2.5l2.4-2.4l2.4-2.4l2.5-2.4l2.6-2.3l2.6-2.3l2.6-2.1l2.8-2.1l2.8-2l2.9-2l2.9-1.9l2.9-1.8
l3-1.8l3-1.8l3-1.6l3.1-1.5l3.1-1.5l3.3-1.4l3.1-1.3l3.3-1.3l3.4-1.3l3.3-1l3.4-1l3.5-1l3.4-0.8l3.5-0.8l3.5-0.8l3.5-0.5l3.5-0.5
l3.6-0.5l3.6-0.3l3.6-0.3l3.6-0.1l3.8-0.1l3.6,0.1l3.8,0.1l3.6,0.3l3.6,0.3l3.5,0.5l3.6,0.5l3.5,0.5l3.5,0.8l3.5,0.8l3.4,0.8
l3.4,1l3.4,1l3.4,1l3.3,1.3l3.3,1.3l3.3,1.3l3.3,1.4l3.1,1.5l3,1.5l3.1,1.6l3,1.8l3,1.8l2.9,1.8l2.9,1.9l2.9,2l2.8,2l2.8,2.1
l2.6,2.1l2.6,2.3l2.5,2.3l2.5,2.4l2.5,2.4l2.4,2.4l2.4,2.5l2.3,2.6l2.3,2.6l2.1,2.6l2,2.8l2.1,2.8l1.9,2.9l1.9,2.9l1.9,2.9l1.8,3
l1.6,3l1.6,3l1.6,3.1l1.4,3.1l1.5,3.3l1.3,3.1l1.3,3.3l1.1,3.4l1.1,3.3l1,3.4l0.9,3.5l0.9,3.4l0.8,3.5l0.6,3.5l0.6,3.5l0.5,3.5
l0.4,3.6l0.4,3.6l0.3,3.6l0.1,3.6v3.8v3.6l-0.1,3.8l-0.3,3.6l-0.4,3.5l-0.4,3.6l-0.5,3.6l-0.6,3.5l-0.6,3.5l-0.8,3.5l-0.9,3.4
l-0.9,3.4l-1,3.4l-1.1,3.4l-1.1,3.3l-1.3,3.3l-1.3,3.3l-1.5,3.3l-1.4,3.1l-1.6,3l-1.6,3.1l-1.6,3l-1.8,3l-1.9,2.9l-1.9,2.9
l-1.9,2.9l-2.1,2.8l-2,2.6l-2.1,2.8l-2.3,2.6l-2.3,2.5l-2.4,2.5l-2.4,2.5l-2.5,2.4l-2.5,2.4l-2.5,2.3l-2.6,2.3l-2.6,2.1l-2.8,2
l-2.8,2.1l-2.9,1.9l-2.9,1.9l-2.9,1.9l-3,1.8l-3,1.6l-3.1,1.6l-3,1.6l-3.1,1.4l-3.3,1.4l-3.3,1.4l-3.3,1.3l-3.3,1.1l-3.4,1.1
l-3.4,1l-3.4,0.9l-3.4,0.9l-3.5,0.8l-3.5,0.6l-3.5,0.6l-3.6,0.5l-3.5,0.4l-3.6,0.4l-3.6,0.3l-3.8,0.1h-3.6h-3.8l-3.6-0.1
l-3.6-0.3l-3.6-0.4l-3.6-0.4l-3.5-0.5l-3.5-0.6l-3.5-0.6l-3.5-0.8l-3.4-0.9l-3.5-0.9l-3.4-1l-3.3-1.1l-3.4-1.1l-3.3-1.3l-3.1-1.4
l-3.3-1.4l-3.1-1.4l-3.1-1.6l-3-1.6l-3-1.6l-3-1.8l-2.9-1.9l-2.9-1.9l-2.9-1.9l-2.8-2.1l-2.8-2l-2.6-2.1l-2.6-2.3l-2.6-2.3
l-2.5-2.4l-2.4-2.4l-2.4-2.5l-2.4-2.5l-2.3-2.5l-2.3-2.6l-2.1-2.8l-2.1-2.6l-2-2.8l-2-2.9l-1.9-2.9l-1.8-2.9l-1.8-3l-1.8-3
l-1.6-3.1l-1.5-3l-1.5-3.1l-1.4-3.3l-1.3-3.3l-1.3-3.3l-1.3-3.3l-1-3.4l-1-3.4l-1-3.4l-0.8-3.4l-0.8-3.5l-0.8-3.5l-0.5-3.5
l-0.5-3.6l-0.5-3.6l-0.3-3.5l-0.3-3.6l-0.1-3.8L34.1,177.4z"
        />
        <path
          d="M42,177.4l0.1-7.4l0.5-7.4l1-7.2l1.3-7l1.5-7l2-6.8l2.3-6.7l2.5-6.7l2.9-6.3l3.1-6.2l3.5-6l3.6-5.8l4-5.7l4.3-5.4
l4.5-5.3l4.8-4.9l4.9-4.8l5.3-4.5l5.5-4.4l5.7-4l5.8-3.6l6-3.5l6.2-3.1l6.4-2.9l6.7-2.5L143,40l6.9-2l6.9-1.6l7-1.3l7.2-0.9
l7.3-0.5l7.4-0.3v0.9l-7.4,0.3l-7.3,0.5l-7,0.9l-7,1.3l-6.9,1.6l-6.9,2l-6.7,2.3l-6.4,2.4l-6.3,2.9l-6.2,3.1l-6,3.5l-5.8,3.6
l-5.7,4L94.8,67l-5.3,4.5l-4.9,4.8l-4.8,4.9l-4.5,5.3L71,91.9l-4,5.7l-3.6,5.8l-3.4,5.9l-3.1,6.2l-2.9,6.3l-2.5,6.5l-2.3,6.7
l-2,6.8l-1.5,6.9l-1.3,7l-1,7.2l-0.5,7.2l-0.1,7.4H42z"
        />
        <path
          d="M185.8,33.4l7.4,0.3l7.3,0.5l7.2,0.9l7,1.3l6.9,1.6l6.8,2l6.7,2.3l6.8,2.5l6.4,2.9l6.2,3.1l6,3.5l5.8,3.6l5.7,4l5.4,4.4
l5.2,4.5l5,4.8l4.8,4.9l4.5,5.3l4.4,5.4l3.9,5.7l3.8,5.8l3.5,6l3.1,6.2l2.9,6.3l2.5,6.8l2.3,6.7l1.9,6.8l1.6,7l1.3,7l0.9,7.2
l0.6,7.3l0.1,7.4h-0.9l-0.1-7.4l-0.6-7.2l-0.9-7.2l-1.3-7l-1.6-7l-1.9-6.8l-2.3-6.7l-2.4-6.4l-2.9-6.3l-3.1-6.2l-3.5-6l-3.8-5.8
l-3.9-5.7l-4.3-5.3l-4.5-5.3l-4.8-4.9l-5-4.8l-5.2-4.5l-5.3-4.3l-5.7-4l-5.8-3.6l-6-3.5l-6.2-3.1l-6.4-2.9l-6.4-2.4l-6.7-2.3
l-6.8-2l-6.9-1.6l-7-1.3l-7.2-0.9l-7.2-0.5l-7.4-0.3V33.4z"
        />
        <line fill="none" x1="185.8" y1="33.4" x2="185.8" y2="34.3" />
        <path
          d="M329.6,177.4l-0.1,7.3l-0.6,7.3l-0.9,7.2l-1.3,7.2l-1.6,6.9l-1.9,6.8l-2.3,6.7l-2.5,6.8l-2.9,6.4l-3.1,6.2l-3.5,6
l-3.8,5.8l-3.9,5.7l-4.4,5.5l-4.5,5.2l-4.8,5l-5,4.8l-5.2,4.4l-5.4,4.3l-5.7,4l-5.8,3.8l-6,3.4l-6.2,3.1l-6.4,2.9l-6.7,2.6
l-6.7,2.3l-6.8,1.9l-6.9,1.6l-7,1.3l-7.2,0.9l-7.4,0.6l-7.4,0.1v-0.8l7.4-0.1l7.2-0.6l7.2-0.9l7-1.3l6.8-1.6l6.8-1.9l6.7-2.3
l6.5-2.6l6.4-2.9l6.2-3.1l5.9-3.3l5.8-3.8l5.7-4l5.4-4.3l5.2-4.4l5-4.8l4.8-5l4.5-5.2l4.3-5.4l3.9-5.7l3.8-5.8l3.5-6l3.1-6.2
l2.9-6.3l2.4-6.5l2.3-6.7l1.9-6.8l1.6-6.9l1.3-7.2l0.9-7l0.6-7.3l0.1-7.3H329.6z"
        />
        <line fill="none" x1="329.6" y1="177.4" x2="328.7" y2="177.4" />
        <path
          d="M185.8,321.2l-7.4-0.1l-7.3-0.6l-7.3-0.9l-7-1.3l-6.9-1.6l-7-1.9l-6.7-2.3l-6.4-2.6l-6.4-2.9l-6.2-3.1l-6-3.4l-5.8-3.8
l-5.8-4l-5.4-4.3l-5.3-4.4l-4.8-4.8l-4.9-5l-4.5-5.2l-4.3-5.4l-4-5.8l-3.6-5.8l-3.5-6l-3.1-6.2l-2.9-6.4l-2.5-6.5l-2.3-6.7
l-2-6.9l-1.5-6.9l-1.3-7.2l-1-7.3l-0.5-7.3l-0.1-7.3h0.8l0.1,7.3l0.5,7.3l1,7l1.3,7.2l1.5,6.9l2,6.8l2.3,6.7l2.5,6.5l2.9,6.3
l3.1,6.2l3.4,6l3.6,5.8l4,5.7l4.3,5.4l4.5,5.2l4.8,5l4.9,4.8l5.3,4.4l5.4,4.3l5.7,4l5.8,3.8l6,3.3l6.2,3.1l6.3,2.9l6.4,2.6
l6.7,2.3l6.9,1.9l6.9,1.6l7,1.3l7,0.9l7.3,0.6l7.4,0.1V321.2z"
        />
        <path
          d="M33.7,177.4l0.3-7.4l0.5-7.4l1-7.2l1.3-7l1.5-7l2-6.8l2.3-6.7l2.5-6.7l2.9-6.3l3.1-6.2l3.5-6l3.6-5.8l4-5.7l4.3-5.4
l4.5-5.3l4.8-4.9l4.9-4.8l5.3-4.5l5.5-4.4l5.7-4l5.8-3.6l6-3.5l6.2-3.1l6.3-2.9l6.8-2.5l6.7-2.3l6.8-2l7-1.6l7-1.3l7.2-0.9
l7.3-0.5l7.4-0.3v0.9l-7.4,0.3l-7.3,0.5l-7,0.9l-7,1.3l-7,1.6l-6.8,2l-6.7,2.3l-6.5,2.4l-6.2,2.9l-6.2,3.1l-6,3.5l-5.8,3.6
l-5.7,4L86.6,67l-5.3,4.5l-4.9,4.8l-4.8,4.9l-4.5,5.3l-4.3,5.4l-4,5.7l-3.6,5.8l-3.4,5.9l-3.1,6.2l-2.9,6.3l-2.5,6.5l-2.3,6.7
l-2,6.8l-1.5,6.9l-1.3,7l-1,7.2l-0.5,7.2l-0.3,7.4H33.7z"
        />
        <path
          d="M177.6,33.4l7.3,0.3l7.3,0.5l7.3,0.9l7,1.3l6.9,1.6l6.8,2l6.7,2.3l6.8,2.5l6.4,2.9l6.2,3.1l6,3.5L258,58l5.7,4l5.4,4.4
l5.2,4.5l5,4.8l4.8,4.9l4.4,5.3l4.4,5.4l4,5.7l3.8,5.8l3.4,6l3.3,6.2l2.8,6.3l2.6,6.8l2.3,6.7l1.9,6.8l1.6,7l1.3,7l0.9,7.2
l0.6,7.3l0.1,7.4h-0.9l-0.1-7.4l-0.6-7.2l-0.9-7.2l-1.3-7l-1.6-7l-1.9-6.8l-2.3-6.7l-2.5-6.4l-2.8-6.3l-3.3-6.2l-3.4-6l-3.8-5.8
l-4-5.7l-4.3-5.3l-4.4-5.3l-4.8-4.9l-5-4.8l-5.2-4.5l-5.3-4.3l-5.7-4l-5.8-3.6l-6-3.5l-6.2-3.1l-6.4-2.9l-6.4-2.4l-6.7-2.3
l-6.8-2l-6.9-1.6l-7-1.3l-7.3-0.9l-7.2-0.5l-7.3-0.3V33.4z"
        />
        <line fill="none" x1="177.6" y1="33.4" x2="177.6" y2="34.3" />
        <path
          d="M321.4,177.4l-0.1,7.3l-0.6,7.3l-0.9,7.2l-1.3,7.2l-1.6,6.9L315,220l-2.3,6.7l-2.6,6.8l-2.8,6.4L304,246l-3.4,6l-3.8,5.8
l-4,5.7l-4.4,5.5l-4.4,5.2l-4.8,5l-5,4.8l-5.2,4.4l-5.4,4.3l-5.7,4l-5.8,3.8l-6,3.4l-6.2,3.1l-6.4,2.9l-6.7,2.6l-6.7,2.3
l-6.8,1.9l-6.9,1.6l-7,1.3l-7.3,0.9l-7.4,0.6l-7.3,0.1v-0.8l7.3-0.1l7.2-0.6l7.3-0.9l7-1.3l6.8-1.6l6.8-1.9l6.7-2.3l6.5-2.6
l6.4-2.9l6.2-3.1l5.9-3.3l5.8-3.8l5.7-4l5.4-4.3l5.2-4.4l5-4.8l4.8-5l4.4-5.2l4.3-5.4l4-5.7l3.8-5.8l3.4-6l3.3-6.2l2.8-6.3
l2.5-6.5l2.3-6.7l1.9-6.8l1.6-6.9l1.3-7.2l0.9-7l0.6-7.3l0.1-7.3H321.4z"
        />
        <line fill="none" x1="321.4" y1="177.4" x2="320.5" y2="177.4" />
        <path
          d="M177.6,321.2l-7.4-0.1l-7.3-0.6l-7.3-0.9l-7-1.3l-7-1.6l-6.9-1.9l-6.7-2.3l-6.5-2.6l-6.3-2.9l-6.2-3.1l-6-3.4l-5.8-3.8
l-5.8-4l-5.4-4.3l-5.3-4.4l-4.8-4.8l-4.9-5l-4.5-5.2l-4.3-5.4l-4-5.8l-3.6-5.8l-3.5-6l-3.1-6.2l-2.9-6.4l-2.5-6.5l-2.3-6.7
l-2-6.9l-1.5-6.9l-1.3-7.2l-1-7.3l-0.5-7.3l-0.3-7.3h0.8l0.3,7.3l0.5,7.3l1,7l1.3,7.2l1.5,6.9l2,6.8l2.3,6.7l2.5,6.5l2.9,6.3
l3.1,6.2l3.4,6l3.6,5.8l4,5.7l4.3,5.4l4.5,5.2l4.8,5l4.9,4.8l5.3,4.4L92,292l5.7,4l5.8,3.8l6,3.3l6.2,3.1l6.2,2.9l6.5,2.6
l6.7,2.3l6.8,1.9l7,1.6l7,1.3l7,0.9l7.3,0.6l7.4,0.1V321.2z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.3,177.4v-3.5l0.3-3.5l0.1-3.5l0.4-3.4l0.4-3.4l0.5-3.4l0.5-3.4l0.6-3.4l0.8-3.3l0.8-3.3l0.9-3.3l1-3.3
l1-3.1l1.1-3.1l1.1-3.1l1.3-3l1.4-3l1.4-3l1.5-3l1.5-2.9l1.6-2.9l1.6-2.8l1.8-2.9l1.8-2.6l1.9-2.8l2-2.6l1.9-2.6l2.1-2.5l2.1-2.5
l2.1-2.4l2.3-2.4l2.3-2.4l2.3-2.3l2.4-2.3l2.5-2.1l2.5-2.1l2.5-2l2.6-2l2.6-1.9l2.6-1.9l2.8-1.9l2.8-1.8l2.9-1.6l2.9-1.6l2.9-1.5
l2.9-1.5l3-1.4l3-1.3l3.1-1.3l3.1-1.3l3.1-1.1l3.1-1l3.3-0.9l3.1-0.9l3.4-0.9l3.3-0.6l3.3-0.8l3.4-0.5l3.4-0.5l3.5-0.4l3.4-0.3
l3.5-0.3l3.4-0.1l3.5-0.1l3.5,0.1l3.5,0.1l3.5,0.3l3.4,0.3l3.5,0.4l3.4,0.5l3.3,0.5l3.4,0.8l3.3,0.6l3.3,0.9l3.3,0.9l3.3,0.9
l3.1,1l3.1,1.1l3.1,1.3l3,1.3l3.1,1.3l2.9,1.4l3,1.5l2.9,1.5l2.9,1.6l2.9,1.6l2.8,1.8l2.8,1.9l2.6,1.9l2.6,1.9l2.6,2l2.5,2
l2.5,2.1l2.4,2.1l2.5,2.3l2.3,2.3l2.3,2.4l2.3,2.4l2.1,2.4l2.1,2.5l2,2.5l2,2.6l2,2.6l1.9,2.8l1.8,2.6l1.8,2.9l1.6,2.8l1.6,2.9
l1.5,2.9l1.5,3l1.4,3l1.3,3l1.3,3l1.3,3.1l1.1,3.1l1,3.1l1,3.3l0.9,3.3l0.8,3.3l0.8,3.3l0.6,3.4l0.5,3.4l0.5,3.4l0.4,3.4l0.4,3.4
l0.1,3.5l0.1,3.5l0.1,3.5l-0.1,3.5l-0.1,3.5l-0.1,3.4l-0.4,3.5l-0.4,3.4l-0.5,3.4l-0.5,3.4l-0.6,3.3l-0.8,3.4l-0.8,3.3l-0.9,3.3
l-1,3.1l-1,3.3l-1.1,3.1l-1.3,3l-1.3,3.1l-1.3,3l-1.4,3l-1.5,2.9l-1.5,3l-1.6,2.9l-1.6,2.8l-1.8,2.8l-1.8,2.8l-1.9,2.6l-2,2.8
l-2,2.5l-2,2.6l-2.1,2.4l-2.1,2.5l-2.3,2.4l-2.3,2.4l-2.3,2.3l-2.5,2.1l-2.4,2.3l-2.5,2.1l-2.5,2l-2.6,2l-2.6,1.9l-2.6,1.9
l-2.8,1.8l-2.8,1.8l-2.9,1.6l-2.9,1.6l-2.9,1.5l-3,1.5l-2.9,1.4l-3.1,1.4l-3,1.3l-3.1,1.1l-3.1,1.1l-3.1,1l-3.3,1l-3.3,0.9
l-3.3,0.8l-3.3,0.8l-3.4,0.6l-3.3,0.6l-3.4,0.4l-3.5,0.5l-3.4,0.3l-3.5,0.3l-3.5,0.1h-3.5H182l-3.4-0.1l-3.5-0.3l-3.4-0.3
l-3.5-0.5l-3.4-0.4l-3.4-0.6l-3.3-0.6l-3.3-0.8l-3.4-0.8l-3.1-0.9l-3.3-1l-3.1-1l-3.1-1.1l-3.1-1.1l-3.1-1.3l-3-1.4l-3-1.4
l-2.9-1.5l-2.9-1.5l-2.9-1.6l-2.9-1.6l-2.8-1.8l-2.8-1.8l-2.6-1.9l-2.6-1.9l-2.6-2l-2.5-2l-2.5-2.1l-2.5-2.3l-2.4-2.1l-2.3-2.3
l-2.3-2.4l-2.3-2.4l-2.1-2.5l-2.1-2.4l-2.1-2.6l-1.9-2.5l-2-2.8l-1.9-2.6l-1.8-2.8L69,248l-1.6-2.8l-1.6-2.9l-1.5-3l-1.5-2.9
l-1.4-3l-1.4-3l-1.3-3.1l-1.1-3l-1.1-3.1l-1-3.3l-1-3.1l-0.9-3.3l-0.8-3.3l-0.8-3.4l-0.6-3.3l-0.5-3.4l-0.5-3.4l-0.4-3.4
l-0.4-3.5l-0.1-3.4l-0.3-3.5V177.4z M41.5,177.4l0.1-3.5l0.1-3.5l0.1-3.5l0.4-3.4l0.4-3.4l0.5-3.4l0.5-3.4l0.6-3.4l0.8-3.3
l0.8-3.3l0.9-3.3l1-3.3l1-3.1l1.1-3.1l1.1-3.1l1.3-3l1.4-3l1.4-3l1.5-3l1.5-2.9l1.6-2.9l1.6-2.8l1.8-2.9l1.8-2.6l1.9-2.8l2-2.6
l1.9-2.6l2.1-2.5l2.1-2.5l2.1-2.4l2.3-2.4l2.3-2.4l2.3-2.3l2.4-2.3l2.5-2.1l2.5-2.1l2.5-2l2.6-2l2.6-1.9l2.6-1.9l2.8-1.9l2.8-1.8
l2.9-1.6l2.9-1.6l2.9-1.5l2.9-1.5l3-1.4l3-1.3l3.1-1.3l3.1-1.3l3.1-1.1l3.1-1l3.3-0.9l3.3-0.9l3.3-0.9l3.3-0.6l3.4-0.8l3.3-0.5
l3.4-0.5l3.5-0.4l3.4-0.3l3.5-0.3l3.5-0.1l3.4-0.1l3.5,0.1l3.5,0.1l3.5,0.3l3.4,0.3l3.5,0.4l3.4,0.5l3.3,0.5l3.4,0.8l3.3,0.6
l3.3,0.9l3.3,0.9l3.3,0.9l3.1,1l3.1,1.1l3.1,1.3l3.1,1.3l3,1.3l3,1.4l2.9,1.5l2.9,1.5l2.9,1.6l2.9,1.6l2.8,1.8l2.8,1.9l2.6,1.9
l2.6,1.9l2.6,2l2.5,2l2.5,2.1l2.5,2.1l2.4,2.3l2.3,2.3l2.3,2.4l2.3,2.4l2.1,2.4l2.1,2.5l2.1,2.5l1.9,2.6l2,2.6l1.9,2.8l1.8,2.6
l1.8,2.9l1.6,2.8l1.6,2.9l1.5,2.9l1.5,3l1.4,3l1.4,3l1.3,3l1.1,3.1l1.1,3.1l1,3.1l1,3.3l0.9,3.3l0.8,3.3l0.8,3.3l0.6,3.4l0.5,3.4
l0.5,3.4l0.4,3.4l0.4,3.4l0.1,3.5l0.1,3.5l0.1,3.5l-0.1,3.5l-0.1,3.5l-0.1,3.4l-0.4,3.5l-0.4,3.4l-0.5,3.4l-0.5,3.4l-0.6,3.3
l-0.8,3.4l-0.8,3.3l-0.9,3.3l-1,3.1l-1,3.3l-1.1,3.1l-1.1,3l-1.3,3.1l-1.4,3l-1.4,3l-1.5,2.9l-1.5,3l-1.6,2.9l-1.6,2.8l-1.8,2.8
l-1.8,2.8l-1.9,2.6l-2,2.8l-1.9,2.5l-2.1,2.6l-2.1,2.4l-2.1,2.5l-2.3,2.4l-2.3,2.4l-2.3,2.3l-2.4,2.1l-2.5,2.3l-2.5,2.1l-2.5,2
l-2.6,2l-2.6,1.9l-2.6,1.9l-2.8,1.8l-2.8,1.8l-2.9,1.6l-2.9,1.6l-2.9,1.5l-2.9,1.5l-3,1.4l-3,1.4l-3.1,1.3l-3.1,1.1l-3.1,1.1
l-3.1,1l-3.3,1l-3.3,0.9l-3.3,0.8l-3.3,0.8l-3.4,0.6l-3.3,0.6l-3.4,0.4l-3.5,0.5l-3.4,0.3l-3.5,0.3l-3.5,0.1h-3.5h-3.4l-3.5-0.1
l-3.5-0.3l-3.4-0.3l-3.5-0.5l-3.4-0.4l-3.3-0.6l-3.4-0.6l-3.3-0.8l-3.3-0.8l-3.3-0.9l-3.3-1l-3.1-1l-3.1-1.1l-3.1-1.1l-3.1-1.3
l-3-1.4l-3-1.4l-2.9-1.5l-2.9-1.5l-2.9-1.6l-2.9-1.6l-2.8-1.8l-2.8-1.8l-2.6-1.9l-2.6-1.9l-2.6-2l-2.5-2l-2.5-2.1l-2.5-2.3
l-2.4-2.1l-2.3-2.3l-2.3-2.4l-2.3-2.4l-2.1-2.5l-2.1-2.4l-2.1-2.6l-1.9-2.5l-2-2.8l-1.9-2.6l-1.8-2.8l-1.8-2.8l-1.6-2.8l-1.6-2.9
l-1.5-3l-1.5-2.9l-1.4-3l-1.4-3l-1.3-3.1l-1.1-3l-1.1-3.1l-1-3.3l-1-3.1l-0.9-3.3l-0.8-3.3l-0.8-3.4l-0.6-3.3l-0.5-3.4l-0.5-3.4
l-0.4-3.4l-0.4-3.5l-0.1-3.4l-0.1-3.5L41.5,177.4z"
        />
        <path
          d="M48.9,177.4l0.1-7l0.6-7l0.8-6.8l1.3-6.8l1.5-6.5l1.9-6.5l2.1-6.3l2.4-6.2l2.8-6l3-5.9l3.3-5.8l3.6-5.7l3.8-5.3l4.1-5.2
l2-2.5l2.3-2.4l4.5-4.8l4.7-4.5l5-4.3l5.2-4l5.3-3.8l5.5-3.6l5.7-3.3l5.9-3l6.3-2.8l6.2-2.5l6.4-2.1l6.4-1.8l6.7-1.6l6.7-1.1
l6.8-0.9l6.9-0.5l7-0.3v0.8l-7,0.3l-6.9,0.5l-6.8,0.9l-6.5,1.1l-6.7,1.6l-6.4,1.8l-6.4,2.1l-6.2,2.5l-6,2.6l-5.9,3l-5.7,3.3
l-5.5,3.6l-5.3,3.8l-5.2,4l-4.9,4.3l-4.7,4.5L85,86.1l-2.3,2.4l-2,2.5l-4.1,5.2l-3.8,5.2l-3.5,5.5l-3.3,5.8l-3,5.9l-2.8,6
l-2.4,6.2l-2.1,6.3l-1.9,6.4l-1.5,6.5l-1.3,6.8l-0.8,6.8l-0.6,6.8l-0.1,7H48.9z"
        />
        <path
          d="M185.5,40.6l7,0.3l6.9,0.5l6.8,0.9l6.8,1.1l6.5,1.6l6.5,1.8l6.3,2.1l6.2,2.5l6.2,2.8l5.9,3l5.8,3.3l5.5,3.6l5.3,3.8
l5.2,4l2.5,2.1l2.5,2.1l4.8,4.5l4.5,4.8l4.3,5l4,5.2l3.8,5.3l3.6,5.5l3.3,5.8l3,5.9l2.8,6.2l2.5,6.2l2.1,6.3l1.8,6.5l1.6,6.5
l1.1,6.8l0.9,6.8l0.5,6.9l0.3,7h-0.8l-0.3-7l-0.5-6.8l-0.9-6.8l-1.1-6.8l-1.6-6.5l-1.8-6.5l-2.1-6.3l-2.5-6.2l-2.6-5.9l-3-5.9
l-3.3-5.8l-3.6-5.5l-3.8-5.3l-4-5.2l-4.1-4.8l-4.5-4.8l-4.8-4.5l-2.4-2.1l-2.5-2.1l-5.2-4l-5.3-3.8l-5.5-3.6l-5.8-3.3l-5.9-3
l-5.9-2.6l-6.2-2.5l-6.3-2.1l-6.5-1.8l-6.5-1.6l-6.8-1.1l-6.8-0.9l-6.8-0.5l-7-0.3V40.6z"
        />
        <line fill="none" x1="185.5" y1="40.6" x2="185.5" y2="41.4" />
        <path
          d="M322.3,177.4l-0.3,6.9l-0.5,7l-0.9,6.8l-1.1,6.7l-1.6,6.7l-1.8,6.4l-2.1,6.3l-2.5,6.3l-2.8,6.3l-3,5.9l-3.3,5.7l-3.6,5.5
l-3.8,5.3l-4,5.2l-2.1,2.5l-2.1,2.6l-4.5,4.7l-4.8,4.5l-4.9,4.3l-5.2,4.1l-5.3,3.6l-5.7,3.6l-5.8,3.3l-5.9,3l-6,2.8l-6.2,2.4
l-6.3,2.1l-6.5,1.9l-6.5,1.5l-6.8,1.1l-6.8,0.9l-7,0.6l-7,0.1v-0.6l7-0.1l6.8-0.6l6.8-0.9l6.8-1.1l6.5-1.5l6.4-1.9l6.3-2.1
l6.2-2.4l6-2.8l5.9-3l5.8-3.3l5.5-3.5l5.3-3.8l5.2-4.1l4.9-4.3l4.8-4.5l4.5-4.7l2-2.4l2.1-2.5l4-5.2l3.8-5.3l3.6-5.5l3.3-5.7
l3-5.9l2.6-6l2.5-6.3l2.1-6.3l1.8-6.4l1.6-6.7l1.1-6.5l0.9-6.8l0.5-7l0.3-6.9H322.3z"
        />
        <line fill="none" x1="322.3" y1="177.4" x2="321.5" y2="177.4" />
        <path
          d="M185.5,314l-7-0.1l-6.9-0.6l-6.8-0.9l-6.8-1.1l-6.7-1.5l-6.4-1.9l-6.5-2.1l-6.2-2.4l-6-2.8l-5.9-3l-5.7-3.3l-5.5-3.6
l-5.3-3.8l-5.2-4.1l-2.5-2l-2.5-2.1L89,274l-4.5-4.7l-4.3-5.2l-4.1-5.2l-3.8-5.3l-3.6-5.5l-3.3-5.7l-3-5.9l-2.8-6l-2.4-6.3
l-2.1-6.4l-1.9-6.4l-1.5-6.7l-1.3-6.8l-0.8-6.8l-0.6-7l-0.1-6.9h0.6l0.1,6.9l0.6,7l0.8,6.8l1.3,6.5l1.5,6.7l1.9,6.4l2.1,6.3
l2.4,6.3l2.8,6l3,5.9l3.3,5.7l3.5,5.4l3.8,5.3l4.1,5.2l4.3,5l4.5,4.7l4.7,4.5l2.5,2.3l2.5,2l5.2,4.1l5.3,3.8l5.4,3.5l5.7,3.3
l5.9,3l6,2.8l6.2,2.4l6.4,2.1l6.4,1.9l6.7,1.5l6.5,1.1l6.8,0.9l6.9,0.6l7,0.1V314z"
        />
        <line fill="none" x1="185.5" y1="314" x2="185.5" y2="313.4" />
        <path
          d="M41.1,177.4l0.1-7l0.6-7l0.9-6.8l1.1-6.8l1.5-6.5l1.9-6.5l2.1-6.3l2.4-6.2l2.8-6l3-5.9l3.3-5.8l3.6-5.7l3.8-5.3l4.1-5.2
l2-2.5l2.3-2.4l4.5-4.8l4.7-4.5l5-4.3l5.2-4l5.3-3.8l5.5-3.6l5.8-3.3l5.8-3l6.3-2.8l6.3-2.5l6.3-2.1l6.4-1.8l6.7-1.6l6.7-1.1
l6.9-0.9l6.9-0.5l6.9-0.3v0.8l-6.9,0.3l-6.9,0.5L157,43l-6.5,1.1l-6.7,1.6l-6.4,1.8l-6.3,2.1l-6.3,2.5l-6,2.6l-5.8,3l-5.8,3.3
l-5.5,3.6l-5.3,3.8l-5.2,4l-4.9,4.3l-4.7,4.5l-4.5,4.8l-2.3,2.4l-2,2.5l-4.1,5.2l-3.8,5.2l-3.5,5.5l-3.3,5.8l-3,5.9l-2.8,6
l-2.4,6.2l-2.1,6.3l-1.9,6.4l-1.5,6.5l-1.1,6.8l-0.9,6.8l-0.6,6.8l-0.1,7H41.1z"
        />
        <path
          d="M177.7,40.6l7,0.3l6.9,0.5l6.9,0.9l6.7,1.1l6.5,1.6l6.5,1.8l6.3,2.1l6.2,2.5l6.2,2.8l5.9,3l5.8,3.3l5.5,3.6l5.3,3.8
l5.2,4l2.5,2.1l2.5,2.1l4.8,4.5l4.5,4.8l4.3,5l4,5.2l3.8,5.3l3.6,5.5l3.3,5.8l3,5.9l2.8,6.2l2.5,6.2l2.1,6.3l1.9,6.5l1.5,6.5
l1.1,6.8l0.9,6.8l0.5,6.9l0.3,7h-0.8l-0.3-7l-0.5-6.8l-0.9-6.8L311,150l-1.5-6.5l-1.9-6.5l-2.1-6.3l-2.5-6.2l-2.6-5.9l-3-5.9
l-3.3-5.8l-3.6-5.5l-3.8-5.3l-4-5.2l-4.1-4.8l-4.5-4.8l-4.8-4.5l-2.4-2.1l-2.5-2.1l-5.2-4l-5.3-3.8l-5.5-3.6l-5.8-3.3l-5.9-3
l-5.9-2.6l-6.2-2.5l-6.3-2.1l-6.5-1.8l-6.5-1.6l-6.7-1.1l-6.9-0.9l-6.8-0.5l-7-0.3V40.6z"
        />
        <line fill="none" x1="177.7" y1="40.6" x2="177.7" y2="41.4" />
        <path
          d="M314.5,177.4l-0.3,6.9l-0.5,7l-0.9,6.8l-1.1,6.7l-1.5,6.7l-1.9,6.4l-2.1,6.3l-2.5,6.3l-2.8,6.3l-3,5.9l-3.3,5.7l-3.6,5.5
l-3.8,5.3l-4,5.2l-2.1,2.5l-2.1,2.6l-4.5,4.7l-4.8,4.5l-4.9,4.3l-5.2,4.1l-5.3,3.6l-5.7,3.6l-5.8,3.3l-5.9,3l-6,2.8l-6.2,2.4
l-6.3,2.1l-6.5,1.9l-6.5,1.5l-6.7,1.1l-6.9,0.9l-7,0.6l-7,0.1v-0.6l7-0.1l6.8-0.6l6.9-0.9l6.7-1.1l6.5-1.5l6.4-1.9l6.3-2.1
l6.2-2.4l6-2.8l5.9-3l5.8-3.3l5.5-3.5l5.3-3.8l5.2-4.1l4.9-4.3l4.8-4.5l4.5-4.7l2-2.4l2.1-2.5l4-5.2l3.8-5.3l3.6-5.5l3.3-5.7
l3-5.9l2.6-6l2.5-6.3l2.1-6.3l1.9-6.4l1.5-6.7l1.1-6.5l0.9-6.8l0.5-7l0.3-6.9H314.5z"
        />
        <line fill="none" x1="314.5" y1="177.4" x2="313.7" y2="177.4" />
        <path
          d="M177.7,314l-6.9-0.1l-6.9-0.6l-6.9-0.9l-6.8-1.1l-6.7-1.5l-6.4-1.9l-6.4-2.1l-6.3-2.4l-6-2.8l-5.8-3l-5.8-3.3l-5.5-3.6
l-5.3-3.8l-5.2-4.1l-2.5-2l-2.5-2.1l-4.7-4.5l-4.5-4.7l-4.3-5.2l-4.1-5.2l-3.8-5.3l-3.6-5.5l-3.3-5.7l-3-5.9l-2.8-6l-2.4-6.3
l-2.1-6.4l-1.9-6.4l-1.5-6.7l-1.1-6.8l-0.9-6.8l-0.6-7l-0.1-6.9h0.6l0.1,6.9l0.6,7l0.9,6.8l1.1,6.5l1.5,6.7l1.9,6.4L50,224
l2.4,6.3l2.8,6l3,5.9l3.3,5.7l3.5,5.4l3.8,5.3l4.1,5.2l4.3,5l4.5,4.7l4.7,4.5l2.5,2.3l2.5,2l5.2,4.1l5.3,3.8l5.4,3.5l5.8,3.3
l5.8,3l6,2.8l6.3,2.4l6.3,2.1l6.4,1.9l6.7,1.5l6.5,1.1l6.9,0.9l6.9,0.6l6.9,0.1V314z"
        />
        <line fill="none" x1="177.7" y1="314" x2="177.7" y2="313.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.9,177.4V174l0.1-3.3l0.3-3.3l0.3-3.4l0.4-3.1l0.5-3.3l0.5-3.3l0.6-3.1l0.6-3.1l0.8-3.1l0.9-3l0.9-3.1
l1-3l1-2.9l1.1-3l1.3-2.9l1.3-2.9l1.3-2.9l1.4-2.8l1.5-2.8l1.5-2.8l1.6-2.6l1.6-2.6l1.8-2.6l1.8-2.6l1.8-2.5l1.9-2.4l2-2.4l2-2.4
l2-2.4l2.1-2.3l2.1-2.3l2.3-2.1l2.3-2.1l2.4-2l2.4-2l2.4-2l2.5-1.9l2.5-1.8L113,70l2.6-1.8l2.6-1.6l2.6-1.6l2.8-1.5l2.8-1.5
l2.8-1.4l2.9-1.3l2.9-1.3l2.9-1.3l3-1.1l2.9-1l3.1-1l3-0.9l3-0.9l3.1-0.8l3.1-0.6l3.3-0.6l3.1-0.5l3.3-0.5l3.3-0.4l3.3-0.3
l3.3-0.3l3.3-0.1h3.4h3.3l3.4,0.1l3.3,0.3l3.3,0.3l3.3,0.4l3.3,0.5l3.1,0.5l3.1,0.6l3.1,0.6l3.1,0.8l3.1,0.9l3,0.9l3,1l3,1l3,1.1
l2.9,1.3l2.9,1.3l2.8,1.3l2.9,1.4l2.8,1.5l2.8,1.5l2.6,1.6l2.6,1.6l2.6,1.8l2.5,1.8l2.5,1.8l2.5,1.9l2.4,2l2.4,2l2.3,2l2.4,2.1
l2.1,2.1l2.3,2.3l2,2.3l2.1,2.4l2,2.4l1.9,2.4l1.9,2.4l1.9,2.5l1.8,2.6l1.8,2.6l1.6,2.6l1.5,2.6l1.6,2.8l1.4,2.8l1.4,2.8l1.4,2.9
l1.3,2.9l1.1,2.9l1.1,3l1.1,2.9l1,3l0.9,3.1l0.9,3l0.8,3.1l0.6,3.1l0.6,3.1l0.5,3.3l0.5,3.3l0.4,3.1l0.3,3.4l0.3,3.3l0.1,3.3v3.4
v3.3l-0.1,3.4l-0.3,3.3l-0.3,3.3l-0.4,3.3l-0.5,3.3l-0.5,3.1l-0.6,3.1l-0.6,3.1l-0.8,3.1l-0.9,3.1l-0.9,3l-1,3l-1.1,3l-1.1,3
l-1.1,2.9l-1.3,2.9l-1.4,2.8l-1.4,2.9l-1.4,2.8l-1.6,2.8l-1.5,2.6l-1.6,2.6l-1.8,2.6l-1.8,2.5l-1.9,2.5l-1.9,2.5l-1.9,2.4l-2,2.4
l-2.1,2.3l-2,2.3l-2.3,2.3l-2.1,2.1l-2.4,2.1l-2.3,2.1l-2.4,2l-2.4,1.9l-2.5,1.9l-2.5,1.9l-2.5,1.8l-2.6,1.8l-2.6,1.6l-2.6,1.5
l-2.8,1.6l-2.8,1.4l-2.9,1.4l-2.8,1.4l-2.9,1.3l-2.9,1.1l-3,1.1l-3,1.1l-3,1l-3,0.9l-3.1,0.8l-3.1,0.9l-3.1,0.6l-3.1,0.6
l-3.1,0.5l-3.3,0.5l-3.3,0.4l-3.3,0.3l-3.3,0.3l-3.4,0.1h-3.3H182l-3.3-0.1l-3.3-0.3l-3.3-0.3l-3.3-0.4l-3.3-0.5l-3.1-0.5
l-3.3-0.6l-3.1-0.6l-3.1-0.9l-3-0.8l-3-0.9l-3.1-1l-2.9-1.1l-3-1.1l-2.9-1.1l-2.9-1.3l-2.9-1.4l-2.8-1.4l-2.8-1.4l-2.8-1.6
l-2.6-1.5l-2.6-1.6l-2.6-1.8l-2.5-1.8l-2.5-1.9l-2.5-1.9l-2.4-1.9l-2.4-2l-2.4-2.1L96,271l-2.3-2.1l-2.1-2.3l-2.1-2.3l-2-2.3
l-2-2.4l-2-2.4l-1.9-2.5l-1.8-2.5l-1.8-2.5l-1.8-2.6l-1.6-2.6l-1.6-2.6l-1.5-2.8l-1.5-2.8l-1.4-2.9l-1.3-2.8l-1.3-2.9l-1.3-2.9
l-1.1-3l-1-3l-1-3l-0.9-3l-0.9-3.1l-0.8-3.1l-0.6-3.1l-0.6-3.1l-0.5-3.1l-0.5-3.3l-0.4-3.3l-0.3-3.3l-0.3-3.3l-0.1-3.4V177.4z
 M48.5,177.4V174l0.1-3.3l0.3-3.3l0.3-3.4l0.4-3.1l0.5-3.3l0.5-3.3l0.6-3.1l0.6-3.1l0.8-3.1l0.9-3l0.9-3.1l1-3l1-2.9l1.1-3
l1.3-2.9l1.3-2.9l1.4-2.9l1.4-2.8l1.4-2.8l1.5-2.8l1.6-2.6l1.6-2.6l1.8-2.6l1.8-2.6l1.9-2.5l1.9-2.4l1.9-2.4l2-2.4l2.1-2.4l2-2.3
l2.3-2.3l2.1-2.1l2.3-2.1l2.4-2l2.4-2l2.4-2l2.5-1.9l2.5-1.8l2.5-1.8l2.6-1.8l2.6-1.6l2.6-1.6l2.8-1.5L119,62l2.8-1.4l2.9-1.3
l2.9-1.3l2.9-1.3l3-1.1l3-1l3-1l3-0.9l3.1-0.9l3-0.8l3.1-0.6l3.3-0.6l3.1-0.5l3.3-0.5l3.3-0.4l3.3-0.3l3.3-0.3l3.3-0.1h3.4h3.4
l3.3,0.1l3.3,0.3l3.3,0.3l3.3,0.4l3.3,0.5l3.1,0.5l3.3,0.6l3.1,0.6l3,0.8l3.1,0.9l3,0.9l3,1l3,1l3,1.1l2.9,1.3l2.9,1.3l2.9,1.3
l2.8,1.4l2.8,1.5l2.8,1.5l2.6,1.6l2.6,1.6l2.6,1.8l2.5,1.8l2.5,1.8l2.5,1.9l2.4,2l2.4,2l2.4,2l2.3,2.1l2.1,2.1l2.3,2.3l2.1,2.3
l2,2.4l2,2.4l1.9,2.4l1.9,2.4l1.9,2.5l1.8,2.6l1.8,2.6l1.6,2.6l1.6,2.6l1.5,2.8l1.4,2.8l1.5,2.8l1.3,2.9l1.3,2.9l1.3,2.9l1.1,3
l1,2.9l1,3l0.9,3.1l0.9,3l0.8,3.1l0.6,3.1l0.6,3.1l0.5,3.3l0.5,3.3l0.4,3.1l0.3,3.4l0.3,3.3l0.1,3.3v3.4v3.3l-0.1,3.4l-0.3,3.3
l-0.3,3.3l-0.4,3.3l-0.5,3.3l-0.5,3.1l-0.6,3.1l-0.6,3.1l-0.8,3.1l-0.9,3.1l-0.9,3l-1,3l-1,3l-1.1,3l-1.3,2.9l-1.3,2.9l-1.3,2.8
l-1.5,2.9l-1.4,2.8l-1.5,2.8l-1.6,2.6l-1.6,2.6l-1.8,2.6l-1.8,2.5l-1.9,2.5l-1.9,2.5l-1.9,2.4l-2,2.4l-2,2.3l-2.1,2.3l-2.3,2.3
l-2.1,2.1l-2.3,2.1l-2.4,2.1l-2.4,2l-2.4,1.9l-2.5,1.9l-2.5,1.9l-2.5,1.8l-2.6,1.8l-2.6,1.6l-2.6,1.5l-2.8,1.6l-2.8,1.4l-2.8,1.4
l-2.9,1.4l-2.9,1.3l-2.9,1.1l-3,1.1l-3,1.1l-3,1l-3,0.9l-3.1,0.8l-3,0.9l-3.1,0.6l-3.3,0.6l-3.1,0.5l-3.3,0.5l-3.3,0.4l-3.3,0.3
l-3.3,0.3l-3.3,0.1H178h-3.4l-3.3-0.1l-3.3-0.3l-3.3-0.3l-3.3-0.4l-3.3-0.5l-3.1-0.5l-3.3-0.6l-3.1-0.6l-3-0.9l-3.1-0.8l-3-0.9
l-3-1l-3-1.1l-3-1.1l-2.9-1.1l-2.9-1.3l-2.9-1.4l-2.8-1.4l-2.8-1.4l-2.8-1.6l-2.6-1.5l-2.6-1.6l-2.6-1.8l-2.5-1.8l-2.5-1.9
l-2.5-1.9l-2.4-1.9l-2.4-2l-2.4-2.1l-2.3-2.1l-2.1-2.1l-2.3-2.3l-2-2.3l-2.1-2.3l-2-2.4l-1.9-2.4l-1.9-2.5l-1.9-2.5l-1.8-2.5
l-1.8-2.6l-1.6-2.6l-1.6-2.6l-1.5-2.8l-1.4-2.8l-1.4-2.9l-1.4-2.8l-1.3-2.9l-1.3-2.9l-1.1-3l-1-3l-1-3l-0.9-3l-0.9-3.1l-0.8-3.1
l-0.6-3.1l-0.6-3.1l-0.5-3.1l-0.5-3.3l-0.4-3.3l-0.3-3.3l-0.3-3.3l-0.1-3.4V177.4z"
        />
        <path
          d="M55.6,177.4l0.1-6.7l0.5-6.8l0.9-6.4l1.1-6.4l1.4-6.3l1.8-6.2l2-6l2.4-5.8l2.5-5.8l2.9-5.5l3.1-5.4l3.5-5.4l3.5-5.2
l3.9-4.9l4-4.7l4.3-4.5l4.5-4.3l4.7-4l4.9-3.9l5.2-3.5l5.3-3.4l5.4-3.1l5.5-2.9l5.8-2.5l3.1-1.4l3-1.1l5.9-2l6.2-1.8l6.3-1.4
l6.4-1.3l6.4-0.8l6.7-0.5l6.7-0.1v0.8l-6.7,0.1l-6.7,0.5l-6.4,0.8l-6.3,1.3l-6.3,1.4l-6.2,1.8l-5.9,2l-3,1.1l-2.9,1.3l-5.8,2.5
l-5.5,2.9l-5.4,3.1l-5.3,3.4l-5.2,3.5l-4.9,3.9l-4.5,4L94,86.1l-4.3,4.5l-4,4.7l-3.9,4.9l-3.5,5l-3.4,5.3l-3.1,5.4l-2.9,5.5
l-2.5,5.8L64,133l-2,6l-1.8,6l-1.4,6.3l-1.1,6.4l-0.9,6.4l-0.5,6.5l-0.1,6.7H55.6z"
        />
        <path
          d="M185.4,47.5l6.7,0.1l6.5,0.5l6.5,0.8l6.3,1.3l6.3,1.4l6.2,1.8l6,2l5.9,2.4l5.8,2.6l5.7,2.9l5.4,3.1l5.3,3.4l5,3.5
l4.9,3.9l4.8,4l4.5,4.3l4.3,4.5l4.1,4.8l3.8,4.9l3.6,5.2l3.4,5.3l3,5.4l2.9,5.5l2.6,5.8l1.3,3l1.1,2.9l2.1,6l1.6,6.2l1.5,6.3
l1.1,6.4l0.8,6.4l0.5,6.7l0.3,6.7h-0.8l-0.3-6.7l-0.5-6.5l-0.8-6.4l-1.1-6.4l-1.5-6.3l-1.6-6.2l-2.1-6l-1.1-2.9l-1.1-2.8
l-2.6-5.8l-2.9-5.5l-3-5.4l-3.4-5.3l-3.6-5.2l-3.8-4.9l-4-4.5l-4.3-4.5l-4.5-4.3l-4.6-4l-4.9-3.9l-5-3.5l-5.3-3.4l-5.4-3.1
l-5.7-2.9l-5.5-2.5l-5.9-2.4l-6-2l-6.2-1.8l-6.3-1.4l-6.3-1.3l-6.5-0.8l-6.4-0.5l-6.7-0.1V47.5z"
        />
        <line fill="none" x1="185.4" y1="47.5" x2="185.4" y2="48.3" />
        <path
          d="M315.2,177.4L315,184l-0.5,6.5l-0.8,6.5l-1.1,6.3l-1.5,6.3l-1.6,6.2l-2.1,6l-2.3,5.9l-2.8,5.9l-2.9,5.7l-3,5.4L293,250
l-3.6,5l-3.8,4.9l-4.1,4.8l-4.3,4.5l-4.5,4.3l-4.7,4.1l-4.9,3.8l-5,3.5l-5.4,3.5l-5.4,3l-5.7,2.9l-5.7,2.6l-3,1.1l-2.9,1.1l-6,2
l-6.2,1.8l-6.3,1.5l-6.3,1.1l-6.5,0.8l-6.7,0.5l-6.7,0.3v-0.6l6.7-0.3l6.4-0.5l6.5-0.8l6.3-1.1l6.3-1.5l6-1.8l6-2l2.9-1.1l3-1.1
l5.7-2.6l5.7-2.9l5.4-3l5.3-3.4l5-3.6l4.9-3.8l4.6-4.1l4.5-4.3l4.3-4.5l4-4.5l3.8-4.9l3.6-5l3.4-5.3l3-5.4l2.9-5.7l2.6-5.7
l2.3-5.9l2.1-6l1.6-6.2l1.5-6.3l1.1-6.2l0.8-6.5l0.5-6.5l0.3-6.7H315.2z"
        />
        <line fill="none" x1="315.2" y1="177.4" x2="314.5" y2="177.4" />
        <path
          d="M185.4,307.2l-6.7-0.3l-6.7-0.5l-6.4-0.8l-6.5-1.1l-6.3-1.5l-6.2-1.8l-6-2l-5.9-2.3l-5.8-2.6l-5.5-2.9l-5.4-3l-5.3-3.5
l-5.2-3.6l-4.9-3.8l-4.7-4l-4.5-4.3l-4.3-4.5l-4-4.8l-3.9-4.9l-3.5-5l-3.5-5.3l-3.1-5.4l-2.9-5.7l-2.5-5.7l-1.3-3l-1.1-3l-2-6
l-1.8-6.2l-1.4-6.3l-1.1-6.4l-0.9-6.5l-0.5-6.5l-0.1-6.7h0.6l0.1,6.7l0.5,6.5l0.9,6.5l1.1,6.2l1.4,6.3l1.8,6.2l2,6l1.1,2.9l1.3,3
l2.5,5.7l2.9,5.7l3.1,5.4l3.4,5.2l3.5,5l3.9,4.9l4,4.7l4.3,4.5l4.5,4.3l4.7,4.1l4.9,3.8l5.2,3.6l5.2,3.4l5.4,3l5.5,2.9l5.8,2.6
l5.9,2.3l5.9,2l6.2,1.8l6.3,1.5l6.3,1.1l6.4,0.8l6.7,0.5l6.7,0.3V307.2z"
        />
        <line fill="none" x1="185.4" y1="307.2" x2="185.4" y2="306.6" />
        <line fill="none" x1="55.6" y1="177.4" x2="56.2" y2="177.4" />
        <path
          d="M48.1,177.4l0.1-6.7l0.5-6.8l0.9-6.4l1.1-6.4l1.4-6.3l1.8-6.2l2-6l2.4-5.8l2.5-5.8l2.9-5.5l3.1-5.4l3.5-5.4l3.5-5.2
l3.9-4.9l4-4.7l4.4-4.5l4.4-4.3l4.8-4l4.9-3.9l5-3.5l5.3-3.4l5.4-3.1l5.5-2.9l5.8-2.5l3.1-1.4l3-1.1l6-2l6-1.8l6.3-1.4l6.4-1.3
l6.5-0.8l6.5-0.5l6.7-0.1v0.8l-6.7,0.1l-6.5,0.5l-6.5,0.8l-6.3,1.3l-6.3,1.4l-6,1.8l-6,2l-3,1.1l-2.9,1.3l-5.8,2.5l-5.5,2.9
L111,67l-5.3,3.4l-5,3.5l-4.9,3.9l-4.7,4l-4.4,4.3l-4.4,4.5l-4,4.7l-3.9,4.9l-3.5,5l-3.4,5.3l-3.1,5.4l-2.9,5.5l-2.5,5.8
l-2.4,5.8l-2,6l-1.8,6l-1.4,6.3l-1.1,6.4l-0.9,6.4l-0.5,6.5l-0.1,6.7H48.1z"
        />
        <path
          d="M178,47.5l6.7,0.1l6.5,0.5l6.5,0.8l6.4,1.3l6.2,1.4l6.2,1.8l6,2l5.9,2.4l5.9,2.6l5.5,2.9l5.4,3.1l5.3,3.4l5,3.5l4.9,3.9
l4.9,4l4.4,4.3L274,90l4.1,4.8l3.8,4.9l3.6,5.2l3.4,5.3l3.1,5.4l2.8,5.5l2.6,5.8l1.4,3l1.1,2.9l2,6l1.8,6.2l1.4,6.3l1.1,6.4
l0.9,6.4l0.5,6.7l0.1,6.7h-0.8l-0.1-6.7l-0.5-6.5l-0.9-6.4l-1.1-6.4l-1.4-6.3l-1.8-6.2l-2-6l-1.1-2.9l-1.3-2.8l-2.6-5.8l-2.8-5.5
l-3.1-5.4l-3.4-5.3l-3.6-5.2l-3.8-4.9l-4-4.5l-4.3-4.5l-4.4-4.3l-4.8-4l-4.9-3.9l-5-3.5l-5.3-3.4l-5.4-3.1l-5.5-2.9l-5.7-2.5
l-5.9-2.4l-6-2l-6.2-1.8l-6.2-1.4l-6.4-1.3l-6.5-0.8l-6.4-0.5l-6.7-0.1V47.5z"
        />
        <line fill="none" x1="178" y1="47.5" x2="178" y2="48.3" />
        <path
          d="M307.8,177.4l-0.1,6.7l-0.5,6.5l-0.9,6.5l-1.1,6.3l-1.4,6.3l-1.8,6.2l-2,6l-2.4,5.9l-2.8,5.9l-2.8,5.7l-3.1,5.4l-3.4,5.3
l-3.6,5l-3.8,4.9l-4.1,4.8l-4.3,4.5l-4.4,4.3l-4.8,4.1l-4.9,3.8l-5,3.5l-5.4,3.5l-5.4,3l-5.5,2.9l-5.8,2.6l-2.9,1.1l-3,1.1l-6,2
l-6.2,1.8l-6.2,1.5l-6.4,1.1l-6.5,0.8l-6.7,0.5l-6.7,0.3v-0.6l6.7-0.3l6.4-0.5l6.5-0.8l6.4-1.1l6.2-1.5l6-1.8l6-2l3-1.1l2.9-1.1
l5.8-2.6l5.5-2.9l5.4-3l5.3-3.4l5-3.6l4.9-3.8l4.8-4.1l4.4-4.3l4.3-4.5l4-4.5l3.8-4.9l3.6-5l3.4-5.3l3.1-5.4l2.8-5.7l2.6-5.7
l2.4-5.9l2-6l1.8-6.2l1.4-6.3l1.1-6.2l0.9-6.5l0.5-6.5l0.1-6.7H307.8z"
        />
        <line fill="none" x1="307.8" y1="177.4" x2="307.1" y2="177.4" />
        <path
          d="M178,307.2l-6.7-0.3l-6.5-0.5l-6.5-0.8l-6.5-1.1l-6.3-1.5l-6-1.8l-6.2-2l-5.9-2.3l-5.8-2.6l-5.5-2.9l-5.4-3l-5.3-3.5
l-5-3.6l-4.9-3.8l-4.8-4l-4.4-4.3l-4.4-4.5l-4-4.8l-3.9-4.9l-3.5-5l-3.5-5.3l-3.1-5.4l-2.9-5.7l-2.5-5.7l-1.3-3l-1.1-3l-2-6
l-1.8-6.2l-1.4-6.3l-1.1-6.4l-0.9-6.5l-0.5-6.5l-0.1-6.7h0.6l0.1,6.7l0.5,6.5l0.9,6.5l1.1,6.2l1.4,6.3l1.8,6.2l2,6l1.1,2.9l1.3,3
l2.5,5.7l2.9,5.7l3.1,5.4l3.4,5.2l3.5,5l3.9,4.9l4,4.7l4.4,4.5l4.4,4.3l4.8,4.1l4.9,3.8l5,3.6l5.2,3.4l5.4,3l5.5,2.9l5.8,2.6
l5.9,2.3l6,2l6,1.8l6.3,1.5l6.3,1.1l6.5,0.8l6.5,0.5l6.7,0.3V307.2z"
        />
        <line fill="none" x1="178" y1="307.2" x2="178" y2="306.6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.2,177.4v-3.1l0.1-3.3l0.3-3l0.3-3.1l0.4-3.1l0.4-3l0.5-3l0.6-3l0.6-3l0.8-3l0.8-2.9l0.9-2.9l0.9-2.9
l1-2.9l1.1-2.8l1.1-2.8l1.1-2.8l1.3-2.6l1.4-2.8l1.4-2.6l1.4-2.5l1.5-2.6l1.6-2.5l1.6-2.4l1.6-2.5l1.8-2.4l1.9-2.3l1.8-2.4
l1.9-2.3l2-2.1l2-2.1l2-2.1l2.1-2.1l2.1-2l2.3-1.9l2.3-1.9l2.3-1.9l2.4-1.8l2.4-1.8l2.4-1.8l2.5-1.6l2.5-1.5l2.5-1.5l2.6-1.5
l2.6-1.4l2.6-1.3l2.8-1.3l2.6-1.3l2.9-1.1l2.8-1l2.9-1l2.8-1l2.9-0.9l3-0.8l2.9-0.8l3-0.6l3-0.5l3-0.6l3.1-0.4l3-0.4l3.1-0.3
l3.1-0.3h3.1l3.1-0.1l3.3,0.1h3.1l3.1,0.3l3,0.3l3.1,0.4l3,0.4l3.1,0.6l3,0.5l2.9,0.6l3,0.8l2.9,0.8l2.9,0.9l2.9,1l2.9,1l2.8,1
L233,64l2.8,1.3l2.8,1.3l2.6,1.3l2.6,1.4l2.5,1.5l2.6,1.5l2.5,1.5l2.5,1.6l2.4,1.8l2.4,1.8l2.4,1.8l2.3,1.9l2.3,1.9l2.1,1.9
l2.3,2l2,2.1l2.1,2.1l2,2.1l1.9,2.1l2,2.3l1.8,2.4l1.9,2.3l1.6,2.4l1.8,2.5l1.6,2.4l1.5,2.5l1.5,2.6l1.5,2.5l1.4,2.6l1.3,2.8
l1.4,2.6l1.1,2.8l1.1,2.8l1.1,2.8l1,2.9l0.9,2.9l0.9,2.9l0.8,2.9l0.8,3l0.6,3l0.6,3l0.5,3l0.4,3l0.4,3.1l0.3,3.1l0.3,3l0.1,3.3
v3.1v3.1l-0.1,3.1l-0.3,3.1l-0.3,3.1l-0.4,3l-0.4,3.1l-0.5,3l-0.6,3l-0.6,3l-0.8,2.9l-0.8,3l-0.9,2.9l-0.9,2.9l-1,2.8l-1.1,2.9
l-1.1,2.8l-1.1,2.8l-1.4,2.6l-1.3,2.6l-1.4,2.6l-1.5,2.6l-1.5,2.5l-1.5,2.5l-1.6,2.5l-1.8,2.4l-1.6,2.4l-1.9,2.4l-1.8,2.3l-2,2.3
l-1.9,2.3l-2,2.1l-2.1,2.1l-2,2l-2.3,2l-2.1,2l-2.3,1.9l-2.3,1.9l-2.4,1.8l-2.4,1.8l-2.4,1.6l-2.5,1.6l-2.5,1.6l-2.6,1.5
l-2.5,1.4l-2.6,1.4l-2.6,1.4l-2.8,1.3l-2.8,1.1l-2.8,1.1l-2.8,1.1l-2.9,1l-2.9,0.9l-2.9,0.9l-2.9,0.9l-3,0.6l-2.9,0.6l-3,0.6
l-3.1,0.5l-3,0.5l-3.1,0.3l-3,0.4l-3.1,0.1l-3.1,0.1h-3.3H182l-3.1-0.1l-3.1-0.1l-3.1-0.4l-3-0.3l-3.1-0.5l-3-0.5l-3-0.6l-3-0.6
l-2.9-0.6l-3-0.9l-2.9-0.9l-2.8-0.9l-2.9-1l-2.8-1.1l-2.9-1.1l-2.6-1.1l-2.8-1.3l-2.6-1.4l-2.6-1.4l-2.6-1.4l-2.5-1.5l-2.5-1.6
l-2.5-1.6l-2.4-1.6l-2.4-1.8l-2.4-1.8l-2.3-1.9l-2.3-1.9l-2.3-2l-2.1-2l-2.1-2l-2-2.1l-2-2.1l-2-2.3l-1.9-2.3l-1.8-2.3l-1.9-2.4
l-1.8-2.4l-1.6-2.4l-1.6-2.5l-1.6-2.5l-1.5-2.5L77,236l-1.4-2.6l-1.4-2.6L73,228l-1.1-2.8l-1.1-2.8l-1.1-2.9l-1-2.8l-0.9-2.9
l-0.9-2.9l-0.8-3l-0.8-2.9l-0.6-3l-0.6-3l-0.5-3l-0.4-3.1l-0.4-3l-0.3-3.1l-0.3-3.1l-0.1-3.1V177.4z M55.2,177.4v-3.1l0.1-3.3
l0.3-3l0.3-3.1l0.4-3.1l0.4-3l0.5-3l0.6-3l0.6-3l0.8-3l0.8-2.9l0.9-2.9l0.9-2.9l1-2.9l1.1-2.8l1.1-2.8l1.1-2.8l1.3-2.6l1.4-2.8
l1.4-2.6l1.5-2.5l1.5-2.6l1.5-2.5l1.6-2.4l1.6-2.5l1.8-2.4l1.9-2.3l1.8-2.4l2-2.3l1.9-2.1l2-2.1l2.1-2.1l2-2.1l2.3-2l2.1-1.9
l2.3-1.9l2.3-1.9l2.4-1.8l2.4-1.8l2.4-1.8l2.5-1.6l2.5-1.5l2.5-1.5l2.6-1.5l2.6-1.4l2.6-1.3l2.8-1.3l2.8-1.3l2.8-1.1l2.8-1l2.9-1
l2.9-1l2.9-0.9l2.9-0.8l3-0.8l2.9-0.6l3-0.5l3-0.6l3.1-0.4l3-0.4l3.1-0.3l3.1-0.3h3.1l3.3-0.1l3.1,0.1h3.1l3.1,0.3l3.1,0.3l3,0.4
l3.1,0.4l3,0.6l3,0.5l3,0.6l2.9,0.8l2.9,0.8l3,0.9l2.8,1l2.9,1l2.8,1L226,64l2.8,1.3l2.8,1.3l2.6,1.3l2.6,1.4l2.6,1.5l2.5,1.5
l2.5,1.5l2.5,1.6l2.4,1.8l2.4,1.8l2.4,1.8l2.3,1.9l2.3,1.9l2.1,1.9l2.3,2l2,2.1l2.1,2.1l2,2.1l1.9,2.1l2,2.3l1.8,2.4l1.9,2.3
l1.8,2.4l1.6,2.5l1.6,2.4l1.5,2.5l1.5,2.6l1.5,2.5l1.4,2.6l1.4,2.8l1.3,2.6l1.1,2.8l1.1,2.8l1.1,2.8l1,2.9l0.9,2.9l0.9,2.9
l0.8,2.9l0.8,3l0.6,3l0.6,3l0.5,3l0.4,3l0.4,3.1l0.3,3.1l0.3,3l0.1,3.3v3.1v3.1l-0.1,3.1l-0.3,3.1l-0.3,3.1l-0.4,3l-0.4,3.1
l-0.5,3l-0.6,3l-0.6,3l-0.8,2.9l-0.8,3l-0.9,2.9l-0.9,2.9l-1,2.8l-1.1,2.9l-1.1,2.8l-1.1,2.8l-1.3,2.6l-1.4,2.6l-1.4,2.6
l-1.5,2.6l-1.5,2.5l-1.5,2.5l-1.6,2.5l-1.6,2.4l-1.8,2.4l-1.9,2.4l-1.8,2.3l-2,2.3l-1.9,2.3l-2,2.1l-2.1,2.1l-2,2l-2.3,2l-2.1,2
l-2.3,1.9l-2.3,1.9l-2.4,1.8l-2.4,1.8l-2.4,1.6l-2.5,1.6l-2.5,1.6l-2.5,1.5l-2.6,1.4l-2.6,1.4l-2.6,1.4l-2.8,1.3l-2.8,1.1
l-2.8,1.1l-2.8,1.1l-2.9,1l-2.8,0.9l-3,0.9l-2.9,0.9l-2.9,0.6l-3,0.6l-3,0.6l-3,0.5l-3.1,0.5l-3,0.3l-3.1,0.4l-3.1,0.1l-3.1,0.1
h-3.1H175l-3.1-0.1l-3.1-0.1l-3.1-0.4l-3-0.3l-3.1-0.5l-3-0.5l-3-0.6l-2.9-0.6l-3-0.6l-2.9-0.9l-2.9-0.9l-2.9-0.9l-2.9-1
l-2.8-1.1l-2.8-1.1l-2.8-1.1l-2.8-1.3l-2.6-1.4l-2.6-1.4l-2.6-1.4l-2.5-1.5l-2.5-1.6l-2.5-1.6l-2.4-1.6l-2.4-1.8l-2.4-1.8
l-2.3-1.9l-2.3-1.9l-2.1-2l-2.3-2l-2-2l-2.1-2.1l-2-2.1l-1.9-2.3l-2-2.3l-1.8-2.3l-1.9-2.4l-1.8-2.4l-1.6-2.4l-1.6-2.5l-1.5-2.5
l-1.5-2.5L70,236l-1.4-2.6l-1.4-2.6L66,228l-1.1-2.8l-1.1-2.8l-1.1-2.9l-1-2.8l-0.9-2.9l-0.9-2.9l-0.8-3l-0.8-2.9l-0.6-3l-0.6-3
l-0.5-3l-0.4-3.1l-0.4-3l-0.3-3.1l-0.3-3.1l-0.1-3.1V177.4z"
        />
        <path
          d="M61.8,177.4L62,171l0.5-6.3l0.8-6.2l1.1-6.2l1.3-5.9l1.8-5.8l1.9-5.8l1.1-2.8l1.1-2.8l2.4-5.5l2.8-5.3l1.4-2.5l1.5-2.6
l3.4-5l3.4-4.9l3.6-4.7l3.9-4.4l4-4.3l4.3-4.1l4.5-3.8l4.7-3.6l4.8-3.5l5-3.1l5.2-3l5.3-2.6l5.7-2.6l5.7-2.3l5.7-1.9l5.9-1.6
l5.9-1.4l6-1.1l6.2-0.8l6.3-0.5l6.3-0.1v0.8l-6.3,0.1l-6.3,0.5l-6.2,0.8l-5.9,1.1l-5.9,1.4l-5.9,1.6l-5.7,1.9l-5.7,2.3l-5.4,2.5
l-5.3,2.6l-5.2,3l-5,3.1l-4.8,3.5l-4.7,3.6l-4.4,3.8l-4.3,4.1l-4,4.3l-3.9,4.4l-3.6,4.7l-3.4,4.8l-3.3,4.9l-1.5,2.6l-1.4,2.5
l-2.8,5.3l-2.4,5.5l-1.1,2.8l-1.1,2.8L68,141l-1.8,5.7l-1.3,5.9l-1.1,6.2l-0.8,6.2l-0.5,6l-0.1,6.4H61.8z"
        />
        <path
          d="M185.1,53.9l6.4,0.1l6.2,0.5l6.2,0.8l6,1.1l6,1.4l5.8,1.6l5.8,1.9l2.8,1.1l2.9,1.3l5.5,2.5l5.3,2.6l2.5,1.5l2.6,1.5
L254,75l4.9,3.5l4.6,3.6l4.5,3.8l4.3,4.1l4.1,4.3l3.8,4.5l3.6,4.7l3.5,4.9l3.1,4.9l3,5.2l2.6,5.3l2.6,5.7l2.1,5.5l2,5.8l1.6,5.8
l1.4,5.9l1.1,6.2l0.8,6.2l0.5,6.2l0.1,6.4h-0.8l-0.1-6.4l-0.5-6l-0.8-6.2l-1.1-6.2l-1.4-5.9l-1.6-5.8l-2-5.8l-2.1-5.5l-2.5-5.4
l-2.6-5.3l-3-5.2l-3.1-4.9l-3.5-4.9l-3.6-4.7L276,95l-4.1-4.3l-4.3-4.1l-4.4-3.8l-4.7-3.6l-4.9-3.5l-4.9-3.1l-2.6-1.5l-2.5-1.5
l-5.3-2.6l-5.5-2.5l-2.6-1.1l-2.8-1.1l-5.8-1.9l-5.8-1.6l-6-1.4l-6-1.1l-6.2-0.8l-6-0.5l-6.4-0.1V53.9z"
        />
        <line fill="none" x1="185.1" y1="53.9" x2="185.1" y2="54.7" />
        <path
          d="M308.6,177.4l-0.1,6.3l-0.5,6.3l-0.8,6.2l-1.1,6l-1.4,5.9L303,214l-2,5.7l-1,2.8l-1.3,3.1l-2.5,5.4l-2.6,5.3l-1.5,2.6
l-1.5,2.5l-3.1,5l-3.5,4.8l-3.6,4.7l-3.8,4.7l-4.1,4.3l-4.3,4l-4.4,3.9l-4.7,3.6l-4.9,3.3l-5,3.4l-5.2,2.9l-5.3,2.8l-5.5,2.4
l-5.5,2.3l-5.8,1.9l-5.8,1.6l-6,1.4l-6,1.1l-6.2,0.8l-6.3,0.5l-6.4,0.1v-0.6l6.4-0.1l6-0.5l6.2-0.8l6-1.1l6-1.4l5.7-1.6l5.8-1.9
l5.5-2.3l5.5-2.4l5.3-2.8l5.2-2.9l4.9-3.3l4.9-3.4l4.7-3.6l4.4-3.9l4.3-4l4.1-4.3l3.6-4.4l3.6-4.7l3.5-4.8l3.1-5l1.5-2.5l1.5-2.6
l2.6-5.3l2.5-5.4l1.1-2.9l1-2.8l2-5.7l1.6-5.9l1.4-5.9l1.1-5.9l0.8-6.2l0.5-6.3l0.1-6.3H308.6z"
        />
        <line fill="none" x1="308.6" y1="177.4" x2="307.8" y2="177.4" />
        <path
          d="M185.1,300.7l-6.3-0.1l-6.3-0.5l-6.2-0.8l-6.2-1.1l-5.9-1.4l-5.9-1.6l-5.7-1.9l-3-1.1L137,291l-5.4-2.4l-5.3-2.8
l-2.6-1.4l-2.5-1.5l-5-3.4l-4.8-3.4l-4.7-3.6l-4.5-3.8l-4.3-4l-4-4.3l-3.9-4.7l-3.6-4.7l-3.4-4.8l-3.4-5l-2.9-5.2l-2.8-5.3
l-2.4-5.4l-2.3-5.7l-1.9-5.8l-1.8-5.9l-1.3-5.9l-1.1-6.2l-0.8-6.2l-0.5-6.3l-0.1-6.3h0.6l0.1,6.3l0.5,6.3l0.8,6.2L65,202l1.3,5.9
l1.8,5.9l1.9,5.7l2.3,5.7l2.4,5.4l2.8,5.3l2.9,5.2l3.3,4.9l3.4,4.8l3.6,4.7l3.9,4.5l4,4.3l4.3,4l4.5,3.9l4.6,3.6l4.8,3.4l4.9,3.3
l2.5,1.5l2.6,1.4l5.3,2.8l5.4,2.4l2.8,1.1l2.9,1.1l5.7,1.9l5.9,1.6l5.9,1.4l5.9,1.1l6.2,0.8l6.3,0.5l6.3,0.1V300.7z"
        />
        <line fill="none" x1="185.1" y1="300.7" x2="185.1" y2="300.1" />
        <line fill="none" x1="61.8" y1="177.4" x2="62.5" y2="177.4" />
        <path
          d="M54.8,177.4l0.1-6.4l0.5-6.3l0.8-6.2l1.1-6.2l1.4-5.9l1.6-5.8l1.9-5.8l1.1-2.8l1.1-2.8l2.4-5.5l2.8-5.3L71,116l1.5-2.6
l3.4-5l3.4-4.9l3.6-4.7l3.9-4.4l4-4.3l4.3-4.1l4.5-3.8l4.7-3.6l4.8-3.5l5-3.1l5.2-3l5.3-2.6l5.7-2.6l5.7-2.3l5.7-1.9l5.9-1.6
l5.9-1.4l6-1.1l6.2-0.8l6.3-0.5l6.3-0.1v0.8l-6.3,0.1l-6.3,0.5l-6.2,0.8l-5.9,1.1l-5.9,1.4l-5.9,1.6l-5.7,1.9l-5.7,2.3l-5.4,2.5
l-5.3,2.6l-5.2,3l-5,3.1l-4.8,3.5l-4.7,3.6l-4.4,3.8l-4.3,4.1l-4,4.3l-3.9,4.4l-3.6,4.7l-3.4,4.8l-3.3,4.9l-1.5,2.6l-1.4,2.5
l-2.8,5.3l-2.4,5.5l-1.1,2.8l-1.1,2.8L61,141l-1.6,5.7l-1.4,5.9l-1.1,6.2l-0.8,6.2l-0.5,6l-0.1,6.4H54.8z"
        />
        <path
          d="M178.1,53.9l6.4,0.1l6.2,0.5l6.2,0.8l6.2,1.1l5.9,1.4l5.8,1.6l5.8,1.9l2.8,1.1l2.9,1.3l5.5,2.5l5.3,2.6l2.5,1.5l2.6,1.5
l5,3.1l4.8,3.5l4.6,3.6l4.5,3.8l4.3,4.1l4.1,4.3l3.9,4.5l3.6,4.7l3.4,4.9l3.1,4.9l3,5.2l2.6,5.3l2.6,5.7l2.3,5.5l1.9,5.8l1.6,5.8
l1.4,5.9l1.1,6.2l0.8,6.2l0.5,6.2l0.1,6.4h-0.8l-0.1-6.4l-0.5-6l-0.8-6.2l-1.1-6.2l-1.4-5.9l-1.6-5.8l-1.9-5.8l-2.3-5.5l-2.5-5.4
l-2.6-5.3l-3-5.2l-3.1-4.9l-3.4-4.9l-3.6-4.7L269,95l-4.1-4.3l-4.3-4.1l-4.4-3.8l-4.7-3.6l-4.8-3.5l-5-3.1l-2.6-1.5l-2.5-1.5
l-5.3-2.6l-5.5-2.5l-2.6-1.1l-2.8-1.1l-5.8-1.9l-5.8-1.6l-5.9-1.4l-6.2-1.1l-6.2-0.8l-6-0.5l-6.4-0.1V53.9z"
        />
        <line fill="none" x1="178.1" y1="53.9" x2="178.1" y2="54.7" />
        <path
          d="M301.5,177.4l-0.1,6.3l-0.5,6.3l-0.8,6.2l-1.1,6l-1.4,5.9L296,214l-1.9,5.7l-1.1,2.8l-1.3,3.1l-2.5,5.4l-2.6,5.3
l-1.5,2.6l-1.5,2.5l-3.1,5l-3.4,4.8l-3.6,4.7l-3.9,4.7l-4.1,4.3l-4.3,4l-4.4,3.9l-4.6,3.6l-4.8,3.3l-5.2,3.4l-5.2,2.9l-5.3,2.8
l-5.5,2.4l-5.5,2.3l-5.8,1.9l-5.8,1.6l-5.9,1.4l-6.2,1.1l-6.2,0.8l-6.3,0.5l-6.4,0.1v-0.6l6.4-0.1l6-0.5l6.2-0.8l6.2-1.1l5.9-1.4
l5.7-1.6l5.8-1.9l5.5-2.3l5.5-2.4l5.3-2.8l5.2-2.9l5-3.3l4.8-3.4l4.7-3.6l4.4-3.9l4.3-4l4.1-4.3l3.8-4.4l3.6-4.7l3.4-4.8l3.1-5
l1.5-2.5l1.5-2.6l2.6-5.3l2.5-5.4l1.1-2.9l1.1-2.8l1.9-5.7l1.6-5.9l1.4-5.9l1.1-5.9l0.8-6.2l0.5-6.3l0.1-6.3H301.5z"
        />
        <line fill="none" x1="301.5" y1="177.4" x2="300.8" y2="177.4" />
        <path
          d="M178.1,300.7l-6.3-0.1l-6.3-0.5l-6.2-0.8l-6.2-1.1l-5.9-1.4l-5.9-1.6l-5.7-1.9l-2.9-1.1L130,291l-5.4-2.4l-5.3-2.8
l-2.6-1.4l-2.5-1.5l-5-3.4l-4.8-3.4l-4.7-3.6l-4.5-3.8l-4.3-4l-4-4.3l-3.9-4.7l-3.6-4.7l-3.4-4.8l-3.4-5l-2.9-5.2l-2.8-5.3
l-2.4-5.4l-2.3-5.7l-1.9-5.8l-1.6-5.9l-1.4-5.9l-1.1-6.2l-0.8-6.2l-0.5-6.3l-0.1-6.3h0.6l0.1,6.3l0.5,6.3l0.8,6.2l1.1,5.9
l1.4,5.9l1.6,5.9l1.9,5.7l2.3,5.7l2.4,5.4l2.8,5.3l2.9,5.2l3.3,4.9l3.4,4.8l3.6,4.7l3.9,4.5l4,4.3l4.3,4l4.5,3.9l4.7,3.6l4.8,3.4
l4.9,3.3l2.5,1.5l2.6,1.4l5.3,2.8l5.4,2.4l2.9,1.1l2.8,1.1l5.7,1.9l5.9,1.6l5.9,1.4l5.9,1.1l6.2,0.8l6.3,0.5l6.3,0.1V300.7z"
        />
        <line fill="none" x1="178.1" y1="300.7" x2="178.1" y2="300.1" />
        <line fill="none" x1="54.8" y1="177.4" x2="55.4" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.1,177.4l0.1-3l0.1-3l0.1-3l0.3-2.9l0.4-3l0.4-2.9l0.5-2.9l0.5-2.9l0.6-2.8l0.8-2.9l0.8-2.8l0.8-2.8
l0.9-2.6l1-2.8l1-2.6l1-2.6l1.1-2.6l1.3-2.5l1.3-2.5l1.4-2.5l1.4-2.5l1.4-2.4l1.5-2.4l1.5-2.4l1.6-2.3l1.6-2.3l1.8-2.3l1.8-2.3
l1.8-2.1l1.9-2l1.9-2.1l2-2l2-1.9l2-1.9l2.1-1.9l2.1-1.9l2.1-1.8l2.3-1.6l2.3-1.6l2.3-1.6l2.4-1.5l2.4-1.5l2.4-1.5l2.5-1.4
l2.5-1.3l2.5-1.3l2.5-1.3l2.6-1.1l2.6-1l2.6-1l2.8-1l2.8-0.9l2.6-0.9l2.9-0.8l2.8-0.6l2.9-0.6l5.7-1l5.9-0.8l2.9-0.3l3-0.3l3-0.1
h3h3l3,0.1l3,0.3l2.9,0.3l2.9,0.4l3,0.4l2.9,0.5l2.8,0.5l2.9,0.6l2.8,0.6l2.8,0.8l2.8,0.9l2.8,0.9l2.8,1l2.6,1l2.6,1l2.6,1.1
l2.5,1.3l2.5,1.3l2.5,1.3l2.5,1.4l2.4,1.5l2.4,1.5l2.4,1.5l2.3,1.6l2.3,1.6l2.3,1.6l2.1,1.8l2.1,1.9l2.1,1.9l2,1.9l2,1.9l2,2
l1.9,2.1l1.9,2l1.8,2.1l1.8,2.3l1.8,2.3l1.6,2.3l1.6,2.3l1.5,2.4l1.5,2.4l1.4,2.4l1.4,2.5l1.4,2.5l1.3,2.5l1.3,2.5l1.1,2.6l1,2.6
l1,2.6l1,2.8l0.9,2.6l0.8,2.8l0.8,2.8l0.8,2.9l0.6,2.8l1,5.8l0.8,5.9l0.3,2.9l0.1,3l0.1,3l0.1,3l-0.1,3l-0.1,3l-0.1,2.9l-0.3,3
l-0.4,2.9l-0.4,2.9L300,198l-0.5,2.9l-0.6,2.9l-0.8,2.8l-0.8,2.8l-0.8,2.8l-0.9,2.8l-1,2.6l-1,2.8l-1,2.6l-1.1,2.5l-1.3,2.6
l-1.3,2.5l-1.4,2.5l-1.4,2.5l-1.4,2.4l-1.5,2.4l-1.5,2.4l-1.6,2.3l-1.6,2.3l-1.8,2.3l-1.8,2.1l-1.8,2.1l-1.9,2.1l-1.9,2l-2,2
l-2,2l-2,1.9l-2.1,1.9l-2.1,1.8l-2.1,1.8l-2.3,1.8l-2.3,1.6l-2.3,1.6l-2.4,1.5l-2.4,1.5l-2.4,1.4l-2.5,1.4l-2.5,1.4l-2.5,1.3
l-2.5,1.1l-2.6,1.1l-2.6,1.1l-2.6,1l-2.8,1l-2.8,0.9l-2.8,0.8l-2.8,0.8l-2.8,0.8l-2.9,0.6l-5.7,1l-5.9,0.8l-2.9,0.3l-3,0.1
l-3,0.1h-3h-3l-3-0.1l-3-0.1l-2.9-0.3l-2.9-0.4l-3-0.4l-2.9-0.5l-2.8-0.5l-2.9-0.6l-2.8-0.8l-2.9-0.8l-2.6-0.8l-2.8-0.9l-2.8-1
l-2.6-1l-2.6-1.1l-2.6-1.1l-2.5-1.1l-2.5-1.3l-2.5-1.4l-2.5-1.4l-2.4-1.4l-2.4-1.5l-2.4-1.5l-2.3-1.6l-2.3-1.6l-2.3-1.8l-2.1-1.8
l-2.1-1.8l-2.1-1.9l-2-1.9l-2-2l-2-2l-1.9-2l-1.9-2.1l-1.8-2.1l-1.8-2.1l-1.8-2.3l-1.6-2.3l-1.6-2.3l-1.5-2.4l-1.5-2.4l-1.4-2.4
l-1.4-2.5l-1.4-2.5l-1.3-2.5l-1.3-2.6l-1.1-2.5l-1-2.6l-1-2.8l-1-2.6l-0.9-2.8l-0.8-2.8l-0.8-2.8l-0.8-2.8l-0.6-2.9l-1-5.8
l-0.8-5.8l-0.3-3l-0.1-2.9l-0.1-3L68.1,177.4z M61.5,177.4l0.1-3v-3l0.3-3l0.3-2.9l0.4-3l0.4-2.9l0.5-2.9l0.5-2.9l0.6-2.8
l0.8-2.9l0.8-2.8l0.8-2.8l0.9-2.6l1-2.8l1-2.6l1-2.6l1.1-2.6l1.3-2.5l1.3-2.5l1.3-2.5l1.4-2.5l1.5-2.4l1.5-2.4l1.5-2.4l1.6-2.3
l1.6-2.3l1.8-2.3l1.8-2.3l1.8-2.1l1.9-2l1.9-2.1l2-2l2-1.9l2-1.9l2.1-1.9l2.1-1.9l2.1-1.8l2.3-1.6l2.3-1.6l2.3-1.6l2.4-1.5
l2.4-1.5l2.4-1.5l2.5-1.4l2.5-1.3l2.5-1.3l2.5-1.3l2.6-1.1l2.6-1l2.6-1l2.8-1l2.6-0.9l2.8-0.9l2.9-0.8l2.8-0.6l2.9-0.6l5.7-1
l5.9-0.8l2.9-0.3l3-0.3l3-0.1h3h3l3,0.1l2.9,0.3l3,0.3l2.9,0.4l2.9,0.4l2.9,0.5l2.9,0.5l2.9,0.6l2.8,0.6l2.8,0.8l2.8,0.9l2.8,0.9
l2.8,1l2.6,1l2.6,1l2.5,1.1L229,72l2.5,1.3l2.5,1.3l2.5,1.4l2.4,1.5l2.4,1.5l2.4,1.5l2.3,1.6l2.3,1.6l2.3,1.6l2.1,1.8l2.1,1.9
l2.1,1.9l2,1.9l2,1.9l2,2l1.9,2.1l1.9,2l1.8,2.1l1.8,2.3l1.8,2.3l1.6,2.3l1.6,2.3l1.5,2.4l1.5,2.4l1.4,2.4l1.4,2.5l1.4,2.5
l1.3,2.5l1.1,2.5l1.3,2.6l1,2.6l1,2.6l1,2.8l0.9,2.6l0.8,2.8l0.8,2.8l0.8,2.9l0.6,2.8l1,5.8l0.8,5.9l0.3,2.9l0.1,3l0.1,3l0.1,3
l-0.1,3l-0.1,3l-0.1,2.9l-0.3,3l-0.4,2.9l-0.4,2.9l-0.5,2.9l-0.5,2.9l-0.6,2.9l-0.8,2.8l-0.8,2.8l-0.8,2.8l-0.9,2.8l-1,2.6
l-1,2.8l-1,2.6l-1.3,2.5l-1.1,2.6l-1.3,2.5l-1.4,2.5l-1.4,2.5l-1.4,2.4l-1.5,2.4l-1.5,2.4l-1.6,2.3l-1.6,2.3l-1.8,2.3l-1.8,2.1
l-1.8,2.1l-1.9,2.1l-1.9,2l-2,2l-2,2l-2,1.9l-2.1,1.9l-2.1,1.8l-2.1,1.8l-2.3,1.8l-2.3,1.6l-2.3,1.6l-2.4,1.5l-2.4,1.5l-2.4,1.4
l-2.5,1.4l-2.5,1.4l-2.5,1.3l-2.6,1.1l-2.5,1.1l-2.6,1.1l-2.6,1l-2.8,1L213,289l-2.8,0.8l-2.8,0.8l-2.8,0.8l-2.9,0.6l-5.8,1
l-5.8,0.8l-3,0.3l-2.9,0.1l-3,0.1h-3h-3l-3-0.1l-3-0.1l-2.9-0.3l-2.9-0.4l-3-0.4l-2.9-0.5l-2.8-0.5l-2.9-0.6l-2.8-0.8l-2.9-0.8
l-2.8-0.8l-2.6-0.9l-2.8-1l-2.6-1l-2.6-1.1l-2.6-1.1l-2.5-1.1l-2.5-1.3l-2.5-1.4l-2.5-1.4l-2.4-1.4l-2.4-1.5l-2.4-1.5l-2.3-1.6
l-2.3-1.6l-2.3-1.8l-2.1-1.8l-2.1-1.8l-2.1-1.9l-2-1.9l-2-2l-2-2l-1.9-2l-1.9-2.1l-1.8-2.1l-1.8-2.1l-1.8-2.3l-1.6-2.3l-1.6-2.3
l-1.5-2.4l-1.5-2.4l-1.5-2.4l-1.4-2.5l-1.3-2.5L73,228l-1.3-2.6l-1.1-2.5l-1-2.6l-1-2.8l-1-2.6l-0.9-2.8l-0.8-2.8l-0.8-2.8
l-0.8-2.8l-0.6-2.9l-1-5.8l-0.8-5.8l-0.3-3l-0.3-2.9v-3L61.5,177.4z"
        />
        <path
          d="M67.9,177.4l0.1-6l0.5-6l0.8-5.9l1-5.8l1.3-5.7l1.6-5.5L75,137l2-5.3l2.4-5.2l2.5-5l2.9-4.9l3-4.8l3.3-4.7l3.4-4.5
l3.8-4.1l3.8-4.1l4.1-3.8l4.3-3.8l4.4-3.4l4.5-3.3l4.8-3l4.9-2.9l5.3-2.5l5.2-2.4l5.3-2.1l5.4-1.8l5.7-1.6l5.7-1.3l5.7-1l5.9-0.8
l5.9-0.5l6-0.1v0.6l-6,0.1l-5.9,0.5l-5.9,0.8l-5.5,1l-5.7,1.3l-5.7,1.6l-5.4,1.8l-5.3,2.1l-5.2,2.4l-5,2.4l-4.9,2.9l-4.8,3
l-4.5,3.3l-4.4,3.4l-4.3,3.8l-4.1,3.8l-3.8,4.1l-3.8,4.1l-3.3,4.4l-3.3,4.7l-3,4.8l-2.9,4.9l-2.5,5l-2.4,5.2l-2,5.3l-1.9,5.3
l-1.6,5.5l-1.3,5.7l-1,5.8l-0.8,5.9l-0.5,5.8l-0.1,6H67.9z"
        />
        <path
          d="M185,60.2l6,0.1l5.9,0.5l5.8,0.8l5.8,1l5.7,1.3l5.5,1.6l5.5,1.8l5.3,2.1l5.2,2.4l5.2,2.5l4.9,2.9l4.8,3l4.5,3.3l4.4,3.4
l4.3,3.8l4.1,3.9l3.9,4.1l3.6,4.1l3.5,4.4l3.3,4.7l3,4.8l2.8,4.9l2.6,5.2l2.3,5.2l2.1,5.3l1.9,5.4l1.5,5.5l1.4,5.7l1,5.8l0.8,5.9
l0.4,5.9l0.3,6h-0.6l-0.3-6l-0.4-5.8l-0.8-5.9l-1-5.8l-1.4-5.7l-1.5-5.5l-1.9-5.4l-2.1-5.3l-2.3-5.2l-2.5-4.9l-2.8-4.9l-3-4.8
l-3.3-4.7l-3.5-4.4l-3.6-4.1l-3.9-4.1l-4-3.8l-4.3-3.8l-4.4-3.4l-4.5-3.3l-4.8-3l-4.9-2.9l-4.9-2.4l-5.2-2.4l-5.3-2.1l-5.5-1.8
l-5.5-1.6l-5.7-1.3l-5.8-1l-5.8-0.8l-5.8-0.5l-6-0.1V60.2z"
        />
        <line fill="none" x1="185" y1="60.2" x2="185" y2="60.8" />
        <path
          d="M302.2,177.4l-0.3,6l-0.4,5.9l-0.8,5.8l-1,5.8l-1.4,5.7l-1.5,5.5l-1.9,5.4l-2.1,5.4l-2.3,5.2l-2.6,5.3l-2.8,4.9l-3,4.7
l-3.3,4.7l-3.5,4.4l-3.6,4.3l-3.9,4l-4.1,3.9l-4.3,3.6l-4.4,3.5l-4.5,3.3l-4.8,3l-4.9,2.8l-5,2.6l-5.2,2.3l-5.3,2.1l-5.5,1.9
l-5.5,1.5l-5.7,1.3l-5.8,1.1l-5.8,0.8l-6,0.4l-6,0.1v-0.5l6-0.1l5.8-0.4l5.8-0.8l5.8-1.1l5.7-1.3l5.5-1.5l5.4-1.9l5.3-2.1
l5.2-2.3l5-2.6l4.9-2.8l4.8-3l4.5-3.3l4.4-3.5l4.3-3.6l4-3.8l3.9-4l3.6-4.3l3.5-4.4l3.3-4.7l3-4.7l2.8-4.9l2.5-5l2.3-5.2l2.1-5.4
l1.9-5.4l1.5-5.5l1.4-5.7l1-5.7l0.8-5.8l0.4-5.9l0.3-6H302.2z"
        />
        <line fill="none" x1="302.2" y1="177.4" x2="301.5" y2="177.4" />
        <path
          d="M185,294.4l-6-0.1l-5.9-0.4l-5.9-0.8l-5.8-1.1l-5.7-1.3l-5.7-1.5l-5.4-1.9l-5.3-2.1l-5.3-2.3l-5-2.6l-4.9-2.8l-4.8-3
l-4.5-3.3l-4.4-3.5l-4.3-3.6l-4.1-3.9l-3.8-4l-3.8-4.3l-3.4-4.4l-3.3-4.7l-3-4.7l-2.9-4.9l-2.5-5L77,223l-2-5.4l-1.9-5.4
l-1.6-5.5l-1.3-5.7l-1-5.9l-0.8-5.8l-0.5-5.9l-0.1-6h0.5l0.1,6l0.5,5.9l0.8,5.8l1,5.7l1.3,5.7l1.6,5.5l1.9,5.4l2,5.4l2.4,5.2
l2.5,5l2.9,4.9l3,4.7l3.3,4.7l3.3,4.3l3.8,4.3l3.8,4l4.1,3.9l4.3,3.6l4.4,3.5l4.5,3.3l4.8,3l4.9,2.8l5,2.6l5.2,2.3l5.3,2.1
l5.4,1.9l5.7,1.5l5.7,1.3l5.5,1.1l5.9,0.8l5.9,0.4l6,0.1V294.4z"
        />
        <line fill="none" x1="185" y1="294.4" x2="185" y2="293.9" />
        <line fill="none" x1="67.9" y1="177.4" x2="68.4" y2="177.4" />
        <path
          d="M61.2,177.4l0.1-6l0.5-6l0.8-5.9l1-5.8l1.3-5.7l1.6-5.5l1.9-5.4l2-5.3l2.4-5.2l2.5-5l2.9-4.9l3-4.8l3.3-4.7l3.4-4.5
l3.8-4.1l3.8-4.1l4.1-3.8l4.1-3.8l4.5-3.4l4.5-3.3l4.8-3l4.9-2.9l5.3-2.5l5.2-2.4l5.3-2.1l5.4-1.8l5.5-1.6l5.7-1.3l5.8-1l5.9-0.8
l5.9-0.5l6-0.1v0.6l-6,0.1l-5.9,0.5l-5.9,0.8l-5.7,1l-5.7,1.3l-5.5,1.6l-5.4,1.8L133,70l-5.2,2.4l-5,2.4l-4.9,2.9l-4.8,3
l-4.5,3.3l-4.5,3.4l-4.1,3.8l-4.1,3.8L92,99.1l-3.8,4.1l-3.3,4.4l-3.3,4.7l-3,4.8l-2.9,4.9l-2.5,5l-2.4,5.2l-2,5.3l-1.9,5.3
l-1.6,5.5l-1.3,5.7l-1,5.8l-0.8,5.9l-0.5,5.8l-0.1,6H61.2z"
        />
        <path
          d="M178.4,60.2l6,0.1l5.9,0.5l5.8,0.8l5.8,1l5.7,1.3l5.5,1.6l5.4,1.8l5.4,2.1l5.2,2.4l5.2,2.5l4.9,2.9l4.8,3l4.5,3.3
l4.4,3.4l4.3,3.8l4.1,3.9l3.9,4.1l3.6,4.1l3.5,4.4l3.3,4.7l3,4.8l2.8,4.9l2.6,5.2l2.3,5.2l2.1,5.3l1.9,5.4l1.5,5.5l1.4,5.7l1,5.8
l0.8,5.9l0.4,5.9l0.1,6h-0.6l-0.1-6l-0.4-5.8l-0.8-5.9l-1-5.8l-1.4-5.7l-1.5-5.5l-1.9-5.4l-2.1-5.3l-2.3-5.2l-2.5-4.9L278,117
l-3-4.8l-3.3-4.7l-3.5-4.4l-3.6-4.1l-3.9-4.1l-4-3.8l-4.3-3.8L248,84l-4.5-3.3l-4.8-3l-4.9-2.9l-4.9-2.4l-5.2-2.4l-5.4-2.1
l-5.4-1.8l-5.5-1.6l-5.7-1.3l-5.8-1l-5.8-0.8l-5.8-0.5l-6-0.1V60.2z"
        />
        <line fill="none" x1="178.4" y1="60.2" x2="178.4" y2="60.8" />
        <path
          d="M295.4,177.4l-0.1,6l-0.4,5.9l-0.8,5.8l-1,5.8l-1.4,5.7l-1.5,5.5l-1.9,5.4l-2.1,5.4l-2.3,5.2l-2.6,5.3l-2.8,4.9l-3,4.7
l-3.3,4.7l-3.5,4.4l-3.6,4.3l-3.9,4l-4.1,3.9l-4.3,3.6l-4.4,3.5l-4.5,3.3l-4.8,3l-4.9,2.8l-5,2.6l-5.2,2.3l-5.4,2.1l-5.4,1.9
l-5.5,1.5L202,292l-5.8,1.1l-5.8,0.8l-6,0.4l-6,0.1v-0.5l6-0.1l5.8-0.4l5.8-0.8l5.8-1.1l5.7-1.3l5.5-1.5l5.3-1.9l5.4-2.1l5.2-2.3
l5-2.6l4.9-2.8l4.8-3l4.5-3.3l4.4-3.5l4.3-3.6l4-3.8l3.9-4l3.6-4.3l3.5-4.4l3.3-4.7l3-4.7l2.8-4.9l2.5-5l2.3-5.2l2.1-5.4l1.9-5.4
l1.5-5.5l1.4-5.7l1-5.7l0.8-5.8l0.4-5.9l0.1-6H295.4z"
        />
        <line fill="none" x1="295.4" y1="177.4" x2="294.7" y2="177.4" />
        <path
          d="M178.4,294.4l-6-0.1l-5.9-0.4l-5.9-0.8l-5.9-1.1l-5.7-1.3l-5.5-1.5l-5.4-1.9l-5.3-2.1l-5.3-2.3l-5-2.6l-4.9-2.8l-4.8-3
l-4.5-3.3l-4.5-3.5l-4.1-3.6l-4.1-3.9l-3.8-4l-3.8-4.3l-3.4-4.4l-3.3-4.7l-3-4.7l-2.9-4.9l-2.5-5l-2.4-5.3l-2-5.4l-1.9-5.4
l-1.6-5.5l-1.3-5.7l-1-5.9l-0.8-5.8l-0.5-5.9l-0.1-6h0.5l0.1,6l0.5,5.9l0.8,5.8l1,5.7l1.3,5.7l1.6,5.5l1.9,5.4l2,5.4l2.4,5.2
l2.5,5l2.9,4.9l3,4.7l3.3,4.7l3.3,4.3l3.8,4.3l3.8,4l4.1,3.9l4.1,3.6l4.5,3.5l4.5,3.3l4.8,3l4.9,2.8l5,2.6l5.2,2.3l5.3,2.1
l5.4,1.9l5.5,1.5l5.7,1.3l5.7,1.1l5.9,0.8l5.9,0.4l6,0.1V294.4z"
        />
        <line fill="none" x1="178.4" y1="294.4" x2="178.4" y2="293.9" />
        <line fill="none" x1="61.2" y1="177.4" x2="61.7" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.8,177.4l0.1-2.9l0.1-2.9l0.1-2.8l0.3-2.8l0.4-2.9l0.4-2.8l0.4-2.8L76,155l1.3-5.4l0.8-2.6l0.8-2.6
l0.9-2.5l0.9-2.6l1-2.5l1-2.5l1.1-2.5l1.1-2.4l1.1-2.4l1.3-2.4l1.4-2.4l1.3-2.3l1.5-2.3l1.4-2.3l3.1-4.4l1.6-2l1.6-2.1l1.8-2
l1.8-2l1.8-2l1.9-1.9l1.9-1.8l2-1.9l2-1.8l2-1.8l2-1.6l2.1-1.6l2.1-1.5l2.3-1.6l2.3-1.4l2.3-1.5l2.3-1.3l2.4-1.4l2.4-1.3l2.4-1.1
l2.4-1.1l2.5-1.1l2.5-1l2.5-1l2.5-0.9l2.6-0.9l2.6-0.8l2.6-0.8l2.6-0.6l2.8-0.5l2.6-0.6l2.8-0.4l2.8-0.4l2.8-0.4l2.9-0.3l2.8-0.1
l2.9-0.1h2.9h2.8l2.9,0.1l2.9,0.1l2.8,0.3l2.8,0.4l2.8,0.4l2.8,0.4l2.8,0.6l5.3,1.1l2.6,0.8l2.6,0.8l2.6,0.9L223,73l2.5,1l2.5,1
l2.5,1.1l2.5,1.1l2.4,1.1l2.4,1.3l2.3,1.4l2.4,1.3l2.3,1.5l2.1,1.4l4.4,3.1l2.1,1.6l2,1.6l2.1,1.8l1.9,1.8l2,1.9l1.9,1.8l1.9,1.9
l1.8,2l1.8,2l1.8,2l1.6,2.1l1.6,2l1.6,2.3l1.5,2.1l1.5,2.3l1.4,2.3l1.4,2.3l1.3,2.4l1.3,2.4l1.3,2.4l1.1,2.4l1,2.5l1.1,2.5
l0.9,2.5l0.9,2.6l0.9,2.5l0.8,2.6l0.8,2.6l0.6,2.6l0.6,2.8l0.5,2.6l0.5,2.8l0.4,2.8l0.3,2.9l0.3,2.8l0.3,2.8l0.1,2.9v2.9v2.9
l-0.1,2.8l-0.3,2.9l-0.3,2.8l-0.3,2.8l-0.4,2.8l-0.5,2.8l-0.5,2.8l-1.3,5.4l-0.8,2.6l-0.8,2.5L290,213l-0.9,2.5l-0.9,2.6
l-1.1,2.5l-1,2.4l-1.1,2.5l-1.3,2.4l-1.3,2.4l-1.3,2.3l-1.4,2.4l-1.4,2.3l-1.5,2.3l-3.1,4.3l-1.6,2.1l-1.6,2.1l-1.8,2l-1.8,2
l-1.8,1.9l-1.9,1.9l-1.9,1.9l-2,1.9l-1.9,1.8l-2.1,1.6l-2,1.8l-2.1,1.6l-2.1,1.5l-2.3,1.5l-2.1,1.5l-2.3,1.4l-2.4,1.4l-2.3,1.3
l-2.4,1.3l-2.4,1.3l-2.5,1.1l-2.5,1.1l-2.5,1l-2.5,0.9l-2.5,1l-2.6,0.8l-2.6,0.8l-2.6,0.8l-2.6,0.6l-2.6,0.6l-2.8,0.5l-2.8,0.5
l-2.8,0.4l-2.8,0.3l-2.8,0.3l-2.9,0.3l-2.9,0.1h-2.8H182l-2.9-0.1l-2.8-0.3l-2.9-0.3l-2.8-0.3l-2.8-0.4l-2.8-0.5l-2.6-0.5
l-5.4-1.3l-2.6-0.8l-2.6-0.8l-2.6-0.8l-2.5-1l-2.5-0.9l-2.5-1l-2.5-1.1l-2.4-1.1l-2.4-1.3l-2.4-1.3l-2.4-1.3l-2.3-1.4l-2.3-1.4
l-2.3-1.5l-4.4-3l-2.1-1.6l-2-1.8l-2-1.6l-2-1.8l-2-1.9l-1.9-1.9l-1.9-1.9l-1.8-1.9l-1.8-2l-1.8-2l-1.6-2.1l-1.6-2.1l-1.6-2.1
l-1.5-2.1l-1.4-2.3l-1.5-2.3l-1.3-2.4l-1.4-2.3l-1.3-2.4l-1.1-2.4l-1.1-2.5l-1.1-2.4l-1-2.5l-1-2.6l-0.9-2.5l-0.9-2.6l-0.8-2.5
l-0.8-2.6l-0.6-2.8l-0.6-2.6l-0.5-2.8l-0.4-2.8l-0.4-2.8l-0.4-2.8l-0.3-2.8L74,183l-0.1-2.8L73.8,177.4z M67.5,177.4v-2.9
l0.1-2.9l0.3-2.8l0.3-2.8l0.3-2.9l0.4-2.8l0.5-2.8l0.5-2.6l1.3-5.4l0.8-2.6l0.8-2.6l0.8-2.5l1-2.6l0.9-2.5l1-2.5l1.1-2.5l1.1-2.4
l1.3-2.4l1.3-2.4l1.3-2.4l1.4-2.3l1.4-2.3l1.5-2.3l3.1-4.4l1.5-2l1.8-2.1l1.6-2l1.9-2l1.8-2l1.9-1.9l1.9-1.8l1.9-1.9l2-1.8l2-1.8
L110,90l2.1-1.6l2.1-1.5l2.1-1.6l2.3-1.4l2.3-1.5l2.4-1.3l2.3-1.4l2.4-1.3l2.4-1.1l2.5-1.1l2.4-1.1l2.5-1l2.6-1l2.5-0.9l2.6-0.9
l2.5-0.8l2.8-0.8l2.6-0.6l2.6-0.5l2.8-0.6l2.8-0.4l2.8-0.4l2.8-0.4l2.8-0.3l2.9-0.1l2.8-0.1h2.9h2.9l2.9,0.1l2.8,0.1l2.9,0.3
l2.8,0.4l2.8,0.4l2.8,0.4l2.6,0.6l5.4,1.1l2.6,0.8l2.6,0.8l2.5,0.9l2.6,0.9l2.5,1l2.5,1l2.5,1.1l2.4,1.1l2.4,1.1l2.4,1.3l2.4,1.4
l2.3,1.3l2.3,1.5l2.3,1.4l4.4,3.1l2,1.6l2.1,1.6l2,1.8l2,1.8l2,1.9l1.9,1.8l1.9,1.9l1.8,2l1.8,2l1.8,2l1.6,2.1l1.6,2l1.5,2.3
l1.6,2.1l1.4,2.3l1.5,2.3l1.3,2.3l1.4,2.4l1.3,2.4l1.1,2.4l1.1,2.4l1.1,2.5l1,2.5l1,2.5l0.9,2.6l0.9,2.5l0.8,2.6l0.8,2.6l0.6,2.6
l0.5,2.8l0.6,2.6l0.4,2.8l0.4,2.8l0.4,2.9l0.3,2.8l0.1,2.8l0.1,2.9v2.9v2.9l-0.1,2.8l-0.1,2.9l-0.3,2.8l-0.4,2.8l-0.4,2.8
l-0.4,2.8l-0.6,2.8l-1.1,5.4l-0.8,2.6l-0.8,2.5l-0.9,2.6l-0.9,2.5l-1,2.6l-1,2.5l-1.1,2.4l-1.1,2.5l-1.1,2.4l-1.3,2.4l-1.4,2.3
l-1.3,2.4l-1.5,2.3l-1.4,2.3l-3.1,4.3l-1.6,2.1l-1.6,2.1l-1.8,2l-1.8,2l-1.8,1.9l-1.9,1.9l-1.9,1.9l-2,1.9l-2,1.8l-2,1.6
l-2.1,1.8l-2,1.6l-2.3,1.5l-2.1,1.5l-2.3,1.5l-2.3,1.4l-2.3,1.4l-2.4,1.3l-2.4,1.3l-2.4,1.3l-2.4,1.1l-2.5,1.1l-2.5,1l-2.5,0.9
l-2.6,1l-2.5,0.8l-2.6,0.8l-2.6,0.8l-2.6,0.6l-2.8,0.6l-2.6,0.5l-2.8,0.5l-2.8,0.4l-2.8,0.3L187,288l-2.8,0.3l-2.9,0.1h-2.9h-2.9
l-2.8-0.1l-2.9-0.3l-2.8-0.3l-2.8-0.3l-2.8-0.4l-2.8-0.5l-2.8-0.5l-5.3-1.3l-2.8-0.8l-2.5-0.8l-2.6-0.8l-2.5-1l-2.6-0.9l-2.5-1
l-2.4-1.1l-2.5-1.1l-2.4-1.3l-2.4-1.3l-2.3-1.3l-2.4-1.4l-2.3-1.4l-2.3-1.5l-4.3-3l-2.1-1.6l-2.1-1.8l-2-1.6l-2-1.8l-1.9-1.9
l-1.9-1.9l-1.9-1.9l-1.8-1.9l-1.9-2l-1.6-2l-1.8-2.1l-1.5-2.1l-1.6-2.1l-1.5-2.1l-1.5-2.3l-1.4-2.3l-1.4-2.4l-1.3-2.3l-1.3-2.4
l-1.3-2.4l-1.1-2.5l-1.1-2.4l-1-2.5l-0.9-2.6l-1-2.5l-0.8-2.6l-0.8-2.5l-0.8-2.6l-0.6-2.8l-0.6-2.6l-0.5-2.8l-0.5-2.8l-0.4-2.8
l-0.3-2.8l-0.3-2.8l-0.3-2.9l-0.1-2.8V177.4z"
        />
        <path
          d="M73.5,177.4l0.3-5.8l0.4-5.7l0.3-2.9l0.5-2.8l0.9-5.4l1.3-5.4l1.5-5.3l1.8-5.2l2-5l2.1-4.9l2.5-4.8l2.6-4.7l2.9-4.5
l3.1-4.4l3.3-4.3l3.5-4l3.6-3.9l3.9-3.6l4-3.5l4.1-3.3l4.4-3.1l4.5-2.9l4.7-2.6l5-2.4l4.9-2.3l5-2l5.2-1.8l5.3-1.5l5.4-1.3
l5.4-0.9l5.5-0.8l5.7-0.4l5.8-0.1v0.6l-5.8,0.1l-5.7,0.4l-5.5,0.8l-5.3,0.9l-5.4,1.3l-5.3,1.5l-5.2,1.8l-5,2l-4.9,2.3l-4.8,2.3
l-4.7,2.6l-4.5,2.9l-4.4,3.1l-4.1,3.3l-4,3.5l-3.9,3.6l-3.6,3.9l-3.5,4l-3.1,4.1l-3.1,4.4l-2.9,4.5l-2.6,4.7l-2.5,4.8l-2.1,4.9
l-2,5l-1.8,5l-1.5,5.3l-1.3,5.4l-0.9,5.4l-0.5,2.8l-0.3,2.6l-0.4,5.7l-0.3,5.8H73.5z"
        />
        <path
          d="M184.9,66.1l5.7,0.1l5.7,0.4L199,67l2.8,0.4l5.5,0.9l5.3,1.3l5.3,1.5l5.2,1.8l5,2l4.9,2.3l5,2.4l4.5,2.6L247,85l4.4,3.1
l4.1,3.3l4,3.5l4,3.8l3.6,3.9l3.5,4l3.3,4.1l3.1,4.4l2.9,4.5l2.6,4.7l2.5,4.9l2.1,4.9l2,5l1.8,5.2l1.5,5.3l1.3,5.4l1,5.4l0.6,5.5
l0.5,5.7l0.1,5.8h-0.6l-0.1-5.8l-0.5-5.5l-0.6-5.5l-1-5.4l-1.3-5.4l-1.5-5.3l-1.8-5.2l-2-5l-2.1-4.9l-2.4-4.7l-2.6-4.7l-2.9-4.5
l-3.1-4.4l-3.3-4.1l-3.5-4l-3.6-3.9l-3.9-3.6l-4-3.5l-4.1-3.3l-4.4-3.1l-4.5-2.9l-4.5-2.6l-4.8-2.3l-4.9-2.3l-5-2l-5.2-1.8
l-5.3-1.5l-5.3-1.3l-5.5-0.9l-2.8-0.4l-2.6-0.4l-5.7-0.4l-5.7-0.1V66.1z"
        />
        <line fill="none" x1="184.9" y1="66.1" x2="184.9" y2="66.8" />
        <path
          d="M296.1,177.4L296,183l-0.5,5.7l-0.3,2.8l-0.4,2.8l-1,5.5l-1.3,5.3l-1.5,5.3l-1.8,5.2l-2,5l-2.1,5l-2.5,5l-2.6,4.7
l-2.9,4.4L274,244l-3.3,4.1l-3.5,4l-3.6,3.9l-4,3.6l-4,3.5l-4.1,3.3l-4.4,3.1l-4.5,2.9l-4.5,2.6l-4.9,2.5l-4.9,2.1l-5,2l-5.2,1.8
l-5.3,1.5l-5.3,1.3l-5.5,1l-5.5,0.6l-5.8,0.5l-5.7,0.1v-0.5l5.7-0.1l5.5-0.5l5.5-0.6l5.5-1l5.3-1.3l5.3-1.5l5-1.8l5-2l4.9-2.1
l4.9-2.5l4.5-2.6l4.5-2.9l4.4-3.1l4.1-3.3l4-3.5l3.9-3.5l3.6-3.9l3.5-4l3.3-4.1l3.1-4.4l2.9-4.4l2.6-4.7l2.4-4.8l2.1-5l2-5
l1.8-5.2l1.5-5.3l1.3-5.3l1-5.4l0.4-2.8l0.3-2.8l0.5-5.7l0.1-5.7H296.1z"
        />
        <line fill="none" x1="296.1" y1="177.4" x2="295.5" y2="177.4" />
        <path
          d="M184.9,288.6l-5.8-0.1l-5.7-0.5l-2.8-0.3l-2.8-0.4l-5.5-1l-5.4-1.3l-5.3-1.5l-5.2-1.8l-5-2l-5-2.1l-4.8-2.5l-4.7-2.6
l-4.5-2.9l-4.4-3.1l-4.1-3.3l-4-3.5l-3.9-3.6l-3.6-3.9l-3.5-4l-3.3-4.1l-3.1-4.4l-2.9-4.4l-2.6-4.7l-2.5-4.8l-2.1-5.2l-2-5
l-1.8-5.2l-1.5-5.3l-1.3-5.3l-0.9-5.7l-0.8-5.5l-0.4-5.7l-0.3-5.7H74l0.3,5.7l0.4,5.7l0.8,5.5l0.9,5.4l1.3,5.3l1.5,5.3l1.8,5.2
l2,5l2.1,5l2.5,4.8l2.6,4.7l2.9,4.4l3.1,4.4l3.1,4l3.5,4l3.6,3.9l3.9,3.6l4,3.5l4.1,3.3l4.4,3.1l4.5,2.9l4.7,2.6l4.8,2.5l4.9,2.1
l5,2l5.2,1.8l5.3,1.5l5.4,1.3l5.3,1l2.8,0.4l2.8,0.3l5.7,0.5l5.8,0.1V288.6z"
        />
        <line fill="none" x1="184.9" y1="288.6" x2="184.9" y2="288.1" />
        <line fill="none" x1="73.5" y1="177.4" x2="74" y2="177.4" />
        <path
          d="M67.2,177.4l0.1-5.8l0.5-5.7l0.3-2.9l0.4-2.8l1-5.4l1.3-5.4l1.5-5.3L74,139l1.9-5l2.3-4.9l2.5-4.8l2.6-4.7l2.9-4.5l3-4.4
l3.4-4.3l3.4-4l3.8-3.9l3.8-3.6l4-3.5l4.3-3.3l4.3-3.1l4.5-2.9l4.7-2.6l5-2.4l4.9-2.3l5-2l5.2-1.8l5.3-1.5l5.4-1.3l5.5-0.9
l5.5-0.8l5.7-0.4l5.7-0.1v0.6l-5.7,0.1l-5.7,0.4l-5.5,0.8l-5.4,0.9l-5.4,1.3l-5.3,1.5l-5.2,1.8l-5,2l-4.9,2.3l-4.8,2.3l-4.7,2.6
l-4.5,2.9l-4.3,3.1l-4.3,3.3l-4,3.5l-3.8,3.6l-3.8,3.9l-3.4,4l-3.3,4.1l-3,4.4l-2.9,4.5l-2.6,4.7l-2.5,4.8l-2.3,4.9l-1.9,5
l-1.8,5l-1.5,5.3l-1.3,5.4l-1,5.4l-0.4,2.8l-0.3,2.6l-0.5,5.7l-0.1,5.8H67.2z"
        />
        <path
          d="M178.5,66.1l5.8,0.1l5.5,0.4l2.9,0.4l2.8,0.4l5.4,0.9l5.4,1.3l5.3,1.5l5.2,1.8l5,2l4.9,2.3l4.9,2.4l4.6,2.6l4.5,2.9
l4.4,3.1l4.1,3.3l4,3.5l4,3.8l3.6,3.9l3.5,4l3.3,4.1l3,4.4l2.9,4.5l2.8,4.7l2.4,4.9l2.3,4.9l2,5l1.8,5.2l1.4,5.3l1.3,5.4l1,5.4
l0.8,5.5l0.4,5.7l0.1,5.8h-0.6l-0.1-5.8l-0.4-5.5l-0.8-5.5l-1-5.4l-1.3-5.4l-1.4-5.3l-1.8-5.2l-2-5l-2.3-4.9l-2.3-4.7l-2.8-4.7
l-2.9-4.5l-3-4.4L264,107l-3.5-4l-3.6-3.9l-3.9-3.6l-4-3.5l-4.1-3.3l-4.4-3.1l-4.5-2.9l-4.7-2.6l-4.6-2.3l-4.9-2.3l-5-2l-5.2-1.8
l-5.3-1.5l-5.4-1.3l-5.4-0.9l-2.8-0.4l-2.8-0.4l-5.5-0.4l-5.8-0.1V66.1z"
        />
        <line fill="none" x1="178.5" y1="66.1" x2="178.5" y2="66.8" />
        <path
          d="M289.7,177.4l-0.1,5.7l-0.4,5.7l-0.4,2.8l-0.4,2.8l-1,5.5l-1.3,5.3l-1.4,5.3l-1.8,5.2l-2,5l-2.3,5l-2.4,5l-2.8,4.7
l-2.9,4.4l-3,4.4l-3.3,4.1l-3.5,4l-3.6,3.9l-4,3.6l-4,3.5l-4.1,3.3l-4.4,3.1l-4.5,2.9l-4.6,2.6l-4.8,2.5l-4.9,2.1l-5,2l-5.2,1.8
l-5.3,1.5l-5.4,1.3l-5.4,1l-5.7,0.6l-5.7,0.5l-5.8,0.1v-0.5l5.8-0.1l5.4-0.5l5.7-0.6l5.4-1l5.4-1.3l5.3-1.5l5-1.8l5-2l4.9-2.1
l4.8-2.5l4.7-2.6l4.5-2.9l4.4-3.1l4.1-3.3l4-3.5l3.9-3.5l3.6-3.9l3.5-4l3.3-4.1l3-4.4l2.9-4.4l2.8-4.7l2.3-4.8l2.3-5l2-5l1.8-5.2
l1.4-5.3l1.3-5.3l1-5.4l0.4-2.8l0.4-2.8l0.4-5.7l0.1-5.7H289.7z"
        />
        <line fill="none" x1="289.7" y1="177.4" x2="289.1" y2="177.4" />
        <path
          d="M178.5,288.6l-5.7-0.1l-5.7-0.5l-2.8-0.3l-2.8-0.4l-5.7-1l-5.4-1.3l-5.3-1.5l-5.2-1.8l-5-2l-5-2.1l-4.8-2.5l-4.7-2.6
l-4.5-2.9l-4.3-3.1l-4.3-3.3l-4-3.5l-3.8-3.6l-3.8-3.9l-3.4-4l-3.4-4.1l-3-4.4l-2.9-4.4l-2.6-4.7l-2.5-4.8l-2.3-5.2l-1.9-5
l-1.8-5.2l-1.5-5.3l-1.3-5.3l-1-5.7l-0.6-5.5l-0.5-5.7l-0.1-5.7h0.5l0.1,5.7l0.5,5.7l0.6,5.5l1,5.4l1.3,5.3l1.5,5.3l1.8,5.2
l1.9,5l2.3,5l2.5,4.8l2.6,4.7l2.9,4.4l3,4.4l3.3,4l3.4,4l3.8,3.9l3.8,3.6l4,3.5l4.3,3.3l4.3,3.1l4.5,2.9l4.7,2.6l4.8,2.5l4.9,2.1
l5,2l5.2,1.8l5.3,1.5l5.4,1.3l5.4,1l2.8,0.4l2.8,0.3l5.7,0.5l5.7,0.1V288.6z"
        />
        <line fill="none" x1="178.5" y1="288.6" x2="178.5" y2="288.1" />
        <line fill="none" x1="67.2" y1="177.4" x2="67.7" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.2,177.4l0.1-2.8V172l0.3-2.8l0.3-2.6l0.6-5.3l0.5-2.6l0.4-2.5l0.6-2.6l0.6-2.5l0.6-2.5l0.8-2.5l0.8-2.4
l0.9-2.5l1-2.4l0.9-2.4l1.1-2.3l1-2.4l1.1-2.3l1.3-2.3l1.3-2.3l1.3-2.1l1.4-2.1l1.4-2.1l1.4-2.1l1.5-2l1.5-2l1.6-2l1.6-1.9
l1.6-1.9l1.8-1.9l1.8-1.8l1.9-1.9l1.8-1.6l1.9-1.8l2-1.6l1.9-1.5l2-1.6l2.1-1.4l2-1.5l2.1-1.4l2.1-1.4l2.3-1.3l2.1-1.3l2.3-1.1
l2.4-1.1l2.3-1.1l2.4-1l2.4-1l2.4-0.9l2.4-0.9l2.5-0.8l5-1.5l2.5-0.6l2.5-0.5l5.3-1l2.6-0.3l2.6-0.4l2.6-0.3l2.6-0.1l2.8-0.1h2.6
h2.8l2.8,0.1l2.6,0.1l2.6,0.3l5.3,0.6l2.6,0.5l2.5,0.5l2.6,0.5l2.5,0.6l2.5,0.8l2.5,0.8l2.4,0.8l2.5,0.9l2.4,0.9l2.4,1l2.4,1
l2.3,1.1l2.3,1.1l2.3,1.1l2.3,1.3l2.1,1.3l2.3,1.4l2,1.4l2.1,1.5l2,1.4l2,1.6l2,1.5l1.9,1.6l2,1.8l1.8,1.6l1.9,1.9l1.8,1.8
l1.6,1.9l1.8,1.9l1.6,1.9l1.5,2l1.6,2l1.5,2l1.4,2.1l1.4,2.1l1.4,2.1l1.3,2.1l1.3,2.3l1.3,2.3l1.1,2.3l1,2.4l1,2.3l1,2.4l0.9,2.4
l0.9,2.5l0.9,2.4l1.4,5l0.6,2.5l0.5,2.6l1,5.2l0.4,2.6l0.3,2.6l0.3,2.6l0.1,2.8l0.1,2.6v2.8v2.6l-0.1,2.8l-0.1,2.6l-0.3,2.8
l-0.6,5.3l-0.5,2.5l-0.5,2.6l-0.5,2.5l-0.6,2.5l-0.6,2.5l-0.8,2.5l-0.9,2.5l-0.9,2.4l-0.9,2.4l-1,2.4l-1,2.4l-1,2.4l-1.1,2.3
l-1.3,2.3l-1.3,2.1l-1.3,2.3l-1.4,2.1l-1.4,2.1l-1.4,2l-1.5,2.1l-1.6,2l-1.5,1.9l-1.6,2l-1.8,1.9l-1.6,1.9l-1.8,1.8l-1.9,1.8
l-1.8,1.8l-2,1.6l-1.9,1.6l-2,1.6l-2,1.5l-2,1.5l-2.1,1.5l-2,1.4l-2.3,1.3l-2.1,1.4l-2.3,1.3l-2.3,1.1l-2.3,1.1l-2.3,1.1l-2.4,1
l-2.4,1l-2.4,0.9l-2.5,0.9l-2.4,0.8l-5,1.4l-2.5,0.6l-2.6,0.6l-5.2,0.9l-2.6,0.4l-2.6,0.3l-2.6,0.3l-2.6,0.3l-2.8,0.1h-2.8H182
l-2.8-0.1l-2.6-0.3l-2.6-0.3l-5.3-0.6l-2.6-0.4l-2.6-0.5l-2.5-0.6l-2.5-0.6l-2.5-0.6l-2.5-0.8l-2.5-0.8l-2.4-0.9l-2.4-0.9l-2.4-1
l-2.4-1l-2.3-1.1l-2.4-1.1l-2.3-1.1l-2.1-1.3l-2.3-1.4l-2.1-1.3l-2.1-1.4l-2-1.5l-2.1-1.5l-2-1.5l-1.9-1.6l-2-1.6l-1.9-1.6
l-1.8-1.8l-1.9-1.8l-1.8-1.8l-1.8-1.9l-1.6-1.9l-1.6-2l-1.6-1.9l-1.5-2l-1.5-2.1l-1.4-2l-1.4-2.1l-1.4-2.1l-1.3-2.3l-1.3-2.1
l-1.3-2.3l-1.1-2.3l-1-2.4l-1.1-2.4l-0.9-2.4l-1-2.4l-0.9-2.4l-0.8-2.5l-1.4-5l-0.6-2.5l-0.6-2.5l-0.9-5.2l-0.4-2.6l-0.3-2.6
l-0.3-2.8l-0.3-2.6V180L79.2,177.4z M73.3,177.4v-2.8l0.1-2.6l0.1-2.8l0.3-2.6l0.6-5.3l0.5-2.6l0.5-2.5l0.5-2.6l0.6-2.5l0.6-2.5
l0.8-2.5l0.9-2.4l0.8-2.5l1-2.4l0.9-2.4l1.1-2.3l1-2.4l1.1-2.3l1.3-2.3l1.3-2.3l1.3-2.1l1.4-2.1l1.4-2.1l1.4-2.1l1.5-2l1.5-2
l1.6-2l1.6-1.9l1.6-1.9l1.8-1.9l1.8-1.8l1.9-1.9l1.8-1.6l1.9-1.8l2-1.6l2-1.5l2-1.6l2-1.4l2-1.5l2.1-1.4l2.1-1.4l2.3-1.3l2.3-1.3
l2.3-1.1l2.3-1.1l2.3-1.1l2.4-1l2.4-1l2.4-0.9l2.4-0.9l2.5-0.8l5-1.5l2.5-0.6l2.5-0.5l5.3-1l2.6-0.3l2.6-0.4l2.6-0.3l2.6-0.1
l2.8-0.1h2.8h2.6l2.8,0.1l2.6,0.1l2.6,0.3l5.3,0.6l2.6,0.5L200,74l2.5,0.5l2.5,0.6l2.5,0.8l2.5,0.8l2.5,0.8l2.4,0.9l2.4,0.9
l2.4,1l2.4,1l2.3,1.1l2.3,1.1l2.3,1.1l2.3,1.3l2.3,1.3l2.1,1.4l2.1,1.4l2,1.5l2,1.4l2,1.6l2,1.5l2,1.6l1.9,1.8l1.8,1.6l1.9,1.9
l1.8,1.8l1.8,1.9l1.6,1.9l1.6,1.9l1.6,2l1.5,2l1.5,2l1.4,2.1l1.4,2.1l1.4,2.1l1.3,2.1l1.3,2.3l1.3,2.3l1.1,2.3l1,2.4l1.1,2.3
l0.9,2.4l1,2.4l0.8,2.5l0.9,2.4l1.4,5l0.6,2.5l0.5,2.6l1,5.2l0.4,2.6l0.3,2.6l0.3,2.6l0.1,2.8l0.1,2.6v2.8v2.6l-0.1,2.8l-0.1,2.6
l-0.3,2.8l-0.6,5.3l-0.5,2.5l-0.5,2.6l-0.5,2.5l-0.6,2.5l-0.6,2.5l-0.8,2.5l-0.9,2.5l-0.8,2.4l-1,2.4l-0.9,2.4l-1.1,2.4l-1,2.4
l-1.1,2.3l-1.3,2.3l-1.3,2.1l-1.3,2.3l-1.4,2.1l-1.4,2.1l-1.4,2l-1.5,2.1l-1.5,2l-1.6,1.9l-1.6,2l-1.6,1.9l-1.8,1.9l-1.8,1.8
l-1.9,1.8l-1.8,1.8l-1.9,1.6l-2,1.6l-2,1.6l-2,1.5l-2,1.5l-2,1.5l-2.1,1.4l-2.1,1.3l-2.3,1.4l-2.3,1.3l-2.3,1.1l-2.3,1.1
l-2.3,1.1l-2.4,1l-2.4,1l-2.4,0.9l-2.4,0.9l-2.5,0.8l-5,1.4l-2.5,0.6l-2.5,0.6l-5.3,0.9l-2.6,0.4l-2.6,0.3l-2.6,0.3l-2.6,0.3
l-2.8,0.1h-2.6H176l-2.8-0.1l-2.6-0.3l-2.6-0.3l-5.3-0.6l-2.6-0.4l-2.6-0.5l-2.5-0.6l-2.5-0.6l-2.5-0.6l-2.5-0.8l-2.5-0.8
l-2.4-0.9l-2.4-0.9l-2.4-1l-2.4-1l-2.3-1.1l-2.3-1.1l-2.3-1.1l-2.3-1.3l-2.3-1.4l-2.1-1.3l-2.1-1.4l-2-1.5l-2-1.5l-2-1.5l-2-1.6
l-2-1.6l-1.9-1.6l-1.8-1.8l-1.9-1.8l-1.8-1.8l-1.8-1.9l-1.6-1.9l-1.6-2l-1.6-1.9l-1.5-2l-1.5-2.1l-1.4-2l-1.4-2.1l-1.4-2.1
l-1.3-2.3l-1.3-2.1l-1.3-2.3l-1.1-2.3l-1-2.4l-1.1-2.4l-0.9-2.4l-1-2.4l-0.8-2.4l-0.9-2.5l-1.4-5l-0.6-2.5l-0.5-2.5l-1-5.2
l-0.4-2.6l-0.3-2.6l-0.3-2.8l-0.1-2.6l-0.1-2.8V177.4z"
        />
        <path
          d="M78.9,177.4l0.1-5.4l0.5-5.5l0.6-5.3l0.9-5.2l1.3-5.2l1.4-5l1.6-4.9l1.9-4.8l2.1-4.7l2.4-4.5l2.5-4.4l2.8-4.3l2.9-4.1
l3.1-4.1l3.3-3.8l3.5-3.8l3.6-3.4l3.9-3.4l3.9-3.1l4.1-2.9l4.3-2.8l4.4-2.5L139,82l4.7-2l4.8-1.9l4.9-1.8l5-1.4l5-1.1l5.2-1
l5.3-0.6l5.4-0.4l5.4-0.1v0.6l-5.4,0.1l-5.4,0.4l-5.3,0.6l-5,1l-5,1.1l-5,1.4l-4.9,1.8l-4.8,1.9l-4.7,2l-4.7,2.3l-4.4,2.5
l-4.3,2.8l-4.1,2.9l-3.9,3.1l-3.9,3.4l-3.6,3.4l-3.5,3.8l-3.3,3.8l-3,4l-2.9,4.1l-2.8,4.3l-2.5,4.4l-2.4,4.5l-2.1,4.7l-1.9,4.8
l-1.6,4.8l-1.4,5l-1.3,5.2l-0.9,5.2l-0.6,5.3l-0.5,5.3l-0.1,5.4H78.9z"
        />
        <path
          d="M184.6,71.7l5.4,0.1l5.4,0.4l5.3,0.6l5.2,1l5.2,1.1l5,1.4l4.9,1.8l4.8,1.9l4.6,2l4.7,2.4l4.4,2.5l4.3,2.8l4.1,2.9l4,3.1
l3.8,3.4l3.9,3.5l3.4,3.8l3.4,3.8l3.1,4l2.9,4.1l2.8,4.3l2.5,4.4l2.4,4.7l2,4.7l1.9,4.8l1.8,4.9l1.4,5l1.1,5.2l1,5.2l0.6,5.3
l0.3,2.6l0.1,2.8l0.1,5.4h-0.6l-0.1-5.4l-0.1-2.8l-0.3-2.5l-0.6-5.3l-1-5.2l-1.1-5.2l-1.4-5l-1.8-4.9l-1.9-4.8l-2-4.7l-2.3-4.4
l-2.5-4.4l-2.8-4.3l-2.9-4.1l-3.1-4l-3.4-3.8l-3.4-3.8l-3.8-3.4l-3.8-3.4l-4-3.1l-4.1-2.9l-4.3-2.8l-4.4-2.5l-4.4-2.3l-4.7-2
l-4.8-1.9l-4.9-1.8l-5-1.4l-5.2-1.1l-5.2-1l-5.3-0.6l-5.3-0.4l-5.4-0.1V71.7z"
        />
        <line fill="none" x1="184.6" y1="71.7" x2="184.6" y2="72.3" />
        <path
          d="M290.4,177.4l-0.1,5.4l-0.4,5.3l-0.6,5.3l-1,5.3l-1.1,5l-1.4,5l-1.8,4.9l-1.9,4.8l-2,4.7l-2.4,4.9l-2.5,4.4l-2.8,4.3
l-2.9,4.1l-3.1,3.9l-3.4,3.9l-3.4,3.6l-3.9,3.5l-3.8,3.3l-4,3.1l-4.1,2.9l-4.3,2.8l-4.4,2.5l-4.5,2.4l-4.7,2.1l-4.8,1.9l-4.9,1.6
l-5,1.4L206,281l-5.2,0.9l-5.3,0.6L190,283l-5.4,0.1v-0.5l5.4-0.1l5.3-0.5l5.3-0.6l5.2-0.9l5.2-1.3l5-1.4l4.8-1.6l4.8-1.9
l4.7-2.1l4.5-2.4l4.4-2.5l4.3-2.8l4.1-2.9l4-3.1l3.8-3.3l3.8-3.4l3.4-3.6l3.4-3.9l3.1-3.9l2.9-4.1l2.8-4.3l2.5-4.4l2.3-4.7l2-4.7
l1.9-4.8l1.8-4.9l1.4-5l1.1-5l1-5.2l0.6-5.3l0.4-5.3l0.1-5.4H290.4z"
        />
        <line fill="none" x1="290.4" y1="177.4" x2="289.7" y2="177.4" />
        <path
          d="M184.6,283.1l-5.4-0.1l-5.4-0.5l-5.3-0.6l-5.3-0.9l-5-1.3l-5-1.4l-4.9-1.6l-4.8-1.9l-4.8-2.1l-4.7-2.4l-4.4-2.5l-4.3-2.8
l-4.1-2.9l-3.9-3.1l-3.9-3.3l-3.6-3.5l-3.5-3.6l-3.3-3.9l-3.1-3.9l-2.9-4.1l-2.8-4.3l-2.5-4.4l-2.4-4.7l-2.1-4.8l-1.9-4.8
l-1.6-4.9l-1.4-5l-1.3-5l-0.9-5.4l-0.6-5.3l-0.3-2.6l-0.3-2.6l-0.1-5.4h0.5l0.1,5.4l0.3,2.6l0.3,2.6l0.6,5.3l0.9,5.2l1.3,5l1.4,5
l1.6,4.9l1.9,4.8l2.1,4.7l2.4,4.7l2.5,4.4l2.8,4.3l2.9,4.1l3,3.8l3.3,3.9l3.5,3.6l3.6,3.5l3.9,3.3l3.9,3.1l4.1,2.9l4.3,2.8
l4.4,2.5l4.7,2.4l4.7,2.1l4.8,1.9l4.9,1.6l5,1.4l5,1.3l5,0.9l5.3,0.6l5.4,0.5l5.4,0.1V283.1z"
        />
        <line fill="none" x1="184.6" y1="283.1" x2="184.6" y2="282.6" />
        <line fill="none" x1="78.9" y1="177.4" x2="79.4" y2="177.4" />
        <path
          d="M72.9,177.4L73,172l0.5-5.5l0.6-5.3L75,156l1.3-5.2l1.4-5l1.6-4.9l1.9-4.8l2.1-4.7l2.4-4.5l2.5-4.4l2.8-4.3l2.9-4.1
L97,110l3.3-3.8l3.5-3.8l3.6-3.4l3.9-3.4l3.9-3.1l4.1-2.9l4.3-2.8l4.4-2.5L133,82l4.6-2l4.8-1.9l4.9-1.8l5-1.4l5-1.1l5.3-1
l5.3-0.6l5.3-0.4l5.4-0.1v0.6l-5.4,0.1l-5.3,0.4l-5.3,0.6l-5.2,1l-5,1.1l-5,1.4l-4.9,1.8l-4.8,1.9l-4.7,2l-4.7,2.3l-4.4,2.5
l-4.3,2.8l-4.1,2.9l-3.9,3.1l-3.9,3.4l-3.6,3.4l-3.5,3.8l-3.3,3.8l-3,4l-2.9,4.1l-2.8,4.3l-2.5,4.4l-2.4,4.5l-2.1,4.7l-1.9,4.8
l-1.6,4.8l-1.4,5l-1.3,5.2l-0.9,5.2l-0.6,5.3l-0.5,5.3l-0.1,5.4H72.9z"
        />
        <path
          d="M178.6,71.7l5.5,0.1l5.3,0.4l5.3,0.6l5.2,1l5.2,1.1l5,1.4l4.9,1.8l4.8,1.9l4.7,2l4.7,2.4l4.4,2.5l4.3,2.8l4.1,2.9l4,3.1
l3.9,3.4l3.8,3.5l3.4,3.8l3.4,3.8l3.1,4l2.9,4.1l2.8,4.3l2.5,4.4l2.4,4.7l2,4.7l1.9,4.8l1.8,4.9l1.4,5l1.1,5.2l1,5.2l0.6,5.3
l0.3,2.6l0.1,2.8l0.1,5.4h-0.6l-0.1-5.4l-0.1-2.8l-0.3-2.5l-0.6-5.3l-1-5.2l-1.1-5.2l-1.4-5l-1.8-4.9l-1.9-4.8l-2-4.7l-2.3-4.4
l-2.5-4.4l-2.8-4.3l-2.9-4.1l-3.1-4l-3.4-3.8L253,103l-3.6-3.4l-3.9-3.4l-4-3.1l-4.1-2.9l-4.3-2.8l-4.4-2.5l-4.4-2.3l-4.7-2
l-4.8-1.9l-4.9-1.8l-5-1.4l-5.2-1.1l-5.2-1l-5.3-0.6l-5.2-0.4l-5.5-0.1V71.7z"
        />
        <line fill="none" x1="178.6" y1="71.7" x2="178.6" y2="72.3" />
        <path
          d="M284.3,177.4l-0.1,5.4l-0.4,5.3l-0.6,5.3l-1,5.3l-1.1,5l-1.4,5l-1.8,4.9l-1.9,4.8l-2,4.7l-2.4,4.9l-2.5,4.4l-2.8,4.3
l-2.9,4.1l-3.1,3.9l-3.4,3.9l-3.4,3.6l-3.8,3.5l-3.9,3.3l-4,3.1l-4.1,2.9l-4.3,2.8l-4.4,2.5l-4.5,2.4l-4.7,2.1l-4.8,1.9l-4.9,1.6
l-5,1.4L200,281l-5.2,0.9l-5.3,0.6l-5.4,0.5l-5.5,0.1v-0.5l5.5-0.1l5.2-0.5l5.3-0.6l5.2-0.9l5.2-1.3l5-1.4l4.8-1.6l4.8-1.9
l4.7-2.1l4.5-2.4l4.4-2.5l4.3-2.8l4.1-2.9l4-3.1l3.9-3.3l3.6-3.4l3.4-3.6l3.4-3.9l3.1-3.9l2.9-4.1l2.8-4.3l2.5-4.4l2.3-4.7l2-4.7
l1.9-4.8l1.8-4.9l1.4-5l1.1-5l1-5.2l0.6-5.3l0.4-5.3l0.1-5.4H284.3z"
        />
        <line fill="none" x1="284.3" y1="177.4" x2="283.7" y2="177.4" />
        <path
          d="M178.6,283.1l-5.4-0.1l-5.3-0.5l-5.3-0.6l-5.4-0.9l-5-1.3l-5-1.4l-4.9-1.6l-4.8-1.9l-4.8-2.1l-4.7-2.4l-4.4-2.5l-4.3-2.8
l-4.1-2.9l-3.9-3.1l-3.9-3.3l-3.6-3.5l-3.5-3.6l-3.3-3.9l-3.1-3.9l-2.9-4.1l-2.8-4.3l-2.5-4.4l-2.4-4.7l-2.1-4.8l-1.9-4.8
l-1.6-4.9l-1.4-5l-1.3-5l-0.9-5.4l-0.6-5.3l-0.3-2.6l-0.3-2.6l-0.1-5.4h0.5l0.1,5.4l0.3,2.6l0.3,2.6l0.6,5.3l0.9,5.2l1.3,5l1.4,5
l1.6,4.9l1.9,4.8l2.1,4.7l2.4,4.7l2.5,4.4l2.8,4.3l2.9,4.1l3,3.8l3.3,3.9l3.5,3.6l3.6,3.5l3.9,3.3l3.9,3.1l4.1,2.9l4.3,2.8
l4.4,2.5l4.7,2.4l4.7,2.1l4.8,1.9l4.9,1.6l5,1.4l5,1.3l5.2,0.9l5.3,0.6l5.3,0.5l5.4,0.1V283.1z"
        />
        <line fill="none" x1="178.6" y1="283.1" x2="178.6" y2="282.6" />
        <line fill="none" x1="72.9" y1="177.4" x2="73.4" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.3,177.4v-2.6l0.1-2.5l0.4-5.2l0.4-2.5l0.3-2.5l0.5-2.4l0.4-2.5l0.6-2.4l0.5-2.4l0.6-2.4l0.8-2.4l0.8-2.4
l0.9-2.3l0.8-2.3l1-2.3l1-2.3l1-2.3l1-2.1l1.1-2.1l1.3-2.1l1.3-2l1.3-2.1l1.3-2l1.4-2l1.4-1.9l1.5-1.9l1.5-1.9l1.6-1.9l1.5-1.8
l1.6-1.8l1.8-1.8l1.6-1.6l1.8-1.6l1.9-1.6l1.8-1.5l3.8-3l2-1.4l2-1.4l2-1.4l2-1.3l2-1.3l2.1-1.1l2.1-1.1l2.3-1.1l2.1-1l2.3-1
l2.3-0.9l2.3-0.9l2.4-0.8l2.3-0.8l2.4-0.8l2.4-0.6l2.4-0.6l2.5-0.5l2.4-0.5l2.5-0.4l2.5-0.4l2.5-0.3l2.5-0.3l2.5-0.1l2.6-0.1h2.5
h2.6l2.5,0.1l5.2,0.4l2.5,0.3l2.5,0.4l2.4,0.4l2.5,0.5l2.4,0.5l2.4,0.6l2.4,0.6l2.4,0.8l2.4,0.8l2.3,0.8l2.3,0.9l2.3,0.9l2.3,1
l2.3,1l2.1,1.1l2.1,1.1l2.1,1.1l2,1.3l2.1,1.3l2,1.4l2,1.4l1.9,1.4l1.9,1.5l1.9,1.5l1.9,1.5l1.8,1.6l1.8,1.6l1.8,1.6l1.6,1.8
l1.6,1.8l1.6,1.8l1.5,1.9l3,3.8l1.4,1.9l1.4,2l1.4,2l1.3,2.1l1.3,2l1.1,2.1l1.1,2.1l1.1,2.1l1,2.3l1,2.3l0.9,2.3l0.9,2.3l0.8,2.3
l0.8,2.4l0.8,2.4l0.6,2.4l0.6,2.4l0.5,2.4l0.5,2.5l0.4,2.4l0.4,2.5l0.3,2.5l0.3,2.5l0.1,2.6l0.1,2.5v2.6v2.5l-0.1,2.6l-0.4,5
l-0.3,2.5l-0.4,2.5l-0.4,2.5l-0.5,2.4l-0.5,2.5l-0.6,2.4l-0.6,2.4l-0.8,2.4l-0.8,2.3l-0.8,2.4l-0.9,2.3l-0.9,2.3l-1,2.3l-1,2.1
l-1.1,2.3l-1.1,2.1l-1.1,2.1l-1.3,2l-1.3,2l-1.4,2l-1.4,2l-1.4,2l-1.5,1.9l-1.5,1.9l-1.5,1.8l-1.6,1.9l-1.6,1.8l-1.6,1.6
l-1.8,1.8l-1.8,1.6l-1.8,1.5l-1.9,1.6l-3.8,3l-1.9,1.4l-2,1.4l-2,1.3l-2.1,1.3l-2,1.3l-2.1,1.3l-2.1,1.1l-2.1,1l-2.3,1l-2.3,1
l-2.3,1l-2.3,0.8l-2.3,0.9l-2.4,0.8l-2.4,0.8l-2.4,0.6l-2.4,0.5l-2.4,0.6l-2.5,0.4l-2.4,0.5l-2.5,0.3l-2.5,0.4l-2.5,0.3l-2.6,0.1
l-2.5,0.1h-2.6H182l-2.6-0.1l-5-0.4l-2.5-0.4l-2.5-0.3l-2.5-0.5l-2.4-0.4l-2.5-0.6l-2.4-0.5l-2.4-0.6l-2.4-0.8l-2.3-0.8l-2.4-0.9
l-2.3-0.8l-2.3-1l-2.3-1l-2.1-1l-2.3-1l-2.1-1.1l-2.1-1.3l-2-1.3l-2-1.3l-2-1.3l-2-1.4l-2-1.4l-1.9-1.5l-1.9-1.5l-1.8-1.6
l-1.9-1.5l-1.8-1.6l-1.6-1.8l-1.8-1.6l-1.6-1.8l-1.5-1.9l-1.6-1.8l-3-3.8l-1.4-2l-1.4-2l-1.3-2l-1.3-2l-1.3-2l-1.3-2.1l-1.1-2.1
l-1-2.3l-1-2.1l-1-2.3l-1-2.3l-0.8-2.3l-0.9-2.4l-0.8-2.3l-0.8-2.4l-0.6-2.4L87,200l-0.6-2.5l-0.4-2.4l-0.5-2.5l-0.3-2.5
l-0.4-2.5l-0.3-2.5l-0.1-2.5l-0.1-2.6V177.4z M78.7,177.4v-2.6l0.1-2.5l0.4-5.2l0.3-2.5l0.4-2.5l0.4-2.4l0.5-2.5l0.5-2.4l0.6-2.4
l0.6-2.4l0.6-2.4l0.9-2.4l0.8-2.3l0.9-2.3l0.9-2.3l1-2.3l1-2.3l1.1-2.1l1.1-2.1l1.1-2.1l1.3-2l1.3-2.1l1.4-2l1.4-2l1.4-1.9
l1.5-1.9l1.5-1.9l1.5-1.9l1.6-1.8l1.6-1.8l1.6-1.8l1.8-1.6l1.8-1.6l1.8-1.6l1.9-1.5l3.8-3l1.9-1.4l2-1.4l2-1.4l2-1.3l2.1-1.3
l2.1-1.1l2.1-1.1l2.1-1.1l2.3-1l2.1-1l2.3-0.9l2.4-0.9l2.3-0.8l2.4-0.8l2.3-0.8l2.5-0.6l2.4-0.6l2.4-0.5l2.5-0.5l2.4-0.4l2.5-0.4
l2.5-0.3l2.5-0.3l2.6-0.1l2.5-0.1h2.6h2.5l2.6,0.1l5,0.4l2.5,0.3l2.5,0.4l2.5,0.4l2.4,0.5l2.5,0.5l2.4,0.6l2.4,0.6l2.4,0.8
l2.3,0.8l2.4,0.8l2.3,0.9l2.3,0.9l2.3,1l2.1,1l2.3,1.1l2.1,1.1l2,1.1l2.1,1.3l2,1.3l2,1.4l2,1.4l2,1.4l1.9,1.5l1.9,1.5l1.8,1.5
l1.9,1.6l1.8,1.6l1.6,1.6l1.8,1.8l1.6,1.8l1.5,1.8l1.6,1.9l2.9,3.8l1.5,1.9l1.4,2l1.3,2l1.3,2.1l1.3,2l1.3,2.1l1.1,2.1l1,2.1
l1,2.3l1,2.3l0.9,2.3l0.9,2.3l0.9,2.3l0.8,2.4l0.8,2.4l0.6,2.4l0.5,2.4l0.6,2.4l0.4,2.5l0.5,2.4l0.3,2.5l0.4,2.5l0.1,2.5l0.3,2.6
v2.5l0.1,2.6l-0.1,2.5v2.6l-0.4,5l-0.4,2.5l-0.3,2.5l-0.5,2.5l-0.4,2.4l-0.6,2.5l-0.5,2.4l-0.6,2.4l-0.8,2.4l-0.8,2.3l-0.9,2.4
l-0.9,2.3l-0.9,2.3l-1,2.3l-1,2.1l-1,2.3l-1.1,2.1l-1.3,2.1l-1.3,2l-1.3,2l-1.3,2l-1.4,2l-1.5,2l-1.4,1.9l-1.5,1.9l-1.6,1.8
l-1.5,1.9l-1.6,1.8l-1.8,1.6l-1.6,1.8l-1.8,1.6l-1.9,1.5l-1.8,1.6l-3.8,3l-2,1.4l-2,1.4l-2,1.3l-2,1.3l-2.1,1.3l-2,1.3l-2.1,1.1
l-2.3,1l-2.1,1l-2.3,1l-2.3,1l-2.3,0.8l-2.4,0.9l-2.3,0.8l-2.4,0.8l-2.4,0.6l-2.4,0.5l-2.5,0.6l-2.4,0.4l-2.5,0.5l-2.5,0.3
l-2.5,0.4l-2.5,0.3l-2.5,0.1l-2.6,0.1h-2.5h-2.6l-2.5-0.1l-5.2-0.4l-2.5-0.4l-2.5-0.3l-2.4-0.5l-2.5-0.4l-2.4-0.6l-2.4-0.5
l-2.5-0.6l-2.3-0.8l-2.4-0.8l-2.3-0.9l-2.4-0.8l-2.3-1l-2.1-1l-2.3-1l-2.1-1l-2.1-1.1l-2.1-1.3l-2.1-1.3l-2-1.3l-2-1.3l-2-1.4
l-1.9-1.4l-1.9-1.5l-1.9-1.5l-1.9-1.6l-1.8-1.5l-1.8-1.6l-1.8-1.8l-1.6-1.6l-1.6-1.8l-1.6-1.9l-1.5-1.8l-3-3.8l-1.4-2l-1.4-2
l-1.4-2l-1.3-2l-1.3-2l-1.1-2.1l-1.1-2.1l-1.1-2.3l-1-2.1l-1-2.3l-0.9-2.3l-0.9-2.3l-0.8-2.4l-0.9-2.3l-0.6-2.4l-0.6-2.4
l-0.6-2.4l-0.5-2.5l-0.5-2.4l-0.4-2.5l-0.4-2.5l-0.3-2.5l-0.3-2.5l-0.1-2.5l-0.1-2.6V177.4z"
        />
        <path
          d="M84.1,177.4l0.1-5.2l0.4-5.3l0.6-5l0.9-4.9l1.1-4.9l1.4-4.7l1.5-4.7l1.9-4.7l2-4.4l2.1-4.3l2.4-4.3l2.6-4l2.8-4l3-3.8
l3.1-3.6l3.3-3.5l3.5-3.3l3.6-3.1l3.8-3l3.9-2.8l4.1-2.6l4.1-2.4l9.1-4.3l4.5-1.8l4.7-1.6l4.8-1.3l4.8-1.1l5-0.9l5-0.6l5-0.4
l5.2-0.1v0.6l-5.2,0.1l-5,0.4l-5,0.6l-4.9,0.9l-4.8,1.1l-4.8,1.3l-4.6,1.6l-4.5,1.8l-8.8,4.1l-4.1,2.4l-4.1,2.6l-3.9,2.8l-3.8,3
l-3.6,3.1l-3.5,3.3l-3.3,3.5l-3.1,3.6l-3,3.8l-2.6,3.9l-2.6,4l-2.4,4.3l-2.1,4.3l-2,4.4l-1.9,4.7l-1.5,4.5l-1.4,4.7l-1.1,4.9
l-0.9,4.9l-0.6,5l-0.4,5l-0.1,5.2H84.1z"
        />
        <path
          d="M184.5,76.9l5.2,0.1l5.2,0.4l4.9,0.6l5,0.9l4.8,1.1l4.8,1.3L219,83l4.5,1.8l4.5,2l4.4,2.3l4.1,2.4l4.1,2.6l3.9,2.8l3.8,3
l3.6,3.1l3.6,3.4l3.3,3.5l3.1,3.6l3,3.8l2.8,3.9l2.6,4l2.4,4.3l4.1,8.8l1.9,4.7l1.5,4.7l1.4,4.7l1.1,4.9l0.9,4.9l0.6,5l0.4,5.2
l0.1,5.2h-0.6l-0.1-5.2l-0.4-5l-0.6-5l-0.9-4.9l-1.1-4.9l-1.4-4.7l-1.5-4.7l-1.9-4.7l-4-8.5l-2.4-4.3l-2.6-4l-2.8-3.9l-3-3.8
l-3.1-3.6l-3.3-3.5l-3.5-3.3l-3.6-3.1l-3.8-3l-3.9-2.8l-4.1-2.6l-4.1-2.4l-4.1-2.1l-4.5-2l-4.5-1.8l-4.7-1.6l-4.8-1.3l-4.8-1.1
l-5-0.9l-4.9-0.6l-5-0.4l-5.2-0.1V76.9z"
        />
        <line fill="none" x1="184.5" y1="76.9" x2="184.5" y2="77.6" />
        <path
          d="M284.9,177.4l-0.1,5.2l-0.4,5l-0.6,5l-0.9,4.9l-1.1,4.9l-1.4,4.8l-1.5,4.7l-1.9,4.5l-1.9,4.4l-2.3,4.7l-2.4,4.1l-2.6,4
l-2.8,4l-3,3.8l-3.1,3.6l-3.3,3.4l-3.6,3.4l-3.6,3.1l-3.8,2.9l-3.9,2.9l-4.1,2.5l-4.1,2.5l-8.8,4.1l-4.5,1.8l-4.7,1.6l-4.8,1.4
l-4.8,1.1l-5,0.9l-4.9,0.6l-5.3,0.4l-5.2,0.1v-0.5l5.2-0.1l5-0.4l4.9-0.6l5-0.9l4.8-1.1l4.8-1.4l4.5-1.6l4.5-1.8l8.8-4.1l4.1-2.5
l4.1-2.5l3.9-2.9l3.8-2.9l3.6-3.1l3.5-3.3l3.3-3.4l3.1-3.6l3-3.8l2.8-4l2.6-4l2.4-4.1l2.1-4.4l1.9-4.4l1.9-4.5l1.5-4.7l1.4-4.8
l1.1-4.9l0.9-4.8l0.6-5l0.4-5l0.1-5.2H284.9z"
        />
        <line fill="none" x1="284.9" y1="177.4" x2="284.3" y2="177.4" />
        <path
          d="M184.5,277.8l-5.2-0.1l-5-0.4l-5-0.6l-5.2-0.9l-4.8-1.1l-4.8-1.4l-4.7-1.6l-4.5-1.8l-4.5-2l-4.4-2.1l-4.1-2.5l-4.1-2.5
l-3.9-2.9l-3.8-2.9l-3.6-3.1l-3.5-3.4l-3.3-3.4l-3.1-3.6l-3-3.8l-2.8-4l-2.6-4l-2.4-4.1l-4.1-8.8l-1.9-4.7l-1.5-4.7l-1.4-4.8
l-1.1-4.9l-0.9-5l-0.6-5l-0.4-5l-0.1-5.2h0.5l0.1,5.2l0.4,5l0.6,5l0.9,4.8l1.1,4.9l1.4,4.8l1.5,4.7l1.9,4.5l4.1,8.8l2.4,4.1
l2.6,4l2.8,4l2.9,3.6l3.1,3.6l3.3,3.4l3.5,3.4l3.6,3.1l3.8,2.9l3.9,2.9l4.1,2.5l4.1,2.5l4.4,2.1l4.4,2l4.5,1.8l4.6,1.6l4.8,1.4
l4.8,1.1l4.9,0.9l5,0.6l5,0.4l5.2,0.1V277.8z"
        />
        <line fill="none" x1="184.5" y1="277.8" x2="184.5" y2="277.3" />
        <line fill="none" x1="84.1" y1="177.4" x2="84.6" y2="177.4" />
        <path
          d="M78.4,177.4l0.1-5.2l0.4-5.3l0.6-5l0.9-4.9l1.1-4.9l1.3-4.7l1.6-4.7l1.8-4.7l2-4.4l2.3-4.3l2.4-4.3l2.6-4l2.8-4l3-3.8
l3.1-3.6l3.3-3.5l3.5-3.3l3.6-3.1l3.8-3l3.9-2.8l4-2.6l4.3-2.4l8.9-4.3l4.7-1.8l4.6-1.6l4.7-1.3l4.9-1.1l4.9-0.9l5-0.6l5.2-0.4
l5.2-0.1v0.6l-5.2,0.1l-5.2,0.4l-5,0.6l-4.8,0.9l-4.9,1.1l-4.7,1.3l-4.7,1.6l-4.7,1.8l-8.7,4.1l-4.3,2.4l-4,2.6l-3.9,2.8l-3.8,3
l-3.6,3.1l-3.5,3.3l-3.3,3.5l-3.1,3.6l-3,3.8l-2.6,3.9l-2.6,4l-2.4,4.3l-2.3,4.3l-2,4.4l-1.8,4.7l-1.6,4.5l-1.3,4.7l-1.1,4.9
l-0.9,4.9l-0.6,5l-0.4,5l-0.1,5.2H78.4z"
        />
        <path
          d="M178.9,76.9l5.2,0.1l5,0.4l5,0.6l4.9,0.9l4.9,1.1l4.8,1.3l4.7,1.6l4.5,1.8l4.4,2l4.4,2.3l4.3,2.4l4,2.6l3.9,2.8l3.9,3
l3.5,3.1l3.6,3.4l3.4,3.5l3.1,3.6l2.9,3.8l2.8,3.9l2.6,4l2.4,4.3l4.3,8.8l1.8,4.7l1.6,4.7l1.4,4.7l1,4.9l0.9,4.9l0.8,5l0.4,5.2
l0.1,5.2h-0.6l-0.1-5.2l-0.4-5l-0.8-5l-0.9-4.9l-1-4.9l-1.4-4.7l-1.6-4.7l-1.8-4.7l-4.1-8.5l-2.4-4.3l-2.6-4l-2.8-3.9l-2.9-3.8
l-3.1-3.6l-3.4-3.5l-3.5-3.3l-3.5-3.1l-3.9-3l-3.9-2.8l-4-2.6l-4.3-2.4l-4.1-2.1l-4.4-2l-4.5-1.8l-4.6-1.6l-4.8-1.3l-4.9-1.1
l-4.9-0.9l-5-0.6l-4.9-0.4l-5.2-0.1V76.9z"
        />
        <line fill="none" x1="178.9" y1="76.9" x2="178.9" y2="77.6" />
        <path
          d="M279.3,177.4l-0.1,5.2l-0.4,5l-0.8,5l-0.9,4.9l-1,4.9l-1.4,4.8l-1.6,4.7l-1.8,4.5l-2,4.4l-2.3,4.7l-2.4,4.1l-2.6,4
l-2.8,4l-2.9,3.8l-3.1,3.6l-3.4,3.4l-3.6,3.4l-3.5,3.1l-3.9,2.9l-3.9,2.9l-4,2.5l-4.3,2.5l-8.7,4.1l-4.5,1.8l-4.7,1.6l-4.8,1.4
l-4.9,1.1l-4.9,0.9l-5,0.6l-5.2,0.4l-5.2,0.1v-0.5l5.2-0.1l4.9-0.4l5-0.6l4.9-0.9l4.9-1.1l4.8-1.4l4.5-1.6l4.5-1.8l8.7-4.1
l4.3-2.5l4-2.5l3.9-2.9l3.9-2.9l3.5-3.1l3.5-3.3l3.4-3.4l3.1-3.6l2.9-3.8l2.8-4l2.6-4l2.4-4.1l2.1-4.4l2-4.4l1.8-4.5l1.6-4.7
l1.4-4.8l1-4.9l0.9-4.8l0.8-5l0.4-5l0.1-5.2H279.3z"
        />
        <line fill="none" x1="279.3" y1="177.4" x2="278.7" y2="177.4" />
        <path
          d="M178.9,277.8l-5.2-0.1l-5.2-0.4l-5-0.6l-5-0.9l-4.9-1.1l-4.6-1.4l-4.7-1.6l-4.7-1.8l-4.5-2l-4.3-2.1l-4.3-2.5l-4-2.5
l-3.9-2.9l-3.8-2.9l-3.6-3.1l-3.5-3.4l-3.3-3.4l-3.1-3.6l-3-3.8l-2.8-4l-2.6-4l-2.4-4.1l-4.3-8.8l-1.8-4.7l-1.6-4.7l-1.3-4.8
l-1.1-4.9l-0.9-5l-0.6-5l-0.4-5l-0.1-5.2h0.5l0.1,5.2l0.4,5l0.6,5l0.9,4.8l1.1,4.9l1.3,4.8l1.6,4.7l1.8,4.5L91,225l2.4,4.1l2.6,4
l2.8,4l2.9,3.6l3.1,3.6l3.3,3.4l3.5,3.4l3.6,3.1l3.8,2.9l3.9,2.9l4,2.5l4.3,2.5l4.3,2.1l4.4,2l4.7,1.8l4.7,1.6l4.7,1.4l4.9,1.1
l4.8,0.9l5,0.6l5.2,0.4l5.2,0.1V277.8z"
        />
        <line fill="none" x1="178.9" y1="277.8" x2="178.9" y2="277.3" />
        <line fill="none" x1="78.4" y1="177.4" x2="78.9" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.2,177.4v-2.5l0.1-2.4l0.1-2.4l0.3-2.5l0.3-2.4l0.4-2.4l0.4-2.3l0.4-2.4l0.5-2.3l0.6-2.3l0.6-2.3l0.6-2.3
l1.5-4.5l1.6-4.3l1-2.1l1-2l1-2.1l1-2l1.1-2l1.1-2l1.3-1.9l1.3-2l1.4-1.9l1.3-1.8l1.4-1.9l1.5-1.8l3-3.4l1.5-1.8l1.6-1.6l1.6-1.5
l1.6-1.6l1.8-1.5l1.6-1.4l1.9-1.5l1.8-1.4l1.9-1.4l1.9-1.3l3.8-2.5l2-1.1l2-1.1l2-1.1l2.1-1l4.1-1.9l2.1-0.9l2.3-0.8l2.1-0.9
l2.3-0.6l2.3-0.8l2.3-0.6l2.3-0.5l2.3-0.5l2.4-0.5l2.4-0.4l2.4-0.3l2.4-0.3l2.4-0.3l2.4-0.1l2.5-0.1h2.4h2.5l2.4,0.1l2.4,0.1
l2.4,0.3l2.5,0.3l2.3,0.3l2.4,0.4l2.4,0.5l2.3,0.5l2.3,0.5l2.3,0.6l2.3,0.8l4.4,1.5l4.4,1.6l2.1,0.9l2,1l2.1,1l2,1.1l2,1.1l2,1.1
l1.9,1.3l1.9,1.3l1.9,1.3l1.9,1.4l1.9,1.4l1.8,1.5l3.4,2.9l1.6,1.6l1.6,1.5l1.6,1.6l1.6,1.8l1.5,1.6l1.4,1.8l1.5,1.8l1.4,1.9
l1.4,1.8l1.3,1.9l2.5,3.9l1.1,2l1.1,2l1,2l1.1,2.1l1.9,4.1l0.9,2.1l0.8,2.1l0.8,2.3l0.8,2.3l0.8,2.3l0.5,2.3l0.6,2.3l0.5,2.3
l0.4,2.4l0.5,2.3l0.3,2.4l0.3,2.4l0.3,2.5l0.1,2.4l0.1,2.4v2.5v2.4l-0.1,2.5l-0.1,2.4l-0.3,2.4l-0.3,2.4l-0.3,2.4l-0.5,2.4
l-0.4,2.3l-0.5,2.4l-0.6,2.3l-0.5,2.3l-0.8,2.3l-1.5,4.4l-1.6,4.4l-1,2l-0.9,2.1l-1.1,2.1l-1,2l-1.1,2l-1.1,1.9l-1.3,2l-1.3,1.9
l-1.3,1.9l-1.4,1.9l-1.4,1.8l-1.5,1.8l-2.9,3.5l-1.6,1.6l-1.6,1.6l-1.6,1.6l-1.6,1.5l-1.6,1.5l-1.8,1.5l-1.8,1.4l-1.9,1.5
l-1.9,1.3l-1.9,1.4l-3.8,2.4l-2,1.3l-2,1.1l-2,1l-2.1,1l-4.1,1.9l-2.1,0.9l-2.3,0.9l-2.1,0.8l-2.3,0.8l-2.3,0.6l-2.3,0.6
l-2.3,0.6l-2.3,0.5l-2.4,0.4l-2.4,0.4l-2.3,0.4l-2.5,0.3l-2.4,0.3l-2.4,0.1l-2.4,0.1h-2.5H182l-2.5-0.1l-2.4-0.1l-2.4-0.3
l-2.4-0.3l-2.4-0.4l-2.4-0.4l-2.4-0.4l-2.3-0.5l-2.3-0.6l-2.3-0.6l-2.3-0.6l-4.4-1.5l-4.4-1.8l-2.1-0.9l-2-1l-2.1-1l-2-1l-2-1.1
l-2-1.3l-1.9-1.1l-1.9-1.3l-1.9-1.4l-1.9-1.3l-1.8-1.5l-1.9-1.4l-3.4-3l-1.6-1.5l-1.6-1.6l-1.6-1.6l-1.5-1.6l-1.5-1.8l-1.5-1.8
l-1.5-1.8l-1.4-1.8l-1.3-1.9l-1.4-1.9l-2.5-3.9l-1.1-1.9l-1.1-2l-1-2l-1-2.1l-2-4.1l-0.9-2.3l-0.8-2.1l-0.8-2.1l-0.8-2.3
l-0.6-2.3l-0.6-2.3l-0.6-2.3l-0.5-2.4l-0.4-2.3l-0.4-2.4l-0.4-2.4l-0.3-2.4l-0.3-2.4l-0.1-2.4l-0.1-2.5V177.4z M83.8,177.4v-2.5
l0.1-2.4l0.1-2.4l0.3-2.5l0.3-2.4l0.3-2.4l0.4-2.3l0.5-2.4l0.5-2.3l0.5-2.3l0.6-2.3l0.8-2.3l1.5-4.5l1.6-4.3l1-2.1l0.9-2l1-2.1
l1.1-2l1.1-2l1.1-2l1.3-1.9l1.3-2l1.3-1.9l1.4-1.8l1.4-1.9l1.5-1.8l2.9-3.4l1.6-1.8l1.5-1.6l1.6-1.5l1.8-1.6l1.6-1.5l1.8-1.4
l1.8-1.5l1.9-1.4l1.9-1.4l1.8-1.3l3.9-2.5l2-1.1l2-1.1l2-1.1l2.1-1l4.1-1.9l2.1-0.9l2.1-0.8l2.3-0.9l2.3-0.6l2.3-0.8l2.3-0.6
l2.3-0.5l2.3-0.5l2.4-0.5l2.4-0.4l2.3-0.3l2.4-0.3l2.5-0.3l2.4-0.1l2.4-0.1h2.5h2.4l2.5,0.1l2.4,0.1l2.4,0.3l2.4,0.3l2.4,0.3
l2.4,0.4l2.3,0.5l2.4,0.5l2.3,0.5l2.3,0.6l2.3,0.8l4.4,1.5l4.4,1.6l2,0.9l2.1,1l2.1,1l2,1.1l2,1.1l1.9,1.1l2,1.3l1.9,1.3l1.9,1.3
l1.9,1.4l1.8,1.4l1.8,1.5l3.5,2.9l1.6,1.6l1.6,1.5l1.6,1.6l1.5,1.8l1.5,1.6l1.5,1.8l1.5,1.8l1.4,1.9l1.3,1.8l1.4,1.9l2.4,3.9
l1.3,2l1.1,2l1,2l1,2.1l2,4.1l0.9,2.1l0.8,2.1l0.8,2.3l0.8,2.3l0.6,2.3l0.6,2.3l0.6,2.3l0.5,2.3l0.4,2.4l0.4,2.3l0.4,2.4l0.3,2.4
l0.3,2.5l0.1,2.4l0.1,2.4v2.5v2.4l-0.1,2.5l-0.1,2.4l-0.3,2.4l-0.3,2.4l-0.4,2.4l-0.4,2.4l-0.4,2.3l-0.5,2.4l-0.6,2.3l-0.6,2.3
l-0.6,2.3l-1.5,4.4l-1.6,4.4l-1,2l-1,2.1l-1,2.1l-1,2l-1.1,2l-1.3,1.9l-1.1,2l-1.3,1.9l-1.4,1.9l-1.3,1.9l-1.4,1.8l-1.5,1.8
l-3,3.5l-1.5,1.6l-1.6,1.6l-1.6,1.6l-1.6,1.5l-1.8,1.5l-1.8,1.5l-1.8,1.4l-1.8,1.5l-1.9,1.3l-1.9,1.4l-3.9,2.4l-1.9,1.3l-2,1.1
l-2,1l-2.1,1l-4.1,1.9l-2.3,0.9l-2.1,0.9l-2.1,0.8l-2.3,0.8l-2.3,0.6l-2.3,0.6l-2.3,0.6l-2.4,0.5l-2.3,0.4l-2.4,0.4l-2.4,0.4
l-2.4,0.3l-2.4,0.3l-2.4,0.1l-2.5,0.1H179h-2.5l-2.4-0.1l-2.4-0.1l-2.5-0.3l-2.4-0.3l-2.3-0.4l-2.4-0.4l-2.4-0.4l-2.3-0.5
l-2.3-0.6l-2.3-0.6l-2.3-0.6l-4.5-1.5l-4.3-1.8l-2.1-0.9l-2-1l-2.1-1l-2-1l-2-1.1l-2-1.3l-1.9-1.1l-2-1.3l-1.8-1.4l-1.9-1.3
l-1.9-1.5l-1.8-1.4l-3.4-3l-1.8-1.5l-1.6-1.6l-1.5-1.6l-1.6-1.6l-1.5-1.8l-1.4-1.8l-1.5-1.8l-1.4-1.8l-1.4-1.9l-1.3-1.9l-2.5-3.9
l-1.1-1.9l-1.1-2l-1.1-2l-1-2.1l-1.9-4.1l-0.9-2.3l-0.8-2.1l-0.9-2.1l-0.6-2.3l-0.8-2.3l-0.6-2.3l-0.5-2.3l-0.5-2.4l-0.5-2.3
l-0.4-2.4l-0.3-2.4l-0.3-2.4l-0.3-2.4l-0.1-2.4l-0.1-2.5V177.4z"
        />
        <path
          d="M89,177.4l0.1-4.9l0.4-4.9l0.5-4.9l0.9-4.7l1.1-4.7l1.3-4.4l1.5-4.5l1.6-4.3l1.9-4.3l2.1-4l2.3-4l2.5-3.9l2.6-3.6
l2.9-3.6l2.9-3.4l3.3-3.4l3.3-3.1l3.4-3l7.4-5.4l3.8-2.5l4.3-2.4l4.1-2.1l4.1-1.9l4.4-1.8l4.4-1.4l4.5-1.4l4.5-1l4.8-0.9l4.8-0.6
l4.8-0.4l4.9-0.1v0.5l-4.9,0.1l-4.8,0.4l-4.8,0.6l-4.8,0.9l-4.5,1l-4.4,1.4l-4.4,1.4l-4.4,1.8l-4.1,1.9l-4.1,2.1l-4,2.3l-3.8,2.5
l-7.4,5.4l-3.4,3l-3.3,3.1l-3.3,3.4l-2.9,3.4l-2.9,3.6l-2.6,3.6l-2.5,3.9l-2.3,4l-2.1,4l-1.9,4.3l-1.6,4.1l-1.5,4.5l-1.3,4.4
l-1.1,4.7l-0.9,4.7l-0.5,4.7l-0.4,4.9l-0.1,4.9H89z"
        />
        <path
          d="M184.4,81.8l4.9,0.1l4.8,0.4l4.8,0.6l4.8,0.9l4.5,1l4.5,1.4l4.4,1.4l4.4,1.8l4.1,1.9l4.1,2.1l4.1,2.4l3.8,2.5l3.8,2.6
l3.6,2.8l3.4,3l3.3,3.1l3.1,3.4l3,3.4l5.5,7.3l2.5,3.9l2.4,4.1l2,4l2,4.3l1.6,4.3l1.5,4.5l1.3,4.4l1.1,4.7l0.8,4.7l0.6,4.8
l0.4,4.9l0.1,4.9h-0.5l-0.1-4.9l-0.4-4.9l-0.6-4.7l-0.8-4.7l-1.1-4.7l-1.3-4.4l-1.5-4.5l-1.6-4.3l-2-4.3l-2-4l-2.3-3.9l-2.5-3.9
l-5.5-7.3l-3-3.4l-3.1-3.4l-3.3-3.1l-3.4-3l-3.6-2.8l-3.8-2.6l-3.8-2.5l-3.9-2.3l-4.1-2.1l-4.1-1.9l-4.4-1.8l-4.4-1.4l-4.5-1.4
l-4.5-1l-4.8-0.9l-4.7-0.6l-4.8-0.4l-4.9-0.1V81.8z"
        />
        <line fill="none" x1="184.4" y1="81.8" x2="184.4" y2="82.3" />
        <path
          d="M279.8,177.4l-0.1,4.9l-0.4,4.8l-0.6,4.8l-0.8,4.7l-1.1,4.7l-1.3,4.5l-1.5,4.4l-1.6,4.3l-2,4.3l-2,4.1l-2.4,4.1l-2.5,3.9
l-2.6,3.8l-2.9,3.5l-3,3.5l-3.1,3.3l-3.3,3.1l-3.4,3l-7.4,5.5l-3.8,2.4l-4,2.3l-4.1,2.1l-4.1,1.9l-4.4,1.8l-4.4,1.5l-4.5,1.3
l-4.5,1l-4.8,0.9l-4.9,0.6l-4.8,0.4l-4.9,0.1v-0.4l4.9-0.1l4.8-0.4l4.7-0.6l4.8-0.9l4.5-1l4.5-1.3l4.4-1.5l4.3-1.8l4.1-1.9
l4.1-2.1l4-2.3l3.8-2.4l7.4-5.5l3.4-3l3.3-3.1l3.1-3.3l3-3.5l2.9-3.5l2.6-3.8l2.5-3.9l2.3-3.9l2-4.1l2-4.3l1.6-4.3l1.5-4.4
l1.3-4.4l1.1-4.7l0.8-4.7l0.6-4.8l0.4-4.8l0.1-4.9H279.8z"
        />
        <line fill="none" x1="279.8" y1="177.4" x2="279.3" y2="177.4" />
        <path
          d="M184.4,272.8l-4.9-0.1l-4.8-0.4l-4.8-0.6l-4.8-0.9l-4.5-1l-4.7-1.3l-4.4-1.5l-4.4-1.8l-4.1-1.9l-4.1-2.1l-4.1-2.3
l-3.8-2.4l-3.8-2.8l-3.6-2.8l-3.3-3l-3.3-3.1l-3.3-3.3l-3-3.5l-5.5-7.3l-2.5-3.9l-2.3-4l-2.1-4.1l-1.9-4.3l-1.6-4.3l-1.5-4.4
l-1.3-4.7l-1.1-4.7l-0.9-4.7l-0.5-4.8l-0.4-4.8l-0.1-4.9h0.4l0.1,4.9l0.4,4.8l0.5,4.8l0.9,4.7l1.1,4.7l1.3,4.4l1.5,4.4l1.6,4.3
l1.9,4.3l2.1,4.1l2.3,3.9l2.5,3.9l5.5,7.3l2.9,3.5l3.3,3.3l3.3,3.1l3.4,3l3.6,2.8l3.8,2.8l3.8,2.4l4,2.3l4.1,2.1l4.1,1.9l4.4,1.8
l4.4,1.5l4.4,1.3l4.5,1l4.8,0.9l4.8,0.6l4.8,0.4l4.9,0.1V272.8z"
        />
        <line fill="none" x1="184.4" y1="272.8" x2="184.4" y2="272.4" />
        <line fill="none" x1="89" y1="177.4" x2="89.4" y2="177.4" />
        <path
          d="M83.6,177.4l0.1-4.9l0.3-4.9l0.6-4.9l0.9-4.7l1-4.7l1.4-4.4l1.4-4.5l1.8-4.3l1.9-4.3l2.1-4l2.3-4l2.5-3.9l2.6-3.6
l2.8-3.6l3-3.4l3.1-3.4l3.4-3.1l3.4-3l7.3-5.4l3.9-2.5l4.3-2.4l4-2.1l4.3-1.9l4.3-1.8l4.5-1.4l4.4-1.4l4.7-1l4.7-0.9l4.8-0.6
l4.9-0.4l4.9-0.1v0.5l-4.9,0.1l-4.9,0.4l-4.8,0.6l-4.7,0.9l-4.7,1l-4.3,1.4l-4.5,1.4l-4.3,1.8l-4.3,1.9l-4,2.1l-4,2.3l-3.9,2.5
l-7.3,5.4l-3.4,3l-3.4,3.1l-3.1,3.4l-3,3.4l-2.8,3.6l-2.6,3.6l-2.5,3.9l-2.3,4l-2.1,4l-1.9,4.3l-1.8,4.1l-1.4,4.5l-1.4,4.4
l-1,4.7l-0.9,4.7l-0.6,4.7l-0.3,4.9l-0.1,4.9H83.6z"
        />
        <path
          d="M179,81.8l4.9,0.1l4.8,0.4l4.8,0.6l4.7,0.9l4.7,1l4.5,1.4l4.4,1.4l4.3,1.8l4.3,1.9l4.1,2.1l4,2.4l3.9,2.5l3.8,2.6
l3.5,2.8l3.5,3l3.3,3.1l3.1,3.4l3,3.4l5.5,7.3l2.4,3.9l2.5,4.1l2,4l1.9,4.3l1.8,4.3l1.5,4.5l1.3,4.4l1.1,4.7l0.8,4.7l0.6,4.8
l0.4,4.9l0.1,4.9h-0.5l-0.1-4.9l-0.4-4.9l-0.6-4.7l-0.8-4.7l-1.1-4.7l-1.3-4.4l-1.5-4.5l-1.8-4.3l-1.9-4.3l-2-4l-2.4-3.9
l-2.4-3.9l-5.5-7.3l-3-3.4l-3.1-3.4l-3.3-3.1l-3.5-3l-3.5-2.8l-3.8-2.6l-3.9-2.5l-3.8-2.3l-4.1-2.1l-4.3-1.9l-4.3-1.8l-4.4-1.4
l-4.5-1.4l-4.6-1l-4.7-0.9l-4.7-0.6l-4.8-0.4l-4.9-0.1V81.8z"
        />
        <line fill="none" x1="179" y1="81.8" x2="179" y2="82.3" />
        <path
          d="M274.4,177.4l-0.1,4.9l-0.4,4.8l-0.6,4.8l-0.8,4.7l-1.1,4.7l-1.3,4.5l-1.5,4.4l-1.8,4.3l-1.9,4.3l-2,4.1l-2.5,4.1
l-2.4,3.9l-2.8,3.8l-2.8,3.5l-3,3.5l-3.1,3.3l-3.3,3.1l-3.5,3l-7.3,5.5l-3.9,2.4l-3.9,2.3l-4.1,2.1l-4.3,1.9l-4.3,1.8l-4.4,1.5
l-4.5,1.3l-4.7,1l-4.7,0.9l-4.9,0.6l-4.8,0.4l-4.9,0.1v-0.4l4.9-0.1l4.8-0.4l4.7-0.6l4.7-0.9l4.6-1l4.5-1.3l4.4-1.5l4.1-1.8
l4.3-1.9l4.1-2.1l3.9-2.3l3.9-2.4l7.3-5.5l3.5-3l3.3-3.1l3.1-3.3l3-3.5l2.8-3.5l2.8-3.8l2.4-3.9l2.4-3.9l2-4.1l1.9-4.3l1.8-4.3
l1.5-4.4l1.3-4.4l1.1-4.7l0.8-4.7l0.6-4.8l0.4-4.8l0.1-4.9H274.4z"
        />
        <line fill="none" x1="274.4" y1="177.4" x2="273.9" y2="177.4" />
        <path
          d="M179,272.8l-4.9-0.1l-4.9-0.4l-4.8-0.6l-4.7-0.9l-4.7-1l-4.5-1.3l-4.5-1.5l-4.3-1.8l-4.3-1.9l-4-2.1l-4.1-2.3l-3.9-2.4
l-3.6-2.8l-3.6-2.8l-3.3-3l-3.4-3.1l-3.1-3.3l-3.1-3.5l-5.4-7.3l-2.5-3.9l-2.3-4l-2.1-4.1l-1.9-4.3l-1.8-4.3l-1.4-4.4l-1.4-4.7
l-1-4.7l-0.9-4.7l-0.6-4.8l-0.3-4.8l-0.1-4.9H84l0.1,4.9l0.3,4.8l0.6,4.8l0.9,4.7l1,4.7l1.4,4.4l1.4,4.4l1.8,4.3l1.9,4.3l2.1,4.1
l2.3,3.9l2.5,3.9l5.4,7.3l3,3.5l3.1,3.3l3.4,3.1l3.4,3l3.6,2.8l3.6,2.8l3.9,2.4l4,2.3l4,2.1l4.3,1.9l4.3,1.8l4.5,1.5l4.3,1.3
l4.7,1l4.7,0.9l4.8,0.6l4.9,0.4l4.9,0.1V272.8z"
        />
        <line fill="none" x1="179" y1="272.8" x2="179" y2="272.4" />
        <line fill="none" x1="83.6" y1="177.4" x2="84" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.9,177.4V175l0.1-2.3l0.1-2.4l0.1-2.3l0.6-4.5l0.4-2.3l0.4-2.1l0.5-2.3l0.5-2.1l1.3-4.3l0.6-2.1l0.8-2.1
l0.9-2l0.8-2l0.9-2l1-2l0.9-2l1-1.9l1.1-1.9l1.1-1.9l1.1-1.9l1.3-1.8l2.5-3.6l2.6-3.4l1.4-1.6l1.5-1.6l2.9-3.1l1.6-1.5l1.5-1.5
l1.6-1.4l1.6-1.4l1.8-1.4l1.8-1.4l1.8-1.3l1.8-1.3l1.8-1.1l1.9-1.1l3.8-2.3l3.9-2l4-1.8l4.1-1.6l2-0.8l2.1-0.6l2.1-0.6l2.1-0.6
l2.3-0.5l2.1-0.5l2.3-0.4l2.1-0.4l4.5-0.6l2.4-0.1l2.3-0.3h2.3l2.4-0.1l2.4,0.1h2.3l2.3,0.3l2.4,0.1l4.5,0.6l2.1,0.4l2.3,0.4
l2.1,0.5l2.3,0.5l4.3,1.3l2.1,0.6l2,0.8l2.1,0.8l2,0.9l2,0.9l2,0.9l1.9,1l2,1l1.9,1.1l1.9,1.1l1.9,1.1l1.8,1.1l3.5,2.5l3.5,2.8
l1.6,1.4l1.6,1.4l3.1,3l1.5,1.6l1.4,1.5l1.5,1.6l1.4,1.6l1.4,1.8l1.3,1.6l1.4,1.8l1.1,1.9l1.3,1.8l1.1,1.9l2.1,3.8l2,3.9l1.9,4
l1.6,4l0.8,2.1l0.6,2.1l0.6,2.1l0.6,2.1l0.5,2.1l0.5,2.3l0.4,2.1l0.4,2.3l0.6,4.5l0.1,2.3l0.1,2.4l0.1,2.3v2.4v2.3l-0.1,2.4
l-0.1,2.3l-0.1,2.3l-0.6,4.5l-0.4,2.3l-0.4,2.3l-0.5,2.1l-0.5,2.3l-1.3,4.3l-0.6,2.1l-0.8,2l-0.9,2.1l-0.8,2l-0.9,2l-1,2
l-0.9,1.9l-1.1,2l-1,1.9l-1.1,1.9l-1.1,1.9l-1.3,1.8l-2.5,3.5l-2.6,3.4l-1.4,1.8l-1.5,1.5l-2.9,3.1l-1.6,1.6l-1.5,1.4l-1.6,1.5
l-1.6,1.4l-1.8,1.4l-1.8,1.3l-1.8,1.3l-1.8,1.3l-1.8,1.3l-1.9,1.1l-3.8,2.1l-3.9,2l-4,1.9l-4.1,1.5l-2,0.8l-2.1,0.8l-2.1,0.6
l-2.1,0.5l-2.3,0.6l-2.1,0.5l-2.3,0.4l-2.1,0.4l-4.5,0.5l-2.4,0.3l-2.3,0.1l-2.3,0.1h-2.4h-2.4l-2.3-0.1l-2.3-0.1l-2.4-0.3
l-4.5-0.5l-2.1-0.4L166,266l-2.1-0.5l-2.3-0.6l-4.3-1.1l-2.1-0.8l-2-0.8l-2.1-0.8l-2-0.8l-2-0.9l-2-1l-1.9-1l-2-1l-1.9-1
l-1.9-1.1l-1.9-1.1l-1.8-1.3l-3.5-2.5l-3.5-2.6l-1.6-1.4l-1.6-1.5l-3.1-3l-1.5-1.5l-1.4-1.6l-1.5-1.5l-1.4-1.8l-1.4-1.6l-1.3-1.8
l-1.4-1.8l-1.1-1.8l-1.3-1.8l-1.1-1.9l-2.3-3.8l-1.9-3.9l-1.9-4l-1.6-4.1l-0.8-2l-0.6-2.1l-0.6-2.1l-0.6-2.1l-0.5-2.3l-0.5-2.1
l-0.4-2.3l-0.4-2.3l-0.6-4.5l-0.1-2.3L94,182l-0.1-2.4V177.4z M88.7,177.4V175l0.1-2.3l0.1-2.4l0.1-2.3l0.6-4.5l0.4-2.3l0.4-2.1
l0.5-2.3l0.5-2.1l1.3-4.3l0.6-2.1l0.8-2.1l0.8-2l0.9-2l0.9-2l1-2l0.9-2l1-1.9l1.1-1.9l1.1-1.9l1.1-1.9l1.1-1.8l2.5-3.6l2.8-3.4
l1.4-1.6l1.4-1.6l3-3.1l1.6-1.5l1.5-1.5l1.6-1.4l1.6-1.4l1.8-1.4l1.8-1.4l1.8-1.3l1.8-1.3l1.8-1.1l1.9-1.1l3.8-2.3l3.9-2l4-1.8
l4.1-1.6l2-0.8l2.1-0.6l2.1-0.6l2.1-0.6l2.1-0.5l2.3-0.5l2.3-0.4l2.1-0.4l4.5-0.6l2.4-0.1l2.3-0.3h2.3l2.4-0.1l2.3,0.1h2.4
l2.3,0.3l2.3,0.1l4.5,0.6l2.3,0.4l2.3,0.4l2.1,0.5l2.3,0.5L206,91l2.1,0.6l2,0.8l2.1,0.8l2,0.9l2,0.9l2,0.9l1.9,1l2,1l1.9,1.1
l1.9,1.1l1.9,1.1l1.8,1.1l3.5,2.5l3.4,2.8l1.8,1.4l1.6,1.4l3.1,3l1.5,1.6l1.4,1.5l1.5,1.6l1.4,1.6l1.4,1.8l1.3,1.6l1.4,1.8
l1.1,1.9l1.3,1.8l1.1,1.9l2.1,3.8l2,3.9l1.9,4l1.6,4l0.6,2.1l0.8,2.1l0.6,2.1l0.6,2.1l0.5,2.1l0.5,2.3l0.4,2.1l0.4,2.3l0.6,4.5
l0.1,2.3l0.1,2.4l0.1,2.3v2.4v2.3l-0.1,2.4l-0.1,2.3l-0.1,2.3l-0.6,4.5l-0.4,2.3l-0.4,2.3l-0.5,2.1l-0.5,2.3l-1.3,4.3l-0.8,2.1
l-0.6,2l-0.9,2.1l-0.8,2l-0.9,2l-1,2l-0.9,1.9l-1.1,2l-1,1.9l-1.1,1.9l-1.1,1.9l-1.3,1.8l-2.5,3.5l-2.6,3.4l-1.4,1.8l-1.5,1.5
l-2.9,3.1l-1.6,1.6l-1.5,1.4l-1.6,1.5l-1.8,1.4l-1.6,1.4l-1.8,1.3l-1.8,1.3l-1.8,1.3l-1.8,1.3l-1.9,1.1l-3.8,2.1l-3.9,2l-4,1.9
l-4.1,1.5l-2,0.8l-2.1,0.8l-2.1,0.6l-2.1,0.5l-2.3,0.6l-2.1,0.5l-2.3,0.4l-2.3,0.4l-4.5,0.5l-2.3,0.3l-2.3,0.1l-2.4,0.1h-2.3
h-2.4l-2.3-0.1l-2.3-0.1l-2.4-0.3l-4.5-0.5l-2.1-0.4l-2.3-0.4l-2.3-0.5l-2.1-0.6l-4.3-1.1l-2.1-0.8l-2-0.8l-2.1-0.8l-2-0.8
l-2-0.9l-2-1l-2-1l-1.9-1l-1.9-1l-1.9-1.1l-1.9-1.1l-1.8-1.3l-3.5-2.5l-3.5-2.6l-1.6-1.4l-1.6-1.5l-3.1-3l-1.5-1.5l-1.5-1.6
l-1.4-1.5l-1.4-1.8l-1.4-1.6l-1.4-1.8l-1.3-1.8l-1.3-1.8l-1.1-1.8l-1.1-1.9l-2.3-3.8l-1.9-3.9l-1.9-4l-1.6-4.1l-0.8-2l-0.6-2.1
l-0.6-2.1l-0.6-2.1l-0.5-2.3l-0.5-2.1l-0.4-2.3l-0.4-2.3l-0.6-4.5l-0.1-2.3l-0.1-2.3l-0.1-2.4V177.4z"
        />
        <path
          d="M93.6,177.4v-4.7l0.4-4.7l0.6-4.7l0.8-4.4l2.3-8.7l1.4-4.3l1.6-4l1.8-4l2-3.9l2.3-3.8l2.3-3.8l5.3-6.9l2.8-3.3l3-3.1
l3.1-3l3.3-2.8l3.4-2.8l3.6-2.5l3.6-2.4l4-2.3l3.9-2l4-1.8l8.3-3l8.7-2.3l4.4-0.8l4.5-0.6l4.7-0.4l4.6-0.1v0.5l-4.6,0.1l-4.7,0.4
l-4.5,0.6L166,89l-8.5,2.3l-8.3,3l-4,1.8l-3.9,2l-3.8,2.1l-3.6,2.4l-3.6,2.5l-3.4,2.8l-3.3,2.8l-3.1,3l-3,3.1l-2.8,3.3l-5.3,6.9
l-2.3,3.8l-2.3,3.8l-2,3.9l-1.8,4l-1.6,3.9l-1.4,4.3l-2.3,8.7l-0.8,4.4l-0.6,4.4l-0.4,4.7v4.7H93.6z"
        />
        <path
          d="M184.3,86.6l4.7,0.1l4.5,0.4l4.5,0.6l4.5,0.8l8.7,2.3l4.1,1.4l4.1,1.6l4,1.8l4,2.1l3.8,2.1l3.6,2.4l6.9,5.3l3.4,2.8
l3.1,3l2.9,3.1l2.9,3.3l2.6,3.4l2.5,3.5l2.4,3.8l2.3,3.9l2,3.9l1.9,4l3,8.3l2.3,8.7l0.8,4.4l0.5,4.5l0.4,4.7l0.1,4.7h-0.5
l-0.1-4.7l-0.4-4.7l-0.5-4.4l-0.8-4.4l-2.3-8.7l-3-8.3l-1.9-4l-2-3.9l-2.1-3.6l-2.4-3.8l-2.5-3.5l-2.6-3.4l-2.9-3.3l-2.9-3.1
l-3.1-3l-3.4-2.8l-6.9-5.3l-3.6-2.4l-3.8-2.1l-3.8-2l-4-1.8l-4.1-1.6l-4.1-1.4l-8.7-2.3l-4.5-0.8l-4.4-0.6l-4.5-0.4l-4.7-0.1
V86.6z"
        />
        <line fill="none" x1="184.3" y1="86.6" x2="184.3" y2="87.1" />
        <path
          d="M274.9,177.4l-0.1,4.7l-0.4,4.5l-0.5,4.5l-0.8,4.5l-2.3,8.7l-1.4,4.1l-1.6,4.1l-1.9,4l-2.1,4l-2.1,3.9l-2.4,3.6l-5.2,6.9
l-2.9,3.3l-2.9,3.1l-3.1,3l-3.4,2.9l-3.4,2.6l-3.5,2.5l-3.6,2.4l-3.8,2.1l-3.9,2l-4,1.8l-8.3,3.1l-8.7,2.1l-4.5,0.9l-4.7,0.5
l-4.5,0.4l-4.7,0.1v-0.4l4.7-0.1l4.5-0.4l4.4-0.5l4.5-0.9l8.7-2.1l8.3-3.1l3.9-1.8l3.9-2l3.8-2.1l3.6-2.4l3.5-2.5l3.4-2.6
l3.4-2.9l3.1-3l2.9-3.1l2.9-3.3l5.2-6.9l2.4-3.6l2.1-3.8l2-3.9l1.9-4l1.6-4.1l1.4-4.1l2.3-8.5l0.8-4.5l0.5-4.5l0.4-4.5l0.1-4.7
H274.9z"
        />
        <line fill="none" x1="274.9" y1="177.4" x2="274.4" y2="177.4" />
        <path
          d="M184.3,268l-4.6-0.1l-4.7-0.4l-4.5-0.5l-4.4-0.9l-8.8-2.1l-4.1-1.5l-4.1-1.6l-4-1.8l-3.9-2l-3.9-2.1l-3.6-2.4l-7-5.2
l-3.1-2.9l-3.1-3l-3-3.1l-2.9-3.3l-2.8-3.4l-2.5-3.5l-2.3-3.6l-2.3-3.9l-2-3.9l-1.8-4l-3-8.3l-2.3-8.8l-0.8-4.5l-0.6-4.5
l-0.4-4.5v-4.7H94v4.7l0.4,4.5l0.6,4.5l0.8,4.5l2.3,8.5l3,8.3l1.8,4l2,3.9l2.3,3.8l2.3,3.6l2.5,3.5l2.8,3.4l2.8,3.3l3,3.1l3.1,3
l3.3,2.9l7,5.2l3.6,2.4l3.8,2.1l3.9,2l4,1.8l4.1,1.6l4.1,1.5l8.5,2.1l4.4,0.9l4.5,0.5l4.7,0.4l4.6,0.1V268z"
        />
        <line fill="none" x1="184.3" y1="268" x2="184.3" y2="267.6" />
        <line fill="none" x1="93.6" y1="177.4" x2="94" y2="177.4" />
        <path
          d="M88.4,177.4l0.1-4.7l0.4-4.7l0.6-4.7l0.8-4.4l2.3-8.7l1.4-4.3l1.6-4l1.8-4l2-3.9l2.3-3.8l2.3-3.8l5.3-6.9l2.8-3.3l3-3.1
l3.1-3l3.3-2.8l3.4-2.8l3.6-2.5l3.6-2.4l4-2.3l3.9-2l4-1.8l8.3-3l8.7-2.3l4.4-0.8l4.5-0.6l4.6-0.4l4.7-0.1v0.5l-4.7,0.1l-4.6,0.4
l-4.5,0.6l-4.4,0.8l-8.5,2.3l-8.3,3l-4,1.8l-3.9,2l-3.8,2.1l-3.6,2.4l-3.6,2.5l-3.4,2.8l-3.3,2.8l-3.1,3l-3,3.1l-2.8,3.3
l-5.3,6.9l-2.3,3.8l-2.3,3.8l-2,3.9l-1.8,4l-1.6,3.9l-1.4,4.3l-2.3,8.7l-0.8,4.4l-0.6,4.4l-0.4,4.7l-0.1,4.7H88.4z"
        />
        <path
          d="M179.1,86.6l4.6,0.1l4.5,0.4l4.5,0.6l4.5,0.8l8.7,2.3l4.1,1.4l4.1,1.6l4,1.8l4,2.1l3.8,2.1l3.6,2.4l6.9,5.3l3.3,2.8
l3.1,3l3,3.1l2.9,3.3l2.6,3.4l2.5,3.5l2.4,3.8l2.3,3.9l2,3.9l1.9,4l3,8.3l2.3,8.7l0.8,4.4l0.5,4.5l0.4,4.7l0.1,4.7h-0.5l-0.1-4.7
l-0.4-4.7l-0.5-4.4l-0.8-4.4l-2.3-8.7l-3-8.3l-1.9-4l-2-3.9l-2.1-3.6l-2.4-3.8l-2.5-3.5l-2.6-3.4l-2.9-3.3l-3-3.1l-3.1-3
l-3.3-2.8l-6.9-5.3l-3.6-2.4l-3.8-2.1l-3.8-2l-4-1.8l-4.1-1.6l-4.1-1.4l-8.7-2.3l-4.5-0.8l-4.4-0.6l-4.5-0.4l-4.6-0.1V86.6z"
        />
        <line fill="none" x1="179.1" y1="86.6" x2="179.1" y2="87.1" />
        <path
          d="M269.7,177.4l-0.1,4.7l-0.4,4.5l-0.5,4.5l-0.8,4.5l-2.3,8.7l-1.5,4.1l-1.5,4.1l-1.9,4l-2.1,4l-2.1,3.9l-2.4,3.6l-5.2,6.9
l-2.9,3.3l-3,3.1l-3.1,3l-3.3,2.9l-3.4,2.6l-3.5,2.5l-3.6,2.4l-3.8,2.1l-3.9,2l-4,1.8l-8.3,3.1l-8.7,2.1l-4.5,0.9l-4.7,0.5
l-4.5,0.4l-4.6,0.1v-0.4l4.6-0.1l4.5-0.4l4.4-0.5l4.5-0.9l8.7-2.1l8.3-3.1l3.9-1.8l3.9-2l3.8-2.1l3.6-2.4l3.5-2.5l3.4-2.6
l3.3-2.9l3.1-3l3-3.1l2.9-3.3l5.2-6.9l2.4-3.6l2.1-3.8l2-3.9l1.9-4l1.5-4.1l1.5-4.1l2.3-8.5l0.8-4.5l0.5-4.5l0.4-4.5l0.1-4.7
H269.7z"
        />
        <line fill="none" x1="269.7" y1="177.4" x2="269.2" y2="177.4" />
        <path
          d="M179.1,268l-4.7-0.1l-4.6-0.4l-4.5-0.5l-4.4-0.9l-8.8-2.1l-4.3-1.5l-4-1.6l-4-1.8l-3.9-2l-3.9-2.1l-3.6-2.4l-7-5.2
l-3.1-2.9l-3.1-3l-3-3.1l-2.9-3.3l-2.8-3.4l-2.5-3.5l-2.3-3.6l-2.3-3.9l-2-3.9l-1.8-4l-3-8.3l-2.3-8.8l-0.8-4.5l-0.6-4.5
l-0.4-4.5l-0.1-4.7h0.4l0.1,4.7l0.4,4.5l0.6,4.5l0.8,4.5l2.3,8.5l3,8.3l1.8,4l2,3.9l2.3,3.8l2.3,3.6l2.5,3.5l2.8,3.4l2.8,3.3
l3,3.1l3.1,3l3.3,2.9l7,5.2l3.6,2.4l3.8,2.1l3.9,2l4,1.8l4,1.6l4.3,1.5l8.5,2.1l4.4,0.9l4.5,0.5l4.6,0.4l4.7,0.1V268z"
        />
        <line fill="none" x1="179.1" y1="268" x2="179.1" y2="267.6" />
        <line fill="none" x1="88.4" y1="177.4" x2="88.7" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.3,177.4v-2.3V173l0.3-2.3l0.1-2.1l0.3-2.1l0.3-2.1l0.8-4.3l0.5-2.1l0.5-2l0.6-2l0.5-2l0.6-2l0.8-2l0.8-2
l0.8-1.9l0.9-1.9l0.9-1.9l0.9-1.9l1-1.9l1-1.8l1.1-1.9l2.1-3.4l2.4-3.4l1.3-1.6l1.4-1.6l1.3-1.5l1.4-1.6l1.4-1.5l1.5-1.4l2.9-2.9
l1.6-1.4l1.5-1.4l1.6-1.3l1.6-1.3l1.6-1.1l1.8-1.3l1.8-1.1l1.8-1.1l3.5-2l1.9-1l1.9-0.9l3.8-1.8l1.9-0.8l2-0.8l2-0.8l2-0.6l2-0.6
l2-0.5l4.1-1l2.1-0.4l2.1-0.4l2.1-0.3l2.1-0.3l2.1-0.3l2.3-0.1h2.1l2.3-0.1l2.3,0.1h2.1l2.3,0.1l2.1,0.3l2.1,0.3l2.1,0.3l4.3,0.8
l2,0.5l2.1,0.5l2,0.5l2,0.6l2,0.6l2,0.8l2,0.8l1.9,0.8l1.9,0.9l1.9,0.9l1.9,0.9l1.9,1l1.8,1l1.8,1l3.5,2.3l3.4,2.4l1.6,1.3
l1.6,1.3l1.5,1.4l1.5,1.4l1.5,1.4l1.5,1.5l2.9,2.9l1.4,1.6l1.3,1.5l1.4,1.6l1.3,1.6l1.1,1.6l1.3,1.8l1.1,1.6l1,1.8l2.1,3.6l1,1.9
l0.9,1.9l1.8,3.8l0.8,1.9l0.8,2l0.6,2l0.8,2l0.5,2l0.6,2l0.9,4.1l0.5,2.1l0.3,2.1l0.4,2.1l0.3,2.1l0.1,2.1l0.1,2.3l0.1,2.1v2.3
v2.1l-0.1,2.3l-0.1,2.1l-0.1,2.3l-0.3,2.1l-0.4,2.1l-0.8,4.3l-0.4,2l-0.5,2.1l-0.6,2l-0.5,2l-0.8,2l-0.6,2l-0.8,1.9l-0.8,2
l-0.9,1.9l-0.9,1.9l-0.9,1.9l-1,1.9l-1,1.8l-1.1,1.8l-2.1,3.5l-2.4,3.4l-1.3,1.6l-1.4,1.5l-1.3,1.6l-1.4,1.5l-1.4,1.5l-1.5,1.5
l-3,2.9l-1.5,1.4l-1.5,1.3l-1.6,1.3l-1.6,1.3l-1.6,1.3l-1.8,1.1l-1.8,1.3l-1.8,1l-3.5,2.1l-1.9,0.9l-1.9,1l-3.8,1.6l-1.9,0.9
l-2,0.8l-2,0.6l-2,0.6l-2,0.6l-2,0.6l-4.1,0.9l-2.1,0.4l-2.1,0.4l-2.1,0.4l-2.1,0.3l-2.1,0.1l-2.3,0.1l-2.1,0.1h-2.3h-2.3
l-2.1-0.1l-2.3-0.1l-2.1-0.1l-2.1-0.3l-2.1-0.4l-4.3-0.8l-2-0.4l-2.1-0.5l-2-0.6l-2-0.6l-2-0.6l-2-0.6l-2-0.8l-1.9-0.9l-1.9-0.8
l-1.9-0.9l-1.9-1l-1.9-0.9l-1.8-1l-1.8-1.1l-3.5-2.3l-3.4-2.4l-1.6-1.3l-1.6-1.3l-1.5-1.3l-1.6-1.4l-1.5-1.5l-1.4-1.4l-2.9-3
l-1.4-1.5l-1.3-1.6l-1.4-1.5l-1.3-1.6l-1.1-1.8l-1.3-1.6l-1.1-1.8l-1-1.8l-2.1-3.5l-1-1.9l-0.9-1.9l-1.8-3.8l-0.8-2l-0.8-1.9
l-0.8-2l-0.6-2l-0.5-2l-0.6-2l-1-4.1l-0.4-2.1l-0.4-2.1l-0.3-2.1l-0.3-2.1l-0.1-2.3l-0.3-2.1v-2.3V177.4z M93.4,177.4v-2.3V173
l0.3-2.3l0.1-2.1l0.3-2.1l0.3-2.1L95,160l0.5-2.1l0.5-2l0.6-2l0.5-2l0.6-2l0.8-2l0.8-2l0.8-1.9l0.9-1.9l0.9-1.9l0.9-1.9l1-1.9
l1-1.8l1.1-1.9l2.1-3.4l2.4-3.4l1.3-1.6l1.4-1.6l1.3-1.5l1.4-1.6l1.4-1.5l1.5-1.4l2.9-2.9l1.6-1.4l1.5-1.4l1.6-1.3l1.6-1.3
l1.6-1.1l1.8-1.3l1.8-1.1l1.8-1.1l3.5-2l1.9-1l1.9-0.9l3.8-1.8l1.9-0.8l2-0.8l2-0.8l2-0.6l2-0.6l2-0.5l4.1-1l2.1-0.4l2.1-0.4
l2.1-0.3l2.1-0.3l2.1-0.3l2.3-0.1h2.1l2.3-0.1l2.3,0.1h2.1l2.3,0.1l2.1,0.3l2.1,0.3l2.1,0.3l4.3,0.8l2,0.5l2.1,0.5l2,0.5l2,0.6
l2,0.6l2,0.8l2,0.8l1.9,0.8l1.9,0.9l1.9,0.9l1.9,0.9l1.9,1l1.8,1l1.8,1l3.5,2.3l3.4,2.4l1.6,1.3l1.6,1.3l1.5,1.4l1.5,1.4l1.5,1.4
l1.5,1.5l2.9,2.9l1.4,1.6l1.3,1.5l1.4,1.6l1.3,1.6l1.1,1.6l1.3,1.8l1.1,1.6l1,1.8l2.1,3.6l1,1.9l0.9,1.9l1.8,3.8l0.8,1.9l0.8,2
l0.6,2l0.8,2l0.5,2l0.6,2l0.9,4.1l0.5,2.1l0.3,2.1l0.4,2.1l0.3,2.1l0.1,2.1l0.1,2.3l0.1,2.1v2.3v2.1l-0.1,2.3l-0.1,2.1l-0.1,2.3
l-0.3,2.1l-0.4,2.1l-0.8,4.3l-0.4,2l-0.5,2.1l-0.6,2l-0.5,2l-0.8,2l-0.6,2l-0.8,1.9l-0.8,2l-0.9,1.9l-0.9,1.9l-0.9,1.9l-1,1.9
l-1,1.8l-1.1,1.8l-2.1,3.5l-2.4,3.4l-1.3,1.6l-1.4,1.5l-1.3,1.6l-1.4,1.5l-1.4,1.5l-1.5,1.5l-3,2.9l-1.5,1.4l-1.5,1.3l-1.6,1.3
l-1.6,1.3l-1.6,1.3l-1.8,1.1l-1.8,1.3l-1.8,1l-3.5,2.1l-1.9,0.9l-1.9,1l-3.8,1.6l-1.9,0.9l-2,0.8l-2,0.6l-2,0.6l-2,0.6l-2,0.6
l-4.1,0.9l-2.1,0.4l-2.1,0.4l-2.1,0.4l-2.1,0.3l-2.1,0.1l-2.3,0.1l-2.1,0.1h-2.3H177l-2.1-0.1l-2.3-0.1l-2.1-0.1l-2.1-0.3
l-2.1-0.4l-4.3-0.8l-2-0.4l-2.1-0.5l-2-0.6l-2-0.6l-2-0.6l-2-0.6l-2-0.8l-1.9-0.9l-1.9-0.8l-1.9-0.9l-1.9-1l-1.9-0.9l-1.8-1
l-1.8-1.1l-3.5-2.3l-3.4-2.4l-1.6-1.3l-1.6-1.3l-1.5-1.3l-1.6-1.4l-1.5-1.5l-1.4-1.4l-2.9-3l-1.4-1.5l-1.3-1.6l-1.4-1.5l-1.3-1.6
l-1.1-1.8l-1.3-1.6l-1.1-1.8l-1-1.8l-2.1-3.5l-1-1.9l-0.9-1.9l-1.8-3.8l-0.8-2l-0.8-1.9l-0.8-2l-0.6-2l-0.5-2l-0.6-2l-1-4.1
l-0.4-2.1l-0.4-2.1l-0.3-2.1l-0.3-2.1l-0.1-2.3l-0.3-2.1v-2.3V177.4z"
        />
        <path
          d="M97.9,177.4L98,173l0.4-4.4l0.5-4.4l0.8-4.3l1-4.1l1.1-4.1l1.4-3.9l1.5-3.9l1.8-3.9l1.9-3.6l4.3-7l2.4-3.4l2.6-3.3
l5.5-6.2l2.9-2.8l1.6-1.4l1.5-1.4l3.3-2.5l3.4-2.4l3.4-2.3l3.9-2.1l3.8-1.9l3.8-1.8l3.9-1.5l4-1.4l4-1.1l4.1-1l4.3-0.8l4.3-0.5
l4.4-0.4l4.4-0.1v0.5l-4.4,0.1l-4.4,0.4l-4.3,0.5l-4.3,0.8l-4.1,1l-3.9,1.1l-4,1.4l-3.9,1.5l-3.8,1.8l-3.8,1.9l-3.6,2l-3.4,2.3
l-3.4,2.4l-3.3,2.5l-1.5,1.4l-1.6,1.4l-2.9,2.8l-5.5,6.2l-2.6,3.3l-2.4,3.4l-4.3,7l-1.9,3.6l-1.8,3.9l-1.5,3.8l-1.4,3.9l-1.1,4.1
l-1,4.1l-0.8,4.3l-0.5,4.1l-0.4,4.4l-0.1,4.4H97.9z"
        />
        <path
          d="M184.1,91.1l4.4,0.1l4.4,0.4l4.3,0.5l4.3,0.8l4.1,1l4,1.1l4,1.4l3.9,1.5l3.8,1.8l3.9,2l7,4.3l3.4,2.4l3.3,2.5l6,5.5
l2.9,3l1.4,1.5l1.3,1.6l2.5,3.3l2.4,3.4l2.3,3.4l2.3,3.8l1.9,3.6l1.6,3.9l1.6,3.9l1.3,3.9l1.3,4.1l0.9,4.1l0.8,4.3l0.6,4.3
l0.3,4.4l0.1,4.4h-0.5l-0.1-4.4l-0.3-4.4l-0.6-4.1l-0.8-4.3l-0.9-4.1l-1.3-4.1l-1.3-3.9l-1.6-3.9l-1.6-3.9l-1.9-3.6l-2.1-3.5
l-2.3-3.4l-2.4-3.4l-2.5-3.3l-1.3-1.6l-1.4-1.5l-2.9-3l-6-5.5l-3.3-2.5l-3.4-2.4l-7-4.3l-3.6-1.9l-3.8-1.8l-3.9-1.5l-4-1.4
l-4-1.1l-4.1-1l-4.3-0.8l-4.1-0.5l-4.4-0.4l-4.4-0.1V91.1z"
        />
        <line fill="none" x1="184.1" y1="91.1" x2="184.1" y2="91.6" />
        <path
          d="M270.2,177.4l-0.1,4.4l-0.3,4.4l-0.6,4.3l-0.8,4.1l-0.9,4.1l-1.3,4.1l-1.3,4l-1.6,3.9l-1.6,3.8l-2,3.8l-4.4,7.3L253,229
l-2.5,3.1l-5.5,6.2l-3,2.9l-1.5,1.3l-1.5,1.4l-3.3,2.5l-3.4,2.4l-3.5,2.3l-3.5,2.1l-3.8,1.9l-3.8,1.6l-3.9,1.6l-4,1.3l-4,1.3
l-4.1,0.9l-4.3,0.8l-4.4,0.6l-4.4,0.3l-4.4,0.1v-0.4l4.4-0.1l4.4-0.3l4.1-0.6l4.3-0.8l4.1-0.9l4-1.3l4-1.3l3.8-1.6l3.8-1.6
l3.8-1.9l3.5-2.1l3.5-2.3l3.4-2.4l3.3-2.5l1.5-1.4l1.5-1.3l3-2.9l5.5-6.2l2.5-3.1l2.4-3.4l4.4-7.2l1.9-3.6l1.6-3.8l1.6-3.9l1.3-4
l1.3-4l0.9-4.1l0.8-4.1l0.6-4.3l0.3-4.4l0.1-4.4H270.2z"
        />
        <line fill="none" x1="270.2" y1="177.4" x2="269.7" y2="177.4" />
        <path
          d="M184.1,263.5l-4.4-0.1l-4.4-0.3l-4.3-0.6l-4.3-0.8l-4.1-0.9l-4.1-1.3l-4-1.3l-3.9-1.6l-3.8-1.6l-3.8-1.9l-7.2-4.4
l-3.4-2.4l-3.3-2.5l-5.9-5.5l-2.9-3l-1.5-1.5l-1.3-1.6L115,229l-2.4-3.4l-2.3-3.5l-2-3.8l-1.9-3.6l-1.8-3.8l-1.5-3.9l-1.4-4
l-1.1-4.3l-1-4.1l-0.8-4.1l-0.5-4.3l-0.4-4.4l-0.1-4.4h0.4l0.1,4.4l0.4,4.4l0.5,4.3l0.8,4.1l1,4.1l1.1,4l1.4,4l1.5,3.9l1.8,3.8
l1.9,3.6l2,3.6l2.3,3.5l2.4,3.4l2.6,3.1l1.3,1.6l1.4,1.5l2.9,3l6,5.5l3.3,2.5l3.4,2.4l7,4.4l3.8,1.9l3.8,1.6l3.9,1.6l4,1.3
l3.9,1.3l4.1,0.9l4.3,0.8l4.3,0.6l4.4,0.3l4.4,0.1V263.5z"
        />
        <line fill="none" x1="184.1" y1="263.5" x2="184.1" y2="263.1" />
        <line fill="none" x1="97.9" y1="177.4" x2="98.3" y2="177.4" />
        <path
          d="M93,177.4l0.1-4.4l0.4-4.4l0.5-4.4l0.8-4.3l1-4.1l1.1-4.1l1.4-3.9l1.5-3.9l1.8-3.9l1.9-3.6l4.3-7l2.4-3.4l2.6-3.3
l5.5-6.2l2.9-2.8l1.6-1.4l1.5-1.4l3.3-2.5l3.4-2.4l3.4-2.3l3.9-2.1l3.8-1.9l3.8-1.8l3.9-1.5l4-1.4l4-1.1l4.1-1l4.3-0.8l4.3-0.5
l4.4-0.4l4.4-0.1v0.5l-4.4,0.1l-4.4,0.4l-4.3,0.5l-4.3,0.8l-4.1,1l-3.9,1.1l-4,1.4l-3.9,1.5l-3.8,1.8l-3.8,1.9l-3.6,2l-3.4,2.3
l-3.4,2.4l-3.3,2.5l-1.5,1.4l-1.6,1.4l-2.9,2.8l-5.5,6.2l-2.6,3.3l-2.4,3.4l-4.3,7l-1.9,3.6l-1.8,3.9l-1.5,3.8l-1.4,3.9l-1.1,4.1
l-1,4.1l-0.8,4.3l-0.5,4.1l-0.4,4.4l-0.1,4.4H93z"
        />
        <path
          d="M179.2,91.1l4.4,0.1l4.4,0.4l4.3,0.5l4.3,0.8l4.1,1l4,1.1l4,1.4l3.9,1.5l3.8,1.8l3.9,2l7,4.3l3.4,2.4l3.1,2.5l6.2,5.5
l2.9,3l1.4,1.5l1.3,1.6l2.5,3.3l2.4,3.4l2.3,3.4l2.3,3.8l1.9,3.6l1.6,3.9l1.6,3.9l1.3,3.9l1.3,4.1l0.9,4.1l0.8,4.3l0.6,4.3
l0.3,4.4l0.1,4.4h-0.5l-0.1-4.4l-0.3-4.4l-0.6-4.1l-0.8-4.3l-0.9-4.1l-1.3-4.1l-1.3-3.9l-1.6-3.9l-1.6-3.9l-1.9-3.6l-2.1-3.5
l-2.3-3.4l-2.4-3.4l-2.5-3.3l-1.3-1.6l-1.4-1.5l-2.9-3l-6.2-5.5l-3.1-2.5l-3.4-2.4l-7-4.3l-3.6-1.9l-3.8-1.8l-3.9-1.5l-4-1.4
l-4-1.1l-4.1-1l-4.3-0.8l-4.1-0.5l-4.4-0.4l-4.4-0.1V91.1z"
        />
        <line fill="none" x1="179.2" y1="91.1" x2="179.2" y2="91.6" />
        <path
          d="M265.3,177.4l-0.1,4.4l-0.3,4.4l-0.6,4.3l-0.8,4.1l-0.9,4.1l-1.3,4.1l-1.3,4l-1.6,3.9l-1.6,3.8l-2,3.8l-4.4,7.3l-2.4,3.4
l-2.5,3.1l-5.5,6.2l-3,2.9l-1.5,1.3l-1.6,1.4l-3.1,2.5l-3.4,2.4l-3.5,2.3l-3.5,2.1l-3.8,1.9l-3.8,1.6l-3.9,1.6l-4,1.3l-4,1.3
l-4.1,0.9l-4.3,0.8l-4.4,0.6l-4.4,0.3l-4.4,0.1v-0.4l4.4-0.1l4.4-0.3l4.1-0.6l4.3-0.8l4.1-0.9l4-1.3l4-1.3l3.8-1.6l3.8-1.6
l3.8-1.9l3.5-2.1l3.5-2.3l3.4-2.4l3.1-2.5l1.6-1.4l1.5-1.3l3-2.9l5.5-6.2l2.5-3.1l2.4-3.4l4.4-7.2l1.9-3.6l1.6-3.8l1.6-3.9l1.3-4
l1.3-4l0.9-4.1l0.8-4.1l0.6-4.3l0.3-4.4l0.1-4.4H265.3z"
        />
        <line fill="none" x1="265.3" y1="177.4" x2="264.8" y2="177.4" />
        <path
          d="M179.2,263.5l-4.4-0.1l-4.4-0.3l-4.3-0.6l-4.3-0.8l-4.1-0.9l-4.1-1.3l-4-1.3l-3.9-1.6l-3.8-1.6l-3.8-1.9l-7.2-4.4
l-3.4-2.4l-3.3-2.5l-5.9-5.5l-2.9-3l-1.5-1.5l-1.3-1.6l-2.6-3.1l-2.4-3.4l-2.3-3.5l-2-3.8l-1.9-3.6l-1.8-3.8l-1.5-3.9l-1.4-4
l-1.1-4.3l-1-4.1l-0.8-4.1l-0.5-4.3l-0.4-4.4l-0.1-4.4h0.4l0.1,4.4l0.4,4.4l0.5,4.3l0.8,4.1l1,4.1l1.1,4l1.4,4l1.5,3.9l1.8,3.8
l1.9,3.6l2,3.6l2.3,3.5l2.4,3.4l2.6,3.1l1.3,1.6l1.4,1.5l2.9,3l6,5.5l3.3,2.5l3.4,2.4l7,4.4l3.8,1.9l3.8,1.6l3.9,1.6l4,1.3
l3.9,1.3l4.1,0.9l4.3,0.8l4.3,0.6l4.4,0.3l4.4,0.1V263.5z"
        />
        <line fill="none" x1="179.2" y1="263.5" x2="179.2" y2="263.1" />
        <line fill="none" x1="93" y1="177.4" x2="93.4" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.4,177.4l0.1-4.3l0.1-2l0.1-2.1l0.3-2l0.3-2l0.4-2l0.4-2l0.4-2l0.5-1.9l0.5-2l0.6-1.9l0.6-1.9l0.6-1.9
l1.5-3.8l0.8-1.8l0.9-1.9l0.9-1.8l0.9-1.8l1-1.6l1-1.8l1-1.6l1.1-1.6l1.1-1.6l1.1-1.6l2.4-3l1.3-1.5l1.3-1.5l1.4-1.4l1.4-1.4
l1.4-1.4l1.4-1.4l3-2.5l3.1-2.4l1.5-1.3l1.6-1.1l3.3-2l1.8-1l1.8-1l1.6-0.9l1.9-0.9l1.8-0.9l1.8-0.8l1.9-0.8l1.9-0.8l1.9-0.6
l1.9-0.6l1.9-0.6l2-0.5l1.9-0.5l2-0.4l2-0.4l2-0.4l2-0.3l2.1-0.3l2-0.1l2.1-0.3h4.1h4.1l2.1,0.3l2,0.1l2.1,0.3l2,0.3l2,0.4l2,0.4
l2,0.4l1.9,0.5l2,0.5l1.9,0.6l1.9,0.6l1.9,0.6l3.8,1.5l1.8,0.8l1.8,0.9l1.9,0.9l1.6,0.9l1.8,1l1.8,1l1.6,1l1.6,1l1.6,1.1l1.5,1.3
l3.1,2.4l1.5,1.3l1.4,1.3l1.5,1.4l1.4,1.4l1.4,1.4l1.4,1.4l2.5,3l2.4,3l1.1,1.6l1.1,1.6l2.1,3.3l1,1.8l1,1.6l0.9,1.8l0.9,1.8
l0.9,1.9l0.8,1.8l0.8,1.9l0.8,1.9l0.6,1.9l0.6,1.9l0.6,1.9l0.5,2l0.5,1.9l0.4,2l0.4,2l0.4,2l0.3,2l0.3,2l0.1,2.1l0.1,2l0.1,2.1
v2.1l-0.1,4.1l-0.1,2.1l-0.1,2l-0.3,2l-0.3,2.1l-0.4,2l-0.4,2l-0.4,1.9l-0.5,2l-0.5,2l-0.6,1.9l-0.6,1.9l-0.6,1.9l-1.5,3.6
l-0.8,1.9l-0.9,1.8l-0.9,1.8l-0.9,1.8l-1,1.8l-1,1.6l-1,1.8l-1.1,1.6l-1.1,1.6l-1.1,1.5l-2.4,3.1l-1.3,1.5l-1.3,1.4l-1.4,1.5
l-1.4,1.4l-1.4,1.4l-1.5,1.3l-2.9,2.6l-3.1,2.4l-1.5,1.1l-1.6,1.1l-3.3,2.1l-1.8,1l-1.8,1l-1.6,0.9l-1.9,0.9l-1.8,0.9l-1.8,0.8
l-1.9,0.8l-1.9,0.8l-1.9,0.6l-1.9,0.6l-1.9,0.5l-2,0.6l-1.9,0.4l-2,0.5l-2,0.4l-2,0.4l-2,0.3l-2.1,0.3l-2,0.1l-2.1,0.1L184,259
l-4.1-0.1l-2.1-0.1l-2-0.1l-2.1-0.3l-2-0.3l-2-0.4l-2-0.4l-2-0.5l-1.9-0.4l-2-0.6l-1.9-0.5l-1.9-0.6l-1.9-0.6l-3.8-1.5l-1.8-0.8
l-1.8-0.9l-1.9-0.9l-1.6-0.9l-1.8-1l-1.8-1l-1.6-1l-1.6-1.1l-1.6-1.1l-1.5-1.1l-3.1-2.4l-1.5-1.3l-1.5-1.4l-1.4-1.3l-1.4-1.4
l-1.4-1.4l-1.4-1.5l-2.5-2.9l-2.4-3.1l-1.1-1.5l-1.1-1.6l-2.1-3.4l-1-1.6l-1-1.8l-0.9-1.8l-0.9-1.8l-0.9-1.8l-0.8-1.9l-0.8-1.8
l-0.8-1.9l-0.6-1.9l-0.6-1.9l-0.6-1.9l-0.5-2l-0.5-2l-0.4-1.9l-0.4-2l-0.4-2l-0.3-2.1l-0.3-2l-0.1-2l-0.1-2.1l-0.1-2V177.4z
 M97.8,177.4l0.1-4.3l0.1-2l0.1-2.1l0.3-2l0.3-2l0.4-2l0.4-2l0.4-2l0.5-1.9l0.5-2l0.6-1.9l0.6-1.9l0.6-1.9l1.5-3.8l0.8-1.8
l0.9-1.9l0.9-1.8l0.9-1.8l1-1.6l1-1.8l1-1.6l1.1-1.6l1-1.6l1.3-1.6l2.4-3l1.3-1.5l1.3-1.5l1.4-1.4l1.4-1.4l1.4-1.4l1.4-1.4l3-2.5
l3-2.4l1.6-1.3l1.6-1.1l3.3-2l1.8-1l1.6-1l1.8-0.9l1.8-0.9l1.9-0.9l1.8-0.8l1.9-0.8l1.9-0.8l1.9-0.6l1.9-0.6l1.9-0.6l2-0.5
l1.9-0.5l2-0.4l2-0.4l2-0.4l2-0.3l2.1-0.3l2-0.1l2.1-0.3h4.1h4.1l2.1,0.3l2,0.1l2.1,0.3l2,0.3l2,0.4l2,0.4l2,0.4l1.9,0.5l2,0.5
l1.9,0.6l1.9,0.6l1.9,0.6l3.8,1.5l1.8,0.8l1.8,0.9l1.8,0.9l1.8,0.9l1.8,1l1.8,1l1.6,1l1.6,1l1.6,1.1l1.5,1.3l3.1,2.4l1.5,1.3
l1.4,1.3l1.5,1.4l1.4,1.4l1.4,1.4l1.3,1.4l2.6,3l2.4,3l1.1,1.6l1.1,1.6l2.1,3.3l1,1.8l1,1.6l0.9,1.8l0.9,1.8l0.9,1.9l0.8,1.8
l0.8,1.9l0.8,1.9l0.6,1.9l0.6,1.9l0.5,1.9l0.6,2l0.4,1.9l0.5,2l0.4,2l0.4,2l0.3,2l0.3,2l0.1,2.1l0.1,2l0.1,2.1v2.1l-0.1,4.1
l-0.1,2.1l-0.1,2l-0.3,2l-0.3,2.1l-0.4,2l-0.4,2l-0.5,1.9l-0.4,2l-0.6,2l-0.5,1.9l-0.6,1.9l-0.6,1.9l-1.5,3.6l-0.8,1.9l-0.9,1.8
l-0.9,1.8l-0.9,1.8l-1,1.8l-1,1.6l-1,1.8L247,223l-1.1,1.6l-1.1,1.5l-2.4,3.1l-1.3,1.5l-1.4,1.4l-1.3,1.5l-1.4,1.4l-1.4,1.4
l-1.5,1.3l-2.9,2.6l-3.1,2.4l-1.5,1.1L225,245l-3.3,2.1l-1.8,1l-1.8,1l-1.8,0.9l-1.8,0.9l-1.8,0.9l-1.8,0.8l-1.9,0.8l-1.9,0.8
l-1.9,0.6l-1.9,0.6l-1.9,0.5l-2,0.6l-1.9,0.4l-2,0.5l-2,0.4l-2,0.4l-2,0.3l-2.1,0.3l-2,0.1l-2.1,0.1l-4.1,0.1l-4.1-0.1l-2.1-0.1
l-2-0.1l-2.1-0.3l-2-0.3l-2-0.4l-2-0.4l-2-0.5l-1.9-0.4l-2-0.6l-1.9-0.5l-1.9-0.6l-1.9-0.6l-3.8-1.5l-1.8-0.8l-1.9-0.9l-1.8-0.9
l-1.8-0.9l-1.6-1l-1.8-1l-1.6-1l-1.6-1.1l-1.6-1.1l-1.6-1.1l-3-2.4l-1.5-1.3l-1.5-1.4l-1.4-1.3l-1.4-1.4l-1.4-1.4l-1.4-1.5
l-2.5-2.9l-2.4-3.1l-1.3-1.5l-1-1.6l-2.1-3.4l-1-1.6l-1-1.8l-0.9-1.8l-0.9-1.8l-0.9-1.8l-0.8-1.9l-0.8-1.8l-0.8-1.9l-0.6-1.9
l-0.6-1.9l-0.6-1.9l-0.5-2l-0.5-2l-0.4-1.9l-0.4-2l-0.4-2l-0.3-2.1l-0.3-2l-0.1-2l-0.1-2.1l-0.1-2V177.4z"
        />
        <path
          d="M102.2,177.4l0.1-4.3l0.3-4.1l0.5-4.1l0.8-4l0.9-4l1.1-3.8l1.3-3.9l1.5-3.6l3.4-7.2l4.1-6.7l4.7-6.3l2.5-3l2.8-2.8
l2.8-2.8l3-2.5l3-2.5l3.3-2.3l3.3-2.1l3.6-2l3.5-1.8l3.6-1.6l3.8-1.5l3.8-1.3l3.9-1.1l3.9-0.9l4-0.8l4-0.5l8.4-0.5v0.5l-8.4,0.5
l-4,0.5l-4,0.8l-3.9,0.9l-3.8,1.1l-3.8,1.3l-3.8,1.5l-3.6,1.6l-3.5,1.8l-3.4,1.9l-3.3,2.1l-3.3,2.3l-3,2.5l-3,2.5l-2.8,2.8
l-2.8,2.8l-2.5,3l-4.7,6.3l-4.1,6.7l-3.4,7.2l-1.5,3.5l-1.3,3.9l-1.1,3.8l-0.9,4l-0.8,4l-0.5,3.9l-0.3,4.1l-0.1,4.3H102.2z"
        />
        <path
          d="M184,95.4l4.1,0.1l4.1,0.4l4.1,0.5l4,0.8l3.9,0.9l3.9,1.1l3.8,1.3l3.8,1.5l7,3.4l6.9,4.1l6.3,4.8l2.9,2.5l2.9,2.8
l2.6,2.8l2.6,3l2.4,3l2.3,3.3l2.1,3.3l2.1,3.5l1.8,3.5l1.6,3.6l1.5,3.6l1.3,3.9l1.1,3.8l0.9,4l0.8,4l0.5,4l0.4,8.4h-0.5L265,169
l-0.5-3.9l-0.8-4l-0.9-4l-1.1-3.8l-1.3-3.9l-1.5-3.6l-1.6-3.6l-1.8-3.5l-2-3.3l-2.1-3.3l-2.3-3.3l-2.4-3l-2.6-3l-2.6-2.8
l-2.9-2.8l-2.9-2.5l-6.3-4.8l-6.7-4l-7-3.4l-3.8-1.5l-3.8-1.3l-3.9-1.1l-3.9-0.9l-4-0.8l-4-0.5l-4.1-0.4l-4.1-0.1V95.4z"
        />
        <line fill="none" x1="184" y1="95.4" x2="184" y2="95.9" />
        <path
          d="M265.8,177.4l-0.1,4.1l-0.3,4.1l-0.5,4.1l-0.8,4l-0.9,3.9l-1.1,3.9l-1.3,3.8l-1.5,3.6l-3.4,7.2l-4.3,7l-4.7,6.3l-2.6,2.9
l-2.6,2.9l-2.9,2.6l-2.9,2.6l-3.1,2.4l-3.1,2.3l-3.4,2.1l-3.4,2l-3.5,1.8l-3.5,1.6l-3.8,1.4l-3.8,1.4l-3.9,1l-3.9,1l-4,0.6
l-4.3,0.6l-8.3,0.4v-0.4l8.3-0.4l4-0.6l4-0.6l3.9-1l3.9-1l3.8-1.4l3.6-1.4l3.5-1.6l3.5-1.8l3.4-2l3.4-2.1l3.1-2.3l3.1-2.4
l2.9-2.6l2.9-2.6l2.6-2.9l2.6-2.9l4.7-6.3l4.1-6.8l3.4-7.2l1.5-3.6l1.3-3.8l1.1-3.8l0.9-3.9l0.8-4l0.5-4.1l0.3-4.1l0.1-4.1H265.8
z"
        />
        <line fill="none" x1="265.8" y1="177.4" x2="265.3" y2="177.4" />
        <path
          d="M184,259.2l-4.3-0.1l-4.1-0.3l-4-0.6l-4-0.6l-3.9-1l-4-1l-3.8-1.4l-3.8-1.4l-7.2-3.4l-6.8-4.1l-6.3-4.7L129,238l-2.8-2.6
l-2.8-2.9l-2.6-2.9l-2.4-3.1l-2.3-3.1l-2.1-3.4l-2-3.5l-1.8-3.5l-1.6-3.6l-1.5-3.6l-1.3-3.8l-1.1-4l-0.9-3.9l-0.8-4l-0.5-4.1
l-0.4-8.3h0.4l0.4,8.3l0.5,4.1l0.8,4l0.9,3.9l1.1,3.8l1.3,3.8l1.5,3.6l1.6,3.6l1.8,3.5l2,3.4l2.1,3.4l2.3,3.1l2.4,3.1l2.5,2.9
l2.8,2.9l2.8,2.6l3,2.6l6.3,4.7l6.7,4.1l7.2,3.4l3.8,1.4l3.8,1.4l3.8,1l3.9,1l4,0.6l4,0.6l4.1,0.3l4.3,0.1V259.2z"
        />
        <line fill="none" x1="184" y1="259.2" x2="184" y2="258.8" />
        <line fill="none" x1="102.2" y1="177.4" x2="102.6" y2="177.4" />
        <path
          d="M97.5,177.4v-4.3l0.4-4.1l0.5-4.1l0.8-4l0.9-4l1.1-3.8l1.3-3.9l1.5-3.6l3.4-7.2l4-6.7l4.8-6.3l2.5-3l2.8-2.8l2.8-2.8
l3-2.5l3-2.5l3.3-2.3l3.3-2.1l3.6-2l3.5-1.8l3.6-1.6l3.8-1.5l3.8-1.3l3.9-1.1l3.9-0.9l4-0.8l4-0.5l8.4-0.5v0.5l-8.4,0.5l-4,0.5
l-4,0.8l-3.9,0.9l-3.8,1.1l-3.8,1.3l-3.8,1.5l-3.6,1.6l-3.5,1.8l-3.4,1.9l-3.3,2.1l-3.3,2.3l-3,2.5l-3,2.5l-2.8,2.8l-2.8,2.8
l-2.5,3l-4.8,6.3l-4,6.7l-3.4,7.2l-1.5,3.5l-1.3,3.9l-1.1,3.8l-0.9,4l-0.8,4l-0.5,3.9l-0.4,4.1v4.3H97.5z"
        />
        <path
          d="M179.4,95.4l4.1,0.1l4.1,0.4l4.1,0.5l4,0.8l3.9,0.9l3.9,1.1l3.8,1.3l3.6,1.5l7.2,3.4l6.9,4.1l6.3,4.8l2.9,2.5l2.9,2.8
l2.6,2.8l2.6,3l2.4,3l2.3,3.3l2.1,3.3l2.1,3.5l1.8,3.5l1.6,3.6l1.5,3.6l1.3,3.9l1.1,3.8l0.9,4l0.8,4l0.5,4l0.4,8.4h-0.5l-0.4-8.4
l-0.5-3.9l-0.8-4l-0.9-4l-1.1-3.8l-1.3-3.9l-1.5-3.6l-1.6-3.6l-1.8-3.5l-2-3.3l-2.1-3.3l-2.3-3.3l-2.4-3l-2.6-3l-2.6-2.8
l-2.9-2.8l-2.9-2.5l-6.3-4.8l-6.7-4l-7.2-3.4l-3.6-1.5l-3.8-1.3l-3.9-1.1l-3.9-0.9l-4-0.8l-4-0.5l-4.1-0.4l-4.1-0.1V95.4z"
        />
        <line fill="none" x1="179.4" y1="95.4" x2="179.4" y2="95.9" />
        <path
          d="M261.2,177.4l-0.1,4.1l-0.3,4.1l-0.5,4.1l-0.8,4l-0.9,3.9l-1.1,3.9l-1.3,3.8l-1.5,3.6l-3.4,7.2l-4.3,7l-4.6,6.3l-2.6,2.9
l-2.6,2.9l-2.9,2.6l-2.9,2.6l-3.1,2.4l-3.1,2.3l-3.4,2.1l-3.4,2l-3.5,1.8l-3.6,1.6l-3.6,1.4l-3.8,1.4l-3.9,1l-3.9,1l-4,0.6
l-4.3,0.6l-8.3,0.4v-0.4l8.3-0.4l4-0.6l4-0.6l3.9-1l3.9-1l3.8-1.4l3.5-1.4l3.6-1.6l3.5-1.8l3.4-2l3.4-2.1l3.1-2.3l3.1-2.4
l2.9-2.6l2.9-2.6l2.6-2.9l2.6-2.9l4.6-6.3l4.1-6.8l3.4-7.2l1.5-3.6l1.3-3.8l1.1-3.8l0.9-3.9l0.8-4l0.5-4.1l0.3-4.1l0.1-4.1H261.2
z"
        />
        <line fill="none" x1="261.2" y1="177.4" x2="260.7" y2="177.4" />
        <path
          d="M179.4,259.2l-4.3-0.1l-4.1-0.3l-4-0.6l-4-0.6l-3.9-1l-4-1l-3.8-1.4l-3.8-1.4l-7.2-3.4l-6.8-4.1l-6.3-4.7l-2.9-2.6
l-2.8-2.6l-2.8-2.9l-2.6-2.9l-2.4-3.1l-2.4-3.1l-2-3.4l-2-3.5l-1.8-3.5l-1.6-3.6l-1.5-3.6l-1.3-3.8l-1.1-4l-0.9-3.9l-0.8-4
l-0.5-4.1l-0.4-8.3h0.4l0.4,8.3l0.5,4.1l0.8,4l0.9,3.9l1.1,3.8l1.3,3.8l1.5,3.6l1.6,3.6l1.8,3.5l2,3.4l2,3.4l2.4,3.1l2.4,3.1
l2.5,2.9l2.8,2.9l2.8,2.6l3,2.6l6.3,4.7l6.7,4.1l7.2,3.4l3.8,1.4l3.8,1.4l3.8,1l3.9,1l4,0.6l4,0.6l4.1,0.3l4.3,0.1V259.2z"
        />
        <line fill="none" x1="179.4" y1="259.2" x2="179.4" y2="258.8" />
        <line fill="none" x1="97.5" y1="177.4" x2="97.9" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.3,177.4l0.1-2v-2l0.1-2l0.3-1.9l0.1-2l0.3-1.9l0.4-1.9l0.4-1.9l0.4-1.9l0.5-1.9l1-3.8l0.6-1.8l0.6-1.8
l1.4-3.5l1.5-3.5l0.9-1.6l0.9-1.6l1.9-3.3l2-3.1l1-1.5l1.1-1.5l1.1-1.5l1.1-1.4l2.5-2.9l2.5-2.6l1.4-1.4l1.3-1.3l1.4-1.3l1.5-1.1
l1.4-1.1l1.5-1.1l1.5-1.1l1.5-1.1l1.6-1l1.5-1l1.6-0.9l1.6-0.9l1.6-0.9l1.8-0.9l1.6-0.8l1.8-0.8l1.8-0.8l1.8-0.8l1.8-0.6l1.9-0.5
l3.6-1.1l3.8-0.8l3.8-0.8l3.9-0.5l3.9-0.3l4-0.1l4,0.1l2,0.1l1.9,0.1l2,0.3l1.9,0.3l1.9,0.4l1.9,0.4l1.9,0.4l1.9,0.4l3.6,1.1
l1.9,0.5l1.8,0.6l3.5,1.5l3.4,1.5l1.8,0.9l1.6,0.9l3.3,1.8l3.1,2l1.5,1.1l1.5,1.1l1.5,1.1l1.4,1.1l2.9,2.4l2.6,2.6l1.3,1.3
l1.4,1.4l1.1,1.4l1.3,1.5l1.1,1.4l1.1,1.5l1.1,1.5l1,1.5l1.1,1.5l0.9,1.6l1,1.6l0.9,1.6l0.9,1.6l0.9,1.6l0.8,1.8l0.8,1.8l0.8,1.8
l0.6,1.8l0.6,1.8l0.6,1.8l1,3.8l0.9,3.8l0.8,3.8l0.5,3.9l0.3,3.9l0.1,2v2v2l-0.1,2l-0.1,1.9l-0.1,2l-0.3,1.9l-0.3,2l-0.4,1.9
l-0.4,1.9l-0.4,1.9l-0.5,1.9l-1,3.6l-0.6,1.9l-0.6,1.8l-1.4,3.5l-1.5,3.4l-0.9,1.8l-0.9,1.6l-1.9,3.3l-2,3.1l-1,1.5l-1.1,1.5
l-1.1,1.5l-1.1,1.4l-2.4,2.8l-2.6,2.8l-1.3,1.3l-1.4,1.3l-1.4,1.3l-1.5,1.3l-1.4,1.1l-1.5,1.1l-1.5,1.1l-1.5,1l-1.6,1l-1.5,1
l-1.6,1l-1.6,0.9l-1.6,0.9l-1.8,0.9l-1.6,0.8l-1.8,0.8l-1.8,0.8l-1.8,0.6l-1.8,0.6l-1.9,0.6l-3.6,1l-3.8,0.9l-3.8,0.6l-3.9,0.5
l-3.9,0.4l-4,0.1l-4-0.1l-1.9-0.1l-2-0.3l-1.9-0.1l-2-0.4l-1.9-0.3l-1.9-0.4l-1.9-0.4l-1.9-0.5l-3.6-1l-1.9-0.6l-1.8-0.6
l-3.5-1.4l-3.4-1.5l-1.8-0.9l-1.6-0.9l-3.3-1.9l-3.1-2l-1.5-1l-1.5-1.1l-1.5-1.1l-1.4-1.1l-2.9-2.5l-2.6-2.5l-1.3-1.4l-1.3-1.4
l-1.3-1.4l-1.3-1.4l-1.1-1.4l-1.1-1.5l-1.1-1.5l-1-1.5l-1.1-1.6l-0.9-1.5l-1-1.6l-0.9-1.6l-0.9-1.6l-0.9-1.8l-0.8-1.6l-0.8-1.8
l-0.8-1.8l-0.6-1.8l-0.6-1.8l-0.6-1.9l-1-3.6L108,193l-0.8-3.8l-0.4-3.9l-0.4-3.9v-2L106.3,177.4z M101.9,177.4v-2l0.1-2l0.1-2
l0.1-1.9l0.3-2l0.3-1.9l0.4-1.9l0.4-1.9l0.4-1.9l0.5-1.9l1-3.8l0.6-1.8l0.6-1.8l1.4-3.5l1.5-3.5l0.9-1.6l0.9-1.6l1.8-3.3l2-3.1
l1.1-1.5l1.1-1.5l1.1-1.5l1.1-1.4l2.4-2.9l2.6-2.6l1.3-1.4l1.4-1.3l1.4-1.3l1.5-1.1l1.4-1.1l1.5-1.1l1.5-1.1l1.5-1.1l1.5-1l1.6-1
l1.6-0.9l1.6-0.9l1.6-0.9l1.6-0.9l1.8-0.8l1.8-0.8l1.8-0.8l1.8-0.8l1.8-0.6l1.8-0.5l3.8-1.1l3.8-0.8l3.8-0.8l3.9-0.5l3.9-0.3
l4-0.1l4,0.1l1.9,0.1l2,0.1l1.9,0.3l2,0.3l1.9,0.4l1.9,0.4l1.9,0.4l1.9,0.4l3.6,1.1l1.9,0.5l1.8,0.6l3.5,1.5l3.4,1.5l1.8,0.9
l1.6,0.9l3.3,1.8l3.1,2l1.5,1.1l1.5,1.1l1.5,1.1l1.4,1.1l2.9,2.4l2.6,2.6l1.3,1.3l1.3,1.4l1.3,1.4l1.3,1.5l1.1,1.4l1.1,1.5
l1.1,1.5l1,1.5l1,1.5l1,1.6l1,1.6l0.9,1.6l0.9,1.6l0.9,1.6l0.8,1.8l0.8,1.8l0.8,1.8l0.6,1.8l0.6,1.8l0.6,1.8l1,3.8l0.9,3.8
l0.6,3.8l0.5,3.9l0.4,3.9v2l0.1,2l-0.1,2v2l-0.1,1.9l-0.3,2l-0.1,1.9l-0.4,2l-0.3,1.9l-0.4,1.9l-0.4,1.9l-0.5,1.9l-1,3.6
l-0.6,1.9l-0.6,1.8l-1.4,3.5l-1.5,3.4l-0.9,1.8l-0.9,1.6l-1.9,3.3l-2,3.1l-1,1.5l-1.1,1.5l-1.1,1.5l-1.1,1.4l-2.5,2.8l-2.5,2.8
l-1.4,1.3l-1.3,1.3l-1.5,1.3l-1.4,1.3l-1.4,1.1l-1.5,1.1l-1.5,1.1l-1.5,1l-1.6,1l-1.5,1l-1.6,1l-1.6,0.9l-1.6,0.9l-1.8,0.9
l-1.6,0.8l-1.8,0.8l-1.8,0.8l-1.8,0.6l-1.8,0.6l-1.9,0.6l-3.6,1l-3.8,0.9l-3.8,0.6l-3.9,0.5l-3.9,0.4l-4,0.1l-4-0.1l-2-0.1
l-1.9-0.3l-2-0.1l-1.9-0.4l-1.9-0.3l-1.9-0.4l-1.9-0.4l-1.9-0.5l-3.8-1l-1.8-0.6l-1.8-0.6l-3.5-1.4l-3.5-1.5l-1.6-0.9l-1.6-0.9
l-3.3-1.9l-3.1-2l-1.5-1l-1.5-1.1l-1.5-1.1l-1.4-1.1l-2.9-2.5l-2.6-2.5l-1.4-1.4l-1.3-1.4l-1.1-1.4l-1.3-1.4l-1.1-1.4l-1.1-1.5
l-1.1-1.5l-1.1-1.5l-1-1.6l-1-1.5l-0.9-1.6l-0.9-1.6l-0.9-1.6l-0.9-1.8l-0.8-1.6l-0.8-1.8l-0.8-1.8l-0.6-1.8l-0.6-1.8l-0.6-1.9
l-1-3.6l-0.9-3.8l-0.8-3.8l-0.5-3.9l-0.3-3.9l-0.1-2V177.4z"
        />
        <path
          d="M106.1,177.4l0.4-7.9l0.5-4l0.8-3.9l0.9-3.6l1-3.8l2.6-7l1.5-3.5l1.8-3.3l1.8-3.3l2.1-3.1l2.1-3l2.3-3l5-5.4l5.5-5
l2.9-2.4l3-2.1l6.7-4l6.8-3.3l3.5-1.4l3.6-1.1l3.6-1.1l3.8-0.9l7.7-1.1l7.9-0.4v0.5l-7.9,0.4l-7.7,1.1l-3.8,0.9l-3.5,1.1
l-3.6,1.1l-3.5,1.4l-6.8,3.3l-6.4,3.9l-3,2.1l-2.9,2.4l-5.5,5l-5,5.4l-2.3,3l-2.1,3l-2.1,3.1l-1.8,3.3l-1.8,3.3l-1.5,3.4l-2.6,7
l-1,3.8l-0.9,3.6l-0.8,3.9l-0.5,3.8l-0.4,7.9H106.1z"
        />
        <path
          d="M183.9,99.6l7.9,0.4l3.9,0.5l3.8,0.6l3.8,0.9l3.6,1.1l7.2,2.5l3.4,1.6l3.4,1.6l3.4,2l3.1,2l3,2.1l2.9,2.4l5.5,5l5,5.4
l2.3,3l2.1,3l4,6.5l3.3,6.8l1.4,3.5l1.3,3.5l1,3.8l0.9,3.6l1.1,7.8l0.5,7.9h-0.5l-0.5-7.9l-1.1-7.7l-0.9-3.6l-1-3.8l-1.3-3.5
l-1.4-3.5l-3.3-6.8l-3.9-6.3l-2.1-3l-2.3-3l-5-5.4l-5.5-5l-2.9-2.4l-3-2.1l-3.1-2l-3.1-1.9l-3.4-1.6l-3.4-1.6l-7.2-2.5l-3.6-1.1
l-3.8-0.9l-3.8-0.6l-3.8-0.5l-7.9-0.4V99.6z"
        />
        <line fill="none" x1="183.9" y1="99.6" x2="183.9" y2="100.1" />
        <path
          d="M261.7,177.4l-0.5,7.9l-0.4,3.9l-0.8,3.8l-0.9,3.8l-1,3.6l-2.6,7.2l-1.5,3.4l-1.8,3.4l-2,3.4l-2,3.1l-2.1,3.1l-2.3,2.9
l-5,5.5l-5.5,5l-2.9,2.3l-3,2.1l-6.4,3.9l-6.8,3.3l-3.5,1.4l-3.6,1.3l-3.6,1l-3.8,0.9l-7.8,1.1l-7.9,0.4v-0.4l7.9-0.4l7.5-1.1
l3.8-0.9l3.6-1l3.6-1.3l3.4-1.4l6.8-3.3l6.4-3.9l3-2.1l2.9-2.3l5.5-5l5-5.5l2.3-2.9l2.1-3.1l2-3.1l1.9-3.1l1.8-3.4l1.5-3.4
l2.6-7.2l1-3.5l0.9-3.8l0.8-3.8l0.4-3.9l0.5-7.9H261.7z"
        />
        <line fill="none" x1="261.7" y1="177.4" x2="261.2" y2="177.4" />
        <path
          d="M183.9,255.1l-7.9-0.4l-3.9-0.5l-3.8-0.6l-3.8-0.9l-3.8-1l-7.2-2.6l-3.4-1.5l-3.4-1.8l-3.4-1.9l-3.1-2l-3-2.1l-2.9-2.3
l-5.4-5l-5.2-5.5l-2.3-2.9l-2.1-3.1l-3.9-6.4l-3.3-6.8l-1.4-3.5l-1.3-3.6l-1-3.8l-0.9-3.8l-1.3-7.7l-0.4-7.9h0.4l0.4,7.9l1.3,7.7
l0.9,3.8l1,3.5l1.3,3.6l1.4,3.5l3.3,6.8l3.9,6.3l2.1,3.1l2.3,2.9l5,5.5l5.5,5l2.9,2.3l3,2.1l3.1,2l3.3,1.9l3.4,1.8l3.4,1.5
l7.2,2.6l3.5,1l3.8,0.9l3.8,0.6l3.9,0.5l7.9,0.4V255.1z"
        />
        <line fill="none" x1="183.9" y1="255.1" x2="183.9" y2="254.7" />
        <line fill="none" x1="106.1" y1="177.4" x2="106.5" y2="177.4" />
        <path
          d="M101.7,177.4l0.4-7.9l0.5-4l0.8-3.9l0.8-3.6l1.1-3.8l2.5-7l1.6-3.5l1.6-3.3l1.9-3.3l2-3.1l2.3-3l2.3-3l5-5.4l5.4-5l3-2.4
l3-2.1l6.7-4l6.8-3.3l3.5-1.4l3.5-1.1l3.8-1.1l3.6-0.9l7.8-1.1l7.9-0.4v0.5l-7.9,0.4l-7.8,1.1l-3.6,0.9l-3.6,1.1l-3.5,1.1
l-3.5,1.4l-6.8,3.3l-6.4,3.9l-3,2.1l-3,2.4l-5.4,5l-5,5.4l-2.3,3l-2.3,3l-2,3.1l-1.9,3.3l-1.6,3.3l-1.6,3.4l-2.5,7l-1.1,3.8
l-0.8,3.6l-0.8,3.9l-0.5,3.8l-0.4,7.9H101.7z"
        />
        <path
          d="M179.5,99.6l7.9,0.4l3.9,0.5l3.8,0.6l3.8,0.9l3.6,1.1l7.2,2.5l3.4,1.6l3.4,1.6l3.4,2l3.1,2l3,2.1l2.9,2.4l5.5,5l5,5.4
l2.3,3l2.1,3l4,6.5l3.3,6.8l1.4,3.5l1.3,3.5l1,3.8l0.9,3.6l1.1,7.8l0.4,7.9h-0.5l-0.4-7.9l-1.1-7.7l-0.9-3.6l-1-3.8l-1.3-3.5
l-1.4-3.5l-3.3-6.8l-3.9-6.3l-2.1-3l-2.3-3l-5-5.4l-5.5-5l-2.9-2.4l-3-2.1l-3.1-2l-3.1-1.9l-3.4-1.6l-3.4-1.6l-7.2-2.5l-3.6-1.1
l-3.8-0.9l-3.8-0.6l-3.8-0.5l-7.9-0.4V99.6z"
        />
        <line fill="none" x1="179.5" y1="99.6" x2="179.5" y2="100.1" />
        <path
          d="M257.2,177.4l-0.4,7.9l-0.5,3.9l-0.6,3.8l-0.9,3.8l-1,3.6l-2.6,7.2l-1.5,3.4l-1.8,3.4l-2,3.4l-2,3.1l-2.1,3.1l-2.3,2.9
l-5,5.5l-5.5,5l-2.9,2.3l-3,2.1l-6.4,3.9l-6.8,3.3l-3.5,1.4l-3.6,1.3l-3.6,1l-3.8,0.9l-7.8,1.1l-7.9,0.4v-0.4l7.9-0.4l7.5-1.1
l3.8-0.9l3.6-1l3.6-1.3l3.4-1.4l6.8-3.3l6.4-3.9l3-2.1l2.9-2.3l5.5-5l5-5.5l2.3-2.9l2.1-3.1l2-3.1l1.9-3.1l1.8-3.4l1.5-3.4
l2.6-7.2l1-3.5l0.9-3.8l0.6-3.8l0.5-3.9l0.4-7.9H257.2z"
        />
        <line fill="none" x1="257.2" y1="177.4" x2="256.7" y2="177.4" />
        <path
          d="M179.5,255.1l-7.9-0.4l-3.9-0.5l-3.9-0.6l-3.6-0.9l-3.9-1l-7-2.6l-3.5-1.5l-3.3-1.8l-3.4-1.9l-3.1-2l-3-2.1l-3-2.3
l-5.3-5l-5.2-5.5l-2.3-2.9l-2.3-3.1l-3.9-6.4l-3.3-6.8l-1.4-3.5l-1.1-3.6l-1.1-3.8l-0.8-3.8l-1.3-7.7l-0.4-7.9h0.4l0.4,7.9
l1.3,7.7l0.8,3.8l1.1,3.5l1.1,3.6l1.4,3.5l3.3,6.8l3.9,6.3l2.3,3.1l2.3,2.9l5,5.5l5.4,5l3,2.3l3,2.1l3.1,2l3.3,1.9l3.3,1.8
l3.5,1.5l7,2.6l3.6,1l3.6,0.9l3.9,0.6l3.9,0.5l7.9,0.4V255.1z"
        />
        <line fill="none" x1="179.5" y1="255.1" x2="179.5" y2="254.7" />
        <line fill="none" x1="101.7" y1="177.4" x2="102.1" y2="177.4" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110.1,177.4l0.1-3.8l0.1-1.9l0.1-1.9l0.5-3.6l0.3-1.9l0.4-1.8l0.9-3.6l0.5-1.8l0.5-1.6l0.5-1.8l0.6-1.8
l0.6-1.6l0.6-1.6l0.8-1.6l0.8-1.6l0.8-1.6l0.9-1.5l1.8-3.1l1.9-3l2.1-2.9l1-1.4l1.1-1.4l1.1-1.4l1.1-1.3l1.3-1.3l1.3-1.3l2.5-2.5
l2.6-2.3l2.9-2.3l2.9-2l2.9-1.9l1.6-0.9l1.5-0.9l3.1-1.6l3.3-1.5l3.4-1.4l3.4-1.1l3.5-1l1.8-0.4l1.8-0.4l3.6-0.6l3.6-0.5l1.9-0.1
l1.9-0.1l3.8-0.1l3.8,0.1l1.9,0.1l1.9,0.1l3.6,0.5l1.9,0.3l1.8,0.4l3.6,0.8l1.8,0.5l1.6,0.5l1.8,0.6l1.8,0.5l1.6,0.8l1.6,0.6
l1.6,0.8l1.6,0.8l1.6,0.8l1.5,0.9l3.1,1.8l3,1.9l2.9,2l1.4,1.1l1.4,1.1l1.4,1.1l1.3,1.1l1.3,1.3l1.3,1.3l2.5,2.5l2.3,2.6l2.3,2.8
l2,2.9l1.9,3l0.9,1.5l0.9,1.6l1.6,3.1l1.5,3.3l1.4,3.3l1.1,3.5l1,3.4l0.4,1.8l0.4,1.9l0.6,3.6l0.5,3.6l0.1,1.9l0.1,1.9l0.1,1.9
v1.9l-0.1,3.8l-0.1,1.9l-0.1,1.9l-0.5,3.6l-0.3,1.9l-0.4,1.8l-0.8,3.5l-0.5,1.8l-0.5,1.8l-0.6,1.8l-0.5,1.6l-0.8,1.8l-0.6,1.6
l-0.8,1.6l-0.8,1.6l-0.8,1.6l-0.9,1.5l-1.8,3.1l-1.9,2.9l-2,2.9l-1.1,1.5l-1.1,1.3l-1.1,1.4l-1.1,1.4l-1.3,1.3l-1.3,1.3l-2.5,2.4
l-2.6,2.4l-2.8,2.1l-2.9,2.1l-3,1.9l-1.5,0.9l-1.6,0.9l-3.1,1.6l-3.3,1.5l-3.3,1.3l-3.5,1.1l-3.4,1l-1.9,0.5l-1.8,0.4l-3.6,0.6
l-3.6,0.5l-1.9,0.1l-1.9,0.1l-3.8,0.1l-3.8-0.1l-1.9-0.1l-1.9-0.1l-3.6-0.5l-1.9-0.3l-1.8-0.4l-3.5-0.9l-1.8-0.5l-1.8-0.5
l-1.8-0.5l-1.6-0.6l-1.8-0.6l-1.6-0.6l-1.6-0.8l-1.6-0.8l-1.6-0.8l-1.5-0.9l-3.1-1.8l-2.9-1.9l-2.9-2.1l-1.5-1l-1.4-1.1l-1.3-1.1
l-1.4-1.3l-1.3-1.1l-1.3-1.3l-2.5-2.5l-2.3-2.8l-2.1-2.8l-2.1-2.9l-1.9-2.9l-0.9-1.6l-0.9-1.5l-1.6-3.1l-1.5-3.3l-1.3-3.4
l-1.1-3.4l-1-3.5L112,194l-0.4-1.8l-0.6-3.6l-0.5-3.6l-0.1-1.9l-0.1-1.9l-0.1-1.9V177.4z M106,177.4l0.1-3.8l0.1-1.9l0.1-1.9
l0.5-3.6l0.3-1.9l0.4-1.8l0.8-3.6l0.5-1.8l0.5-1.6l0.6-1.8l0.5-1.8l0.6-1.6l0.8-1.6l0.8-1.6l0.8-1.6l0.8-1.6l0.9-1.5l1.8-3.1
l1.9-3l2-2.9l1.1-1.4l1.1-1.4l1.1-1.4l1.1-1.3l1.3-1.3l1.1-1.3l2.6-2.5l2.6-2.3l2.8-2.3l2.9-2l3-1.9l1.5-0.9l1.5-0.9l3.3-1.6
l3.3-1.5l3.3-1.4l3.4-1.1l3.5-1l1.8-0.4l1.9-0.4l3.5-0.6l3.8-0.5l1.9-0.1l1.9-0.1l3.8-0.1l3.8,0.1l1.9,0.1l1.9,0.1l3.6,0.5
l1.8,0.3l1.9,0.4l3.5,0.8l1.8,0.5l1.8,0.5l1.8,0.6l1.6,0.5l1.8,0.8l1.6,0.6l1.6,0.8l1.6,0.8l1.5,0.8l1.6,0.9l3.1,1.8l2.9,1.9
l2.9,2l1.4,1.1l1.4,1.1l1.4,1.1l1.4,1.1l1.3,1.3l1.3,1.3l2.4,2.5l2.4,2.6l2.1,2.8l2.1,2.9l1.9,3l0.9,1.5l0.9,1.6l1.6,3.1l1.5,3.3
l1.3,3.3l1.1,3.5l1,3.4l0.5,1.8l0.4,1.9l0.6,3.6l0.5,3.6l0.1,1.9l0.1,1.9v1.9l0.1,1.9l-0.1,3.8L253,183l-0.1,1.9l-0.5,3.6
l-0.4,1.9l-0.3,1.8l-0.9,3.5l-0.5,1.8l-0.5,1.8l-0.5,1.8l-0.6,1.6l-0.6,1.8l-0.6,1.6l-0.8,1.6l-0.8,1.6l-0.8,1.6l-0.9,1.5
l-1.8,3.1l-1.9,2.9l-2.1,2.9l-1,1.5l-1.1,1.3l-1.1,1.4l-1.3,1.4l-1.1,1.3l-1.3,1.3l-2.5,2.4l-2.8,2.4l-2.8,2.1l-2.9,2.1l-2.9,1.9
l-1.6,0.9l-1.5,0.9l-3.1,1.6l-3.3,1.5l-3.4,1.3l-3.4,1.1l-3.5,1l-1.8,0.5l-1.8,0.4l-3.6,0.6l-3.6,0.5l-1.9,0.1l-1.9,0.1l-3.8,0.1
l-3.8-0.1l-1.9-0.1l-1.9-0.1l-3.8-0.5l-1.8-0.3l-1.8-0.4l-3.6-0.9l-1.8-0.5l-1.8-0.5l-1.6-0.5l-1.8-0.6l-1.6-0.6l-1.6-0.6
l-1.6-0.8l-1.6-0.8l-1.6-0.8l-1.6-0.9l-3-1.8l-3-1.9l-2.9-2.1l-1.4-1l-1.4-1.1l-1.4-1.1l-1.3-1.3l-1.4-1.1l-1.3-1.3l-2.4-2.5
l-2.3-2.8l-2.3-2.8l-2-2.9l-1.9-2.9l-1-1.6l-0.8-1.5l-1.6-3.1l-1.5-3.3l-1.4-3.4l-1.1-3.4l-1-3.5l-0.4-1.8l-0.4-1.8l-0.6-3.6
l-0.5-3.6l-0.1-1.9l-0.1-1.9l-0.1-1.9V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M110.1,177.4l0.4-7.5l0.5-3.8l0.6-3.5l1.8-7.2l1.3-3.4l1.3-3.3l1.5-3.3
l1.6-3.3l3.6-6l2.1-2.9l2.1-2.8l4.8-5.3l2.5-2.4l2.6-2.3l2.8-2.3l2.9-2l3-1.9l3.1-1.9l6.4-3l6.8-2.5l3.5-1l3.5-0.8l3.6-0.6
l3.6-0.5l7.5-0.4l7.5,0.4l3.6,0.5l3.6,0.6l7,1.8l3.4,1.1l3.4,1.4l3.3,1.5l3.1,1.5l6.2,3.8l2.9,2l2.8,2.3l5.2,4.7l2.5,2.6l2.3,2.6
l2.3,2.8l2,2.9l1.9,3l1.8,3l3.1,6.5l2.5,6.7l0.9,3.5l0.9,3.6l0.6,3.5l0.5,3.8l0.4,7.5l-0.4,7.5l-0.5,3.6l-0.6,3.6l-1.8,7
l-1.3,3.4l-1.3,3.4l-1.5,3.3l-1.6,3.1l-3.6,6l-2,2.9l-2.3,2.8l-4.8,5.3l-2.5,2.4l-2.6,2.4l-2.8,2.1l-2.9,2.1l-3,1.9l-3.1,1.8
l-6.4,3.1l-6.8,2.4l-3.5,1l-3.5,0.9l-3.6,0.6l-3.6,0.5l-7.5,0.4l-7.5-0.4l-3.6-0.5l-3.6-0.6l-7-1.9l-3.4-1.1l-3.4-1.3l-3.3-1.5
l-3.1-1.6l-6.2-3.6l-2.9-2.1l-2.8-2.1l-5.2-4.8l-2.5-2.5l-2.3-2.8l-2.1-2.8l-2.1-2.9l-1.9-3l-1.8-3l-3.1-6.4l-2.5-6.8l-0.9-3.5
l-0.9-3.5l-0.6-3.6l-0.5-3.6L110.1,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M106,177.4l0.4-7.5l0.4-3.8l0.8-3.5l1.8-7.2l1.1-3.4l1.4-3.3l1.4-3.3
l1.6-3.3l3.8-6l2-2.9l2.1-2.8l4.8-5.3l2.6-2.4l2.6-2.3l2.8-2.3l2.9-2l3-1.9l3-1.9l6.5-3l6.7-2.5l3.5-1l3.6-0.8l3.5-0.6l3.8-0.5
l7.5-0.4l7.5,0.4l3.6,0.5l3.6,0.6l7,1.8l3.4,1.1l3.4,1.4l3.3,1.5l3.1,1.5l6,3.8l2.9,2l2.8,2.3l5.3,4.7l2.4,2.6l2.4,2.6l2.1,2.8
l2,2.9l2,3l1.8,3l3,6.5l2.5,6.7l1,3.5l0.9,3.6l0.6,3.5l0.4,3.8l0.4,7.5l-0.4,7.5l-0.4,3.6l-0.6,3.6l-1.9,7l-1.1,3.4l-1.4,3.4
l-1.4,3.3l-1.6,3.1l-3.8,6l-2,2.9l-2.1,2.8l-4.8,5.3l-2.5,2.4l-2.8,2.4l-2.8,2.1l-2.9,2.1l-3,1.9l-3,1.8l-6.4,3.1l-6.8,2.4
l-3.5,1l-3.5,0.9l-3.6,0.6l-3.6,0.5l-7.5,0.4l-7.5-0.4l-3.8-0.5l-3.5-0.6l-7.2-1.9l-3.4-1.1l-3.3-1.3l-3.3-1.5l-3.3-1.6l-6-3.6
l-2.9-2.1l-2.8-2.1l-5.3-4.8l-2.4-2.5l-2.4-2.8l-2.1-2.8l-2-2.9l-1.9-3l-1.9-3l-3-6.4l-2.5-6.8l-1-3.5l-0.8-3.5l-0.8-3.6
l-0.4-3.6L106,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.8,177.4v-3.6l0.1-1.8l0.3-1.8l0.4-3.5l0.3-1.8l0.4-1.6l0.4-1.8l0.4-1.6l0.5-1.8l0.5-1.6l1.1-3.3
l0.5-1.5l0.6-1.6l1.5-3.1l1.5-3l0.8-1.4l0.9-1.5l1.9-2.8l0.9-1.4l1-1.4l1-1.4l1.1-1.3l2.1-2.5l2.4-2.5l2.4-2.3l1.3-1.1l1.3-1.1
l1.4-1l1.3-1l2.8-2l2.9-1.8l2.9-1.8l1.5-0.8l1.5-0.8l1.5-0.8l1.6-0.6l3.1-1.3l3.3-1.1l1.6-0.5l1.6-0.4l3.4-0.8l3.5-0.6l1.6-0.3
l1.8-0.3l1.9-0.1l1.8-0.1l3.5-0.1l3.6,0.1l1.8,0.1l1.8,0.1l3.5,0.5l1.8,0.3l1.8,0.4l1.6,0.3l1.6,0.5l1.8,0.4l1.6,0.5l3.3,1.1
l1.5,0.6l1.6,0.6l3.1,1.4l3,1.5l1.4,0.9l1.5,0.9l2.9,1.8l1.4,1l1.3,1l1.4,1l1.3,1l2.6,2.3l2.4,2.3l2.3,2.5l1.1,1.3l1.1,1.3l1,1.3
l1,1.4l2,2.8l1.8,2.8l1.8,2.9l0.8,1.5l0.8,1.5l0.8,1.6l0.6,1.5l1.3,3.1l1.1,3.3l0.5,1.6l0.4,1.8l0.9,3.4l0.6,3.4l0.1,1.8l0.3,1.8
l0.1,1.8l0.1,1.8l0.1,1.8v1.9l-0.1,3.5l-0.1,1.9l-0.1,1.8l-0.4,3.5l-0.4,1.6l-0.3,1.8l-0.4,1.8l-0.5,1.6l-0.4,1.6l-0.5,1.6
l-1.1,3.3l-0.6,1.6l-0.6,1.5l-1.4,3.1l-1.5,3l-0.9,1.5l-0.9,1.4l-1.8,2.9l-1,1.4l-1,1.4l-1,1.3l-1,1.4l-2.3,2.5l-2.3,2.4
l-2.4,2.4l-1.3,1.1l-1.4,1l-1.3,1.1l-1.4,1l-2.6,1.9l-2.9,1.9l-2.9,1.6l-1.5,0.9l-1.5,0.8l-1.6,0.6l-1.5,0.8l-3.1,1.3l-3.3,1
l-1.6,0.5l-1.8,0.5l-3.3,0.8l-3.5,0.6l-1.8,0.3l-1.8,0.3l-1.8,0.1l-1.8,0.1h-3.6h-3.5l-1.8-0.1l-1.9-0.1l-3.4-0.5l-1.8-0.3
l-1.8-0.4l-1.6-0.4l-1.8-0.4l-1.6-0.5l-1.6-0.5l-3.3-1l-1.6-0.6l-1.5-0.6l-3.1-1.4l-3-1.6l-1.5-0.8l-1.4-0.9l-2.9-1.9l-1.4-0.9
l-1.4-1l-1.3-1l-1.4-1.1l-2.5-2.1l-2.4-2.4l-2.4-2.4l-1.1-1.3l-1-1.3l-1.1-1.4l-1-1.3l-1.9-2.8l-1.9-2.9l-1.6-2.9l-0.8-1.5
l-0.8-1.5l-0.8-1.5l-0.8-1.6l-1.1-3.1l-1.1-3.3l-0.5-1.6l-0.5-1.6l-0.8-3.4l-0.6-3.4l-0.3-1.8l-0.1-1.8l-0.3-1.8l-0.1-1.9v-1.8
V177.4z M109.7,177.4l0.1-3.6l0.1-1.8l0.1-1.8l0.4-3.5l0.4-1.8l0.3-1.6l0.4-1.8l0.4-1.6l0.5-1.8l0.5-1.6l1.1-3.3l0.6-1.5l0.6-1.6
l1.4-3.1l1.5-3l0.9-1.4l0.9-1.5l1.8-2.8l1-1.4l1-1.4l1-1.4l1-1.3l2.3-2.5l2.3-2.5l2.4-2.3l1.3-1.1l1.4-1.1l1.3-1l1.4-1l2.6-2
l2.9-1.8l2.9-1.8l1.5-0.8l1.5-0.8l1.5-0.8l1.6-0.6l3.1-1.3l3.3-1.1l1.6-0.5l1.6-0.4l3.4-0.8l3.5-0.6l1.8-0.3l1.8-0.3l1.8-0.1
l1.8-0.1l3.6-0.1l3.5,0.1l1.8,0.1l1.8,0.1l3.5,0.5l1.8,0.3l1.8,0.4l1.6,0.3l1.8,0.5l1.6,0.4l1.6,0.5l3.3,1.1l1.6,0.6l1.5,0.6
l3.1,1.4l3,1.5l1.5,0.9l1.4,0.9l2.9,1.8l1.4,1l1.4,1l1.3,1l1.4,1l2.5,2.3l2.4,2.3l2.4,2.5l1,1.3l1.1,1.3l1,1.3l1.1,1.4l1.9,2.8
l1.9,2.8l1.6,2.9l0.8,1.5l0.8,1.5l0.8,1.6l0.6,1.5l1.3,3.1l1.1,3.3l0.5,1.6l0.5,1.8l0.8,3.4l0.6,3.4l0.3,1.8l0.1,1.8l0.3,1.8v1.8
l0.1,1.8v1.9l-0.1,3.5v1.9l-0.3,1.8l-0.4,3.5l-0.4,1.6l-0.3,1.8l-0.4,1.8l-0.4,1.6l-0.5,1.6l-0.5,1.6l-1.1,3.3l-0.5,1.6l-0.8,1.5
l-1.4,3.1l-1.5,3l-0.9,1.5l-0.8,1.4l-1.9,2.9l-1,1.4l-0.9,1.4l-1.1,1.3l-1,1.4l-2.1,2.5l-2.4,2.4l-2.4,2.4l-1.3,1.1l-1.3,1
l-1.4,1.1l-1.3,1l-2.8,1.9l-2.9,1.9l-2.9,1.6l-1.5,0.9l-1.5,0.8l-1.5,0.6l-1.6,0.8l-3.1,1.3l-3.3,1l-1.6,0.5l-1.6,0.5l-3.4,0.8
l-3.5,0.6l-1.8,0.3l-1.8,0.3l-1.8,0.1l-1.8,0.1h-3.5h-3.6l-1.8-0.1l-1.8-0.1l-3.5-0.5l-1.8-0.3l-1.8-0.4l-1.6-0.4l-1.8-0.4
l-1.6-0.5l-1.6-0.5l-3.3-1l-1.6-0.6l-1.5-0.6l-3.1-1.4l-3-1.6l-1.4-0.8l-1.5-0.9l-2.9-1.9l-1.4-0.9l-1.3-1l-1.4-1l-1.3-1.1
l-2.6-2.1l-2.4-2.4l-2.3-2.4l-1.1-1.3l-1.1-1.3l-1-1.4l-1-1.3l-2-2.8l-1.8-2.9l-1.8-2.9l-0.8-1.5l-0.8-1.5l-0.8-1.5l-0.6-1.6
l-1.3-3.1l-1.1-3.3l-0.5-1.6l-0.5-1.6l-0.8-3.4l-0.6-3.4l-0.3-1.8l-0.1-1.8l-0.1-1.8l-0.1-1.9l-0.1-1.8V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M113.8,177.4l0.3-7.2l1.1-6.9l0.8-3.4l1-3.4l1-3.3l1.3-3.1l3-6.2
l3.5-5.8l4-5.3l2.1-2.6l2.4-2.4l2.4-2.3l2.5-2.3l5.4-4l5.8-3.5l3-1.5l3.1-1.4l6.4-2.4l6.7-1.8l3.4-0.6l3.5-0.4l7.2-0.4l7.2,0.4
l6.9,1l3.4,0.9l3.4,0.9l3.3,1.1l3.1,1.3l6.2,2.9l5.7,3.5l5.4,4l2.5,2.3l2.5,2.3l2.3,2.4l2.3,2.6l4,5.3l3.5,5.8l1.5,3l1.4,3.1
l2.4,6.4l1.6,6.8l0.6,3.4l0.5,3.5l0.4,7.2l-0.4,7.2l-1.1,6.9l-0.8,3.4l-0.9,3.3l-1.1,3.3l-1.3,3.1l-2.9,6.2l-3.5,5.8l-4,5.4
l-2.3,2.5l-2.3,2.4l-2.5,2.4l-2.5,2.1l-5.4,4l-5.7,3.5l-3,1.5l-3.1,1.5l-6.4,2.3l-6.8,1.8l-3.4,0.6l-3.5,0.4l-7.2,0.4l-7.2-0.4
l-6.9-1l-3.4-0.8l-3.3-1l-3.3-1.1l-3.1-1.1l-6.2-3l-5.8-3.5l-5.4-4l-2.5-2.1l-2.4-2.4l-2.4-2.4l-2.1-2.5l-4-5.4l-3.5-5.8l-1.5-3
l-1.5-3.1l-2.3-6.4l-1.8-6.7l-0.6-3.5l-0.5-3.4L113.8,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M109.7,177.4l0.4-7.2l1-6.9l0.8-3.4l1-3.4l1.1-3.3l1.3-3.1l2.9-6.2
l3.5-5.8l4-5.3l2.1-2.6l2.4-2.4l2.4-2.3l2.5-2.3l5.4-4l5.8-3.5l3-1.5l3.1-1.4l6.4-2.4l6.7-1.8l3.5-0.6l3.5-0.4l7-0.4l7.2,0.4l7,1
l3.4,0.9l3.3,0.9l3.3,1.1l3.1,1.3l6.2,2.9l5.8,3.5l5.3,4l2.6,2.3l2.4,2.3l2.3,2.4l2.3,2.6l4,5.3l3.5,5.8l1.5,3l1.4,3.1l2.4,6.4
l1.8,6.8l0.6,3.4l0.4,3.5l0.4,7.2l-0.4,7.2l-1,6.9l-0.8,3.4l-1,3.3l-1.1,3.3l-1.3,3.1l-2.9,6.2l-3.5,5.8l-4,5.4l-2.3,2.5
l-2.3,2.4l-2.4,2.4l-2.6,2.1l-5.3,4l-5.8,3.5l-3,1.5l-3.1,1.5l-6.4,2.3l-6.7,1.8l-3.5,0.6l-3.5,0.4l-7.2,0.4l-7-0.4l-7-1
l-3.4-0.8l-3.3-1l-3.3-1.1l-3.1-1.1l-6.2-3l-5.8-3.5l-5.4-4l-2.5-2.1l-2.4-2.4l-2.4-2.4l-2.1-2.5l-4-5.4l-3.5-5.8l-1.5-3
l-1.4-3.1l-2.4-6.4l-1.8-6.7l-0.6-3.5l-0.4-3.4L109.7,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.1,177.4v-1.8V174l0.4-3.4l0.4-3.4l0.3-1.6l0.4-1.6l0.3-1.6l0.4-1.5l0.5-1.6l0.5-1.5l0.5-1.6l0.5-1.5
l0.5-1.5l0.6-1.5l0.6-1.5l0.8-1.4l0.8-1.5l0.8-1.4l0.8-1.4l0.8-1.4l1.8-2.8l1.9-2.6l1-1.3l1-1.3l2-2.4l1.1-1.1l1.1-1.1l2.3-2.3
l1.3-1l1.1-1l1.3-1l1.3-1l2.6-1.9l2.6-1.6l1.4-0.9l1.4-0.8l2.9-1.5l1.5-0.6l1.5-0.6l3-1.3l1.5-0.5l1.5-0.5l3.1-0.9l3.3-0.8
l3.3-0.6l1.6-0.3l1.6-0.1l1.8-0.1l1.6-0.1l3.5-0.1l3.4,0.1l3.4,0.3l3.3,0.4l1.6,0.3l1.6,0.4l1.6,0.4l1.6,0.4l1.5,0.4l1.6,0.5
l1.5,0.5l1.5,0.5l1.5,0.6l1.5,0.6l1.5,0.6l1.5,0.6l1.4,0.8l1.5,0.8l1.4,0.8l1.4,0.9l2.6,1.6l2.6,1.9l1.3,1l1.3,1l2.4,2l1.1,1.1
l1.1,1.1l2.3,2.3l1,1.3l1.1,1.1l1,1.3l0.9,1.3l1.9,2.6l1.8,2.8l0.8,1.4l0.8,1.4l1.5,2.9l0.8,1.4l0.6,1.5l1.1,3l0.6,1.5l0.5,1.6
l0.9,3.1l0.8,3.1l0.5,3.3l0.3,1.6l0.3,1.8l0.1,1.6l0.1,1.8v1.6v1.8v1.6v1.8l-0.3,3.4l-0.5,3.3l-0.3,1.6l-0.3,1.6l-0.4,1.6
L248,194l-0.5,1.6l-0.4,1.5l-0.5,1.5l-0.6,1.6l-0.5,1.5l-0.6,1.5l-0.6,1.4l-0.8,1.5l-0.8,1.5l-0.8,1.4l-0.8,1.4l-0.8,1.4
l-1.8,2.6l-1.9,2.6l-0.9,1.3l-1,1.3l-2.1,2.4l-1.1,1.3l-1.1,1.1l-2.3,2.1l-1.1,1.1l-1.3,1l-1.3,1l-1.3,1l-2.6,1.8l-2.6,1.8
l-1.4,0.9l-1.4,0.8l-2.9,1.4l-1.5,0.8l-1.5,0.6l-3,1.1l-1.5,0.6l-1.5,0.5l-3.1,0.9l-3.3,0.8l-3.3,0.5l-1.6,0.3l-1.6,0.3l-1.8,0.1
l-1.6,0.1l-3.4,0.1l-3.5-0.1l-3.4-0.3l-3.3-0.5l-1.6-0.3l-1.6-0.3l-1.6-0.4l-1.6-0.4l-1.5-0.4l-1.6-0.5l-1.5-0.5l-1.5-0.6
l-1.5-0.5l-1.5-0.6l-1.5-0.6l-1.5-0.8l-1.4-0.6l-1.5-0.8l-1.4-0.8l-1.4-0.9l-2.6-1.8l-2.6-1.8l-1.3-1l-1.3-1l-2.4-2.1l-1.1-1
l-1.1-1.1l-2.3-2.4l-1-1.1l-1-1.3l-1-1.3l-1-1.3l-1.9-2.6l-1.8-2.6l-0.8-1.4l-0.8-1.4l-1.5-2.9l-0.8-1.5l-0.6-1.4l-1.1-3
l-0.5-1.6l-0.5-1.5l-1-3.1l-0.6-3.3l-0.6-3.3l-0.3-1.6l-0.1-1.6l-0.3-1.8l-0.1-1.6V179V177.4z M113.4,177.4v-1.8V174l0.3-3.4
l0.5-3.4l0.3-1.6l0.3-1.6l0.4-1.6l0.4-1.5l0.5-1.6l0.4-1.5l0.5-1.6l0.6-1.5l0.5-1.5l0.6-1.5l0.6-1.5l0.8-1.4l0.6-1.5l0.8-1.4
l0.9-1.4l0.8-1.4l1.8-2.8l1.9-2.6l0.9-1.3l1-1.3l2.1-2.4l1.1-1.1l1-1.1l2.4-2.3l1.1-1l1.3-1l1.3-1l1.3-1l2.6-1.9l2.6-1.6l1.4-0.9
l1.4-0.8l2.9-1.5l1.5-0.6l1.5-0.6l3-1.3l1.5-0.5l1.5-0.5l3.1-0.9l3.3-0.8l3.3-0.6l1.6-0.3l1.6-0.1l1.8-0.1l1.6-0.1l3.4-0.1
l3.5,0.1l3.3,0.3l3.4,0.4l1.6,0.3l1.6,0.4l1.6,0.4l1.6,0.4l1.5,0.4l1.6,0.5l1.5,0.5l1.5,0.5l1.5,0.6l1.5,0.6l1.5,0.6l1.5,0.6
l1.4,0.8l1.4,0.8l1.5,0.8l1.4,0.9l2.6,1.6l2.6,1.9l1.3,1l1.3,1l2.4,2l1.1,1.1l1.1,1.1l2.3,2.3l1,1.3l1,1.1l1,1.3l1,1.3l1.9,2.6
l1.8,2.8l0.8,1.4l0.8,1.4l1.5,2.9l0.6,1.4l0.8,1.5l1.1,3l0.5,1.5l0.5,1.6l0.9,3.1l0.8,3.1l0.6,3.3l0.3,1.6l0.1,1.8l0.3,1.6v1.8
l0.1,1.6v1.8v1.6l-0.1,1.8l-0.3,3.4l-0.4,3.3l-0.3,1.6l-0.4,1.6l-0.3,1.6l-0.5,1.6l-0.4,1.6l-0.5,1.5l-0.5,1.5l-0.5,1.6l-0.6,1.5
l-0.5,1.5l-0.8,1.4l-0.6,1.5l-0.8,1.5l-0.8,1.4l-0.8,1.4l-0.8,1.4l-1.8,2.6l-1.9,2.6l-1,1.3l-1,1.3l-2,2.4l-1.1,1.3l-1.1,1.1
l-2.3,2.1l-1.3,1.1l-1.1,1l-1.3,1l-1.3,1l-2.6,1.8l-2.6,1.8l-1.4,0.9l-1.5,0.8l-2.8,1.4l-1.5,0.8l-1.5,0.6l-3,1.1l-1.5,0.6
l-1.5,0.5l-3.1,0.9l-3.3,0.8l-3.3,0.5l-1.6,0.3l-1.8,0.3l-1.6,0.1l-1.6,0.1l-3.5,0.1l-3.4-0.1l-3.4-0.3l-3.3-0.5l-1.6-0.3
l-1.6-0.3l-1.6-0.4l-1.6-0.4l-1.5-0.4l-1.6-0.5l-1.5-0.5l-1.5-0.6l-1.6-0.5l-1.4-0.6l-1.5-0.6l-1.5-0.8l-1.4-0.6l-1.5-0.8
l-1.4-0.8l-1.4-0.9l-2.6-1.8l-2.6-1.8l-1.3-1l-1.3-1l-2.4-2.1l-1.1-1l-1.3-1.1l-2.1-2.4l-1-1.1l-1.1-1.3l-1-1.3l-0.9-1.3
l-1.9-2.6l-1.8-2.6l-0.8-1.4l-0.9-1.4l-1.4-2.9l-0.8-1.5l-0.6-1.4l-1.1-3l-0.6-1.6l-0.5-1.5l-0.9-3.1l-0.8-3.3l-0.5-3.3l-0.3-1.6
l-0.3-1.6l-0.1-1.8l-0.1-1.6V179V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M117.1,177.4l0.3-6.8l0.5-3.4l0.5-3.3l1.6-6.4l1.1-3l1.1-3l2.8-5.8
l3.4-5.5l3.8-5.2l4.4-4.7l2.3-2.3l2.4-2l2.5-2l2.6-1.9l2.6-1.8l2.8-1.5l2.9-1.5l3-1.4l3-1.1l3-1l3.1-0.9l3.3-0.8l3.3-0.6l3.3-0.4
l6.8-0.4l6.8,0.4l3.4,0.4l3.3,0.6l6.4,1.6l3,1l3,1.1l5.8,2.9l5.5,3.3l5.2,3.9l4.7,4.3l2.3,2.3l2,2.4l2,2.5l1.9,2.6l1.8,2.6
l1.5,2.9l1.5,2.8l1.4,3l1.1,3l1,3l1,3.3l0.6,3.1l0.6,3.3l0.4,3.4l0.4,6.8l-0.4,6.8l-0.4,3.3l-0.6,3.3l-1.6,6.4l-1,3l-1.1,3
l-2.9,5.9l-3.3,5.4l-3.9,5.2l-4.3,4.8l-2.3,2.1l-2.4,2.1l-2.5,1.9l-2.6,1.9l-2.6,1.8l-2.9,1.6l-2.8,1.4l-3,1.4l-3,1.1l-3,1.1
l-3.1,0.9l-3.3,0.8l-3.3,0.5l-3.4,0.5l-6.8,0.3l-6.8-0.3l-3.3-0.5l-3.3-0.5l-6.4-1.6l-3-1.1l-3-1.1l-5.9-2.8l-5.4-3.4l-5.2-3.8
l-4.6-4.3l-2.3-2.4l-2.1-2.4l-1.9-2.5l-1.9-2.6l-1.8-2.6l-1.6-2.8l-1.4-2.9l-1.4-3l-1.1-3l-1.1-3l-0.9-3.1l-0.8-3.3l-0.5-3.3
l-0.5-3.3L117.1,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M113.2,177.4l0.4-6.8l0.5-3.4l0.5-3.3l1.6-6.4l1.1-3l1.1-3l2.8-5.8
l3.4-5.5l3.8-5.2l4.3-4.7l2.4-2.3l2.4-2l2.5-2l2.6-1.9l2.6-1.8l2.8-1.5l2.9-1.5l2.9-1.4l3-1.1l3.1-1l3.1-0.9l3.3-0.8l3.3-0.6
l3.3-0.4l6.8-0.4l6.8,0.4l3.4,0.4l3.3,0.6l6.3,1.6l3.1,1l3,1.1l5.8,2.9l5.5,3.3l5.2,3.9l4.6,4.3l2.3,2.3l2,2.4l2,2.5l1.9,2.6
l1.6,2.6l1.6,2.9l1.5,2.8l1.3,3l1.3,3l1,3l0.9,3.3l0.8,3.1l0.6,3.3l0.4,3.4l0.4,6.8l-0.4,6.8l-0.4,3.3l-0.6,3.3l-1.6,6.4l-1,3
l-1.3,3l-2.8,5.9l-3.3,5.4l-3.9,5.2l-4.3,4.8l-2.3,2.1l-2.4,2.1l-2.5,1.9l-2.6,1.9l-2.8,1.8l-2.8,1.6l-2.9,1.4l-2.9,1.4l-3,1.1
l-3.1,1.1l-3.1,0.9l-3.1,0.8l-3.3,0.5l-3.4,0.5l-6.8,0.3l-6.8-0.3l-3.3-0.5l-3.3-0.5l-6.4-1.6l-3.1-1.1l-3-1.1l-5.8-2.8l-5.4-3.4
l-5.2-3.8l-4.8-4.3l-2.1-2.4l-2.1-2.4l-1.9-2.5l-1.9-2.6l-1.8-2.6l-1.6-2.8l-1.4-2.9l-1.4-3l-1.1-3l-1.1-3l-0.9-3.1l-0.8-3.3
l-0.5-3.3l-0.5-3.3L113.2,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.3,177.4l0.1-1.6v-1.6l0.1-1.6l0.1-1.6l0.1-1.5l0.3-1.6l0.6-3l0.6-3.1l0.5-1.5l0.4-1.5l0.5-1.4l0.5-1.5
l0.5-1.4l0.6-1.5l1.3-2.8l1.4-2.8l1.5-2.6l1.6-2.5l1.8-2.5l1-1.1l0.9-1.3l1-1.1l1-1.1l2.1-2.1l2.1-2.1l1.1-1l1.1-1l2.4-1.9
l2.5-1.8l2.5-1.6l2.6-1.5l2.8-1.4l1.4-0.6l1.4-0.6l1.5-0.6l1.4-0.5l2.9-1l1.5-0.4l1.5-0.5l1.5-0.4l1.6-0.3l3-0.6l3.1-0.4l3.3-0.3
l3.3-0.1l3.3,0.1l1.5,0.1l1.6,0.1l1.6,0.3l1.5,0.1l3.1,0.6l3,0.6l1.5,0.5l1.5,0.4l1.5,0.5l1.5,0.5l1.4,0.5l1.4,0.6l2.9,1.3
l2.6,1.4l2.6,1.5l2.6,1.6l2.4,1.8l1.3,1l1.1,0.9l1.3,1l1.1,1l2.1,2.1l2.1,2.1l1,1.1l0.9,1.1l1.9,2.4l1.8,2.5l1.6,2.5l1.5,2.6
l1.5,2.8l0.6,1.4l0.6,1.4l0.5,1.5l0.6,1.4l1,2.9l0.4,1.5l0.4,1.5l0.4,1.5l0.4,1.6l0.5,3l0.5,3.1l0.1,3.3l0.1,3.3v1.6l-0.1,1.6
v1.5l-0.1,1.6l-0.3,1.6l-0.3,1.5l-0.5,3.1l-0.8,3l-0.4,1.5l-0.4,1.5l-0.5,1.5l-0.5,1.5l-0.6,1.4l-0.5,1.4l-1.3,2.9l-1.5,2.6
l-1.5,2.6l-1.6,2.6l-1.8,2.4l-0.9,1.3l-1,1.1l-0.9,1.3l-1,1.1l-2.1,2.1l-2.1,2.1l-1.1,1l-1.3,0.9l-2.4,1.9l-2.4,1.8l-2.6,1.6
l-2.6,1.5l-2.6,1.5l-1.4,0.6l-1.5,0.6l-1.4,0.5l-1.4,0.6l-3,1l-1.5,0.4l-1.5,0.4l-1.5,0.4l-1.5,0.4l-3.1,0.5l-3.1,0.5l-3.1,0.1
l-3.3,0.1l-3.3-0.1h-1.6l-1.6-0.1l-1.5-0.3l-1.6-0.3l-3-0.5l-3.1-0.8l-1.5-0.4l-1.5-0.4l-1.4-0.5l-1.5-0.5l-1.4-0.6l-1.5-0.5
l-2.8-1.3l-2.8-1.5l-2.6-1.5l-2.5-1.6l-2.5-1.8l-1.1-0.9l-1.3-1l-1.1-0.9l-1.1-1l-2.1-2.1l-2.1-2.1l-1-1.1l-1-1.3l-1.9-2.4
l-1.8-2.4l-1.6-2.6l-1.5-2.6l-1.4-2.6l-0.6-1.4l-0.6-1.5l-0.6-1.4l-0.5-1.4l-1-3l-0.4-1.5l-0.5-1.5l-0.4-1.5l-0.3-1.5l-0.6-3.1
l-0.4-3.1l-0.3-3.1L120.3,177.4z M116.8,177.4v-1.6v-1.6l0.1-1.6l0.1-1.6l0.3-1.5l0.3-1.6l0.5-3l0.8-3.1l0.4-1.5l0.4-1.5l0.5-1.4
l0.5-1.5l0.6-1.4l0.5-1.5l1.3-2.8l1.4-2.8l1.6-2.6l1.6-2.5l1.8-2.5l0.9-1.1l0.9-1.3l1-1.1l1-1.1l2.1-2.1l2.1-2.1l1.1-1l1.3-1
l2.4-1.9l2.4-1.8l2.6-1.6l2.6-1.5l2.6-1.4l1.4-0.6l1.5-0.6l1.4-0.6l1.4-0.5l3-1l1.5-0.4l1.5-0.5l1.5-0.4l1.5-0.3l3.1-0.6l3.1-0.4
l3.1-0.3l3.3-0.1l3.3,0.1l1.6,0.1l1.5,0.1l1.6,0.3l1.6,0.1l3,0.6l3.1,0.6l1.5,0.5l1.4,0.4l1.5,0.5l1.5,0.5l1.4,0.5l1.5,0.6
l2.8,1.3l2.8,1.4l2.6,1.5l2.5,1.6l2.5,1.8l1.1,1l1.3,0.9l1.1,1l1.1,1l2.1,2.1l2.1,2.1l1,1.1l1,1.1l1.9,2.4l1.8,2.5l1.6,2.5
l1.5,2.6l1.4,2.8l0.6,1.4l0.6,1.4l0.6,1.5l0.5,1.4l1,2.9l0.4,1.5l0.5,1.5l0.4,1.5l0.3,1.6l0.6,3l0.4,3.1l0.3,3.3v3.3v1.6v1.6
l-0.1,1.5l-0.1,1.6l-0.3,1.6l-0.1,1.5l-0.6,3.1l-0.6,3l-0.5,1.5l-0.4,1.5l-0.5,1.5l-0.5,1.5l-0.5,1.4l-0.6,1.4l-1.3,2.9l-1.4,2.6
l-1.5,2.6l-1.6,2.6l-1.8,2.4l-1,1.3l-0.9,1.1l-1,1.3l-1,1.1l-2.1,2.1l-2.1,2.1l-1.1,1l-1.1,0.9l-2.4,1.9l-2.5,1.8l-2.5,1.6
l-2.6,1.5l-2.8,1.5l-1.4,0.6l-1.4,0.6l-1.5,0.5l-1.4,0.6l-3,1l-1.4,0.4l-1.5,0.4l-1.5,0.4l-1.6,0.4l-3,0.5l-3.3,0.5l-3.1,0.1
l-3.3,0.1l-3.3-0.1H175l-1.5-0.1l-1.6-0.3l-1.5-0.3l-3.1-0.5l-3-0.8l-1.5-0.4l-1.5-0.4l-1.5-0.5l-1.5-0.5l-1.4-0.6l-1.4-0.5
l-2.9-1.3l-2.6-1.5l-2.6-1.5l-2.6-1.6l-2.4-1.8l-1.3-0.9l-1.1-1l-1.3-0.9l-1.1-1l-2.1-2.1l-2.1-2.1l-1-1.1l-1-1.3l-1.8-2.4
l-1.8-2.4l-1.6-2.6l-1.6-2.6l-1.4-2.6l-0.6-1.4l-0.6-1.5l-0.5-1.4l-0.6-1.4l-1-3l-0.4-1.5l-0.4-1.5l-0.4-1.5l-0.4-1.5l-0.5-3.1
l-0.5-3.1l-0.3-3.1V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M120.3,177.4l0.4-6.5l0.4-3.1l0.6-3.1l1.5-6l2.1-5.8l1.3-2.8l1.4-2.8
l3.1-5.2l3.6-4.9l4-4.5l2.3-2l2.3-2l2.4-1.9l2.5-1.8l5.2-3.1l2.8-1.4l2.8-1.3l5.8-2.1l6-1.5l6.3-1l6.5-0.4l6.4,0.4l3.1,0.4
l3.1,0.6l6,1.5l5.8,2.1l2.8,1.3l2.8,1.4l5.3,3.1l4.8,3.6l4.5,4l2,2.3l2,2.3l1.9,2.4l1.8,2.5l3.1,5.2l1.4,2.8l1.4,2.8l2,5.8l1.6,6
l0.9,6.3l0.4,6.5l-0.4,6.4l-0.4,3.1l-0.5,3.1l-1.6,6l-2,5.8l-1.4,2.8l-1.4,2.8l-3.1,5.3l-3.6,4.8l-4,4.5l-2.3,2.1l-2.3,1.9
l-2.4,1.9l-2.4,1.8l-5.3,3.1l-2.8,1.4l-2.8,1.4l-5.8,2l-6,1.6l-6.3,0.9l-6.4,0.4l-6.5-0.4l-3.1-0.4l-3.1-0.5l-6-1.6l-5.8-2
l-2.8-1.4l-2.8-1.4l-5.2-3.1l-4.9-3.6l-4.5-4l-2-2.3l-2-2.3l-1.9-2.4l-1.8-2.4l-3.1-5.3l-1.4-2.8l-1.3-2.8l-2.1-5.8l-1.5-6
l-1-6.3L120.3,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M116.8,177.4l0.3-6.5l0.4-3.1l0.6-3.1l1.5-6l2.1-5.8l1.3-2.8l1.4-2.8
l3.1-5.2l3.6-4.9l4.1-4.5l2.1-2l2.3-2l2.4-1.9l2.5-1.8l5.3-3.1l2.6-1.4l2.8-1.3l5.9-2.1l6-1.5l6.3-1l6.4-0.4l6.4,0.4l3.1,0.4
l3.1,0.6l6,1.5l5.9,2.1l2.8,1.3l2.6,1.4l5.3,3.1l4.9,3.6l4.4,4l2.1,2.3l2,2.3l1.9,2.4l1.8,2.5l3.1,5.2l1.4,2.8l1.3,2.8l2.1,5.8
l1.5,6l1,6.3l0.3,6.5l-0.3,6.4l-0.4,3.1l-0.6,3.1l-1.5,6l-2.1,5.8l-1.3,2.8l-1.4,2.8l-3.1,5.3l-3.6,4.8l-4.1,4.5l-2.1,2.1
l-2.3,1.9l-2.4,1.9l-2.5,1.8l-5.3,3.1l-2.6,1.4l-2.8,1.4l-5.9,2l-6,1.6l-6.3,0.9l-6.4,0.4l-6.4-0.4l-3.3-0.4l-3-0.5l-6-1.6
l-5.9-2l-2.8-1.4l-2.6-1.4l-5.3-3.1l-4.9-3.6l-4.4-4l-2.1-2.3l-2-2.3l-1.9-2.4l-1.8-2.4l-3.1-5.3l-1.4-2.8l-1.3-2.8l-2.1-5.8
l-1.5-6l-1-6.3L116.8,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.4,177.4v-3.1l0.3-3l0.4-3l0.3-1.5l0.4-1.4l0.3-1.5l0.4-1.4l0.4-1.5l0.4-1.4l1-2.8l1-2.8l1.3-2.6
l0.6-1.3l0.6-1.4l1.5-2.5l0.8-1.1l0.8-1.3l1.8-2.4l0.9-1.1l0.9-1.1l0.9-1l1-1.1l2-2.1l2-1.9l2.3-1.9l2.3-1.9l1.1-0.8l1.1-0.9
l2.5-1.5l2.5-1.5l1.3-0.6l1.3-0.6l2.6-1.3l2.8-1l1.4-0.5l1.4-0.5l2.9-0.8l1.4-0.4l1.5-0.4l2.9-0.5l1.5-0.3l1.5-0.1l1.5-0.1
l1.6-0.1h1.5h1.5h3.1l3,0.3l3,0.4l1.5,0.3l1.4,0.3l1.5,0.4l1.4,0.4l1.5,0.4l1.4,0.4l2.8,1l2.8,1l2.6,1.3l1.3,0.6l1.4,0.6l2.5,1.5
l1.1,0.8l1.3,0.8l2.4,1.6l1.1,0.9l1.1,1l1,0.9l1.1,1l2.1,1.9l1.9,2.1l1.9,2.1l1.8,2.3l0.9,1.3l0.9,1.1l1.5,2.4l1.5,2.5l0.6,1.4
l0.6,1.3l1.3,2.6l1,2.8l0.5,1.4l0.5,1.4l0.8,2.9l0.4,1.4l0.4,1.5l0.5,2.9l0.1,1.5l0.3,1.5l0.1,1.5l0.1,1.5v1.5v1.6v3l-0.3,3.1
l-0.4,2.9l-0.3,1.5l-0.3,1.5l-0.4,1.5l-0.4,1.4l-0.4,1.4l-0.4,1.5l-1,2.8l-1,2.8l-1.3,2.6l-0.6,1.3l-0.6,1.3l-1.5,2.5l-0.8,1.3
l-0.8,1.3l-1.8,2.3l-0.9,1.1l-0.9,1.1l-0.9,1.1l-1,1.1l-1.9,2l-2.1,2l-2.1,1.9l-2.3,1.8l-1.3,0.9l-1.1,0.9l-2.4,1.5l-2.5,1.4
l-1.4,0.8l-1.3,0.6l-2.6,1.3l-2.8,1l-1.4,0.5l-1.4,0.5l-2.9,0.8l-1.4,0.4l-1.5,0.3l-2.9,0.6l-1.5,0.1l-1.5,0.3l-1.5,0.1h-1.5
l-1.6,0.1h-1.5l-3-0.1l-3.1-0.1l-3-0.4l-1.4-0.3l-1.5-0.4l-1.5-0.3l-1.4-0.4l-1.5-0.4l-1.4-0.4l-2.8-1l-2.8-1l-2.6-1.3l-1.3-0.6
l-1.3-0.8l-2.5-1.4l-1.3-0.8l-1.3-0.8l-2.3-1.8l-1.3-0.9l-1-0.9l-1.1-0.9l-1.1-1l-2-2l-2-2l-1.9-2.3l-1.8-2.3l-0.9-1.1l-0.9-1.1
l-1.5-2.5l-1.5-2.5l-0.6-1.3l-0.6-1.3l-1.3-2.6l-1-2.8l-0.5-1.4l-0.5-1.4l-0.8-2.9l-0.4-1.4l-0.3-1.5l-0.6-3l-0.1-1.4l-0.3-1.5
l-0.1-1.5l-0.1-1.6v-1.5V177.4z M120,177.4v-3.1l0.3-3l0.4-3l0.3-1.5l0.3-1.4l0.4-1.5l0.4-1.4l0.4-1.5l0.4-1.4l1-2.8l1-2.8
l1.3-2.6l0.6-1.3l0.6-1.4l1.5-2.5l0.8-1.1l0.8-1.3l1.6-2.4l0.9-1.1l1-1.1l0.9-1l1-1.1l1.9-2.1l2.1-1.9l2.1-1.9l2.3-1.9l1.3-0.8
l1.1-0.9l2.4-1.5l2.5-1.5l1.4-0.6l1.3-0.6l2.6-1.3l2.8-1l1.4-0.5l1.4-0.5l2.9-0.8l1.4-0.4l1.5-0.4l2.9-0.5l1.5-0.3l1.5-0.1
l1.5-0.1l1.5-0.1h1.6h1.5h3l3.1,0.3l3,0.4l1.4,0.3l1.5,0.3l1.5,0.4l1.4,0.4l1.4,0.4l1.5,0.4l2.8,1l2.8,1l2.6,1.3l1.3,0.6l1.3,0.6
l2.5,1.5l1.3,0.8l1.3,0.8l2.3,1.6l1.1,0.9l1.1,1l1.1,0.9l1.1,1l2,1.9l2,2.1l1.9,2.1l1.8,2.3l0.9,1.3l0.9,1.1l1.5,2.4l1.4,2.5
l0.8,1.4l0.6,1.3l1.3,2.6l1,2.8l0.5,1.4l0.5,1.4l0.8,2.9l0.4,1.4l0.3,1.5l0.6,2.9l0.1,1.5l0.3,1.5l0.1,1.5v1.5l0.1,1.5v1.6
l-0.1,3l-0.1,3.1l-0.4,2.9l-0.3,1.5l-0.4,1.5l-0.3,1.5l-0.4,1.4l-0.4,1.4l-0.4,1.5l-1,2.8l-1,2.8l-1.3,2.6l-0.6,1.3l-0.8,1.3
l-1.4,2.5l-0.8,1.3l-0.8,1.3l-1.8,2.3l-0.9,1.1l-0.9,1.1l-0.9,1.1l-1,1.1l-2,2l-2,2l-2.3,1.9l-2.3,1.8l-1.1,0.9l-1.1,0.9
l-2.5,1.5l-2.5,1.4l-1.3,0.8l-1.3,0.6l-2.6,1.3l-2.8,1l-1.4,0.5l-1.4,0.5l-2.9,0.8l-1.4,0.4l-1.5,0.3l-2.9,0.6l-1.5,0.1l-1.5,0.3
l-1.5,0.1H183l-1.5,0.1H180l-3.1-0.1l-3-0.1l-3-0.4l-1.5-0.3l-1.4-0.4l-1.5-0.3l-1.4-0.4l-1.5-0.4l-1.4-0.4l-2.8-1l-2.8-1
l-2.6-1.3l-1.3-0.6l-1.4-0.8l-2.5-1.4l-1.1-0.8l-1.3-0.8l-2.4-1.8l-1.1-0.9l-1.1-0.9l-1-0.9l-1.1-1l-2.1-2l-1.9-2l-1.9-2.3
l-1.9-2.3l-0.8-1.1l-0.9-1.1l-1.5-2.5l-1.5-2.5l-0.6-1.3l-0.6-1.3l-1.3-2.6l-1-2.8l-0.5-1.4l-0.5-1.4l-0.8-2.9l-0.4-1.4l-0.4-1.5
l-0.5-3l-0.3-1.4l-0.1-1.5l-0.1-1.5l-0.1-1.6v-1.5V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M123.4,177.4l0.3-6.2l0.9-5.9l0.8-3l0.8-2.8l2-5.5l2.5-5.3l1.5-2.5
l1.5-2.4l3.5-4.7l3.9-4.3l4.1-3.9l2.3-1.8l2.4-1.6l4.9-3l5.3-2.5l5.5-2l2.9-0.9l2.9-0.6l2.9-0.5l3-0.4l3-0.3l3.1-0.1l6.2,0.4
l5.9,0.9l2.9,0.6l2.9,0.9l5.5,2l5.2,2.5l2.5,1.5l2.5,1.5l4.5,3.4l4.3,3.9l3.9,4.3l1.8,2.3l1.8,2.4l3,4.9l2.5,5.3l2,5.5l0.8,2.8
l0.6,3l0.6,2.9l0.4,3l0.3,3v3.1l-0.3,6l-1,6l-0.6,2.9l-0.8,2.9l-2,5.4l-2.5,5.3l-1.5,2.5l-1.5,2.4l-3.5,4.7l-3.9,4.3l-4.3,3.9
l-2.3,1.8l-2.3,1.6l-5,3l-5.2,2.5l-5.5,2l-2.9,0.9l-2.9,0.6l-2.9,0.5l-3,0.4l-3,0.3l-3.1,0.1l-6.2-0.4l-5.9-0.9l-2.9-0.6
l-2.9-0.9l-5.5-2l-5.3-2.5l-2.5-1.4l-2.4-1.6l-4.7-3.4l-4.1-3.9l-3.9-4.3l-1.8-2.3l-1.8-2.4l-3-4.9l-2.5-5.3l-2-5.4l-0.8-2.9
l-0.8-2.9l-0.5-3l-0.4-3l-0.3-3V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M119.9,177.4l0.4-6.2l0.9-5.9l0.8-3l0.8-2.8l2-5.5l2.5-5.3l1.5-2.5
l1.5-2.4l3.4-4.7l3.9-4.3l4.3-3.9l2.3-1.8l2.4-1.6l4.9-3l5.3-2.5l5.5-2l2.8-0.9l3-0.6l2.9-0.5l3-0.4l3-0.3l3.1-0.1l6,0.4l6,0.9
l2.9,0.6l2.9,0.9l5.4,2l5.3,2.5l2.5,1.5l2.5,1.5l4.5,3.4l4.3,3.9l3.9,4.3l1.8,2.3l1.6,2.4l3,4.9l2.5,5.3l2,5.5l0.9,2.8l0.6,3
l0.5,2.9l0.4,3l0.3,3l0.1,3.1l-0.4,6l-0.9,6l-0.6,2.9l-0.9,2.9l-2,5.4l-2.5,5.3l-1.4,2.5l-1.6,2.4l-3.4,4.7l-3.9,4.3l-4.3,3.9
l-2.3,1.8l-2.3,1.6l-5,3l-5.3,2.5l-5.4,2l-2.9,0.9l-2.9,0.6l-3,0.5l-3,0.4l-3,0.3l-3,0.1l-6.2-0.4l-5.9-0.9l-3-0.6l-2.8-0.9
l-5.5-2l-5.3-2.5l-2.5-1.4l-2.4-1.6l-4.7-3.4l-4.3-3.9l-3.9-4.3l-1.8-2.3l-1.6-2.4l-3-4.9l-2.5-5.3l-2-5.4l-0.8-2.9l-0.8-2.9
l-0.5-3l-0.4-3l-0.3-3L119.9,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.3,177.4l0.1-3l0.1-2.9l0.4-2.8l0.5-2.9l0.6-2.6l0.8-2.8l0.5-1.4l0.5-1.3l1-2.6l1.1-2.5l1.3-2.4l1.4-2.4
l1.4-2.4l0.9-1.1l0.8-1l1.8-2.3l1.8-2l1.9-2l2-1.9l1-0.9l1-0.9l2.1-1.6l2.3-1.6l2.3-1.5l2.4-1.4l2.5-1.3l1.3-0.5l1.3-0.6l2.6-1
l2.6-0.9l1.3-0.4l1.4-0.4l2.8-0.6l2.8-0.5l2.9-0.4l2.9-0.3h1.5l1.4-0.1l3,0.1l2.9,0.3l2.8,0.4l2.9,0.5l2.8,0.6l2.6,0.8l1.4,0.4
l1.3,0.5l2.6,1l2.5,1.1l2.4,1.3l2.4,1.4l2.4,1.5l1.1,0.8l1.1,0.9l2.1,1.6l2,1.8l2,1.9l1.9,2l0.9,1l0.9,1l1.8,2.3l1.5,2.1l1.5,2.4
l1.4,2.4l1.3,2.4l0.6,1.3l0.5,1.3l1,2.6l0.9,2.6l0.5,1.4l0.3,1.4l0.8,2.6l0.5,2.9l0.3,2.8l0.3,2.9l0.1,1.5v1.5l-0.1,2.9l-0.3,2.9
l-0.3,2.9l-0.5,2.8l-0.8,2.8l-0.8,2.8l-0.4,1.3l-0.5,1.4l-1,2.5l-1.1,2.5l-1.3,2.5l-1.4,2.4l-1.5,2.3l-0.8,1.1l-0.8,1.1l-1.8,2.1
l-1.8,2.1l-1.9,1.9l-2,1.9l-1,1l-1,0.9l-2.1,1.6l-2.3,1.6l-2.4,1.5l-2.4,1.3l-2.4,1.3l-1.3,0.6l-1.3,0.6l-2.6,1l-2.6,0.9
l-1.4,0.4l-1.3,0.4l-2.8,0.6l-2.9,0.5l-2.8,0.4l-2.9,0.3h-1.5h-1.5h-2.9l-2.9-0.3l-2.9-0.4l-2.8-0.5l-2.8-0.6l-2.6-0.8l-1.4-0.5
l-1.3-0.4l-2.6-1l-2.5-1.3l-2.5-1.3l-2.4-1.3l-2.3-1.5l-1.1-0.8l-1.1-0.9l-2.1-1.6l-2-1.9l-2-1.9l-1.9-1.9l-0.9-1.1l-0.9-1
l-1.8-2.1l-1.6-2.3l-1.4-2.3l-1.4-2.4L132,202l-0.6-1.3l-0.5-1.3l-1-2.5l-1-2.6l-0.4-1.4l-0.4-1.4l-0.6-2.8l-0.5-2.8l-0.4-2.9
l-0.1-2.9l-0.1-1.4V177.4z M123.1,177.4l0.1-3l0.3-2.9l0.3-2.8l0.5-2.9l0.6-2.6l0.9-2.8l0.4-1.4l0.5-1.3l1-2.6l1.1-2.5l1.3-2.4
l1.4-2.4l1.5-2.4l0.8-1.1l0.8-1l1.8-2.3l1.8-2l1.9-2l2-1.9l1-0.9l1-0.9l2.1-1.6l2.3-1.6l2.3-1.5l2.4-1.4l2.5-1.3l1.3-0.5l1.3-0.6
l2.6-1l2.6-0.9l1.3-0.4l1.4-0.4l2.8-0.6l2.8-0.5l2.9-0.4l2.9-0.3h1.5l1.5-0.1l2.9,0.1l2.9,0.3l2.9,0.4l2.8,0.5l2.8,0.6l2.6,0.8
l1.4,0.4l1.3,0.5l2.6,1l2.5,1.1l2.5,1.3l2.4,1.4l2.3,1.5l1.1,0.8l1.1,0.9l2.1,1.6l2,1.8l2,1.9l1.9,2l0.9,1l0.9,1l1.8,2.3l1.5,2.1
l1.5,2.4l1.4,2.4l1.3,2.4l0.6,1.3l0.5,1.3l1,2.6l0.9,2.6l0.5,1.4l0.4,1.4l0.6,2.6l0.5,2.9l0.4,2.8l0.1,2.9l0.1,1.5v1.5l-0.1,2.9
l-0.1,2.9l-0.4,2.9l-0.5,2.8l-0.6,2.8l-0.9,2.8l-0.4,1.3l-0.5,1.4l-1,2.5l-1.1,2.5l-1.3,2.5l-1.4,2.4l-1.5,2.3l-0.8,1.1l-0.8,1.1
l-1.8,2.1l-1.8,2.1l-1.9,1.9l-2,1.9l-1,1l-1,0.9l-2.1,1.6l-2.3,1.6l-2.3,1.5l-2.4,1.3l-2.5,1.3l-1.3,0.6l-1.3,0.6l-2.6,1
l-2.6,0.9l-1.3,0.4l-1.4,0.4l-2.8,0.6l-2.8,0.5l-2.9,0.4l-2.9,0.3h-1.5h-1.4h-3l-2.9-0.3l-2.9-0.4l-2.8-0.5l-2.8-0.6l-2.6-0.8
l-1.4-0.5l-1.3-0.4l-2.6-1l-2.5-1.3l-2.5-1.3l-2.4-1.3l-2.3-1.5l-1.1-0.8l-1.1-0.9l-2.1-1.6l-2-1.9l-2-1.9l-1.9-1.9l-0.9-1.1
l-0.9-1l-1.8-2.1l-1.5-2.3l-1.5-2.3l-1.4-2.4l-1.3-2.5l-0.6-1.3l-0.5-1.3l-1-2.5l-0.9-2.6l-0.5-1.4l-0.4-1.4l-0.6-2.8l-0.5-2.8
l-0.3-2.9l-0.3-2.9l-0.1-1.4V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M126.3,177.4l0.3-5.9l0.9-5.7l1.4-5.4l2-5.3l2.4-4.9l2.8-4.8l3.3-4.4
l3.8-4l4-3.6l4.4-3.3l4.6-2.9l5-2.4l2.5-1l2.6-0.9l5.5-1.4l5.7-0.9l2.9-0.3l2.9-0.1l5.9,0.4l5.7,0.9l5.4,1.4l5.3,1.9l4.9,2.4
l4.8,2.9l4.4,3.3l4,3.6l3.6,4l3.3,4.4l2.9,4.8l2.4,4.9l1,2.6l0.9,2.6l1.4,5.4l0.9,5.7l0.3,2.9l0.1,3l-0.4,5.8l-0.9,5.7l-1.4,5.4
l-1.9,5.3l-2.4,5l-2.9,4.7l-3.3,4.4l-3.6,4l-4,3.6l-4.4,3.4l-4.8,2.8l-4.9,2.4l-2.6,1.1l-2.6,0.9l-5.4,1.4l-5.7,0.9l-2.9,0.1
l-3,0.1l-5.8-0.3l-5.7-0.9l-5.5-1.4l-5.2-2l-5-2.4l-4.6-2.8l-4.4-3.4l-4-3.6l-3.8-4l-3.3-4.4l-2.8-4.7l-2.4-5l-1.1-2.5l-0.9-2.8
l-1.4-5.4l-0.9-5.7l-0.1-2.9L126.3,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M123.1,177.4l0.3-5.9l0.9-5.7l1.4-5.4l2-5.3l2.4-4.9l2.8-4.8l3.4-4.4
l3.6-4l4-3.6l4.4-3.3l4.7-2.9l5-2.4l2.6-1l2.6-0.9l5.4-1.4l5.7-0.9l2.9-0.3l2.9-0.1l5.9,0.4l5.7,0.9l5.4,1.4l5.3,1.9l4.9,2.4
l4.8,2.9l4.4,3.3l4,3.6l3.6,4l3.3,4.4l2.9,4.8l2.4,4.9l1,2.6l0.9,2.6l1.5,5.4l0.8,5.7l0.3,2.9l0.1,3l-0.4,5.8l-0.8,5.7l-1.5,5.4
l-1.9,5.3l-2.4,5l-2.9,4.7l-3.3,4.4l-3.6,4l-4,3.6l-4.4,3.4l-4.8,2.8l-4.9,2.4l-2.6,1.1l-2.6,0.9l-5.4,1.4l-5.7,0.9l-2.9,0.1
l-3,0.1l-5.8-0.3l-5.7-0.9l-5.4-1.4l-5.3-2l-5-2.4l-4.7-2.8l-4.4-3.4l-4-3.6l-3.6-4l-3.4-4.4l-2.8-4.7l-2.4-5l-1-2.5l-1-2.8
l-1.4-5.4l-0.9-5.7l-0.1-2.9L123.1,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M129.1,177.4l0.1-2.8v-1.4l0.1-1.4l0.4-2.8l0.3-1.3l0.3-1.4l0.6-2.6l0.6-2.5l0.5-1.3l0.4-1.3l1-2.5l1.1-2.4
l1.1-2.3l1.3-2.4l0.8-1l0.8-1.1l1.5-2.1l1.5-2l1.8-2l1.8-1.9l1-0.9l0.9-0.9l1-0.9l1-0.9l1-0.8l1-0.8l1-0.8l1.1-0.8l2.1-1.5
l2.3-1.3l2.4-1.1l2.4-1.1l2.5-1l1.3-0.4l1.3-0.5l2.5-0.6l2.6-0.6l1.4-0.3l1.3-0.3l2.8-0.4l2.8-0.1l2.8-0.1l2.8,0.1h1.4l1.4,0.1
l2.6,0.4l1.4,0.3l1.4,0.3l2.5,0.6l2.6,0.6l1.3,0.5l1.3,0.4l2.4,1l2.4,1.1l2.4,1.1l2.3,1.3l1.1,0.8l1.1,0.8l2,1.5l2.1,1.5l1.9,1.8
l1.9,1.8l0.9,1l1,0.9l0.8,1l0.9,1l0.9,1l0.8,1l0.8,1l0.8,1.1l1.4,2.1l1.3,2.4l1.3,2.3l1.1,2.4l0.9,2.5l0.5,1.3l0.4,1.3l0.8,2.5
l0.6,2.6l0.3,1.4l0.1,1.3l0.4,2.8l0.3,2.8v2.8v2.8l-0.1,1.4l-0.1,1.4l-0.4,2.6l-0.1,1.4l-0.3,1.4l-0.6,2.5l-0.8,2.6l-0.4,1.3
L234,196l-0.9,2.4l-1.1,2.5l-1.3,2.3l-1.3,2.3l-0.6,1.1l-0.8,1.1l-1.5,2l-1.6,2.1l-1.6,1.9l-1.9,1.9l-0.9,1l-1,0.9l-0.9,0.8
l-1,0.9l-1,0.9l-1.1,0.8l-1,0.8l-1,0.8l-2.3,1.4l-2.3,1.3l-2.4,1.3l-2.4,1.1l-2.4,0.9l-1.3,0.5l-1.3,0.4l-2.6,0.8l-2.5,0.6
l-1.4,0.3l-1.4,0.3l-2.6,0.3l-2.8,0.3h-2.8h-2.8l-1.4-0.1l-1.4-0.1l-2.8-0.3l-1.3-0.3l-1.4-0.3l-2.6-0.6l-2.5-0.8l-1.3-0.4
l-1.3-0.5l-2.5-0.9l-2.4-1.1l-2.4-1.3l-2.3-1.3l-1-0.6l-1.1-0.8l-2.1-1.5l-2-1.6l-2-1.6l-1.9-1.9l-0.9-0.9l-0.9-1l-0.9-0.9
l-0.9-1l-0.8-1l-0.8-1.1l-0.8-1l-0.8-1l-1.5-2.3l-1.3-2.3l-1.1-2.3l-1.1-2.5l-1-2.4l-0.4-1.3l-0.5-1.3l-0.6-2.6l-0.6-2.5
l-0.3-1.4l-0.3-1.4l-0.4-2.6l-0.1-2.8L129.1,177.4z M125.9,177.4l0.1-2.8l0.1-1.4l0.1-1.4l0.3-2.8l0.3-1.3l0.3-1.4l0.6-2.6
l0.8-2.5l0.4-1.3l0.5-1.3l0.9-2.5l1.1-2.4l1.1-2.3l1.4-2.4l0.6-1l0.8-1.1l1.5-2.1l1.6-2l1.6-2l1.9-1.9l0.9-0.9l1-0.9l0.9-0.9
l1-0.9l1-0.8l1-0.8l1.1-0.8l1-0.8l2.3-1.5l2.3-1.3l2.3-1.1l2.5-1.1l2.4-1l1.3-0.4l1.3-0.5l2.6-0.6l2.5-0.6l1.4-0.3l1.4-0.3
l2.6-0.4l2.8-0.1l2.8-0.1l2.8,0.1h1.4l1.4,0.1l2.8,0.4l1.3,0.3l1.4,0.3l2.6,0.6l2.5,0.6l1.3,0.5l1.3,0.4l2.5,1l2.4,1.1l2.3,1.1
l2.3,1.3l1.1,0.8l1.1,0.8l2.1,1.5l2,1.5l2,1.8l1.9,1.8l0.9,1l0.9,0.9l0.9,1l0.8,1l0.9,1l0.8,1l0.8,1l0.8,1.1l1.4,2.1l1.4,2.4
l1.1,2.3l1.1,2.4l1,2.5l0.4,1.3l0.4,1.3l0.8,2.5l0.6,2.6l0.3,1.4l0.3,1.3l0.4,2.8l0.1,2.8l0.1,2.8l-0.1,2.8v1.4l-0.1,1.4
l-0.4,2.6l-0.3,1.4l-0.3,1.4l-0.6,2.5l-0.8,2.6l-0.4,1.3L231,196l-1,2.4l-1.1,2.5l-1.1,2.3l-1.4,2.3l-0.6,1.1l-0.8,1.1l-1.5,2
l-1.6,2.1l-1.6,1.9l-1.8,1.9l-1,1l-0.9,0.9l-1,0.8l-1,0.9l-1,0.9l-1,0.8l-1.1,0.8l-1,0.8l-2.3,1.4l-2.3,1.3l-2.3,1.3l-2.4,1.1
l-2.5,0.9l-1.3,0.5l-1.3,0.4l-2.5,0.8l-2.6,0.6l-1.4,0.3l-1.3,0.3l-2.8,0.3l-2.8,0.3h-2.8h-2.8l-1.4-0.1l-1.4-0.1l-2.6-0.3
l-1.4-0.3l-1.4-0.3l-2.5-0.6L164,229l-1.3-0.4l-1.3-0.5l-2.4-0.9l-2.5-1.1l-2.3-1.3l-2.3-1.3L151,223l-1.1-0.8l-2.1-1.5l-2-1.6
l-1.9-1.6l-1.9-1.9l-1-0.9l-0.9-1l-0.9-0.9l-0.8-1l-0.9-1l-0.8-1.1l-0.8-1l-0.8-1l-1.4-2.3l-1.4-2.3l-1.1-2.3l-1.1-2.5l-0.9-2.4
l-0.5-1.3l-0.4-1.3l-0.8-2.6l-0.6-2.5l-0.3-1.4l-0.3-1.4l-0.3-2.6l-0.3-2.8L125.9,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M129.1,177.4l0.3-5.5l0.4-2.8l0.5-2.6l0.5-2.6l0.8-2.5l1.9-5l2.3-4.8
l2.6-4.4l1.5-2.1l1.6-2l3.5-3.9l3.9-3.5l4.1-3l4.4-2.8l2.4-1.3l2.4-1l5-1.9l5.2-1.3l5.4-0.9l5.5-0.3l5.5,0.3l2.6,0.4l2.6,0.5
l2.6,0.6l2.6,0.6l4.9,1.9l4.8,2.3l4.4,2.8l2.1,1.5l2.1,1.5l3.8,3.5l3.5,3.9l3.1,4.1l2.6,4.4l1.3,2.4l1,2.4l1.9,5l1.4,5.2l0.8,5.4
l0.3,5.5l-0.3,5.5l-0.4,2.6l-0.4,2.6l-0.6,2.6l-0.8,2.6l-1.9,4.9l-2.3,4.8l-2.6,4.4l-1.5,2.1l-1.6,2.1l-3.5,3.8l-3.8,3.5
l-4.3,3.1l-4.4,2.6l-2.4,1.3l-2.4,1.1l-4.9,1.8l-5.3,1.4l-5.3,0.8l-5.5,0.3l-5.5-0.3l-2.8-0.4l-2.6-0.4l-2.6-0.6l-2.5-0.8l-5-1.8
l-4.8-2.4l-4.4-2.6l-2.1-1.5l-2-1.6l-3.9-3.5l-3.5-3.8l-3.1-4.3l-2.6-4.4l-1.3-2.4l-1-2.4l-1.9-4.9l-1.3-5.3l-0.9-5.3
L129.1,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M125.9,177.4l0.4-5.5l0.3-2.8l0.5-2.6l0.6-2.6l0.8-2.5l1.8-5l2.3-4.8
l2.8-4.4l1.5-2.1l1.6-2l3.4-3.9l3.9-3.5l4.1-3l4.5-2.8l2.3-1.3l2.4-1l5-1.9l5.2-1.3l5.4-0.9l5.5-0.3l5.5,0.3l2.8,0.4l2.6,0.5
l2.6,0.6l2.5,0.6l5,1.9l4.7,2.3l4.5,2.8l2.1,1.5l2,1.5l3.9,3.5l3.4,3.9l3.1,4.1l2.8,4.4l1.1,2.4l1.1,2.4l1.8,5l1.4,5.2l0.8,5.4
l0.4,5.5l-0.4,5.5l-0.3,2.6l-0.5,2.6l-0.6,2.6l-0.8,2.6l-1.8,4.9l-2.3,4.8l-2.8,4.4l-1.5,2.1l-1.6,2.1l-3.4,3.8l-3.9,3.5
l-4.1,3.1l-4.5,2.6l-2.3,1.3l-2.4,1.1l-5,1.8l-5.2,1.4l-5.4,0.8l-5.5,0.3l-5.5-0.3l-2.8-0.4l-2.6-0.4l-2.6-0.6L164,229l-5-1.8
l-4.7-2.4l-4.5-2.6l-2.1-1.5l-2-1.6l-3.9-3.5l-3.4-3.8l-3.1-4.3l-2.8-4.4l-1.1-2.4l-1.1-2.4l-1.8-4.9l-1.4-5.3l-0.8-5.3
L125.9,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M131.7,177.4l0.1-2.6l0.1-2.6l0.4-2.6l0.4-2.5l0.3-1.3l0.4-1.1l0.6-2.5l0.9-2.4l0.9-2.3l0.5-1.3l0.5-1.1
l1.1-2.1l1.3-2.1l1.4-2.1l1.4-2l1.5-1.9l1.6-1.9l1.8-1.9l0.9-0.8l0.9-0.9l0.9-0.9l1-0.8l1.9-1.5l2-1.4l2.1-1.4l1-0.6l1.1-0.6
l1.1-0.6l1.1-0.5l2.3-1l2.3-1l2.4-0.8l2.5-0.6l2.5-0.6l2.5-0.5l1.3-0.1l1.3-0.1l2.6-0.3h2.6h2.6l2.6,0.3l2.5,0.3l2.6,0.5l1.3,0.3
l1.1,0.4l2.5,0.6l2.4,0.8l2.3,1l1.3,0.5l1.1,0.5l2.1,1.1l2.1,1.3l2.1,1.4l2,1.4l1.9,1.5l1.9,1.6l1.8,1.6l0.9,1l0.9,0.9l0.8,0.9
l0.9,1l1.5,1.9l1.4,2l1.4,2.1l0.6,1l0.6,1.1l0.6,1.1l0.5,1l1,2.4l0.9,2.3l0.9,2.4l0.6,2.5l0.6,2.4l0.5,2.5l0.1,1.4l0.1,1.3
l0.3,2.6v2.6v2.6l-0.3,2.6l-0.3,2.5l-0.5,2.5l-0.3,1.3l-0.4,1.3l-0.6,2.4l-0.9,2.4l-0.9,2.4l-0.5,1.1l-0.5,1.1l-1.1,2.3l-1.3,2.1
l-1.4,2.1l-1.4,2l-1.5,1.9l-1.6,1.9l-1.8,1.8l-0.9,0.9l-0.9,0.9l-0.9,0.8l-1,0.9l-1.9,1.5l-2,1.4l-2.1,1.4l-1,0.6l-1.1,0.6
l-1.1,0.5l-1,0.6l-2.4,1l-2.3,0.9l-2.4,0.9l-2.5,0.6l-2.4,0.6l-2.6,0.4l-1.3,0.3l-1.3,0.1l-2.6,0.1l-2.6,0.1l-2.6-0.1l-2.6-0.1
l-2.5-0.4l-2.5-0.4l-1.3-0.4l-1.3-0.3l-2.5-0.6l-2.4-0.9l-2.3-0.9l-1.1-0.5l-1.1-0.5l-2.3-1.1l-2.1-1.3l-2.1-1.4l-2-1.4l-1.9-1.5
l-1.9-1.6l-1.8-1.8l-0.9-0.9l-0.9-0.9l-0.8-0.9l-0.9-1l-1.5-1.9l-1.4-2l-1.4-2.1l-0.6-1.1l-0.6-1l-0.5-1.1l-0.6-1.1l-1-2.3
l-0.9-2.4l-0.9-2.4l-0.6-2.4l-0.6-2.5l-0.4-2.5l-0.3-1.3l-0.1-1.3l-0.1-2.6L131.7,177.4z M128.8,177.4v-2.6l0.3-2.6l0.3-2.6
l0.5-2.5l0.3-1.3l0.4-1.1l0.6-2.5l0.8-2.4l1-2.3l0.5-1.3l0.5-1.1l1.1-2.1l1.3-2.1l1.4-2.1l1.4-2l1.5-1.9l1.6-1.9l1.6-1.9l0.9-0.8
l1-0.9l0.9-0.9l1-0.8l1.9-1.5l2-1.4l2.1-1.4l1-0.6l1.1-0.6l1.1-0.6l1-0.5l2.4-1l2.3-1l2.4-0.8l2.5-0.6l2.4-0.6l2.5-0.5l1.4-0.1
l1.3-0.1l2.6-0.3h2.6h2.6l2.6,0.3l2.5,0.3l2.5,0.5l1.3,0.3l1.3,0.4l2.4,0.6l2.4,0.8l2.4,1l1.1,0.5l1.1,0.5l2.3,1.1l2.1,1.3
l2.1,1.4l2,1.4l1.9,1.5l1.9,1.6l1.8,1.6l0.9,1l0.9,0.9l0.8,0.9l0.8,1l1.6,1.9l1.4,2l1.4,2.1l0.6,1l0.6,1.1l0.5,1.1l0.6,1l1,2.4
l0.9,2.3l0.9,2.4l0.6,2.5l0.6,2.4l0.4,2.5l0.3,1.4l0.1,1.3l0.1,2.6l0.1,2.6l-0.1,2.6l-0.1,2.6l-0.4,2.5l-0.4,2.5l-0.4,1.3
l-0.3,1.3l-0.6,2.4l-0.9,2.4l-0.9,2.4l-0.5,1.1l-0.5,1.1l-1.1,2.3l-1.3,2.1l-1.4,2.1l-1.4,2l-1.6,1.9l-1.5,1.9l-1.8,1.8l-0.9,0.9
l-0.9,0.9l-0.9,0.8l-1,0.9l-1.9,1.5l-2,1.4l-2.1,1.4l-1.1,0.6l-1,0.6l-1.1,0.5l-1.1,0.6l-2.3,1l-2.4,0.9l-2.4,0.9l-2.4,0.6
l-2.5,0.6l-2.5,0.4l-1.3,0.3l-1.3,0.1l-2.6,0.1l-2.6,0.1l-2.6-0.1l-2.6-0.1l-2.6-0.4l-2.5-0.4l-1.3-0.4l-1.1-0.3l-2.5-0.6
l-2.4-0.9l-2.3-0.9l-1.3-0.5l-1.1-0.5l-2.1-1.1l-2.1-1.3l-2.1-1.4l-2-1.4l-1.9-1.5l-1.9-1.6l-1.9-1.8l-0.8-0.9l-0.9-0.9l-0.9-0.9
l-0.8-1l-1.5-1.9l-1.4-2l-1.4-2.1l-0.6-1.1l-0.6-1l-0.6-1.1l-0.5-1.1l-1-2.3l-1-2.4l-0.8-2.4l-0.6-2.4l-0.6-2.5l-0.5-2.5
l-0.1-1.3l-0.1-1.3l-0.3-2.6V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M131.7,177.4l0.3-5.3l0.8-5.2l1.3-4.9l1.8-4.8l2.1-4.4l2.6-4.3l1.4-2
l1.5-2l3.4-3.6l3.6-3.3l3.9-3l4.3-2.5l4.5-2.1l4.6-1.8l5-1.3l2.5-0.5l2.5-0.3l5.3-0.3l5.3,0.3l5,0.8l5,1.3l4.7,1.8l4.5,2.1
l4.3,2.5l2,1.5l1.9,1.5l3.6,3.3l3.4,3.6l2.9,4l2.6,4.3l2.1,4.4l1.8,4.8l1.3,4.9l0.4,2.5l0.4,2.6l0.3,5.3l-0.3,5.3l-0.8,5
l-1.3,4.9l-1.8,4.8l-2.1,4.5l-2.6,4.1l-1.4,2l-1.5,2l-3.4,3.6l-3.6,3.3l-3.9,3l-4.3,2.5l-4.5,2.3l-4.7,1.6l-5,1.4l-2.5,0.4
l-2.5,0.4l-5.3,0.3l-5.3-0.3l-5-0.8l-5-1.4l-4.6-1.6l-4.5-2.3l-4.3-2.5l-2-1.4l-1.9-1.6l-3.6-3.3l-3.4-3.6l-2.9-4l-2.6-4.1
l-2.1-4.5l-1.8-4.8l-1.3-4.9l-0.4-2.5l-0.4-2.5L131.7,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M128.8,177.4l0.3-5.3l0.8-5.2l1.3-4.9l1.8-4.8l2.1-4.4l2.5-4.3l1.5-2
l1.5-2l3.3-3.6l3.6-3.3l4-3l4.3-2.5l4.4-2.1l4.8-1.8l4.9-1.3l2.5-0.5l2.6-0.3l5.3-0.3l5.2,0.3l5.2,0.8l4.9,1.3l4.8,1.8l4.5,2.1
l4.1,2.5l2,1.5l2,1.5l3.6,3.3l3.3,3.6l3,4l2.5,4.3l2.3,4.4l1.6,4.8l1.3,4.9l0.5,2.5l0.4,2.6l0.3,5.3l-0.3,5.3l-0.9,5l-1.3,4.9
l-1.6,4.8l-2.3,4.5l-2.5,4.1l-1.4,2l-1.6,2l-3.3,3.6l-3.6,3.3l-4,3l-4.1,2.5l-4.5,2.3l-4.8,1.6l-4.9,1.4l-2.5,0.4l-2.6,0.4
l-5.2,0.3l-5.3-0.3l-5.2-0.8l-4.9-1.4l-4.8-1.6l-4.4-2.3l-4.3-2.5l-2-1.4l-2-1.6l-3.6-3.3l-3.3-3.6l-3-4l-2.5-4.1l-2.1-4.5
l-1.8-4.8l-1.3-4.9l-0.5-2.5l-0.3-2.5L128.8,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.2,177.4v-2.5l0.3-2.5l0.3-2.4l0.3-1.3l0.3-1.3l0.5-2.3l0.6-2.4l0.8-2.3l0.9-2.1l1-2.3l1.1-2l1.1-2.1
l1.3-2l1.4-1.9l1.5-1.8l1.5-1.9l1.6-1.6l1.6-1.6l1.8-1.5l1.9-1.5l1.9-1.4l2-1.3l2-1.1l2.1-1.1l2.1-0.9l2.3-0.9l2.3-0.8l1.1-0.4
l1.1-0.4l2.4-0.5l2.4-0.4l2.5-0.4l1.3-0.1h1.3l2.5-0.1l2.5,0.1l2.4,0.1l2.5,0.4l1.1,0.1l1.3,0.3l2.4,0.5l2.3,0.8l2.3,0.8l2.3,0.9
l2.1,0.9l2.1,1.1l2,1.1l2,1.3l1.9,1.4l1.9,1.5l1.8,1.5l1.6,1.6l1.6,1.6l1.5,1.9l1.5,1.8l1.4,1.9l1.3,2l1.1,2.1l1.1,2l1,2.3
l0.9,2.1l0.8,2.3l0.3,1.1l0.4,1.3l0.5,2.3l0.5,2.5l0.3,2.4l0.1,1.3l0.1,1.3v2.5v2.5l-0.3,2.5l-0.3,2.4l-0.3,1.3l-0.3,1.1
l-0.5,2.4l-0.6,2.3l-0.8,2.3l-0.9,2.3l-1,2.1l-1.1,2.1l-1.1,2l-1.3,2l-1.4,1.9l-1.5,1.9l-1.5,1.8l-1.6,1.8l-1.6,1.6l-1.8,1.5
l-1.9,1.4l-1.9,1.4l-2,1.3l-2,1.3l-2.1,1l-2.1,1l-2.3,0.9l-2.3,0.8l-1.1,0.4l-1.1,0.3l-2.4,0.6l-2.4,0.4l-2.5,0.3l-1.3,0.1
l-1.1,0.1h-2.5h-2.5l-2.5-0.3l-2.5-0.3l-1.1-0.3l-1.3-0.1l-2.4-0.6l-2.3-0.6l-2.3-0.8l-2.3-0.9l-2.1-1l-2.1-1l-2-1.3l-2-1.3
l-1.9-1.4l-1.9-1.4l-1.8-1.5l-1.6-1.6l-1.6-1.8l-1.5-1.8l-1.5-1.9l-1.4-1.9l-1.3-2l-1.1-2l-1.1-2.1l-1-2.1l-0.9-2.3l-0.8-2.3
l-0.3-1.1l-0.4-1.1l-0.5-2.4l-0.5-2.4l-0.3-2.4l-0.1-1.3l-0.1-1.3V177.4z M131.5,177.4v-2.5l0.3-2.5l0.3-2.4l0.3-1.3l0.3-1.3
l0.5-2.3l0.6-2.4l0.8-2.3l0.9-2.1l1-2.3l1.1-2l1.1-2.1l1.3-2l1.4-1.9l1.4-1.8l1.6-1.9l1.6-1.6l1.6-1.6l1.8-1.5l1.9-1.5l1.9-1.4
l2-1.3l2-1.1l2.1-1.1l2.1-0.9l2.3-0.9l2.3-0.8l1.1-0.4l1.1-0.4l2.4-0.5l2.4-0.4l2.5-0.4l1.1-0.1h1.3l2.5-0.1l2.5,0.1l2.5,0.1
l2.5,0.4l1.1,0.1l1.3,0.3l2.3,0.5l2.4,0.8l2.3,0.8l2.3,0.9l2.1,0.9l2.1,1.1l2,1.1l2,1.3l1.9,1.4l1.9,1.5l1.8,1.5l1.6,1.6l1.6,1.6
l1.5,1.9l1.5,1.8l1.4,1.9l1.3,2l1.1,2.1l1.1,2l0.9,2.3l0.9,2.1l0.9,2.3l0.3,1.1l0.4,1.3l0.5,2.3l0.5,2.5l0.3,2.4l0.1,1.3l0.1,1.3
v2.5v2.5l-0.3,2.5l-0.3,2.4l-0.3,1.3l-0.3,1.1l-0.5,2.4l-0.6,2.3l-0.9,2.3l-0.9,2.3l-0.9,2.1l-1.1,2.1l-1.1,2l-1.3,2l-1.4,1.9
l-1.5,1.9l-1.5,1.8l-1.6,1.8l-1.6,1.6l-1.8,1.5l-1.9,1.4l-1.9,1.4l-2,1.3l-2,1.3l-2.1,1l-2.1,1l-2.3,0.9l-2.3,0.8l-1.1,0.4
l-1.3,0.3l-2.3,0.6l-2.4,0.4l-2.5,0.3L184,226l-1.3,0.1h-2.5h-2.5l-2.4-0.3l-2.5-0.3l-1.1-0.3l-1.3-0.1l-2.4-0.6l-2.3-0.6
l-2.3-0.8l-2.3-0.9l-2.1-1l-2.1-1l-2-1.3l-2-1.3l-1.9-1.4l-1.9-1.4l-1.8-1.5l-1.6-1.6l-1.6-1.8l-1.6-1.8l-1.4-1.9l-1.4-1.9
l-1.3-2l-1.1-2l-1.1-2.1l-1-2.1l-0.9-2.3l-0.8-2.3l-0.4-1.1l-0.3-1.1l-0.5-2.4l-0.5-2.4l-0.3-2.4l-0.1-1.3l-0.1-1.3V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M134.2,177.4l0.3-5l0.8-4.9l1.1-4.7l1.6-4.5l2.1-4.3l2.4-4l2.8-3.8
l3.3-3.4l3.4-3.1l3.8-2.9l4-2.4l4.3-2l4.5-1.8l4.6-1.1l4.9-0.8l4.9-0.3l5,0.3l4.9,0.8l4.7,1.1l4.5,1.8l4.3,2l4,2.4l3.8,2.9
l3.4,3.1l3.1,3.4l2.9,3.8l2.4,4l2.1,4.3l1.6,4.5l1.1,4.7l0.8,4.9l0.3,5l-0.3,4.9l-0.8,4.9l-1.1,4.7l-1.6,4.5l-2.1,4.3l-2.4,4
l-2.9,3.8l-3.1,3.5l-3.4,3.1l-3.8,2.8l-4,2.4l-4.3,2.1l-4.5,1.6l-4.7,1.1l-4.9,0.8l-5,0.3l-4.9-0.3l-4.9-0.8l-4.6-1.1l-4.5-1.6
l-4.3-2.1l-4-2.4l-3.8-2.8l-3.4-3.1l-3.3-3.5l-2.8-3.8l-2.4-4l-2.1-4.3l-1.6-4.5l-1.1-4.7l-0.8-4.9L134.2,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M131.5,177.4l0.3-5l0.6-4.9l1.3-4.7l1.6-4.5l2-4.3l2.5-4l2.8-3.8
l3.1-3.4l3.5-3.1l3.8-2.9l4-2.4l4.3-2l4.5-1.8l4.7-1.1l4.8-0.8l5-0.3l5,0.3l4.9,0.8l4.6,1.1l4.5,1.8l4.1,2l4.1,2.4l3.6,2.9
l3.5,3.1l3.1,3.4l2.9,3.8l2.4,4l2,4.3l1.6,4.5l1.3,4.7l0.8,4.9l0.3,5l-0.3,4.9l-0.8,4.9l-1.3,4.7l-1.6,4.5l-2,4.3l-2.4,4
l-2.9,3.8l-3.1,3.5l-3.5,3.1l-3.6,2.8l-4.1,2.4l-4.1,2.1l-4.5,1.6l-4.6,1.1l-4.9,0.8l-5,0.3l-5-0.3l-4.8-0.8l-4.7-1.1l-4.5-1.6
l-4.3-2.1l-4-2.4l-3.8-2.8l-3.5-3.1l-3.1-3.5l-2.8-3.8l-2.5-4l-2-4.3l-1.6-4.5l-1.3-4.7l-0.6-4.9L131.5,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.6,177.4V175l0.3-2.4l0.3-2.3l0.4-2.4l0.5-2.1l0.6-2.3l0.8-2.1l0.9-2.1l0.4-1l0.5-1l0.5-1l0.5-1l0.6-1
l0.5-1l1.3-1.9l1.3-1.8l1.4-1.8l1.5-1.6l0.8-0.9l0.8-0.8l1.6-1.6l0.8-0.6l0.9-0.8l1.8-1.4l1.9-1.3l1.9-1.3l1.9-1.1l2-1l2-0.9
l2.1-0.9l2.1-0.8l2.3-0.6l1.1-0.3l1.1-0.3l2.3-0.4l1.1-0.1l1.1-0.3l2.4-0.1h2.4h2.4l2.4,0.1l2.3,0.4l2.3,0.4l2.3,0.5l2.3,0.6
l2.1,0.8l2.1,0.9l1,0.4l1,0.5l1,0.5l1,0.5l1,0.5l0.9,0.6l1.9,1.3l1.9,1.3l1.8,1.4l1.6,1.4l0.9,0.9l0.8,0.8l1.5,1.6l0.8,0.8
l0.8,0.9l1.4,1.8l1.3,1.8l1.3,1.9l1.1,2l1,2l0.9,2l0.9,2.1l0.8,2.1l0.6,2.3l0.3,1l0.3,1.1l0.4,2.4l0.1,1.1l0.1,1.1l0.3,2.4v2.4
v2.4l-0.3,2.4l-0.3,2.3l-0.4,2.3l-0.5,2.3l-0.6,2.3l-0.8,2.1l-0.9,2.1l-0.4,1l-0.5,1l-0.5,1l-0.5,1l-0.6,1l-0.5,0.9l-1.3,1.9
l-1.3,1.9l-1.4,1.8l-1.5,1.6l-0.8,0.9l-0.8,0.8l-1.6,1.5l-0.8,0.8l-0.9,0.8l-1.8,1.4l-1.9,1.3l-1.9,1.3l-1.9,1l-2,1.1l-2,0.9
L199,221l-2.1,0.6l-2.3,0.6l-1.1,0.4l-1.1,0.3l-2.3,0.4l-1.1,0.1l-1.1,0.1l-2.4,0.3H183h-2.4l-2.4-0.3l-2.3-0.3l-2.3-0.4
l-2.3-0.6l-2.3-0.6L167,221l-2.1-0.9l-1-0.5l-1-0.4l-1-0.5l-1-0.6l-1-0.5l-0.9-0.5l-1.9-1.3l-1.9-1.3l-1.8-1.4l-1.6-1.5l-0.9-0.8
l-0.8-0.8l-1.5-1.6l-0.8-0.9l-0.8-0.8l-1.4-1.8l-1.3-1.9l-1.3-1.9l-1.1-1.9l-1-2l-0.9-2l-0.9-2.1l-0.8-2.1l-0.6-2.3l-0.3-1.1
l-0.3-1.1l-0.4-2.3l-0.1-1.1l-0.1-1.1l-0.3-2.4V177.4z M134,177.4V175l0.3-2.4l0.3-2.3l0.4-2.4l0.5-2.1l0.6-2.3l0.8-2.1l0.9-2.1
l0.4-1l0.5-1l0.5-1l0.5-1l0.6-1l0.5-1l1.3-1.9l1.3-1.8l1.4-1.8l1.5-1.6l0.8-0.9l0.8-0.8l1.6-1.6l0.8-0.6l0.9-0.8l1.8-1.4l1.8-1.3
l1.9-1.3l2-1.1l2-1l2-0.9l2.1-0.9l2.1-0.8l2.3-0.6l1.1-0.3l1-0.3l2.4-0.4l1.1-0.1l1.1-0.3l2.4-0.1h2.4h2.4l2.4,0.1l2.3,0.4
l2.3,0.4l2.3,0.5l2.3,0.6l2.1,0.8l2.1,0.9l1,0.4l1,0.5l1,0.5l1,0.5l1,0.5l0.9,0.6l1.9,1.3l1.9,1.3l1.8,1.4l1.6,1.4l0.9,0.9
l0.8,0.8l1.5,1.6l0.8,0.8l0.8,0.9l1.4,1.8l1.3,1.8l1.3,1.9l1.1,2l1,2l0.9,2l0.9,2.1l0.8,2.1l0.6,2.3l0.3,1l0.3,1.1l0.4,2.4
l0.1,1.1l0.1,1.1l0.3,2.4v2.4v2.4l-0.3,2.4l-0.3,2.3l-0.4,2.3l-0.5,2.3l-0.6,2.3l-0.8,2.1l-0.9,2.1l-0.4,1l-0.5,1l-0.5,1l-0.5,1
l-0.6,1l-0.5,0.9l-1.3,1.9l-1.3,1.9l-1.4,1.8l-1.5,1.6l-0.8,0.9l-0.8,0.8l-1.6,1.5l-0.8,0.8l-0.9,0.8l-1.8,1.4l-1.9,1.3l-1.9,1.3
l-1.9,1l-2,1.1l-2,0.9l-2.1,0.9l-2.1,0.6l-2.3,0.6l-1.1,0.4l-1.1,0.3l-2.3,0.4l-1.1,0.1l-1.1,0.1l-2.4,0.3h-2.4H178l-2.4-0.3
l-2.3-0.3l-2.4-0.4l-2.1-0.6l-2.3-0.6l-2.1-0.6l-2.1-0.9l-1-0.5l-1-0.4l-1-0.5l-1-0.6l-1-0.5l-1-0.5l-1.9-1.3l-1.8-1.3l-1.8-1.4
l-1.6-1.5l-0.9-0.8l-0.8-0.8l-1.5-1.6l-0.8-0.9l-0.8-0.8l-1.4-1.8l-1.3-1.9l-1.3-1.9l-1.1-1.9l-1-2l-0.9-2l-0.9-2.1l-0.8-2.1
l-0.6-2.3l-0.3-1.1l-0.3-1.1l-0.4-2.3l-0.1-1.1l-0.1-1.1l-0.3-2.4V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M136.6,177.4l0.3-4.8l0.6-4.7l1.1-4.4l1.6-4.3l1.9-4l2.4-3.9l2.6-3.5
l1.5-1.8l1.5-1.5l3.3-3l3.5-2.8l3.9-2.3l4-2l2.1-0.8l2.1-0.8l4.4-1.1l4.7-0.8l4.8-0.3l4.8,0.3l4.5,0.8l4.5,1.1l4.3,1.5l4,2
l3.8,2.3l3.6,2.8l1.6,1.4l1.6,1.6l3,3.3l2.6,3.5l2.4,3.9l1.9,4l0.9,2.1l0.8,2.1l1.1,4.4l0.6,4.7l0.3,4.8l-0.3,4.7l-0.6,4.7
l-1.1,4.4l-1.6,4.3l-1.9,4.1l-2.4,3.8l-2.6,3.6l-1.5,1.6l-1.5,1.6l-3.3,3l-3.6,2.6l-3.8,2.3l-4,2L199,221l-2.1,0.6l-4.5,1.1
l-4.5,0.8l-4.8,0.3l-4.8-0.3l-4.7-0.8l-4.4-1.1l-4.3-1.5l-4-2l-3.9-2.3l-3.5-2.6l-1.6-1.5l-1.6-1.5l-3-3.3l-2.6-3.6l-2.4-3.8
l-1.9-4.1l-0.9-2l-0.8-2.3l-1.1-4.4l-0.6-4.7L136.6,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M134,177.4l0.1-4.8l0.8-4.7l1.1-4.4l1.6-4.3l1.9-4l2.4-3.9l2.6-3.5
l1.4-1.8l1.6-1.5l3.3-3l3.5-2.8l3.9-2.3l4-2l2.1-0.8l2.1-0.8l4.4-1.1l4.6-0.8l4.8-0.3l4.8,0.3l4.5,0.8l4.5,1.1l4.3,1.5l4,2
l3.8,2.3l3.6,2.8l1.6,1.4l1.6,1.6l3,3.3l2.6,3.5l2.3,3.9l2,4l0.9,2.1l0.6,2.1l1.3,4.4l0.6,4.7l0.3,4.8l-0.3,4.7l-0.6,4.7
l-1.3,4.4l-1.5,4.3l-2,4.1l-2.3,3.8l-2.6,3.6l-1.5,1.6l-1.5,1.6l-3.3,3l-3.6,2.6l-3.8,2.3l-4,2l-2.1,0.9l-2.1,0.6l-4.5,1.1
l-4.5,0.8l-4.8,0.3l-4.8-0.3l-4.6-0.8l-4.4-1.1l-4.3-1.5l-4-2l-3.9-2.3l-3.5-2.6l-1.6-1.5l-1.6-1.5l-3-3.3l-2.6-3.6l-2.4-3.8
l-1.9-4.1l-0.9-2l-0.8-2.3l-1.1-4.4l-0.8-4.7L134,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.9,177.4v-2.3l0.1-2.3l0.1-1.1l0.3-1.1l0.4-2.1l0.5-2.1l0.5-2.1l0.8-2l0.8-2l0.9-1.9l1-1.9l1-1.9
l1.3-1.8l1.1-1.8l0.6-0.9l0.8-0.8l1.4-1.6l1.4-1.5l1.6-1.5l1.5-1.4l1.8-1.3l0.8-0.6l0.9-0.6l1.9-1.1l1.8-1.1l1.9-0.9l2-0.9l2-0.9
l2-0.6l2.1-0.6l2.1-0.5l2.1-0.4l2.3-0.3l2.1-0.3h2.3h2.4l2.1,0.3l1.1,0.1l1.1,0.1l2.1,0.4l2.1,0.5l2.1,0.6l2,0.6l2,0.9l2,0.9
l1.9,0.9l1.8,1.1l1.9,1.1l1.6,1.3l0.9,0.6l0.9,0.6l1.5,1.4l1.6,1.5l1.4,1.5l1.4,1.6l1.4,1.6l0.6,0.9l0.5,0.9l1.1,1.8l1.1,1.9
l1,1.9l0.9,1.9l0.8,2l0.6,2l0.6,2.1l0.5,2.1l0.4,2.1l0.3,2.3l0.3,2.3v2.3v2.3l-0.3,2.3l-0.1,1l-0.1,1.1l-0.4,2.1l-0.5,2.1
l-0.6,2.1l-0.6,2l-0.8,2l-0.9,2l-1,1.9l-1.1,1.9l-1.1,1.8l-1.1,1.8l-0.8,0.8l-0.6,0.9l-1.4,1.6l-1.4,1.5l-1.6,1.4l-1.5,1.4
l-1.8,1.4l-0.8,0.6l-0.9,0.6l-1.9,1.1l-1.8,1l-1.9,1l-2,0.9l-2,0.8l-2,0.8l-2.1,0.6l-2.1,0.4l-2.1,0.5l-2.3,0.3l-2.1,0.1
l-2.4,0.1l-2.3-0.1l-2.1-0.1l-1.1-0.1l-1.1-0.1l-2.1-0.5l-2.1-0.4l-2.1-0.6l-2-0.8l-2-0.8l-2-0.9l-1.9-1l-1.8-1l-1.9-1.1
l-1.6-1.3l-0.9-0.6l-0.9-0.8l-1.5-1.4l-1.6-1.4l-1.4-1.5l-1.4-1.6l-1.4-1.6l-0.6-0.9l-0.5-0.9l-1.3-1.8l-1-1.9l-1-1.9l-0.9-2
l-0.8-2l-0.8-2l-0.5-2.1l-0.5-2.1l-0.4-2.1l-0.4-2.1l-0.1-2.3V177.4z M136.4,177.4v-2.3l0.1-2.3l0.1-1.1l0.3-1.1l0.4-2.1l0.5-2.1
l0.5-2.1l0.8-2l0.8-2l0.9-1.9l1-1.9l1-1.9l1.3-1.8l1.1-1.8l0.6-0.9l0.8-0.8l1.4-1.6l1.4-1.5l1.6-1.5l1.5-1.4l1.8-1.3l0.8-0.6
l0.9-0.6l1.9-1.1l1.8-1.1l1.9-0.9l2-0.9l2-0.9l2-0.6l2.1-0.6l2.1-0.5l2.1-0.4l2.3-0.3l2.1-0.3h2.3h2.3l2.3,0.3l1.1,0.1l1.1,0.1
l2.1,0.4l2.1,0.5l2.1,0.6l2,0.6l2,0.9l2,0.9l1.9,0.9l1.8,1.1l1.9,1.1l1.6,1.3l0.9,0.6l0.9,0.6l1.5,1.4l1.5,1.5l1.5,1.5l1.4,1.6
l1.4,1.6l0.6,0.9l0.5,0.9l1.1,1.8l1.1,1.9l1,1.9l0.9,1.9l0.8,2l0.6,2l0.6,2.1l0.5,2.1l0.4,2.1l0.3,2.3l0.3,2.3v2.3v2.3l-0.3,2.3
l-0.1,1L224,184l-0.4,2.1l-0.5,2.1l-0.6,2.1l-0.6,2l-0.8,2l-0.9,2l-1,1.9l-1.1,1.9l-1.1,1.8l-1.1,1.8l-0.8,0.8l-0.6,0.9L213,207
l-1.5,1.5l-1.5,1.4l-1.5,1.4l-1.8,1.4l-0.9,0.6l-0.8,0.6l-1.9,1.1l-1.8,1l-1.9,1l-2,0.9l-2,0.8l-2,0.8l-2.1,0.6l-2.1,0.4
l-2.1,0.5l-2.3,0.3l-2.3,0.1l-2.3,0.1l-2.3-0.1l-2.1-0.1l-1.1-0.1l-1.1-0.1l-2.1-0.5l-2.1-0.4l-2.1-0.6l-2-0.8l-2-0.8l-2-0.9
l-1.9-1l-1.8-1l-1.9-1.1l-1.6-1.3l-0.9-0.6l-0.9-0.8l-1.5-1.4l-1.6-1.4l-1.4-1.5l-1.4-1.6l-1.4-1.6l-0.6-0.9l-0.5-0.9l-1.3-1.8
l-1-1.9l-1-1.9l-0.9-2l-0.8-2l-0.8-2l-0.5-2.1l-0.5-2.1l-0.4-2.1l-0.4-2.1l-0.1-2.3V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M138.9,177.4l0.1-4.5l0.8-4.4l1-4.3l0.8-2l0.8-2l1.9-3.9l1-1.8l1.1-1.8
l2.6-3.4l2.8-3.1l3.1-2.9l3.4-2.5l3.6-2.3l3.9-1.9l4-1.4l4.3-1.1l4.3-0.6l4.5-0.3l4.5,0.3l4.4,0.6l4.3,1.1l2,0.6l2,0.8l3.9,1.9
l1.8,1.1l1.8,1.1l3.4,2.5l3.1,2.9l2.9,3.1l2.5,3.4l2.3,3.5l1.9,3.9l1.4,4l1.1,4.3l0.6,4.4l0.3,4.5l-0.3,4.5l-0.6,4.3l-1.1,4.3
l-0.6,2l-0.8,2l-1.9,3.9l-1.1,1.9l-1.1,1.8l-2.5,3.4l-2.9,3.1l-3.1,2.8l-3.4,2.6l-3.5,2.1l-3.9,1.9l-4,1.5l-4.3,1l-4.4,0.8
l-4.5,0.1l-4.5-0.1l-4.3-0.8l-4.3-1l-2-0.8l-2-0.8l-3.9-1.9l-1.9-1l-1.8-1.1l-3.4-2.6l-3.1-2.8l-2.8-3.1l-2.6-3.4l-2.1-3.6
l-1.9-3.9l-1.5-4l-1-4.3l-0.8-4.3L138.9,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M136.4,177.4l0.1-4.5l0.8-4.4l1-4.3l0.8-2l0.8-2l1.9-3.9l1-1.8l1.1-1.8
l2.6-3.4l2.8-3.1l3.1-2.9l3.4-2.5l3.6-2.3l3.9-1.9l4-1.4l4.3-1.1l4.3-0.6l4.5-0.3l4.5,0.3l4.4,0.6l4.3,1.1l2,0.6l2,0.8l3.9,1.9
l1.8,1.1l1.8,1.1l3.4,2.5l3.1,2.9l2.9,3.1l2.5,3.4l2.3,3.5l1.9,3.9l1.4,4l1.1,4.3l0.6,4.4l0.3,4.5l-0.3,4.5l-0.6,4.3l-1.1,4.3
l-0.6,2l-0.8,2l-1.9,3.9l-1.1,1.9l-1.1,1.8l-2.5,3.4l-2.9,3.1l-3.1,2.8L205,214l-3.5,2.1l-3.9,1.9l-4,1.5l-4.3,1l-4.4,0.8
l-4.5,0.1l-4.5-0.1l-4.3-0.8l-4.3-1l-2-0.8l-2-0.8l-3.9-1.9l-1.9-1l-1.8-1.1l-3.4-2.6l-3.1-2.8l-2.8-3.1l-2.6-3.4l-2.1-3.6
l-1.9-3.9l-1.5-4l-1-4.3l-0.8-4.3L136.4,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M141,177.4v-2.1l0.1-2.1l0.4-2.1l0.3-2l0.3-1l0.3-1l0.6-2l0.3-1l0.4-1l0.8-1.9l0.4-0.9l0.5-1l0.9-1.8l1-1.8
l1.1-1.8l1.1-1.6l1.3-1.5l1.3-1.5l1.4-1.5l1.5-1.4l1.5-1.3l1.6-1.3l1.6-1.3l1.6-1l1.8-1l1.8-1l1.9-0.8l1.9-0.8l2-0.6l2-0.6l2-0.5
l2-0.4l2.1-0.3l2.1-0.1h2.1h2.1l2.1,0.1l2.1,0.3l2,0.4l1,0.3l1,0.3l2,0.6l1,0.3l1,0.4l1.9,0.8l0.9,0.4l0.9,0.4l1.9,1l1.8,1l1.6,1
l1.6,1.3l1.6,1.3l1.5,1.3l1.5,1.4l1.4,1.5l1.3,1.5l1.3,1.5l1.1,1.6l1.1,1.8l1,1.8l0.9,1.8l0.9,1.9l0.8,1.9l0.6,2l0.6,2l0.4,2
l0.4,2l0.3,2.1l0.3,2.1v2.1v2.1l-0.3,2.1l-0.3,2.1l-0.4,2l-0.1,1l-0.3,1l-0.6,2l-0.3,1l-0.4,0.9l-0.8,2l-0.4,0.9l-0.5,0.9
l-0.9,1.9l-1,1.8l-1.1,1.6l-1.1,1.6l-1.3,1.6l-1.3,1.5l-1.4,1.4l-1.5,1.4l-1.5,1.4l-1.6,1.3l-1.6,1.1l-1.6,1.1l-1.8,1l-1.9,0.9
l-1.8,0.9l-1.9,0.8l-2,0.6l-2,0.6l-2,0.4l-2,0.4l-2.1,0.3l-2.1,0.3h-2.1h-2.1l-2.1-0.3l-2.1-0.3l-2-0.4l-1-0.1l-1-0.3l-2-0.6
l-1-0.3l-1-0.4l-1.9-0.8l-0.9-0.4l-1-0.5l-1.8-0.9l-1.8-1l-1.6-1.1l-1.6-1.1l-1.6-1.3l-1.5-1.4l-1.5-1.4l-1.4-1.4l-1.3-1.5
l-1.3-1.6l-1.1-1.6l-1.1-1.6l-1-1.8l-0.9-1.9l-0.9-1.8l-0.8-2l-0.6-1.9l-0.6-2l-0.5-2l-0.3-2l-0.4-2.1l-0.1-2.1V177.4z
 M138.6,177.4v-2.1l0.1-2.1l0.4-2.1l0.3-2l0.3-1l0.3-1l0.6-2l0.3-1l0.4-1l0.8-1.9l0.4-0.9l0.5-1l0.9-1.8l1-1.8l1.1-1.8l1.1-1.6
l1.3-1.5l1.3-1.5l1.4-1.5l1.5-1.4l1.5-1.3l1.6-1.3l1.6-1.3l1.6-1l1.8-1l1.8-1l1.9-0.8l1.9-0.8l2-0.6l2-0.6l2-0.5l2-0.4l2.1-0.3
l2.1-0.1h2.1h2.1l2.1,0.1l2.1,0.3l2,0.4l1,0.3l1,0.3l2,0.6l1,0.3l1,0.4l1.9,0.8l0.9,0.4l0.9,0.4l1.9,1l1.8,1l1.6,1l1.6,1.3
l1.6,1.3l1.5,1.3l1.5,1.4l1.4,1.5l1.3,1.5l1.3,1.5l1.1,1.6l1.1,1.8l1,1.8l0.9,1.8l0.9,1.9l0.8,1.9l0.6,2l0.6,2l0.4,2l0.4,2
l0.3,2.1l0.3,2.1v2.1v2.1l-0.3,2.1l-0.3,2.1l-0.4,2l-0.1,1l-0.3,1l-0.6,2l-0.3,1l-0.4,0.9l-0.8,2l-0.4,0.9l-0.5,0.9l-0.9,1.9
l-1,1.8l-1.1,1.6l-1.1,1.6l-1.3,1.6l-1.3,1.5l-1.4,1.4l-1.5,1.4l-1.5,1.4l-1.6,1.3l-1.6,1.1l-1.6,1.1l-1.8,1l-1.9,0.9l-1.8,0.9
l-1.9,0.8l-2,0.6l-2,0.6l-2,0.4l-2,0.4l-2.1,0.3l-2.1,0.3h-2.1h-2.1l-2.1-0.3l-2.1-0.3l-2-0.4l-1-0.1l-1-0.3l-2-0.6l-1-0.3
l-1-0.4l-1.9-0.8l-0.9-0.4l-1-0.5l-1.8-0.9l-1.8-1l-1.6-1.1l-1.6-1.1l-1.6-1.3l-1.5-1.4l-1.5-1.4l-1.4-1.4l-1.3-1.5l-1.3-1.6
l-1.1-1.6l-1.1-1.6l-1-1.8l-0.9-1.9l-0.9-1.8l-0.8-2l-0.6-1.9l-0.6-2l-0.5-2l-0.3-2l-0.4-2.1l-0.1-2.1V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M141,177.4l0.1-4.3l0.6-4.1l1.1-4l1.4-3.9l1.8-3.6l2.1-3.5l2.4-3.1
l2.6-3l3-2.6l3.3-2.5l3.4-2l3.6-1.8l3.9-1.5l4-1l4.1-0.6l4.3-0.3l4.3,0.3l4.1,0.6l4,1l3.9,1.5l3.6,1.8l3.4,2l3.3,2.5l2.9,2.6
l2.8,3l2.4,3.1l2.1,3.5l1.8,3.6l1.4,3.9l1,4l0.6,4.1l0.3,4.3l-0.3,4.3l-0.6,4.1l-1,4l-1.4,3.8l-1.8,3.8l-2.1,3.4l-2.4,3.3
l-2.8,2.9l-2.9,2.8l-3.3,2.4l-3.4,2.1l-3.6,1.8l-3.9,1.4l-4,1l-4.1,0.6l-4.3,0.3l-4.3-0.3l-4.1-0.6l-4-1l-3.9-1.4l-3.6-1.8
l-3.4-2.1l-3.3-2.4l-3-2.8l-2.6-2.9l-2.4-3.3l-2.1-3.4l-1.8-3.8l-1.4-3.8l-1.1-4l-0.6-4.1L141,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M138.6,177.4l0.1-4.3l0.6-4.1l1.1-4l1.4-3.9l1.8-3.6l2-3.5l2.5-3.1
l2.6-3l3-2.6l3.1-2.5l3.5-2l3.6-1.8l3.9-1.5l4-1l4.1-0.6l4.3-0.3l4.3,0.3l4.1,0.6l4,1l3.9,1.5l3.6,1.8l3.4,2l3.3,2.5l2.9,2.6
l2.8,3l2.4,3.1l2.1,3.5l1.8,3.6l1.4,3.9l1,4l0.6,4.1l0.3,4.3l-0.3,4.3l-0.6,4.1l-1,4l-1.4,3.8l-1.8,3.8l-2.1,3.4l-2.4,3.3
l-2.8,2.9l-2.9,2.8l-3.3,2.4l-3.4,2.1l-3.6,1.8l-3.9,1.4l-4,1l-4.1,0.6l-4.3,0.3l-4.3-0.3l-4.1-0.6l-4-1l-3.9-1.4l-3.6-1.8
l-3.5-2.1l-3.1-2.4l-3-2.8l-2.6-2.9l-2.5-3.3l-2-3.4l-1.8-3.8l-1.4-3.8l-1.1-4l-0.6-4.1L138.6,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143,177.4v-2l0.3-2.1l0.3-1.9l0.3-2l0.5-1.9l0.5-1.9l0.6-1.9l0.8-1.8l0.8-1.8l0.9-1.8l1-1.6l1-1.6l1.1-1.6
l1.1-1.5l1.3-1.4l1.4-1.4l1.4-1.4l1.4-1.1l1.5-1.3l1.5-1.1l1.6-1l0.9-0.5l0.9-0.5l1.6-0.9l1.8-0.8l1.9-0.8l1.8-0.6l1.9-0.5l2-0.4
l1.9-0.4l2-0.3l2-0.1l2-0.1l2.1,0.1l2,0.1l2,0.3l1.9,0.4l2,0.4l1.9,0.5l1.8,0.6l1.9,0.8l1.8,0.8l1.6,0.9l1.8,1l1.5,1l1.6,1.1
l1.5,1.3l1.4,1.1l1.4,1.4l1.4,1.4l1.3,1.4l1.1,1.5l1.1,1.6l1,1.6l0.5,0.8l0.5,0.9l0.9,1.8l0.8,1.8l0.8,1.8l0.6,1.9l0.5,1.9
l0.5,1.9l0.3,2l0.3,1.9l0.3,2.1v2v2l-0.3,2l-0.3,2l-0.3,2l-0.5,1.9l-0.5,1.9l-0.6,1.9l-0.8,1.8l-0.8,1.8l-0.9,1.8l-1,1.6l-1,1.6
l-1.1,1.5l-1.1,1.5l-1.3,1.5l-1.4,1.4l-1.4,1.3l-1.4,1.3l-1.5,1.1l-1.6,1.1l-1.5,1.1l-0.9,0.4l-0.9,0.5l-1.6,0.9l-1.8,0.9
l-1.9,0.6l-1.8,0.6l-1.9,0.5l-2,0.5l-1.9,0.4l-2,0.3l-2,0.1h-2.1h-2l-2-0.1l-2-0.3l-1.9-0.4l-2-0.5l-1.9-0.5l-1.8-0.6l-1.9-0.6
l-1.8-0.9l-1.6-0.9l-1.8-0.9l-1.6-1.1l-1.5-1.1l-1.5-1.1l-1.4-1.3l-1.4-1.3l-1.4-1.4l-1.3-1.5l-1.1-1.5l-1.1-1.5l-1-1.6l-0.5-0.9
l-0.5-0.8l-0.9-1.8l-0.8-1.8l-0.8-1.8l-0.6-1.9l-0.5-1.9l-0.5-1.9l-0.3-2l-0.3-2l-0.3-2V177.4z M140.8,177.4v-2l0.1-2.1l0.3-1.9
l0.4-2l0.5-1.9l0.5-1.9l0.6-1.9l0.8-1.8l0.8-1.8l0.9-1.8l1-1.6l1-1.6l1.1-1.6l1.1-1.5l1.3-1.4l1.4-1.4l1.4-1.4l1.4-1.1l1.5-1.3
l1.5-1.1l1.6-1l0.9-0.5l0.9-0.5l1.6-0.9l1.8-0.8l1.9-0.8l1.8-0.6l1.9-0.5l2-0.4l1.9-0.4l2-0.3l2-0.1l2-0.1l2.1,0.1l2,0.1l2,0.3
l1.9,0.4l2,0.4l1.9,0.5l1.8,0.6l1.9,0.8l1.8,0.8l1.6,0.9l1.8,1l1.5,1l1.6,1.1l1.5,1.3l1.4,1.1l1.4,1.4l1.4,1.4l1.3,1.4l1.1,1.5
l1.1,1.6l1,1.6l0.5,0.8l0.5,0.9l0.9,1.8l0.8,1.8l0.8,1.8l0.6,1.9l0.5,1.9l0.5,1.9l0.3,2l0.3,1.9l0.3,2.1v2v2l-0.3,2l-0.3,2
l-0.3,2l-0.5,1.9l-0.5,1.9l-0.6,1.9l-0.8,1.8l-0.8,1.8l-0.9,1.8l-1,1.6l-1,1.6l-1.1,1.5l-1.1,1.5l-1.3,1.5l-1.4,1.4l-1.4,1.3
l-1.4,1.3l-1.5,1.1l-1.6,1.1l-1.5,1.1l-0.9,0.4l-0.9,0.5l-1.6,0.9l-1.8,0.9l-1.9,0.6l-1.8,0.6l-1.9,0.5l-2,0.5l-1.9,0.4l-2,0.3
l-2,0.1h-2.1h-2l-2-0.1l-2-0.3l-1.9-0.4l-2-0.5l-1.9-0.5l-1.8-0.6l-1.9-0.6l-1.8-0.9l-1.6-0.9l-1.8-0.9l-1.6-1.1l-1.5-1.1
l-1.5-1.1l-1.4-1.3l-1.4-1.3l-1.4-1.4l-1.3-1.5l-1.1-1.5l-1.1-1.5l-1-1.6l-0.5-0.9l-0.5-0.8l-0.9-1.8l-0.8-1.8l-0.8-1.8l-0.6-1.9
l-0.5-1.9l-0.5-1.9l-0.4-2l-0.3-2l-0.1-2V177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M143,177.4l0.1-4.1l0.6-3.9l1-3.8l1.4-3.8l1.6-3.4l2-3.3l2.3-3.1
l2.6-2.8l2.8-2.6l3-2.3l3.3-2l3.5-1.6l3.6-1.4l3.9-0.9l3.9-0.6l4-0.3l4.1,0.3l3.9,0.6l3.9,0.9l3.6,1.4l3.4,1.6l3.3,2l3.1,2.3
l2.8,2.6l2.6,2.8l2.3,3.1l2,3.3l1.6,3.4l1.4,3.8l1,3.8l0.5,3.9l0.3,4.1l-0.3,4l-0.5,3.9l-1,3.9l-1.4,3.6l-1.6,3.5l-2,3.3l-2.3,3
l-2.6,2.9l-2.8,2.5l-3.1,2.3l-3.3,2l-3.4,1.6l-3.6,1.4l-3.9,1l-3.9,0.6l-4.1,0.1l-4-0.1l-3.9-0.6l-3.9-1l-3.6-1.4l-3.5-1.6
l-3.3-2l-3-2.3l-2.8-2.5l-2.6-2.9l-2.3-3l-2-3.3l-1.6-3.5l-1.4-3.6l-1-3.9l-0.6-3.9L143,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M140.8,177.4l0.1-4.1l0.6-3.9l1-3.8l1.4-3.8l1.6-3.4l2-3.3l2.3-3.1
l2.5-2.8l2.9-2.6l3-2.3l3.3-2l3.5-1.6l3.6-1.4l3.8-0.9l4-0.6l4-0.3l4.1,0.3l3.9,0.6l3.8,0.9l3.8,1.4l3.4,1.6l3.3,2l3.1,2.3
l2.8,2.6l2.6,2.8l2.3,3.1l2,3.3l1.6,3.4l1.4,3.8l0.9,3.8l0.6,3.9l0.3,4.1l-0.3,4l-0.6,3.9l-0.9,3.9l-1.4,3.6l-1.6,3.5l-2,3.3
l-2.3,3l-2.6,2.9l-2.8,2.5l-3.1,2.3l-3.3,2l-3.4,1.6l-3.8,1.4l-3.8,1l-3.9,0.6l-4.1,0.1l-4-0.1l-4-0.6l-3.8-1L165,214l-3.5-1.6
l-3.3-2l-3-2.3l-2.9-2.5l-2.5-2.9l-2.3-3l-2-3.3l-1.6-3.5l-1.4-3.6l-1-3.9l-0.6-3.9L140.8,177.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M144.9,177.4l0.1-2l0.1-1.9l0.3-1.9l0.3-1.9l0.5-1.8l0.5-1.9l0.6-1.8l0.6-1.6l0.8-1.8l0.4-0.8l0.5-0.9
l0.9-1.5l1-1.5l1-1.5l0.5-0.8l0.6-0.8l1.1-1.3l1.3-1.4l1.4-1.3l1.4-1.1l1.4-1.1l1.5-1.1l1.5-0.9l1.6-1l1.6-0.8l1.6-0.8l1.8-0.8
l1.8-0.5l1.8-0.5l1.8-0.5l1.9-0.3l1.9-0.3l2-0.3h1.9h2l1.9,0.3l1.9,0.3l1.9,0.3l1.8,0.5l1.8,0.5l1.8,0.5l1.8,0.8l1.6,0.8l0.9,0.4
l0.8,0.4l1.6,1l1.5,0.9l1.5,1.1l0.8,0.5l0.6,0.6l1.4,1.1l1.4,1.3l1.3,1.4l1.1,1.3l1.1,1.5l1,1.5l1,1.5l0.9,1.5l0.9,1.6l0.8,1.8
l0.8,1.6l0.5,1.8l0.5,1.9l0.5,1.8l0.3,1.9l0.3,1.9l0.1,1.9l0.1,2l-0.1,1.9l-0.1,2l-0.3,1.9l-0.3,1.8l-0.5,1.9l-0.5,1.8l-0.5,1.8
l-0.8,1.8l-0.8,1.6l-0.4,0.9l-0.5,0.8l-0.9,1.6l-1,1.5l-1,1.5l-0.5,0.6l-0.6,0.8l-1.1,1.4l-1.3,1.3l-1.4,1.3l-1.4,1.3l-1.4,1.1
l-1.5,1l-1.5,1l-1.6,0.9l-1.6,0.9l-1.6,0.8l-1.8,0.6l-1.8,0.6l-1.8,0.5l-1.8,0.4l-1.9,0.4l-1.9,0.3l-1.9,0.1l-2,0.1l-1.9-0.1
l-2-0.1l-1.9-0.3l-1.9-0.4l-1.8-0.4l-1.8-0.5l-1.8-0.6l-1.8-0.6l-1.6-0.8l-0.9-0.4l-0.8-0.5l-1.6-0.9l-1.5-1l-1.5-1l-0.8-0.6
l-0.6-0.5l-1.4-1.3L156,204l-1.3-1.3l-1.1-1.4l-1.1-1.4l-1-1.5l-1-1.5l-0.9-1.6l-0.9-1.6l-0.8-1.6l-0.6-1.8l-0.6-1.8l-0.5-1.8
l-0.5-1.9l-0.3-1.8l-0.3-1.9l-0.1-2L144.9,177.4z M142.8,177.4l0.1-2l0.1-1.9l0.3-1.9l0.3-1.9l0.5-1.8l0.5-1.9l0.5-1.8l0.8-1.6
l0.8-1.8l0.4-0.8l0.4-0.9l1-1.5l0.9-1.5l1.1-1.5l0.5-0.8l0.6-0.8l1.1-1.3l1.3-1.4l1.4-1.3l1.4-1.1l1.4-1.1l1.5-1.1l1.5-0.9l1.6-1
l1.5-0.8l1.8-0.8l1.6-0.8l1.8-0.5l1.9-0.5l1.8-0.5l1.9-0.3l1.9-0.3l1.9-0.3h2h1.9l2,0.3l1.9,0.3l1.8,0.3l1.9,0.5l1.8,0.5l1.8,0.5
l1.8,0.8l1.6,0.8l0.9,0.4l0.8,0.4l1.6,1l1.5,0.9l1.5,1.1l0.8,0.5l0.6,0.6l1.4,1.1l1.4,1.3l1.1,1.4l1.3,1.3l1.1,1.5l1,1.5l1,1.5
l0.9,1.5l0.9,1.6l0.8,1.8l0.6,1.6l0.6,1.8l0.5,1.9l0.5,1.8l0.3,1.9l0.3,1.9l0.1,1.9l0.1,2l-0.1,1.9l-0.1,2l-0.3,1.9l-0.3,1.8
l-0.5,1.9l-0.5,1.8l-0.6,1.8l-0.6,1.8l-0.8,1.6l-0.4,0.9l-0.5,0.8l-0.9,1.6l-1,1.5l-1,1.5l-0.6,0.6l-0.5,0.8l-1.3,1.4l-1.1,1.3
l-1.4,1.3l-1.4,1.3l-1.4,1.1l-1.5,1l-1.5,1l-1.6,0.9l-1.6,0.9l-1.6,0.8l-1.8,0.6l-1.8,0.6L190,214l-1.9,0.4l-1.8,0.4l-1.9,0.3
l-2,0.1l-1.9,0.1l-2-0.1l-1.9-0.1l-1.9-0.3l-1.9-0.4l-1.8-0.4l-1.9-0.5l-1.8-0.6l-1.6-0.6l-1.8-0.8l-0.8-0.4l-0.8-0.5l-1.6-0.9
l-1.5-1l-1.5-1l-0.8-0.6l-0.6-0.5l-1.4-1.3l-1.4-1.3l-1.3-1.3l-1.1-1.4l-1.1-1.4l-1.1-1.5l-0.9-1.5l-1-1.6l-0.8-1.6l-0.8-1.6
l-0.8-1.8l-0.5-1.8l-0.5-1.8l-0.5-1.9l-0.3-1.8l-0.3-1.9l-0.1-2L142.8,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M144.9,177.4l0.3-3.9l0.5-3.8l1-3.6l1.3-3.4l1.5-3.4l2-3l2.1-3l2.4-2.6
l2.8-2.4l2.9-2.3l3.1-1.9l3.3-1.5l3.4-1.4l3.6-0.9l3.8-0.6l3.9-0.1l3.9,0.1l3.8,0.6l3.5,0.9l3.5,1.4l3.3,1.5l3.1,1.9l2.9,2.3
l2.8,2.4l2.4,2.6l2.1,3l1.9,3l1.6,3.4l1.3,3.4l1,3.6l0.5,3.8l0.3,3.9l-0.3,3.8l-0.5,3.8l-1,3.6l-1.3,3.5l-1.6,3.3l-1.9,3.1
l-2.1,2.9l-2.4,2.6l-2.8,2.5l-2.9,2.1l-3.1,1.9l-3.3,1.6l-3.5,1.3l-3.5,0.9l-3.8,0.6l-3.9,0.1l-3.9-0.1l-3.8-0.6l-3.6-0.9
l-3.4-1.3l-3.3-1.6l-3.1-1.9l-2.9-2.1L156,204l-2.4-2.6l-2.1-2.9l-2-3.1l-1.5-3.3l-1.3-3.5l-1-3.6l-0.5-3.8L144.9,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M142.8,177.4l0.3-3.9l0.5-3.8l1-3.6l1.3-3.4l1.5-3.4l1.9-3l2.3-3
l2.4-2.6l2.6-2.4l3-2.3l3-1.9l3.4-1.5l3.4-1.4l3.6-0.9l3.8-0.6l3.9-0.1l3.8,0.1l3.8,0.6l3.6,0.9l3.5,1.4l3.3,1.5l3.1,1.9l2.9,2.3
l2.6,2.4l2.5,2.6l2.1,3l1.9,3l1.6,3.4l1.3,3.4l0.9,3.6l0.6,3.8l0.1,3.9l-0.1,3.8l-0.6,3.8l-0.9,3.6l-1.3,3.5l-1.6,3.3l-1.9,3.1
l-2.1,2.9l-2.5,2.6l-2.6,2.5l-2.9,2.1l-3.1,1.9l-3.3,1.6l-3.5,1.3l-3.6,0.9l-3.8,0.6l-3.8,0.1l-3.9-0.1l-3.8-0.6l-3.6-0.9
l-3.4-1.3l-3.4-1.6l-3-1.9l-3-2.1l-2.6-2.5l-2.4-2.6l-2.3-2.9l-1.9-3.1l-1.5-3.3l-1.3-3.5l-1-3.6l-0.5-3.8L142.8,177.4z"
        />
        <path
          vectorEffect="non-scaling-stroke"
          fill="none"
          stroke="#000000"
          strokeWidth="1.006"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="9"
          d="M244.2,177.4c0,34.6-28,62.6-62.6,62.6c-34.6,0-62.6-28-62.6-62.6
s28-62.6,62.6-62.6C216.2,114.8,244.2,142.8,244.2,177.4z"
        />
      </g>
    </svg>
  );
}

export default Vinyl;
