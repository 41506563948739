// @flow
import React from 'react';
import type { Node } from 'react';
import colors from '../../styles/_colors.scss';

type Props = {
  className: string,
};

function Waves(props: Props): Node {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 1400 300"
      className={props.className}
    >
      <g transform="translate(700,150) scale(1,1) translate(-700,-150)">
        <linearGradient id="lg-0.5450171058836231" x1="0" x2="1" y1="0" y2="0">
          <stop stopColor={colors.primary} offset="0" />
          <stop stopColor={colors.primary} offset="1" />
        </linearGradient>
        <path d="" fill="url(#lg-0.5450171058836231)" opacity="0.4">
          <animate
            attributeName="d"
            dur="10s"
            repeatCount="indefinite"
            keyTimes="0;0.333;0.667;1"
            calcmod="spline"
            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
            begin="0s"
            values="M0 0L 0 127.81926109817817Q 140 159.16423172117695  280 125.71210747668857T 560 111.96633802845489T 840 135.71490292395748T 1120 136.1427335312767T 1400 119.77208956290036L 1400 0 Z;M0 0L 0 138.75269765430448Q 140 180.63332502319847  280 145.05635320050084T 560 116.75475845295645T 840 111.59169579758677T 1120 119.0937810145976T 1400 114.0543431347259L 1400 0 Z;M0 0L 0 101.6816450105519Q 140 178.24866061758763  280 145.33320190338887T 560 139.36844017467862T 840 107.98741784729204T 1120 128.57237179291852T 1400 146.09429654445523L 1400 0 Z;M0 0L 0 127.81926109817817Q 140 159.16423172117695  280 125.71210747668857T 560 111.96633802845489T 840 135.71490292395748T 1120 136.1427335312767T 1400 119.77208956290036L 1400 0 Z"
          />
        </path>
        <path d="" fill="url(#lg-0.5450171058836231)" opacity="0.4">
          <animate
            attributeName="d"
            dur="10s"
            repeatCount="indefinite"
            keyTimes="0;0.333;0.667;1"
            calcmod="spline"
            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
            begin="-2s"
            values="M0 0L 0 130.40620719619005Q 140 152.32088710046344  280 123.18809395428599T 560 149.15423084336277T 840 112.44789101211072T 1120 112.21093244592052T 1400 108.22888095940141L 1400 0 Z;M0 0L 0 143.51314729777758Q 140 180.75714495298155  280 133.47471453427448T 560 137.32349243849544T 840 144.11915863235754T 1120 123.23734480125435T 1400 108.13749563995064L 1400 0 Z;M0 0L 0 127.60782383204197Q 140 127.62320618864781  280 110.20800868546047T 560 108.23936255400889T 840 103.75855119622277T 1120 149.83363031495023T 1400 118.37993600571689L 1400 0 Z;M0 0L 0 130.40620719619005Q 140 152.32088710046344  280 123.18809395428599T 560 149.15423084336277T 840 112.44789101211072T 1120 112.21093244592052T 1400 108.22888095940141L 1400 0 Z"
          />
        </path>
        <path d="" fill="url(#lg-0.5450171058836231)" opacity="0.4">
          <animate
            attributeName="d"
            dur="10s"
            repeatCount="indefinite"
            keyTimes="0;0.333;0.667;1"
            calcmod="spline"
            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
            begin="-4s"
            values="M0 0L 0 141.4032805001336Q 140 187.23964078276572  280 139.52271602339653T 560 119.307674601033T 840 115.55096824001897T 1120 127.44657928140401T 1400 109.18254782406368L 1400 0 Z;M0 0L 0 122.02528210237821Q 140 140.68595454223208  280 124.81890436939382T 560 107.30888610389715T 840 109.19919252722742T 1120 114.48578116041334T 1400 149.24634246572387L 1400 0 Z;M0 0L 0 109.08520821748776Q 140 188.68342368504796  280 149.46365012708944T 560 149.5832823811205T 840 135.12483598294494T 1120 137.889772784625T 1400 144.4610787105375L 1400 0 Z;M0 0L 0 141.4032805001336Q 140 187.23964078276572  280 139.52271602339653T 560 119.307674601033T 840 115.55096824001897T 1120 127.44657928140401T 1400 109.18254782406368L 1400 0 Z"
          />
        </path>
        <path d="" fill="url(#lg-0.5450171058836231)" opacity="0.4">
          <animate
            attributeName="d"
            dur="10s"
            repeatCount="indefinite"
            keyTimes="0;0.333;0.667;1"
            calcmod="spline"
            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
            begin="-6s"
            values="M0 0L 0 120.88132224348341Q 140 154.61812066489313  280 112.63019633154799T 560 136.00534060559974T 840 137.02555404100067T 1120 119.33809747026885T 1400 105.83868382614044L 1400 0 Z;M0 0L 0 130.8053272071435Q 140 173.8853017678091  280 137.32266040547148T 560 142.64756143706043T 840 146.54898888120948T 1120 124.51004162870531T 1400 132.3480483858758L 1400 0 Z;M0 0L 0 133.73605147696387Q 140 177.79628952145535  280 141.34772807545102T 560 105.97979512288791T 840 137.50064320625506T 1120 147.23028718107744T 1400 107.09156581004174L 1400 0 Z;M0 0L 0 120.88132224348341Q 140 154.61812066489313  280 112.63019633154799T 560 136.00534060559974T 840 137.02555404100067T 1120 119.33809747026885T 1400 105.83868382614044L 1400 0 Z"
          />
        </path>
        <path d="" fill="url(#lg-0.5450171058836231)" opacity="0.4">
          <animate
            attributeName="d"
            dur="10s"
            repeatCount="indefinite"
            keyTimes="0;0.333;0.667;1"
            calcmod="spline"
            keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
            begin="-8s"
            values="M0 0L 0 118.63894749208882Q 140 122.50050553817638  280 102.8414325574296T 560 132.07084267573748T 840 140.88987655996743T 1120 145.62882571985585T 1400 138.0529692061058L 1400 0 Z;M0 0L 0 125.25985277500443Q 140 165.22136312766926  280 119.4930058789854T 560 123.5386354294391T 840 130.7407231866018T 1120 131.54995066933074T 1400 115.20521120910676L 1400 0 Z;M0 0L 0 103.8046088895997Q 140 182.81361883182547  280 141.97332212482144T 560 103.76961982991051T 840 135.91195700478744T 1120 111.05910407978156T 1400 136.37694754661746L 1400 0 Z;M0 0L 0 118.63894749208882Q 140 122.50050553817638  280 102.8414325574296T 560 132.07084267573748T 840 140.88987655996743T 1120 145.62882571985585T 1400 138.0529692061058L 1400 0 Z"
          />
        </path>
      </g>
    </svg>
  );
}

export default Waves;
