// @flow
import React from 'react';
import type { Node } from 'react';
import colors from '../../styles/_colors.scss';

type Props = {
  className: string,
};

function HeroGraphic(props: Props): Node {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1700 700"
    >
      <path
        fill={colors.bg}
        d="M0,776.1c-2.8,0-5-2.2-5-5v-566c0-2.5,1.8-4.6,4.3-4.9c23.6-3.6,47.5-5.4,71.3-5.5c65,0,111.9,15.1,157.3,29.7c44.9,14.5,87.3,28.1,145.9,28.1c31.4,0,64.4-3.9,101-12c9.3-2,18.8-3,28.3-3c38.3,0,77.2,14.6,114.8,28.8c33.5,12.6,65.1,24.6,92.7,24.5c9,0.1,18-1.4,26.4-4.6c20.8-8,41-12.1,60.1-12.1c28.5,0,54.7,8.9,77.9,26.6c21,16,39.1,38.8,53.6,67.7c10.6,21.1,11.8,34.5,12.8,45.2c1.8,19.7,2.6,28.7,74.7,67.7c33.1,18,39.4,45.4,45.5,72c8.2,36.1,15.4,67.3,88.8,73.3c10.7,0.9,19.9,1.4,28.8,1.8c31.9,1.7,54.9,2.9,95,20.3c68.6,29.8,182.8,43.2,265.5,43.2c11.2,0,21.6-0.2,30.9-0.7c69.1-3.7,126.6-21.5,162.6-35.9c2.6-1,5.5,0.2,6.5,2.8c0.2,0.6,0.4,1.2,0.4,1.8v111.1c0,2.8-2.2,5-5,5L0,776.1z"
      />
      <path
        fill={colors.primaryLight}
        d="M70.6,199.7c126.7,0,182.9,57.8,303.2,57.8c29.5,0,62.9-3.5,102.1-12.1c8.9-1.9,18.1-2.9,27.2-2.9c71.5,0,147.8,53.4,207.4,53.4c9.6,0.1,19.2-1.5,28.2-4.9c20.9-8.1,40.3-11.8,58.3-11.8c57.3,0,99.9,37.7,127.1,91.5c31,61.6-21.3,55,89.6,115c74.9,40.6,4.3,135,136.3,145.8c49.5,4.1,72.2,0,122.2,21.7c73,31.7,190.5,43.6,267.5,43.6c11.4,0,21.9-0.3,31.2-0.8c60.8-3.2,118.2-17.9,164.2-36.2v111.2H0v-566C23.4,201.6,47,199.8,70.6,199.7 M70.6,189.7c-24.1,0.1-48.2,1.9-72.1,5.6c-4.9,0.7-8.5,4.9-8.5,9.9v566c0,5.5,4.5,10,10,10h1735.2c5.5,0,10-4.5,10-10V660c0-5.5-4.5-10-10-10c-1.3,0-2.5,0.2-3.7,0.7c-35.7,14.2-92.6,31.9-161,35.5c-9.3,0.5-19.6,0.7-30.7,0.7c-82.2,0-195.5-13.2-263.5-42.7c-40.9-17.8-64.3-19-96.7-20.7c-8.9-0.5-18.1-1-28.7-1.8c-32-2.6-53.5-10.5-65.9-24.2c-10.9-12.1-14.6-28.2-18.5-45.2c-6-26.3-12.9-56.2-48-75.3c-34.5-18.7-54-31.4-63.4-41.1c-7.4-7.7-7.8-12.7-8.7-22.6c-1-10.6-2.3-25.1-13.3-47c-14.9-29.6-33.4-53-55.1-69.5c-24-18.3-51.3-27.6-80.9-27.6c-19.7,0-40.5,4.2-61.9,12.4c-7.9,2.9-16.2,4.4-24.6,4.2c-26.6,0-57.9-11.8-90.9-24.2c-38-14.3-77.3-29.1-116.5-29.1c-9.9,0-19.7,1-29.4,3.1c-36.3,8-68.9,11.9-99.9,11.9c-57.8,0-99.9-13.5-144.4-27.9C183.7,205,136.5,189.8,70.6,189.7L70.6,189.7z"
      />
    </svg>
  );
}

export default HeroGraphic;
